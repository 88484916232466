import ResizeObserverPolyfill from 'resize-observer-polyfill'


// Using resizeobserverpolyfill to help Jest testing and compatibility with some browsers
// https://github.com/ZeeCoder/use-resize-observer/issues/40
// https://github.com/euvl/vue-js-modal/blob/master/src/utils/resizeObserver.js
const observer =
  typeof window !== 'undefined' && window.ResizeObserver
    ? ResizeObserver
    : ResizeObserverPolyfill

export default observer;