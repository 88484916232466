export default (to, name) => {
    // check for and format middleware meta tag
    let middleware = to.meta?.middleware;

    if (typeof middleware !== "string" && !Array.isArray(middleware)) {
        return false;
    }

    if (typeof middleware === "string") {
        middleware = [middleware];
    }

    return middleware.includes(name);
};
