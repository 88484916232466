export default {
    state() {
        return {
            post_priority_loading: false,
        };
    },

    mutations: {
        setPostPriorityLoading(state, post_priority_loading) {
            state.post_priority_loading = post_priority_loading;
        },
    },

    actions: {
        async postApplicationPriority(
            { commit, dispatch },
            { alias, data_priority }
        ) {
            commit("setPostPriorityLoading", true);
            await this.$http.post(`funding/application/${alias}/priority`, {
                data_priority_id: data_priority.id,
            });
            commit("setPostPriorityLoading", false);
            dispatch(
                "applications/setCurrentApplicationPriority",
                data_priority,
                { root: true }
            );

            return;
        },
    },
    getters: {
        application_priorities(_, __, rootState) {
            return rootState.applicationPriorities ?? {};
        },
        post_priority_loading(state) {
            return state.post_priority_loading;
        },
    },
};
