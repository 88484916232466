<template>
    <router-link :to="{ name: section.route, params: { alias: application.alias } }">
        <base-button
            class="status-button py-2 px-2 border  outline outline-2 flex items-center h-full justify-center relative w-full bg-white/50"
            :class="computedClasses"
        >
            <div>

                <div
                    class="absolute w-4 h-4 -top-2 -left-2 pt-px rounded-full bg-white flex items-center justify-center"
                >
                    <status-icon :status="section.statusId" />
                </div>
                <div class="text-sm font-medium">{{ section.title }}</div>
            </div>
        </base-button>
    </router-link>
</template>
<script>

export default {
    props: {
        application: {
            type: Object,
            required: true,
        },
        section: {
            type: Object,
            required: true,
        },
    },

    computed: {
        statusObject(){
            return this.$store.getters.statuses[this.section.statusId] ?? { name: 'NOT_STARTED' }
        },
        computedClasses(){
            switch(this.statusObject.name){
                case "COMPLETED": return "outline-status_COMPLETED-200/10";
                case "IN_PROGRESS": return "outline-status_IN_PROGRESS-200/10";
                case "UNDER_REVIEW": return "outline-status_UNDER_REVIEW-200/10";
                case "REJECTED": return "outline-status_REJECTED-200/10";
                default: return "outline-status_NOT_STARTED-200/10";
            };
        },
    }
};
</script>

<style scoped>
</style>
