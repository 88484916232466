<template>
    <!-- wrapper -->
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="form-element text-left w-full grid min-w-full text-lg"
            v-bind="field.wrapperAttributes"
            :class="field.wrapperClass"
        >
            <!-- label -->
            <form-label :field="field" :required="isRequired"></form-label>

            <!-- teleport target -->
            <form-teleport :teleport="field.teleport"></form-teleport>

            <!-- input -->
            <div class="flex flex-wrap items-center relative order-3 text-lg">
                <input
                    @blur="validateAndClearAutocomplete"
                    @input="handleInput"
                    @keydown.enter.prevent.stop="onEnter"
                    @keydown.down.prevent.stop="$refs.autocomplete?.next"
                    @keydown.up.prevent.stop="$refs.autocomplete?.back"
                    @keyup="handleAutocomplete"
                    @focus="clearErrorsAndAutocomplete"
                    class="form-element__input mt-1 block w-full p-2 rounded-md border border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 order-3"
                    v-bind="computedAttributes"
                    v-bind:[dataName]="`${field.name}--input`"
                    v-focus="field.focused ?? null"
                />
            </div>

            <!-- autofill -->
            <autocomplete-container 
                :settings="settings"
                @selected="onAutocompleteSelected"
                ref="autocomplete"
                v-bind:[dataName]="`${field.name}--autocomplete`"
            /> 


            <!-- errors -->
            <form-error :error="errorText" :field="field" class="order-5"></form-error>
        </div>
    </transition>
</template>

<script>
import fieldMixin from "./mixins/fieldMixin";
import AutocompleteContainer from "./AutocompleteContainer.vue";
import isObject from "@/services/utils/isObject.js";
import stateAbbreviations from "@/services/forms/StateAbbreviations.js";

export default {
    mixins: [fieldMixin],
    components: {
        AutocompleteContainer,
    },
    data() {
        return {
            settings: {
                endpoint: "address/autocomplete",
                column: "street_line",
                displayTransform: this.mergeAddressProps, 
                returnObject: true,
                minCharacters: 5,
                filterResultsUnder: 10,
            },
            defaultFieldMap: {
                street_line: "address_1",
                secondary: "address_2",
                city: "city",
                state: "state",
                zipcode: "zip",
            },
        };
    },

    computed: {
        computedAttributes() {
            let attributes = {
                // set our type using the type computed property
                type: this.field.type,

                // set min using the min computed property
                min: this.field.min,

                // set our name using the name provided by our field schema
                name: this.field.name,

                // set our disabled status using the isDisabled computed mixin
                disabled: this.isDisabled,

                // set our classes using our computed classes mixin
                class: this.computedClasses,

                // set our model value from our modelValue prop
                value: this.modelValue,
            };

            attributes.list = "autocomplete";
            attributes.autocomplete = "off";

            // merge in any attributes from our field.attributes property
            if (isObject(this.field.attributes)) {
                attributes = { ...attributes, ...this.field.attributes };
            }

            return attributes;
        },

        fieldMap(){
            return this.field.fieldMap ?? this.defaultFieldMap;
        },
    },

    methods: {
        mergeAddressProps(suggestion){
            let results = suggestion.street_line;

            if(suggestion.secondary){
                results += ` ${suggestion.secondary}`;
            }

            results += `. ${suggestion.city}, ${suggestion.state} ${suggestion.zipcode}`;

            return results;
        },

        handleAutocomplete(event) {
            this.$refs.autocomplete.debounceInput(event);
        },

        clearErrorsAndAutocomplete() {
            this.clearErrors();
            this.$refs.autocomplete?.clear();
        },

        onAutocompleteSelected(suggestion) {
            // if we are passed a null value do nothing
            if(suggestion === null){
                return;
            }

            Object.entries(this.fieldMap).forEach(([suggestionProp, formField]) => {
                let value = suggestion[suggestionProp];

                if(suggestionProp === "state"){
                    value = stateAbbreviations.getName(value);
                }

                this.updateValue(formField, value);
            });
        },

        validateAndClearAutocomplete() {
            this.validate();
            this.$refs.autocomplete?.abort();
            this.$refs.autocomplete?.clear();
        }
    }
};
</script>

<style scoped>
</style>
