<template>
    <div v-if="teleport" v-bind="attributes"></div>
</template>

<script>
import isObject from "@/services/utils/isObject.js";

export default {
    props: ["teleport"],
    computed: {
        attributes() {
            // if we passed in an object as our teleport prop return it as our attributes
            if (isObject(this.teleport)) {
                return this.teleport;
            }

            // if we are passed a string use that string as the element ID
            if (typeof this.teleport === "string") {
                return {
                    id: this.teleport,
                    class: "order-2",
                };
            }

            // otherwise return null
            return null;
        },
    },
};
</script>
