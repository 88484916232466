<template>
    <div v-if="!application.deleted_at && isAdmin" class="my-2">

        <!-- verified address -->
        <success-alert 
            v-if="address?.verified" 
            class="w-full text-sm !py-1 !px-2"
            icon="circle-check"
        >
            Address has been verified
        </success-alert>

        <!-- address failed verification -->
        <error-alert 
            v-if="address?.verified === 0" 
            class="w-full text-sm !py-1 !px-2"
            icon="circle-xmark"
        >
            Address failed validation
        </error-alert>

        <!-- unchecked / ambiguous address -->
        <warning-alert 
            v-if="address?.verified === null"
            class="w-full text-sm !py-1 !px-2"
            icon="circle-question"
        >
            <div class="flex items-center justify-between">
                <span>Unverified address</span>

                <!-- validate button -->
                <primary-button v-if="addressIsSet" size="xsmall" @click="validateAddress">Verify</primary-button>
            </div>

        </warning-alert>
        
        <!-- candidate select modal -->
        <base-modal
            :open="results?.candidates?.length"
            title="Review Address Candidates"
            icon="house-circle-exclamation"
        >
            <!-- candidates -->
            <div class="flex flex-wrap gap-4 p-4">

                <!-- original address -->
                <review-section
                    title="Current Address"
                    :items="originalItems"
                >
                    <div class="flex justify-center mt-4">
                        <secondary-button size="xsmall" @click="results = null">
                            keep current address
                        </secondary-button>
                    </div>
                </review-section>

                <!-- candidates -->
                <validation-candidate
                    v-for="(candidate, index) in results?.candidates" 
                    :key="index"
                    :index="index"
                    :candidate="candidate"
                    @selected="saveAddress"
                />
            </div>
        </base-modal>
    </div>
</template>

<script>
import ValidationCandidate from './ValidationCandidate.vue';

export default {
    components: {
        ValidationCandidate,
    },

    props: {
        address: {
            type: Object,
            default: null,
        },
    },

    data(){
        return {
            results: null,
        };
    },
    
    computed: { 
        application(){
            return this.$store.getters["applications/current"] ?? {};
        },
        
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        addressIsSet(){
            if(!this.address.address_1){
                return false;
            }

            if(this.address.zip){
                return true;
            }

            return this.address.city && this.address.state;
        },

        originalItems(){
            return [
                {
                    name: "address_1",
                    label: "Address",
                    value: this.address.address_1,
                },
                {
                    name: "address_2",
                    label: "Address 2",
                    value: this.address.address_2,
                },
                {
                    name: "city",
                    value: this.address.city,
                },
                {
                    name: "state",
                    value: this.address.state,
                },
                {
                    name: "zip",
                    value: this.address.zip,
                },
            ];
        },
    },

    methods: {
        validateAddress() {
            this.$http.post(`/address/${this.address?.id}/verify`)
                .then(response => this.handleResponse(response));
        },

        saveAddress(candidate) {
              this.$http.patch(`/address/${this.address?.id}/save`, candidate)
                .then(response => this.handleResponse(response));
        },

        handleResponse(response){
            this.results = response.data;
            this.alertHidden = false;

            // if we received back an updated application, update the store
            if(this.results.application){
                this.$store.dispatch('applications/setCurrentApplication', { application: this.results.application });
            }
        },
    },
};
</script>
<style scoped>
    .review-section__title {
        font-size: .9em;
    }
</style>

