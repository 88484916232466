<template>
   <base-modal 
        :open="showModal"
        @close="hideModal"
        titleRole="warning"
        title="You Have Pending Invites"
        icon="handshake"
        class="md:w-xs"
    >
        <div class="pb-8 pt-2 px-4">
            You have been invited to contribute to one or more applications. Click the 
            <span class="font-bold">manage invitations</span> button below to accept or 
            decline these invitations.    
        </div>  

        <div class="flex gap-3 justify-end">
            <secondary-button @click="hideModal">close</secondary-button>
            <primary-button @click="navigateToInvites">manage invitations</primary-button>
        </div>
        
   </base-modal>
</template>

<script>
export default {
    data(){
        return {
            hide: false,
        }
    },
    methods: {
        navigateToInvites(){
            this.hideModal();
            this.$router.push({ name: "invites" });
        },

        hideModal(){
            this.$store.dispatch("invites/setHideModal");
        }
    },
    computed: {
        showModal(){
            return this.$store.getters['invites/count'] && !this.$store.getters['invites/hideModal'];
        },
    },
};
</script>

<style scoped>

</style>
