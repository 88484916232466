<template>
    <router-link
        class="mx-2 md:mx-5 hover:text-primary-500 transition-all flex items-center gap-2 whitespace-nowrap"
        active-class="text-primary-500"
    >
        <i
            v-if="icon"
            class="fad opacity-75"
            :class="`fa-${icon}`"
            aria-hidden="true"
        ></i>

        <span v-if="text" class="hidden lg:inline">{{ text }}</span>

        <div
            v-if="count"
            class="bg-red-500 rounded-full w-4 h-4 text-xs flex items-center justify-center"
        >
            <span class="text-white font-bold">{{ count }}</span>
        </div>

        <slot></slot>
    </router-link>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
        },
        icon: {
            type: String,
        },
        count: {
            type: Number,
            default: 0,
        },
    },
};
</script>
