<template>            
    <sidebar-section
        title="resources"
        icon="paperclip-vertical"
        :showAddItem="false"
    >
        <resource-link v-for="link in links" :key="link.title" :link="link" />
    </sidebar-section>
</template>

<script>
import ResourceLink from "./ResourceLink.vue";

export default {
    components: {
        ResourceLink,
    },

    computed: {
        links(){
            return [
                {title: "State Documents", href: process.env.VUE_APP_LANDING_SITE_URL+"forms#state_docs" },
                {title: "Medicare Documents", href: process.env.VUE_APP_LANDING_SITE_URL+"medicare-documents" },
                {title: "My AAC Consultant", href: "https://www.prc-saltillo.com/consultants" },
                {title: "Evaluator Loan Program", href: process.env.VUE_APP_LANDING_SITE_URL+"evaluation-loan" },
            ];
        },
    }
};
</script>

<style scoped></style>