<template>
   <DropdownSelector
        v-bind="$attrs"
        :selection="selection"
        :name="name"
        :setTheme="setTheme"
        :hideSearch="true"
        :hideSelectButtons="true"
        :count="count"
    >                            
        <template #additional-controls>
            <div class="flex gap-1.5 text-xxs flex-wrap px-4 pt-4 border-b pb-2">
                <span class="w-full text-gray-700">SUBMITTED IN THE LAST:</span>
                <button 
                    v-for="preset in presets"
                    :key="preset.text"
                    class="rounded py-1 px-2 transition-all hover:bg-secondary-100 bg-gray-100"
                    @click="getRelativeDate(preset.unit, preset.value)"
                >
                    {{ preset.text }}
                </button>
            </div>
        </template>

        <template #custom-content>
            <div class="grid p-2.5 gap-2.5">
                <div>
                    <div class="text-xxs font-medium">FROM DATE</div>
                    <input
                        datepicker
                        type="date"
                        :value="start"
                        @input="$event => debounceInput('start', $event.target.value)"
                        class="bg-gray-50 text-gray-600 rounded sm:text-sm p-2.5 w-full"
                        placeholder="Select date"
                        :max="maxDate"
                    />
                </div>
                <div>
                    <div class="text-xxs font-medium">TO DATE <span class="text-xxs font-normal ml-1">(leave blank to set to today)</span></div>
                    <input
                        datepicker
                        type="date"
                        :value="end"
                        @input="$event => debounceInput('end', $event.target.value)"
                        class="bg-gray-50 text-gray-600 rounded sm:text-sm p-2.5 w-full"
                        placeholder="Select date"
                        :max="maxDate"
                    />
                </div>
            </div>
        </template>
    </DropdownSelector>
</template>
<script>
import DropdownSelector from "./DropdownSelector";
import dateService from "@/services/utils/dateFormatterService";

export default {
    components: {
        DropdownSelector,
    },
    data() {
        return {
            presets: [
                { text: "day", value: 1, unit: "day" },
                { text: "week", value: 1, unit: "week" },
                { text: "2 weeks", value: 2, unit: "week" },
                { text: "month", value: 1, unit: "month" },
                { text: "3 months", value: 3, unit: "month" },
                { text: "6 months", value: 6, unit: "month" },
                { text: "9 months", value: 9, unit: "month" },
                { text: "year", value: 1, unit: "year" },
            ],
            maxDate: dateService.formatForBackend(new Date()),
            debounceInterval: {
                start: null,
                end: null,
            },
            debounceTime: 1500,
            initial: {
                start: null,
                end: null,
            },
        };
    },
    
    emits: ['update'],

    props: {
        selection: {
            default: [],
        },
        setTheme: {
            type: String,
            default: 'default',
        },
        name: {
            type: String,
            default: 'SUBMITTED IN',
        },
        start: {
            type: String,
        },
        end: {
            type: String,
        },
    },

    created(){
        this.initial.start = this.start;
        this.initial.end = this.end;
    },

    computed: {
        count(){
            let count = 0;

            if(this.start){
                count++;
            } 
            
            if(this.end){
                count++;
            }

            return count;
        },

        computedClasses(){
            return "hover:bg-secondary-100 bg-gray-100";
        },
    },

    methods: {
        debounceInput(name, value){
            let updatedValues = { start: this.start, end: this.end };
            updatedValues[name] = value;

            if(this.debounceInterval[name]) {
                clearTimeout(this.debounceInterval[name]);
            }

            this.debounceInterval[name] = setTimeout(() => {
                this.$emit(`update`, updatedValues);
            }, this.debounceTime);
        },

        getRelativeDate(unit, value){
            let date = dateService.pastDate(unit, value, "YYYY-MM-DD");
            let updatedValues = { start: date, end: null };
            this.$emit(`update`, updatedValues);
        },
    },
};
</script>
<style scoped>
</style>
