
const initialParams = {
    orderBy: "ModifyDate_desc",
    date_start: null,
    date_end: null,
    state_filter: [],
    category_filter: null,
};

export default {

    namespaced: true,

    state() {
        return {
            params: { ...initialParams },
            auditLogs: [],
            categories: [
                {
                    value: 'clientInfo',
                    text: 'Client Info'
                },
                {
                    value: 'evaluationInfo',
                    text: 'Evaluation (legacy)'
                },
                {
                    value: 'evaluationV2Info',
                    text: 'Evaluation'
                },
                {
                    value: 'insuranceInfo',
                    text: 'Insurance'
                },
                {
                    value: 'cmnInfo',
                    text: 'CMN'
                },
                {
                    value: 'selectedDevice',
                    text: 'Equipment Selection',
                },
                {
                    value: 'tasks',
                    text: 'Tasks',
                },
                {
                    value: 'notes',
                    text: 'Notes',
                },
                {
                    value: 'repairEvaluationInfo',
                    text: 'Repair Evaluation',
                },
                {
                    value: 'slpAddendumInfo',
                    text: 'SLP Addendum Info',
                },
                {
                    value: 'uploads',
                    text: 'Uploads',
                },
                {
                    value: 'conversions',
                    text: 'Conversions',
                },
                {
                    value: 'submissions',
                    text: 'Submissions',
                },
                {
                    value: 'systemIntegration',
                    text: 'System Integration',
                },
                {
                    value: 'shares',
                    text: 'Share',
                },
                {
                    value: 'insuranceSubmission',
                    text: 'Insurance Submission',
                },
                



                
            ],
            pages: 1,
            per_page: 25,
            page: 1,
            total: 0,

        }
    },
    mutations: {
        clearFilters(state) {
            state.params = { ...initialParams };
        },
        setAuditLogs(state, auditLogs) {
            state.auditLogs = auditLogs;
        },
        setTotal(state, total) {
            state.total = total;
        },
        setPages(state, pages) {
            state.pages = pages;
        },
        setParam(state, { param, value }) {
            state.params[param] = value;
        },
        setPage(state, page) {
            state.page = page;
        },
        setPerPage(state, per_page) {
            state.per_page = per_page;
            state.page = 1;
        },
    },
    actions: {
        setParam({ commit }, data){
            commit("setParam", data);
        },
        clearFilters({ commit }) {
            commit("clearFilters");
        },
        async loadAll({ commit, state, getters}){
            console.log("AuditLogs load all fired");

            let url = `funding/application/${getters.application.alias}/audit-logs`;

            let query = {};

            if (state.page) {
                query.page = state.page;
            }
            if (state.per_page) {
                query.per_page = state.per_page;
            }
            if (state.params.date_start) {
                query.date_start = state.params.date_start;
            }
            if (state.params.date_end) {
                query.date_end = state.params.date_end;
            }
            if (state.params.orderBy) {
                query.sort = state.params.orderBy;
            }
            if (state.params.category_filter) {
                query.category = state.params.category_filter;
            }
            const auditLogs = await this.$http.get(url, { params: query, successNotification: false });
            commit("setAuditLogs", auditLogs.data.data);
            commit("setTotal", auditLogs.data.total);
            commit("setPages", auditLogs.data.last_page);
        },
    },
    getters: {
        application(_state, _getters, rootState) {
            return rootState.applications.current;
        },
        params(state) {
            return state.params;
        },
        auditLogs(state) {
            return state.auditLogs;
        },
        categories(state) {
            return state.categories;
        },
        total(state) {
            return state.total;
        },
        page(state) {
            return state.page;
        },
        pages(state) {
            return state.pages;
        },
        per_page(state) {
            return state.per_page;
        },
    },
};