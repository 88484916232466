// used to display our "awaiting ajax request" spinner with each request we make
// can be turned off by including spinner : false in our request config
export default (http, { store }) => {
    // for each request that is made, increment our spinner request queue
    http.interceptors.request.use((request) => {
        if (request.spinner !== false && !request.silent) {
            store.dispatch("spinner/increment");
        }
        return request;
    }, (error) => {
        if (error.config?.spinner !== false && !error.config?.silent) {
            store.dispatch("spinner/decrement");
        }
        return Promise.reject(error);
      }
    );

    // for each successful response decrement our spinner request queue (if needed)
    const responseCallback = (response) => {
        if (response?.config?.spinner !== false && !response?.silent) {
            store.dispatch("spinner/decrement");
        }
        return response;
    };

    // for each failed response decrement our spinner request queue (if needed)
    const errorCallback = (error) => {
        if (error.config?.spinner !== false && !error.config?.silent) {
            store.dispatch("spinner/decrement");
        }
        return Promise.reject(error);
    };

    // apply response interceptors
    http.interceptors.response.use(responseCallback, errorCallback);
};
