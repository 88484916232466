<template>
    <base-button
        class="bg-gradient-to-b leading-tight tracking-widest capitalize"
        :class="classes"
        :disabled="disabled"
        :icon="icon"
    >
        <slot></slot>
    </base-button>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
        },
        icon: {
            type: String,
        },
        size: {
            type: String,
        },
        role: {
            type: String,
        },
    },

    computed: {
        classes() {
            let classes = [];

            if (this.size?.includes("xsmall")) {
                classes.push("text-xs px-3 py-[3px]");
            } else if (this.size?.includes("small")) {
                classes.push("text-sm px-3 py-1");
            } else if (this.size?.includes("large")) {
                classes.push("text-lg px-8 py-4");
            } else {
                classes.push("px-6 py-2.5");
            }

            if (this.role?.includes("danger")) {
                classes.push("text-white bg-red-600 border border-red-600 hover:bg-red-700");
            } else if (this.role?.includes("info")) {
                classes.push("text-white bg-blue-600 border border-blue-600 hover:bg-blue-700");
            } else if (this.role?.includes("neutral")) {
                classes.push("text-white bg-gray-500 border border-gray-500 hover:bg-gray-600");
            } else {
                classes.push(
                    "!text-gray-800 from-primary-500 to-primary-600 hover:to-primary-500 border border-primary-500 hover:from-primary-400"
                );
            }

            return classes.join(" ");
        },
    },
};
</script>
