<template>
    <div>
        <!-- button -->
        <component 
            :is="buttonComponent"
            :role="buttonRole"
            :class="buttonClass"
            @click="submitRequest"
            :disabled="isLoading"
        >
            {{ buttonText }}
        </component>

        <!-- response -->
        <component
            v-if="response"
            :is="alertType"
            class="relative text-left mt-2"
        >
            <!-- close button -->
            <i
                class="fas fa-times cursor-pointer absolute top-2 right-2"
                @click="response = null"
            ></i>

            {{ responseText }}
        </component>
    </div>
</template>

<script>
export default {
    props: {
        endpoint: {
            type: String,
            required: true,
        },
        method: {
            type: String,
            default: "get",
        },
        buttonText: {
            type: String,
            default: "Send Request",
        },
        config: {
            type: Object,
            default: () => {},
        },
        data: {
            type: Object,
            default: () => {},
        },
        successText: {
            default: "Request Successful",
        },
        errorText: {
            type: String,
            default: "Something went wrong with this request"
        },
        buttonComponent: {
            type: String,
            default: "primary-button",
        },
        buttonRole: {
            type: String,
        },
        buttonClass: {
            type: String,
        }
    },

    data() {
        return {
            response: null,
            isLoading: false,
        };
    },

    computed: {
        responseText(){
            if(this.response?.status === "error"){
                return this.errorText;
            }

            if(typeof this.successText === 'function'){
                return this.successText(this.response.data);
            }

            return this.successText;
        },

        alertType(){
            return this.response?.status === "error" ? "error-alert" : "success-alert";
        },
    },

    methods: {
        submitRequest() {
            this.response = null;
            this.isLoading = true;

            if (this.method === "get") {
                this.$http
                    .get(this.endpoint, this.config)
                    .then((response) => this.onSuccess(response))
                    .catch((errors) => this.onError(errors));

                return;
            }

            this.$http[this.method](
                this.endpoint,
                this.data,
                this.config
            ).then((response) => this.onSuccess(response))
             .catch((errors) => this.onError(errors));
        },

        onSuccess(response) {
            this.isLoading = false;

            this.response = {
                status: "success",
                data: response?.data,
            };
        },

        onError(errors) {
            this.isLoading = false;

            this.response = {
                status: "error",
                data: errors?.response?.data,
            };
        },
    },
};
</script>
