// manages our notifications state
export default {
    namespaced: true,

    state() {
        return {
            timeout: 3,
            errorTimeout: 5,
            queue: [],
        };
    },

    mutations: {
        // add new note to front of queue
        add(state, note) {
            state.queue.unshift(note);
        },

        // remove the given note from the queue
        remove(state, note) {
            state.queue = state.queue.filter((item) => item.id !== note.id);
        },
    },

    actions: {
        /**
         * A note
         * @typedef {Object} Note
         * @property {string} type - The type of the note success|error|info
         * @property {boolean} persist - Should this note persist until clicked?
         * @property {string} message - An optional message to include in the note
         */

        /**
         * Create a notification item
         *
         * @param {Context} context
         * @param {Note} note
         */
        create({ dispatch, state, commit }, note = { type: "success" }) {
            note.type = note.type ?? "success";

            // generate an id from a timestamp
            note.id = new Date().getTime();

            /*
            // persist errors until clicked
            if (note.type === "error" && note.persist !== false) {
                note.persist = true;
            }
            */

            // add our note
            commit("add", note);

            // if our not isn't set to persist, remove it in {state.timeout} seconds
            if (!note.persist) {
                const timeout = note.type === 'error' ? state.errorTimeout : state.timeout;
                setTimeout(
                    () => dispatch("remove", note),
                    timeout * 1000
                );
            }
        },

        /**
         * Remove the given note from the queue
         *
         * @param {Context} context
         * @param {Note}
         */
        remove(context, note) {
            context.commit("remove", note);
        },

        /**
         * Clear out old notifications
         *
         * @param {Context} context
         * @oaram {int} seconds
         */
        clear(context, seconds = 0) {
            const clearTime = new Date().getTime() - seconds * 1000;

            for (const note of context.state.queue) {
                if (note.id < clearTime && note.persist != 'superPersist') {
                    context.commit("remove", note);
                }
            }
        },
    },

    getters: {
        queue(state) {
            return state.queue;
        },
    },
};
