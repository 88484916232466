<template>
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="text-left grid form-element"
            v-bind="field.wrapperAttributes"
            :class="field.wrapperClass"
        >
            <!-- label -->
            <form-label :field="field" :required="isRequired"></form-label>

            <!-- teleport target -->
            <form-teleport :teleport="field.teleport"></form-teleport>

            <div class="relative order-3 mt-1 w-full overflow-auto form-element__input text-lg rounded-md border border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 bg-white"
                :class="computedClasses"
            >
                <!-- input -->
                <ckeditor 
                    :editor="editor" 
                    :config="editorConfig"
                    :disabled="isDisabled"
                    :modelValue="modelValue"
                    @update:modelValue="handleEditorInput"
                    ref="editor"
                    v-bind:[dataName]="`${field.name}--editor`"
                    @blur="formatThenValidate"
                    @focus="clearErrors"
                />
            </div>

            <!-- errors -->
            <form-error :error="errorText" :field="field" class="order-5"></form-error>
        </div>
    </transition>
</template>

<script>
import fieldMixin from "./mixins/fieldMixin";

// official CKEditor plugins
import CKEditor from '@ckeditor/ckeditor5-vue';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Bold, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { Link, AutoLink } from '@ckeditor/ckeditor5-link';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Alignment } from '@ckeditor/ckeditor5-alignment';
import { Heading } from '@ckeditor/ckeditor5-heading';
import { List } from '@ckeditor/ckeditor5-list';
import { Indent, IndentBlock } from '@ckeditor/ckeditor5-indent';
import { PasteFromOffice } from '@ckeditor/ckeditor5-paste-from-office';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { SelectAll } from '@ckeditor/ckeditor5-select-all';
import { Table, TableToolbar } from '@ckeditor/ckeditor5-table';
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';

// custom CKEditor plugins
import { TabIndent, ShiftTabOutdent } from './richTextPlugins/tabIndent.js';

export default {
    mixins: [fieldMixin],
    components: {
        ckeditor: CKEditor.component,
    },
    data() {
        return {
            editor: ClassicEditor,
            baseEditorConfig: {
                plugins: [
                    Essentials,
                    PasteFromOffice,
                    Bold,
                    Italic,
                    Underline,
                    Paragraph,
                    Heading,
                    Alignment,
                    List,
                    Indent,
                    IndentBlock,
                    BlockQuote,
                    SelectAll,
                    Table, 
                    TableToolbar,
                    HorizontalLine,
                    GeneralHtmlSupport,
                    TabIndent, 
                    ShiftTabOutdent,
                ],
                toolbar: {
                    items: [
                        '|', 'heading', 
                        '|', 'bold', 'italic', 'underline', 
                        '|', 'bulletedList', 'numberedList', 
                        '|', 'outdent', 'indent', 
                        '|', 'alignment', 
                        '|', 'blockQuote', 
                        '|', 'selectAll', 'horizontalLine'
                    ],
                },
                indentBlock: {
                    offset: 1,
                    unit: 'em'
                },
                ui: {
                    poweredBy: {
                        position: 'inside',
                        side: 'right',
                        label: 'Powered by',
                    }
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /^(blockquote|hr|pre|div|p|br|h1|h2|h3|h4|h5|h6|strong|em|ol|ul|li|u|s|i|b|table|td|tr|tbody|thead|th|span|dl|dd|dt|menu|big|small|abbr|caption|cite|center)$/,
                            attributes: {
                                'height': true,
                                'width': true,
                                'bgcolor': true,
                                'border': true,
                            },
                            classes: true,
                            styles: true
                        },
                    ],
                },
            },
        };
    },


    methods: {
        handleEditorInput(content){
            this.clearErrors();
            this.updateValue(this.field.name, content);
        },

        formatThenValidate(){
            // remove all tags from the content to double check there is still any actual text included
            let contentWithoutTags = this.modelValue.replace(/<[^>]*>/g, '');  
            contentWithoutTags = contentWithoutTags.trim();

            // if our field has no content except tags, strip the tags as well before validation so
            // things like "required" validation don't think there is any real content here
            if(!contentWithoutTags){
                this.updateValue(this.field.name, "");
            }

            this.validate();
        },
        
        addLinksToConfig(config){
            // add Link and AutoLink plugins
            config.plugins = [...config.plugins, Link, AutoLink];
                
            // add link toolbar button
            config.toolbar.items = [...config.toolbar.items, '|', 'link'];
            
            // add link support to htmlSupport
            config.htmlSupport.allow.push({
                name: 'a',
                attributes: {
                    'target': true,
                    'rel': true,
                },
                classes: true,
                styles: true
            });

            return config;
        },
    },

    computed: {
        editorConfig(){
            let config = { ...this.baseEditorConfig };

            // modify config to allow links
            if(Array.isArray(this.field.toolbar)){
                config.toolbar.items = this.field.toolbar;
            }

            // modify config to allow links
            if(this.field.allowLinks){
                config = this.addLinksToConfig(config);
            }

            return config;
        },

        computedClasses(){
            let classes = [];

            if(this.isDisabled){
                classes.push('pointer-events-none');
            }

            if (this.showRequiredHighlight && !this.isDisabled ) {
                classes.push("form-element__input--required-highlight");
            }
            
            return classes;
        },

        editorOptions(){
            const fieldOptions = this.field.options ?? {};
            return {...this.options, ...fieldOptions};
        },
    },
};
</script>

<style>
.ck-editor__main .ck.ck-content {
    padding: .75rem 1.25rem;
}

.ck-content a {
    color: #4F46E5;
    text-decoration: underline;
}

.ck-editor__editable_inline {
    min-height: 150px;
}

.ck-content ul, .ck-content ol {
    padding: 0 0 0 20px;
}

:root {
    --ck-powered-by-line-height: 10px;
    --ck-powered-by-padding-vertical: 2px;
    --ck-powered-by-padding-horizontal: 4px;
    --ck-powered-by-text-color: hsl(0, 0%, 47%);
    --ck-powered-by-border-radius: 100px;
    --ck-powered-by-background: hsl(0, 0%, 100%);
}

.ck.ck-content hr {
    height: 1px;
}

/** 
 * The following two rules turns off sticky toolbar if we want to do that
 *
.ck.ck-sticky-panel__placeholder {
  display : none !important;
}

.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
  position: unset;
}
*/
</style>
