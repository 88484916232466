import store from "@/store/index";
import rules from "./FormValidationRules";

/**
 *
 * The validator class handles validating fields and passing any errors on to the Error class
 *
 */
export default class Validator {
    /**
     * @param {object} schema the Schema used to build the form
     * @param {object} data an object containing name => value pairs for each field in the form
     * @param {Errors} errors the form's Errors class
     */
    constructor(schema, data, errors) {
        this.schema = schema;
        this.data = data;
        this.errors = errors;
        this.rules = rules;
    }

    /**
     * Validate all of our fields
     */
    all() {
        this.errors.clearAll();
        this.validateGroup(this.schema.fields);
    }

    /**
     * Validate a group of fields
     *
     * @param {array} group a group of fields
     */
    validateGroup(group) {
        for (const field of group) {
            // recursively call this method if we have a group
            if (field.type === "group") {
                if (!field.shouldFilter) {
                    this.validateGroup(field.fields);
                }
                continue;
            }

            // otherwise validate this field
            this.validateField(field.name, true);
        }
    }

    /**
     * Validate a given field
     *
     * @param {string} name
     * @param {boolean} validatingAll
     * @returns null
     */
    validateField(name, validatingAll) {
        // find our matching field
        const field = this.findField(this.schema.fields, name);

        if (!field) {
            return console.error("Validation Error: Could not find field in schema");
        }

        const validateRules = this.getValidationRules(field);

        // if we don't need to check this field, abort
        if (!this.shouldCheckField(field, validateRules, validatingAll)) {
            return;
        }

        // test our rules
        for (const rule of validateRules) {
            try {
                this.testValidateRule(rule, field);
            } catch (error) {
                console.error(error);
            }
        }
    }

    /**
     *
     */
    shouldCheckField(field, validateRules, validatingAll) {
        // if this field has no validation rules, abort
        if (!validateRules.length) {
            return false;
        }

        const hasRequireRule = validateRules.find(
            (rule) => typeof rule === "string" && rule.startsWith("require")
        );

        // if this field has a required type validation we need to check it
        // but only is the whole form is being submitted
        if (validatingAll && hasRequireRule) {
            return true;
        }

        // otherwise we only need to check the field if it has content
        return `${this.data[field.name]}`.length ? true : false;
    }

    /**
     * Search our schema fields for a given field
     *
     * @param {array} fields a collection of fields
     * @param {string} name the name of the field we are searching for
     * @returns {object|null}
     */
    findField(fields, name) {
        let result = null;

        for (let item of fields) {
            if (item?.name === name) {
                result = item;
            } else if (Array.isArray(item?.fields)) {
                result = this.findField(item?.fields, name);
            }

            if (result) return result;
        }

        return result;
    }

    /**
     * Return an array from our validation rules after applying any needed modifications
     *
     * @param {object} field
     */
    getValidationRules(field) {
        let array = [];

        if (Array.isArray(field.validate)) {
            array = field.validate;
        }

        if (field.required && !array.includes("required")) {
            array.push("required");
        }

        if (field.type === 'textarea' || field.type === 'richtext') {
            array.push("maxText");
        }

        if (field.type === 'counts' && Number.isInteger(field.maxCount)) {
            array.push("maxCount");
        }

        return array;
    }

    /**
     * Test a given rule and apply any errors
     *
     * @param {string|function} rule
     * @param {object} field
     * @returns
     */
    testValidateRule(rule, field) {
        let value = this.data[field.name];

        // trim strings to match the Laravel middleware
        if (typeof value === "string") {
            value = value.trim();
        }

        const context = {
            value: value,
            fieldSchema: field,
            formSchema: this.schema,
            formValues: this.data,
            store: store,
        };

        const result = this.getRuleResults(rule, context);

        // if our results are true, we are good here
        if (result === true) {
            return;
        }

        // if we got an error string then apply it to our errors object
        if (typeof result === "string") {
            return this.errors.set(field.name, result);
        }

        return console.error("Validation result is not valid");
    }

    /**
     * Get the results of our rule test
     *
     * @param {string|function} rule
     * @param {object} context
     * @returns {true|string}
     */
    getRuleResults(rule, context) {
        // if we got passed a string, lets check our rules for a matching function
        if (typeof rule === "string") {
            const ruleParams = this.getRuleParams(rule);

            if (this.rules[ruleParams.name] instanceof Function) {
                return this.rules[ruleParams.name](
                    context,
                    ...ruleParams.params
                );
            } else {
                return console.error(
                    `Validation rule with the name ${ruleParams.name} doesn't exist`
                );
            }
        }

        // if we got passed a function lets test it directly
        if (rule instanceof Function) {
            return rule(context);
        }

        return console.error("Validation rule is not valid");
    }

    /**
     * If we are passed a string as a rule we need to convert that string into a rule name
     * and any parameters
     *
     * @param {string} rule
     */
    getRuleParams(rule) {
        const result = {
            name: null,
            params: [],
        };

        // first take our rule string and split by ":", everything before the : (if it exists) will be our rule name
        const ruleArray = rule.split(":");
        result.name = ruleArray.shift();

        // everything after the : will be our parameters, we ned to split them by "," into an array
        if (ruleArray.length) {
            result.params = ruleArray[0].split(",");
        }

        return result;
    }
}
