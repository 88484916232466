export default {
    props: ["field", "option", "modelValue", "defaultCheckedIcon", "defaultUncheckedIcon"],

    computed: {
        hideIcon() {
            return (
                this.field.hideIcon || this.option?.hideIcon
            );
        },

        checkIcon() {
            return this.isChecked ? this.checkedIcon : this.uncheckedIcon;
        },

        checkedIcon() {
            // from the option schema
            if (this.option?.checkedIcon) {
                return "fad fa-" + this.option.checkedIcon;
            }

            // from the parent field schema
            if (this.field.checkedIcon) {
                return "fad fa-" + this.field.checkedIcon;
            }

            // from the parent component
            if (this.defaultCheckedIcon) {
                return this.defaultCheckedIcon;
            }

            return "fad fa-check-square";
        },

        uncheckedIcon() {
            // from the option schema
            if (this.option?.uncheckedIcon) {
                return "fad fa-" + this.option.uncheckedIcon;
            }

             // from the parent field schema
            if (this.field.uncheckedIcon) {
                return "fad fa-" + this.field.uncheckedIcon;
            }

            // from the parent component
            if (this.defaultUncheckedIcon) {
                return this.defaultUncheckedIcon;
            }

            return "far fa-square";
        },
    },
};
