<template>
    <info-alert class="leading-3 text-xl" icon="location-dot">
        <div class="flex items-center gap-3">
            <!-- name -->
            <span class="font-bold">{{ regionName }} Region</span>

            <!-- select all -->
            <button type="button" @click="$emit('selectAll', regionName)" class="ml-auto text-sm opacity-80">
                <i class="fas fa-square-plus"></i> select
            </button>
            
            <!-- deselect all -->
            <button type="button" @click="$emit('deselectAll', regionName)" class="text-sm opacity-80">
                <i class="fas fa-square-minus"></i> deselect
            </button>
        </div>
    </info-alert>
</template>

<script>
export default {
    props: {
        regionName: {
            type: String,
            required: true,
        },
    },
    emits: ["selectAll", "deselectAll"],
};
</script>
<style scoped>

</style>
