<template>
    <error-alert v-if="formattedErrors" class="my-4 justify-center" :icon="false">

        <!-- display all validation errors if error status is 422 -->
        <div v-if="formattedErrors?.status === 422" class="p-3">
            <p class="font-bold">One or more fields are invalid</p>
            <ul class="py-2">
                <li v-for="(errors, field) in formattedErrors?.data?.errors?.validationErrors" :key="field">
                    <span class="italic">{{ startCase(field) }}</span>: {{ errors.join(', ') }}
                </li>
            </ul> 
            <p class="font-bold">Please make the appropriate changes and re-submit</p>
        </div>

        <!-- display general error if error is anything but 422 -->
        <template v-else>{{ text }}</template>

    </error-alert>
</template>
<script>
import startCase from "lodash/startCase";

export default {
    props: {
        errors: {
            type: Object
        },
        message: {
            type: String,
        },
    }, 

    computed: {
        text(){
            // if we passed in an error text, use that
            if(this.message){
                return this.message;
            }

            // otherwise use our default text
            return "There was a problem with this request. Please contact the funding staff if it persists.";
        },

        formattedErrors(){
            
            if(!this.errors){
                return null;
            }

            // if our errors object was not returned by a quick-form component they will be
            // wrapped in an additional object we will have to unwrap 
            if('response' in this.errors){
                return this.errors.response;
            }

            return this.errors;
        }
    },

    methods: {
        startCase(string){
            return startCase(string);
        }
    }
};
</script>