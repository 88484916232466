<template>
    <!-- confirmation modal -->
    <base-modal
        :open="open"
        class="w-xs"
        :title="title"
        :icon="modalIcon"
        :titleRole="titleRole"
        @close="handleNoButton"
    >
        <p class="mb-4" v-html="message"></p>
        <div v-if="typeConfirm" class="mb-4">
            <label class="block">
                <span class="block text-sm font-medium text-slate-700">Please type "{{ typeConfirm }}" to confirm.</span>
                <input type="text" v-model="confirmInput" class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"/>
            </label>
        </div>
        <!-- buttons -->
        <div class="flex items-center justify-end gap-3">
            <secondary-button :disabled="!canConfirm" :role="confirmButtonRole" type="button" @click="handleYesButton">
                {{confirmText}}
            </secondary-button>
            <secondary-button
                type="button"
                @click="handleNoButton"
                :role="cancelButtonRole"
            >
                {{cancelText}}
            </secondary-button>
        </div>
    </base-modal>    
</template>

<script>
export default {
    emits: ['confirmed', 'declined'],
    data(){
        return {
            confirmInput: '',
        }
    },
    props: {
        message: {
            type: String,
            default: "Are you sure you want to perform this action?"
        },
        confirmText: {
            type: String,
            default: "yes"
        },
        cancelText: {
            type: String,
            default: "no"
        },
        typeConfirm: {
            type: String
        },
        confirmButtonRole: {
            type: String,
            default: 'danger',
        },
        cancelButtonRole: {
            type: String,
            default: 'default',
        },
        titleRole: {
            type: String,
            default: 'default'
        },
        title: {
            type: String,
            default: 'Please Confirm',
        },
        modalIcon: {
            type: String,
            default: 'badge-check',
        },
        open: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        canConfirm() {
            if(!this.typeConfirm)
            {
                return true;
            }
            return this.typeConfirm === this.confirmInput;
        }
    },
    methods: {
        handleYesButton() {
            // this.showConfirmation = false;
            // this.confirmInput = '';
            this.$emit('confirmed');
        },

        handleNoButton() {
            // this.showConfirmation = false;
            // this.confirmInput = '';
            this.$emit('declined');
        }
    }
}
</script>