<template>
    <action-button
        class="text-red-600"
        v-bind="$attrs"
        @click="showModal = true"
        :icon="$store.getters.icons.delete.icon"
        :iconStyle="$store.getters.icons.delete.style"
        title="Remove Requirement"
    />

    <base-modal
        :open="showModal"
        @close="close"
        class="w-xs"
        title="Remove Requirement"
        icon="circle-info"
    >
        <!-- step one - select a requirement -->
        <quick-form v-if="!showConfirmation"
            class="mt-6 md:pb-6 w-full"
            :schema="schema"
            @success="onSuccess"
            @updated="onUpdated"
            ref="form"
        >
            <template #buttons>
                <secondary-button @click="close">Cancel</secondary-button>
            </template>
        </quick-form>
        
        <!-- step two - confirm deletion -->
        <div v-else class="grid gap-4">
            <p>
                Are you sure you want to remove <span class="font-medium">{{ this.requirementName }}</span> 
                from this application?
            </p>
            <p class="font-medium">
                All documents and data associated with this requirement will be deleted.
            </p>

            <!-- buttons -->
            <div class="flex items-center gap-2">
                <secondary-button class="mr-auto" @click="showConfirmation = false">back</secondary-button>
                <secondary-button @click="close">cancel</secondary-button>
                <primary-button role="danger" @click="removeSection">delete requirement</primary-button>
            </div>
        </div>
    

    </base-modal>
</template>

<script>
export default {
    data() {
        return {
            showModal: false,
            showConfirmation: false,
            formFields: {},
        };
    },

    methods: {
        close(){
            this.showModal = false;
            this.showConfirmation = false;
        },

        onUpdated({ fields }){
            this.formFields = fields;
        },

        onSuccess(){
            this.showConfirmation = true;
        },

        removeSection(){
            let url = `funding/application/${this.application.alias}/additional-requirement/${this.formFields.requirement}`;
            
            this.$http.delete(url, { successNotification: "Requirement Deleted" })
                .then(response => {
                    this.$store.dispatch('applications/setCurrentApplication', 
                        { application: response.data, refreshApplications: true }
                    );

                    this.close();
                });
        },
    },

    computed: {
        application(){
            return this.$store.getters["applications/current"];
        },
    
        additionalRequirements(){
            return this.application?.additional_completion_requirements ?? [];
        },

        selectedRequirement() {
            if(this.formFields.requirement === null){
                return null;
            }

            return this.additionalRequirements.find(req => Number(this.formFields.requirement) === req.id);
        },

        requirementName(){
            return this.selectedRequirement?.custom_name;
        },

        requirementOptions() {
            return this.additionalRequirements.map(req => {
                return { value: req.id, text: req.custom_name };
            });
        },

        schema() {
            return {
                submitText: "continue",
                name: "terms-and-conditions",
                class: "md:grid-cols-6",
                resetOnSuccess: false,
                fields: [
                    {
                        name: "requirement",
                        label: "Select an AD HOC requirement to remove:",
                        type: "select",
                        value: "",
                        placeholder: "-- select --",
                        required: true,
                        wrapperClass: "md:col-span-6",
                        options: this.requirementOptions,
                    },
                ],
            };
        },
    },
};
</script>

<style scoped></style>
