<template>
    <div class="pb-6 px-6 flex mb-10" :class="!maxWidth ? 'w-full justify-self-stretch' : 'justify-center'">
        <div class="grid gap-4 w-full" :class="maxWidth" v-bind="$attrs">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        maxWidth: {
            type: [String, Boolean],
            default: "max-w-xm",
        },
    },
}
</script>