<template>
    <!-- used to teleport content only after everything has a chance to be mounted -->
    <Teleport v-if="isMounted" :to="to">
        <slot></slot>
    </Teleport>
</template>

<script>
export default {
    props: ["to"], // selector used to locate where we should mount our slot content

    data() {
        return {
            isMounted: false,
        };
    },

    mounted() {
        // wait a tick before mounting our teleport to insure the target has had a chance to be rendered
        this.$nextTick(() => {
            this.isMounted = true;
        });
    },
};
</script>
