<template>
    <base-alert type="success" :icon="icon" class="text-green-700 border-green-600 bg-green-100" @close="$emit('close')">
        <slot></slot>
    </base-alert>
</template>

<script>
export default {
    emits: ["close"],
    props: {
        icon: {
            default: "check-circle",
        },
    },
};
</script>
