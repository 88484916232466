<template>
    <!-- visible element -->
    <span
        :disabled="isDisabled"
        class="flex items-center justify-between gap-2 form-element__option select-none"
        :class="computedClasses"
        @click="increment"
    >
        <!-- name -->
        <span class="shrink grow form-element__option-text" v-html="text"></span>

        <!-- counter -->
        <div class="inline-flex items-center gap-2">
            <!-- decrement button -->
            <button 
                type="button" 
                class="count-button" 
                :class="decrementClasses"
                :disabled="decrementDisabled" 
                @click.stop="decrement"
            >
                <i class="fas fa-caret-down relative bottom-[1px]"></i>
            </button>
            
            <!-- count text -->
            <span class="text-xl font-semibold -my-.5 text-[#52708a] bg-white/50 border border-white/90 rounded px-1.5 py-.5">{{ optionCount }}</span>
            
            <!-- increment button -->
            <button 
                type="button" 
                class="count-button" 
                :disabled="incrementDisabled"
                :class="incrementClasses" 
                @click.stop="increment"
            >
                <i class="fas fa-caret-up relative top-[1px]"></i>
            </button>
        </div>
    </span>
</template>

<script>
import optionMixin from "./mixins/optionMixin";
import checkIconMixin from "./mixins/checkIconMixin";
import isObject from "@/services/utils/isObject.js";
import hasValue from "@/services/forms/FieldHasValue.js"

export default {
    mixins: [optionMixin, checkIconMixin],
    computed: {
        decrementClasses(){
            return (this.isDisabled || !this.hasCount) ? "count-button--disabled" : "";
        },

        incrementClasses(){
            return (this.isDisabled || this.maxCountReached) ? "count-button--disabled" : "";
        },

        computedClasses() {
            let classes = "border";

            // if we have a count color our check bar
            if (this.hasCount) {
                classes += " form-element__option--active";
            }

            // if we are disabled turn off our pointer cursor and reduce opacity
            if (this.isDisabled || this.isDisabledMaxCount) {
                classes += " opacity-60";
            }

            if (!this.maxCountReached) {
                classes += " cursor-pointer";
            }

            // if we are soft required and need a value show a yellow outline
            if ((this.field.required || this.field.softRequired) && !hasValue(this.modelValue, this.field)) {
                classes += " form-element__input--required-highlight";
            }

            // if we set classes for all options
            if (this.field.optionsClass) {
                classes += " " + this.field.optionsClass;
            }

            // if we set classes specifically on this option
            if (this.option?.class) {
                classes += " " + this.option?.class;
            }

            return classes;
        },

        computedAttributes() {
            let attributes = {
                // set id using the ID computed property
                id: this.id,

                // set our name using the name provided by our field schema
                name: this.field.name,

                // set our disabled status using the isDisabled computed mixin
                disabled: this.isDisabled || this.isDisabledMaxCount,

                // set our classes using our computed classes mixin
                class: this.computedClasses,

                // set our model value from our modelValue prop
                value: this.option.value,
            };

            // merge in any attributes from our field.attributes property
            if (isObject(this.option.attributes)) {
                attributes = { ...attributes, ...this.option.attributes };
            }

            return attributes;
        },

        hasMaxCount(){
            return Number.isInteger(this.field.maxCount);
        },

        totalCount(){
            return this.modelValue ? Object.values(this.modelValue).reduce((acc, cur) => acc + cur, 0) : 0;
        },

        isDisabledMaxCount(){
            // if we have at least one count in this option, it should never
            // be disabled, even if we have reached max-count, because we need
            // to be able to decrement this option
            if(this.hasCount){
                return false;
            }

            // otherwise we are disabled if we have reached max-count
            return this.maxCountReached;
        },

        maxCountReached(){
            return this.hasMaxCount && this.totalCount >= this.field.maxCount;
        },

        hasCount() {
            return this.modelValue ? this.modelValue[this.option.value] > 0 : false;
        },

        optionCount() {
            return this.modelValue ? this.modelValue[this.option.value] : 0;
        },

        decrementDisabled() {
            return this.isDisabled || !this.hasCount;
        },

        incrementDisabled() {
            return this.isDisabled || this.maxCountReached;
        },
    },

    methods: {
        decrement(){
            if(this.decrementDisabled){
                return;
            }

            this.updateCount(-1);
        },

        increment(){
            if(this.incrementDisabled){
                return;
            }

            this.updateCount(1);
        },

        updateCount(shift) {
            this.$emit("updated", this.option.value, this.optionCount + shift);  
        },
    },
};
</script>

<style scoped>
.count-button {
    @apply text-secondary-600 text-lg;
}

.count-button.count-button--disabled {
    @apply opacity-60 saturate-50 cursor-default;
}
</style>