// manages our loaded application
export default {
    namespaced: true,

    state() {
        return {
            historyFeed: [],
        };
    },

    mutations: {
        setHistoryFeed(state, historyFeed) {
            state.historyFeed = historyFeed;
        },
    },

    actions: {
        /**
         * Get the history feed of a given application alias
         *
         * @param {string} alias
         */
        async getHistoryFeed({ commit }, alias) {
            commit("setHistoryFeed", []);
            let historyFeed = await this.$http.get(
                `funding/application/${alias}/history-feed`, {
                    successNotification: false,
            });
            if (!Array.isArray(historyFeed.data)) {
                return console.error(`Failed to load history feed`);
            }
            commit("setHistoryFeed", historyFeed.data);
        },
    },

    getters: {
        historyFeed(state) {
            return state.historyFeed;
        },
    },
};
