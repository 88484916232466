export default [
    {
        path: "",
        name: "application.additionalRequirement.info",
        component: () =>
            import(
                "@/views/application/additionalRequirement/AdditionalRequirementInfo.vue"
            ),
    },
    {
        path: ":requirementId",
        name: "application.additionalRequirement",
        component: () =>
            import(
                "@/views/application/additionalRequirement/AdditionalRequirement.vue"
            ),
    },
];
