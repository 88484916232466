//import router from "@/router/index";

// manages our user state
export default {
    namespaced: true,

    state() {
        return {
            hideModal: false,
        };
    },

    mutations: {
        setHideModal(state, value = true) {
            state.hideModal = value;
        },
    },

    actions: {
        /**
         * hide (or show) our invite modal
         *
         * @param {object} context
         * @param {object} value
         */
        setHideModal({ commit }, value = true) {
            commit("setHideModal", value);
        },

        /**
         * Update our root user's invites/share and reload our applications is required
         * 
         * @param {object} context
         * @param {object} options
         */
        update({ commit, dispatch }, { invites, refreshApplications }) {
            commit('setUserShares', invites, { root: true });
            
            if(refreshApplications){
                dispatch("applications/loadAll", { spinner: false }, { root: true });
            }
        },

        /**
         * Load user invites data from the API
         *
         * @param {Context} context
         */
        loadInvites({ dispatch }) {
            this.$http.get("funding/invites", { successNotification: false })
                .then(response => {
                    dispatch("update", { invites: response?.data, refreshApplications: true })
                });
        },

    },

    getters: {
        hideModal(state) {
            return state.hideModal;
        },

        all(_, __, rootState) {
            return rootState.user.user?.shares ?? [];
        },

        count(_, getters) {
            return getters.all.length ?? 0;
        },
    },
};
