import objectPropertiesHaveValue from "../services/utils/objectPropertiesHaveValue";

export default {
    namespaced: true,

    state() {
        return {};
    },

    mutations: {},

    actions: {},

    getters: {
        sections(_state, getters, _rootState, _rootGetters) {
            return {
                device: getters.device,
                specifications: getters.specifications,
            };
        },

        /**
         * Is there a selected device
         *
         * @returns int (status_id)
         */
        device(_state, _getters, rootState, _rootGetters) {
            const selectedDevice = rootState.applications.current?.selected_device;
            if (!selectedDevice) return _rootGetters.getStatusId("NOT_STARTED");
            const properties = ["device_id"];
            return objectPropertiesHaveValue(selectedDevice, properties);
        },
        /*
         * Calculate the status of the sections so we know whether the subsection is complete or not

         */
        calculatedSections(_state, _getters, rootState, _rootGetters) {
            const app = rootState.applications.current;
            const selected_device = app?.selected_device;
            const device = app?.device;
            const baseSpecifications = selected_device?.requested_specifications ?? device?.specifications;
            const submitted = selected_device?.specifications ?? [];
            const keyedSubmitted = submitted.reduce((acc, section) => {``
                acc[section.section] = section.fields;
                return acc;
            }, {});
            if (!baseSpecifications) return [];

            const valuedSections =  baseSpecifications.map(section => {
                // We need to calculate if the section is complete or not
                // For that, we iterate through the sections fields and 
                // then we find oud if the required fields are
                // - filled
                // - hidden if not filled

                // To do this, we need to do three passes: 
                // First one will add the values to the fields
                // Second one will determine whether each field is hidden or not
                // Third pass will check the status
                const sectionValues = keyedSubmitted[section.label];
                const filledFields = section.fields?.map(field => {
                    return {
                        ...field,
                        value: sectionValues?.[field.name]
                    };
                });
                return {
                    label: section.label,
                    fields: filledFields
                };
            });
            const processedSections =  valuedSections.map(section => {
                const savedFields = {};
                const calculateHiddenStatus = (field) => {
                    // If we have already calculated the status of the field, we reuse that claculation
                    if (Object.prototype.hasOwnProperty.call(savedFields, field.name)) return savedFields[field.name];

                    const dependants = field.dependants;
                    // If we don't have a dependant, this field is not hidden
                    if (!dependants?.length) {
                        savedFields[field.name] = false;
                        return false;
                    }
                    // If we have a dependant, we need to check if the dependant has a value present
                    // And we also need to know if the dependant is hidden or not

                    // The first thing we do is call recursively on our dependant
                    // Because we need to know if the dependant is visible or not
                    // If the dependant is visible we move on, otherwise it means we are not visible
                    // So our hidden status is true
                    let hide = false;
                    dependants.forEach(dependantObj => {
                        if(hide) return;
                        const dependantName = dependantObj.name;   

                        const dependantField =  section.fields.find(field => field.name == dependantName)
                        if (dependantField && calculateHiddenStatus(dependantField)) {
                            savedFields[field.name] = true;
                            hide = true;
                            return;
                        }

                        const expectedValue = dependantObj.value;
                        const actualValue = dependantField?.value;
                        if (Array.isArray(actualValue)) {
                            if (expectedValue) {
                                hide = !actualValue.includes(expectedValue);
                            } else {
                                hide = actualValue.length == 0;
                            }
                        } else {
                            if (expectedValue) {
                                hide = actualValue !== expectedValue;
                            } else {
                                hide = !actualValue;
                            }
                        }
                    });
                    savedFields[field.name] = hide;
                    return hide;
                };
                const processedFields = section.fields.map(field => {
                    return {
                        ...field,
                        hidden : calculateHiddenStatus(field)
                    };
                });
                
                
                return {
                    label: section.label,
                    fields: processedFields
                }
            })
            const calculatedSections = processedSections.map(section => {
                // If we have no submitted answers, even if we don't have required fields.
                // The section is not completed
                let completed = true;
                if (!keyedSubmitted[section.label]) {
                    completed = false;
                } else {
                    completed = !section.fields?.map(field => {
                        if (field.required && !field.hidden) {
                            const val = field.value;
                            if (Array.isArray(val)) {
                                return val.length == 0;
                            } else {
                                return !val;
                            }
                        }
                        return false;
                    }).reduce((acc, fieldReq) => acc || fieldReq, false)
                }
                return {
                    ...section, 
                    completed: completed
                };

                
            });
            return calculatedSections;
            
        },
        /**
         * Is there a selected device
         *
         * @returns int (status_id)
         */
        specifications(_state, _getters, rootState, _rootGetters) {

            // We'll show this one completed if we have sent ANY specifications and the 
            // parent section is green, this is because the check for completed
            // requirements is very complicated to do on the front end as well
            // (side issue: if there is an upload an incomplete specifications, 
            // this section will show green, because the upload turns the parent
            // section as completed)
            const selectedDevice = rootState.applications.current?.selected_device;
            if (!selectedDevice) return _rootGetters.getStatusId("NOT_STARTED");
            const specs = selectedDevice['specifications'];
            // Calling objectProperties have value with a null properties array will use Object.keys(spec) as properties to check
            if (!specs || objectPropertiesHaveValue(specs, null) == _rootGetters.getStatusId("NOT_STARTED"));
            const equipment_info = rootState.applications.current?.equipment_selection_info;
            if (_rootGetters.getStatusName(equipment_info?.status_id) == "COMPLETED") {
                return _rootGetters.getStatusId("COMPLETED");
            } else {
                return _rootGetters.getStatusId("NOT_STARTED");
            }
        },
    },
};
