<template>
    <tr class="border-b even:bg-[#fcfcfd]">
        <td class="whitespace-nowrap text-left py-2 px-4">
            <confirm-button
                class="text-red-600"
                component="action-button"
                @confirmed="$emit('deleted', item.id)"
                message="Are you sure you want to remove this function?"
                :icon="$store.getters.icons.delete.icon"
                :iconStyle="$store.getters.icons.delete.style"
                title="Remove function"
            />
        </td>
        <td class="whitespace-nowrap text-left py-2 px-4">{{ item.function }}</td>
        <td class="whitespace-nowrap text-left py-2 px-4">{{ item.what_was_said }}</td>
        <td class="whitespace-nowrap text-left py-2 px-4">{{ item.partner }}</td>
        <td class="whitespace-nowrap text-left py-2 px-4">{{ item.environment }}</td>
        <td class="whitespace-nowrap text-left py-2 px-4">{{ item.independence }}</td>
        <td class="whitespace-nowrap text-left py-2 px-4">{{ item.baseline }}</td>
        <td class="whitespace-nowrap text-left py-2 px-4">{{ item.endline }}</td>
    </tr>
</template>

<script>


export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    emits: {

    },

    computed: {

    },

    methods: {

    },
};
</script>
<style scoped>
</style>