<template>
    <sidebar-item class="relative" :clickable="true" :role="status?.role">
        <sidebar-text class="sm:text-[10px] font-bold">
            <div class="flex items-center justify-between max-w-[226px] gap-2">
                <div class="shrink-0">
                    {{ task.application.alias }}
                </div>
                <div class="truncate text-right">
                    {{ assignedTo }}
                </div>
            </div>
        </sidebar-text>
        <sidebar-title>
            {{ task.name }}
        </sidebar-title>
        <sidebar-text>{{ clientName }}</sidebar-text>

        <sidebar-date>
            Due: {{ dueDate }}
            <span v-if="isOverdue" class="italic font-bold text-red-500">
                OVERDUE
            </span>
            <span v-else-if="isDueToday" class="italic font-bold text-yellow-500">
                DUE TODAY
            </span>
        </sidebar-date>
    </sidebar-item>
</template>

<script>
import dateService from "@/services/utils/dateFormatterService";

export default {
    props: {
        task: {
            type: Object,
            required: true,
        },

        role: {
            type: String,
        },
    },

    data() {
        return {};
    },

    computed: {
        assignedTo() {
            if (!this.task.assignees) return "No one";
            if (this.task.assignees && this.task.assignees.length == 0)
                return "No one";
            let assigneesTxt = [];
            for (let i = 0; i < this.task.assignees.length; i++) {
                let user = this.task.assignees[i].user;
                if(user){
                    let name = `${user?.first_name} ${user?.last_name.charAt(0)}.`;
                    if(user?.credentials){
                        name += ` ${user.credentials}`;
                    }
                    assigneesTxt.push(name);
                }
            }
            return assigneesTxt.join(', ');
        },
        clientInfo() {
            return this.task.application?.basic_client_info ?? {};
        },

        clientName() {
            return `${this.clientInfo.first_name} ${this.clientInfo.last_name}`;
        },

        dueDate() {
            return dateService.humanReadable(this.task.due_by);
        },

        isOverdue() {
            let due_date = new Date(this.task.due_by);
            let today = new Date();
            return !this.task.completed_at
                && dateService.formatForBackend(due_date) < dateService.formatForBackend(today)
        },
        isDueToday() {
            let due_date = new Date(this.task.due_by);
            let today = new Date();
            return !this.task.completed_at
                && dateService.formatForBackend(due_date) === dateService.formatForBackend(today)
        },
        status() {
            if (this.role) {
                return {
                    role: this.role,
                };
            }

            if (this.task.completed_at) {
                return {
                    role: "success",
                    icon: "check-circle",
                };
            }

            if (this.task.priority) {
                return {
                    role: "error",
                    icon: "exclamation-triangle",
                };
            }

            return null;
        },
    },
};
</script>

<style scoped>
</style>
