<template>            
    <sidebar-item v-if="!link.hide" :clickable="true">
        <sidebar-title>
            <div class="flex items-center gap-1.5">
                <i class="fas fa-link text-secondary-800"></i>

                <!-- if we have an external link -->
                <a v-if="link.href" class="block" target="_blank" :href="link.href" v-html="link.title"></a>

                <!-- if we have an internal link -->
                <router-link v-if="link.route" :to="{ name: link.route }" class="block" v-html="link.title"></router-link>
            </div>
        </sidebar-title>
    </sidebar-item>
       
</template>

<script>
export default {
    props: {
        link: {
            type: Object,
            required: true,
        }
    }
};
</script>

<style scoped></style>