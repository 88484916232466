<template>
    <div v-if="count === 0" class="text-center lowercase border border-dotted py-1 rounded-sm text-secondary-700 border-secondary-800">
        <slot></slot>
    </div>
</template>
<script>
export default {
    props: {
        count: {
            type: Number,
            default: 0,
        },
    },
}
</script>
