<template>
    <div class="backdrop backdrop-blur-sm" v-if="open" @click="onward()"></div>

    <div class="modal-core-content" v-if="open">
        <i class="fa-solid fa-xmark" @click="onward()"></i>
        <div class="fireworks">
            <div class="before"></div>
            <div class="after"></div>
        </div>
        <svg
            id="badge"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1040 1347"
        >
            <g
                id="Page-1"
                fill="none"
                fill-rule="evenodd"
                stroke="none"
                stroke-width="1"
            >
                <g id="Artboard-Copy-19" transform="translate(-6935 -4534)">
                    <g id="badge" transform="translate(6935 4534)">
                        <ellipse
                            id="badge_shadow"
                            cx="527.62"
                            cy="1224.46"
                            fill="#000"
                            fill-opacity=".02"
                            rx="480.9"
                            ry="122.54"
                        />
                        <path
                            id="Rectangle"
                            fill="#0091FF"
                            d="M216.4 789.6h611.9a65.67 65.67 0 0 1 65.37 59.3l33.43 342.4a65.67 65.67 0 0 1-85.15 69.01l-302.04-95.42a65.67 65.67 0 0 0-39.56 0l-301 95.1a65.67 65.67 0 0 1-85.09-69.66l36.84-342.09a65.67 65.67 0 0 1 65.3-58.64Z"
                        />
                        <path
                            id="Path"
                            fill="#007EDE"
                            stroke="#979797"
                            stroke-width=".5"
                            d="M519.36 789.85v371.75a65.92 65.92 0 0 0-19.09 3.05l-301 95.1a65.22 65.22 0 0 1-49.89-4.34 65.42 65.42 0 0 1-34.87-65.05l36.84-342.09a65.27 65.27 0 0 1 21.33-41.67 65.27 65.27 0 0 1 43.72-16.75h302.96Z"
                        />
                        <path
                            id="Path"
                            fill="#32C5FF"
                            d="M571.1 795.38v379.36l-31.19-9.85a65.67 65.67 0 0 0-39.56 0l-31.18 9.85V795.38h101.92Z"
                        />
                        <path
                            id="Path"
                            fill="#2AB6ED"
                            d="M519.6 801.69v360.15a65.67 65.67 0 0 0-19.25 3.05l-31.18 9.85V801.7h50.44Z"
                        />
                        <path
                            id="Path"
                            fill="#000"
                            fill-opacity=".05"
                            d="M828.3 789.6a65.67 65.67 0 0 1 65.36 59.25l1.4 14.24-1.65 1.74c-84.13 89.74-42.29 172.94-135.7 214.22-93.42 41.27-118.99-49.12-237.97-49.12-118.99 0-144.55 90.39-237.97 49.12-92.32-40.8-52.54-122.53-132.8-211.06l2.18-20.22.07-.52a65.67 65.67 0 0 1 65.18-57.65h611.9Z"
                        />
                        <path
                            id="Star"
                            fill="#FFE100"
                            d="M520 989.98c-105.83 0-128.57 75.37-211.66 40.95-83.1-34.41-45.87-103.79-120.7-178.62C112.8 777.48 43.41 814.71 9 731.61c-34.42-83.08 40.96-105.82 40.96-211.64C49.96 414.14-25.42 391.4 9 308.32c34.42-83.09 103.8-45.87 178.63-120.7C262.47 112.79 225.25 43.42 308.33 9 391.44-25.4 414.18 49.96 520 49.96c105.83 0 128.57-75.37 211.66-40.96 83.1 34.42 45.87 103.79 120.7 178.62 74.84 74.83 144.22 37.61 178.64 120.7 34.42 83.08-40.96 105.82-40.96 211.65 0 105.82 75.38 128.56 40.96 211.64-34.42 83.1-103.8 45.87-178.63 120.7-74.84 74.83-37.62 144.2-120.7 178.62-83.1 34.42-105.84-40.95-211.67-40.95Z"
                        />
                        <ellipse
                            id="Oval"
                            cx="519.9"
                            cy="520.06"
                            fill="#FF9C00"
                            rx="367.98"
                            ry="367.95"
                        />
                        <path
                            id="Path"
                            fill="#FFCE00"
                            d="M519.6 49.96v940.02l-2.75.02c-103.14 1.47-126.25 75-208.51 40.93-83.1-34.41-45.87-103.79-120.7-178.62C112.8 777.48 43.41 814.71 9 731.61c-34.42-83.08 40.96-105.82 40.96-211.64C49.96 414.14-25.42 391.4 9 308.32c34.42-83.09 103.8-45.87 178.63-120.7C262.47 112.79 225.25 43.42 308.33 9c83-34.37 105.78 40.77 211.28 40.96Z"
                        />
                        <path
                            id="Path"
                            fill="#FF9112"
                            d="M519.6 152.11v735.9c-203.09-.15-367.68-164.83-367.68-367.95 0-201.18 161.47-364.65 361.9-367.9l5.79-.05Z"
                        />
                        <path
                            id="Path"
                            fill="#000"
                            fill-opacity=".05"
                            d="M519.9 152.11a366.47 366.47 0 0 1 232.89 83.05 424.94 424.94 0 0 0-198.3-48.77c-180.36 0-334.58 111.86-397.08 269.97 30.18-172.85 180.99-304.25 362.5-304.25Z"
                        />
                        <path
                            id="Path"
                            fill="#FFF"
                            fill-rule="nonzero"
                            d="M303.89 569.76a54.56 54.56 0 0 1-16.78-38.34 54.6 54.6 0 0 1 15.22-39 54.47 54.47 0 0 1 38.3-16.77c14-.3 28.1 4.76 38.99 15.21l93.96 90.45 187.08-237.73 6.7 5.11-6.71-5.13a8.6 8.6 0 0 1 1.81-1.77 54.5 54.5 0 0 1 38.76-13.26v-.01l.67.06a54.47 54.47 0 0 1 37.05 18.07 54.56 54.56 0 0 1 13.88 39.49h.02l-.06.67a54.47 54.47 0 0 1-17.47 36.52L510.8 696.73h.01c-.42.54-.9 1-1.4 1.4a54.54 54.54 0 0 1-37 13.54 54.5 54.5 0 0 1-36.92-15.23l-131.6-126.68Z"
                        />
                    </g>
                </g>
            </g>
        </svg>

        <h1 class="text-5xl md:text-6xl my-6" v-html="content.heading"></h1>
        <h3 v-html="content.subHeading"></h3>

        <button 
            @click="onPrimary" 
            class="transition-all rounded ripple focus:outline-none bg-transparent border leading-tight tracking-wider text-xl my-8 px-16 font-bold py-1 h-16 border-primary-600 hover:border-primary-400 uppercase mr-10" 
            :class="content.primaryButtonClass"
            v-html="content.primaryButtonText">
        </button>

        <br />

        <button
            v-show="secondaryShow"
            @click="onSecondary"
            class="transition-all rounded ripple focus:outline-none bg-transparent border leading-tight tracking-wider text-md my-1 px-6 font-bold py-1 h-10 border-red-500 hover:border-red-500 uppercase"
            v-html="content.secondaryButtonText"
        ></button>

        <svg
            id="rocket"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 790 1995"
        >
            <g
                id="Page-1"
                fill="none"
                fill-rule="evenodd"
                stroke="none"
                stroke-width="1"
            >
                <g id="Artboard-Copy-19" transform="translate(-7060 -8099)">
                    <g id="rocket" transform="translate(7060 8099)">
                        <path
                            id="Rectangle-Copy"
                            fill="#F46767"
                            d="M48.98 1086.75c-38.84 143.55-90.71 608 7.17 459.2 65.25-99.2 141.87-148.8 229.84-148.8l-153.5-459.2c-29.77 3.49-57.6 53.09-83.5 148.8Z"
                        />
                        <path
                            id="Rectangle-Copy-2"
                            fill="#FF7171"
                            d="M741.02 1086.75c38.84 143.55 90.71 608-7.17 459.2-65.25-99.2-141.87-148.8-229.84-148.8l153.5-459.2c29.77 3.49 57.6 53.09 83.5 148.8Z"
                        />
                        <path
                            id="Path"
                            fill="#F1F1FA"
                            d="M335.19 1531.62a175.03 175.03 0 0 1-163.32-118.25c-62.62-182.74-94.25-396.13-94.88-640.16v-7.4l.05-13.55C80.67 315.58 259.12 12.35 387.34.36L391.2.1 395 0l3.78.1c128.46 6.13 310.5 311.07 314.17 752.16l.05 13.55c0 247.12-31.63 462.97-94.88 647.56a175.03 175.03 0 0 1-163.32 118.25H335.2Z"
                        />
                        <g
                            id="rocket_flame"
                            transform="translate(230.99 1472.63)"
                        >
                            <g id="rocket_flame_outer">
                                <path
                                    id="Rectangle"
                                    fill="#FFD84A"
                                    d="M64.18 0h200.8a32.5 32.5 0 0 1 29.5 18.9c25.12 54.5 36.33 110.24 33.64 167.21-4.46 94.42-52.57 173.79-91.8 246.53l-2.7 5c-15.02 27.73-29.1 50.85-42.25 69.34l-.66.92a32.5 32.5 0 0 1-52.81.07c-12.98-18.03-27.36-41.53-43.13-70.48l-3.98-7.34C49.13 353.4 4.46 275.08.34 186.1-2.18 131.73 9.21 76.12 34.5 19.3A32.5 32.5 0 0 1 64.18 0Z"
                                />
                                <path
                                    id="Path"
                                    fill="#FFC94A"
                                    d="M164.5 0v521.49a32.36 32.36 0 0 1-26.6-13.52c-12.98-18.03-27.36-41.53-43.13-70.48l-3.98-7.34C49.13 353.4 4.46 275.08.34 186.1-2.18 131.73 9.21 76.12 34.5 19.3A32.5 32.5 0 0 1 64.18 0H164.5Z"
                                />
                            </g>
                            <g
                                id="rocket_flame_inner"
                                transform="translate(82 39.99)"
                            >
                                <path
                                    id="Rectangle-Copy-3"
                                    fill="#FFC800"
                                    d="M32.12 0h100.59c6.34 0 12.1 3.69 14.75 9.45 12.58 27.28 18.2 55.17 16.85 83.7-2.25 47.72-26.81 87.77-46.57 124.47-7.94 14.76-15.36 26.96-22.26 36.6a16.25 16.25 0 0 1-26.4.04c-6.7-9.3-14.15-21.52-22.34-36.64C25.52 178.47 2.28 138.57.17 93.14c-1.26-27.22 4.44-55.05 17.1-83.5A16.25 16.25 0 0 1 32.13 0Z"
                                />
                                <path
                                    id="Path"
                                    fill="#FFB600"
                                    d="M82 0v261a16.18 16.18 0 0 1-12.92-6.74l-.88-1.23c-6.18-8.73-12.98-19.88-20.4-33.45l-8.75-16.1C20.25 168.82 2.02 133.13.17 93.15c-1.26-27.22 4.44-55.05 17.1-83.5A16.25 16.25 0 0 1 32.13 0H82Z"
                                />
                            </g>
                        </g>
                        <path
                            id="Path"
                            fill="#F8F8FF"
                            d="m395 0 3.78.1c128.46 6.13 310.5 311.07 314.17 752.16l.05 13.55c0 247.12-31.63 462.97-94.88 647.56a175.03 175.03 0 0 1-163.32 118.25H395V0Z"
                        />
                        <path
                            id="Path"
                            fill="#BD2727"
                            d="M454.8 1531.62H335.2a175.07 175.07 0 0 1-132.66-63.48h384.93a175.07 175.07 0 0 1-132.66 63.48Z"
                        />
                        <path
                            id="Path"
                            fill="#FF7171"
                            d="m395 0 3.78.1c60.3 2.88 132.42 71.6 192.34 189.35H198.87C257.52 74.2 327.83 5.93 387.33.37l3.87-.28L395 0Z"
                        />
                        <path
                            id="Path"
                            fill="#991212"
                            d="M395 1468.14v63.48h-59.81a175.07 175.07 0 0 1-132.66-63.48H395Z"
                        />
                        <path
                            id="Rectangle"
                            fill="#FF7171"
                            d="M394.37 984.76h1.83a50 50 0 0 1 49.66 44.14c4.92 41.73 6.46 98.54 4.64 170.43-2.51 98.77-15.95 219.75-40.3 362.94a15.42 15.42 0 0 1-15.2 12.84 15.33 15.33 0 0 1-15.13-12.85c-24.57-149.51-38.03-270.49-40.38-362.93l-.1-4.14c-1.59-67.47.18-122.9 5.32-166.3a50 50 0 0 1 49.66-44.13Z"
                        />
                        <path
                            id="Path"
                            fill="#F46767"
                            d="M395 0v189.45H198.86C257.52 74.2 327.83 5.93 387.33.37l3.87-.28L395 0Zm-.63 984.76h.62v590.35c-7.5 0-13.9-5.44-15.12-12.85-24.57-149.51-38.03-270.49-40.38-362.93l-.1-4.14c-1.59-67.47.18-122.9 5.32-166.3a50 50 0 0 1 49.66-44.13Z"
                        />
                        <g id="Group-39" transform="translate(196.99 415.4)">
                            <path
                                id="Star"
                                fill="#FFE100"
                                d="M198 376.88c-40.3 0-48.95 28.7-80.6 15.6-31.63-13.1-17.46-39.52-45.95-68-28.5-28.5-54.92-14.32-68.02-45.95-13.1-31.64 15.6-40.3 15.6-80.58 0-40.29-28.7-48.94-15.6-80.57 13.1-31.63 39.52-17.47 68.02-45.95 28.5-28.5 14.32-54.9 45.96-68 31.64-13.1 40.3 15.59 80.6 15.59s48.95-28.7 80.6-15.6c31.63 13.1 17.46 39.52 45.95 68 28.5 28.5 54.92 14.33 68.02 45.96 13.1 31.63-15.6 40.28-15.6 80.57 0 40.29 28.7 48.94 15.6 80.58-13.1 31.63-39.52 17.46-68.02 45.95-28.5 28.48-14.32 54.9-45.96 68-31.64 13.1-40.3-15.6-80.6-15.6Z"
                            />
                            <ellipse
                                id="Oval"
                                cx="197.97"
                                cy="197.99"
                                fill="#FF9C00"
                                rx="140.12"
                                ry="140.08"
                            />
                            <path
                                id="Path"
                                fill="#FFCE00"
                                d="M197.85 19.02v357.86l-1.04.01c-39.28.56-48.08 28.56-79.4 15.59-31.64-13.1-17.47-39.52-45.96-68-28.5-28.5-54.92-14.32-68.02-45.95-13.1-31.64 15.6-40.3 15.6-80.58 0-40.29-28.7-48.94-15.6-80.57 13.1-31.63 39.52-17.47 68.02-45.95 28.5-28.5 14.32-54.9 45.96-68 31.6-13.09 40.28 15.52 80.44 15.59Z"
                            />
                            <path
                                id="Path"
                                fill="#FF8900"
                                d="M197.86 57.9v280.17C120.52 338 57.85 275.32 57.85 197.99c0-76.6 61.48-138.83 137.8-140.06l2.2-.02Z"
                            />
                            <path
                                id="Path"
                                fill="#000"
                                fill-opacity=".05"
                                d="M197.97 57.9c33.65 0 64.52 11.86 88.68 31.63a161.83 161.83 0 0 0-75.51-18.57c-68.68 0-127.4 42.58-151.2 102.78C71.43 107.94 128.85 57.9 197.97 57.9Z"
                            />
                            <path
                                id="Path"
                                fill="#FFF"
                                fill-rule="nonzero"
                                d="M115.71 216.9a20.77 20.77 0 0 1-6.39-14.59 20.78 20.78 0 0 1 20.39-21.23 20.75 20.75 0 0 1 14.84 5.79l35.78 34.43 71.23-90.5 2.56 1.95-2.56-1.96a20.76 20.76 0 0 1 15.45-5.72l.25.02a20.74 20.74 0 0 1 14.11 6.88 20.77 20.77 0 0 1 5.29 15.03l-.02.26a20.74 20.74 0 0 1-6.65 13.9L194.5 265.24c-.15.21-.33.39-.53.54-4 3.51-9.06 5.24-14.08 5.15a20.76 20.76 0 0 1-14.07-5.8l-50.1-48.22Z"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>

        <svg
            id="clouds"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 11346 4432"
        >
            <g
                id="Page-1"
                fill="none"
                fill-rule="evenodd"
                stroke="none"
                stroke-width="1"
            >
                <g
                    id="Artboard-Copy-19"
                    fill="#F8F8FF"
                    transform="translate(-2654 -9363)"
                >
                    <g id="clouds" transform="translate(2654 9363)">
                        <circle id="cloud1" cx="1226" cy="1543" r="1226" />
                        <circle id="cloud4" cx="4242" cy="2031" r="1226" />
                        <circle id="cloud8" cx="8052" cy="1033" r="510" />
                        <circle id="cloud2" cx="2754" cy="1395" r="768" />
                        <circle id="cloud5" cx="5896" cy="1488" r="861" />
                        <circle
                            id="cloud6"
                            cx="5756.5"
                            cy="2787.5"
                            r="1644.5"
                        />
                        <circle
                            id="cloud7"
                            cx="7261.5"
                            cy="1721.5"
                            r="1047.5"
                        />
                        <circle
                            id="cloud9"
                            cx="9668.5"
                            cy="1677.5"
                            r="1677.5"
                        />
                        <circle id="cloud3" cx="2637" cy="2372" r="1481" />
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
import gsap from "gsap";
var main;
var launch;

export default {
    data() {
        return {
            modalHide: false,
            secondaryShow: false,
        };
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        nextRoute: {
            type: [String, Object],
            default: "application.overview",
        },
        appSubmitted: {
            type:Boolean,
            default:false,
        },
    },
    computed: {
        content() {
            if(this.appSubmitted){
                return {
                    heading:'Congratulations!!!',
                    subHeading:"You've submitted your application!<br /><sub>Track the progress of your application in the main overview area.</sub>",
                    primaryButtonText:'Return to Overview',
                    primaryButtonRoute:'application.overview',
                };
            }

            if(this.applicationCompleted){
                return {
                    heading:'Great Job!',
                    subHeading:"Your Application is Complete!<br /><sub>It's time to review your application and submit it.</sub>",
                    primaryButtonText:'Review and Submit to PRC-Saltillo',
                    primaryButtonRoute:'application.submit',
                    primaryButtonClass: 'bg-gradient-to-b !text-gray-800 from-primary-500 to-primary-600 hover:to-primary-500',
                    secondaryButtonText:'Return to Overview',
                    secondaryButtonRoute:'application.overview',
                };
            }

            return {
                heading: "Great Job!",
                subHeading:
                    "You've completed this section.<br />Where do you want to go next?",
                primaryButtonText: "Continue to next Section",
                primaryButtonRoute: this.nextRoute,
                secondaryButtonText: "Return to Overview",
                secondaryButtonRoute: "application.overview",
            };
        },
        application() {
            return this.$store.getters["applications/current"] ?? {};
        },

        applicationCompleted() {
            return this.application?.status_id === this.$store.getters.getStatusId('COMPLETED');
        },
    },
    watch: {
        open(newVal, oldVal) {
            if (oldVal == false && newVal == true) {
                this.preventBodyScroll();

                if(!this.appSubmitted)
                {
                    this.secondaryShow = true;
                }
                setTimeout(() => {
                    main = gsap.timeline({ defaults: { force3D: true } });
                    main.fromTo(
                        ".modal-core-content svg#badge",
                        { autoAlpha: 0, y: -250 },
                        {
                            autoAlpha: 1,
                            scale: 1.75,
                            y: 150,
                            ease: "bounce",
                            duration: 1.1,
                        }
                    )
                        .to(
                            ".modal-core-content svg#badge #badge_shadow",
                            { autoAlpha: 1, ease: "none", duration: 0.25 },
                            "2"
                        )
                        .to(
                            ".modal-core-content svg#badge",
                            { scale: 1, y: -35, ease: "power1", duration: 0.5 },
                            "2"
                        )
                        .to(
                            ".modal-core-content h1, .modal-core-content h3, .modal-core-content button",
                            { autoAlpha: 1 },
                            "2.5"
                        );
                }, 250);
            }
        },
    },
    methods: {
		launch() {
            launch = gsap.timeline({defaults:{force3D:true}});
            launch.to('.modal-core-content svg#rocket #rocket_flame_inner', {opacity:0.5, ease:'step', duration:0.1, repeat:-1, yoyo:true}).to('.modal-core-content svg#rocket #rocket_flame_outer', {opacity:0.75, ease:'step', duration:0.08, repeat:-1, yoyo:true}).to('.modal-core-content svg#badge, .modal-core-content h1, .modal-core-content h3, .modal-core-content button', {autoAlpha:0, ease:'none', duration: 0.35}).to('.modal-core-content #clouds #cloud1, .modal-core-content #clouds #cloud2, .modal-core-content #clouds #cloud3, .modal-core-content #clouds #cloud4, .modal-core-content #clouds #cloud5, .modal-core-content #clouds #cloud6, .modal-core-content #clouds #cloud7, .modal-core-content #clouds #cloud8, .modal-core-content #clouds #cloud9', {scale:50, ease:'bounce', duration:12, transformOrigin:'50% 50%'}, '1.75').to('.modal-core-content svg#rocket', {y:-1500, ease:'bounce(-4)', duration:8}, '0.1').to('.modal-core-content', {opacity:0, duration:1, onComplete: () => { this.onward() }}, '2.75');
		},
        onPrimary() {
            if(this.appSubmitted) {
                return this.launch();
            }
            this.onward();
        },
        onSecondary() {
            gsap.to('.modal-core-content', {autoAlpha:0, ease:'none', duration:0.35, onComplete: () => {
                this.$router.push({
                    name: this.content.secondaryButtonRoute,
                    params: { alias: this.application.alias },
                });
            }});
        },
        onward(router = true) {
            gsap.to('.modal-core-content, .backdrop', {autoAlpha:0, ease:'none', duration:0.35, onComplete: () => {
                this.modalHide = true;
                main.seek(0).pause();
                if(this.appSubmitted && launch)
                {
                    launch.seek(0).pause();
                }
                let el = document.getElementById('badge');
                el.style.visibility = 'hidden';
                this.allowBodyScroll();
                if(router)
                {
                    let route = this.primaryButtonRoute;
                    if (typeof this.primaryButtonRoute == "string");
                    route = {
                        name: this.content.primaryButtonRoute,
                        params: { alias: this.application.alias },
                    };
                    this.$router.push(route);
                }
            }});
        },
        preventBodyScroll() {
            let html = document.querySelector('html');
            let hasScroll = html.scrollHeight > html.clientHeight;
            if(hasScroll)
            {
                let primaryNav = document.getElementById('primary-nav');
                primaryNav.classList.add('-mr-4');
                document.body.classList.add('pr-4');
            }
            html.style.overflow = 'hidden';
        },
        allowBodyScroll() {
            let html = document.querySelector('html');
            html.style['scrollbar-gutter'] = 'initial';
            html.style.overflow = '';
            document.body.classList.remove('pr-4');
            let primaryNav = document.getElementById('primary-nav');
            if(primaryNav)
            {
                primaryNav.classList.remove('-mr-4');
            }
        },
    }
};
</script>


<style scoped>
.modal-core-content {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding-top: 6em;
    height: 800px;
    margin: 0 auto;
    width: 900px;
    background: linear-gradient(15deg, #283a65 25%, #4b567c 75%, #7468a8 100%);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    border-radius: 20px;
    box-shadow: 1px 100px 50px -65px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    text-align: center;
    z-index: 99;
}
.modal-core-content svg#badge {
    visibility: hidden;
    width: 100px;
    margin: 0 auto;
}
.modal-core-content svg#rocket {
    position: absolute;
    width: 10%;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, 100%);
}
.modal-core-content svg#clouds {
    position: absolute;
    bottom: -15em;
    left: -8%;
    width: 120%;
    overflow: visible;
}
.modal-core-content h1,
.modal-core-content h3,
.modal-core-content button,
.modal-core-content svg#badge #badge_shadow {
    visibility: hidden;
    user-select: none;
}
.modal-core-content button {
    margin-right: 1em;
}
.modal-core-content a {
    font-weight: bold;
}
.modal-core-content h1 {
    letter-spacing: 0.05em;
    text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.1),
        1px 1px 6px rgba(0, 0, 0, 0.35);
}
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.85);
    z-index: 98;
}
.fa-xmark {
    position: absolute;
    top: 0.5em;
    right: 0.75em;
    font-size: 32px;
    color: #fff;
}
.fa-xmark:hover {
    cursor: pointer;
}
@media screen and (max-width: 1024px), screen and (max-height: 1024px) {
    .modal-core-content {
        transform: translate(-50%, -50%) scale(0.8);
    }
}
@media screen and (max-width: 800px), screen and (max-height: 800px) {
    .modal-core-content {
        transform: translate(-50%, -50%) scale(0.6);
    }
}
@media screen and (max-width: 640px), screen and (max-height: 640px) {
    .modal-core-content {
        transform: translate(-50%, -50%) scale(0.4);
    }
}
.modal-core-content .fireworks > .before,
.modal-core-content .fireworks > .after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
        0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
        0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
        0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
        0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
        0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
        0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff,
        0 0 #fff, 0 0 #fff, 0 0 #fff;
    animation: 1s bang ease-out 3 backwards, 1s gravity ease-in 3 backwards,
        5s position linear 3 backwards;
}
.modal-core-content .fireworks > .after {
    animation-delay: 1.25s, 1.25s, 1.25s;
    animation-duration: 1.25s, 1.25s, 6.25s;
}
@keyframes bang {
    to {
        box-shadow: -223px -339.66667px #00ff1e, -119px -189.66667px #ddff00,
            154px -123.66667px #00ff62, -176px -351.66667px #5e00ff,
            162px -284.66667px #ff007b, 6px 72.33333px #6600ff,
            129px -130.66667px #00ff40, 140px -333.66667px #ff00cc,
            -11px -326.66667px #0800ff, 209px -196.66667px #00d0ff,
            77px -140.66667px #bb00ff, 137px -368.66667px #fb00ff,
            -84px -219.66667px #00fbff, 229px -97.66667px #51ff00,
            -42px -275.66667px #0095ff, -72px -313.66667px #ccff00,
            210px -76.66667px #00ffe1, -104px -268.66667px #dd00ff,
            -119px -253.66667px yellow, 63px -335.66667px #00ffd0,
            -35px -62.66667px cyan, 76px -143.66667px #ffbf00,
            -174px -328.66667px #62ff00, 215px 1.33333px #002bff,
            -228px -50.66667px #91ff00, -202px -113.66667px #ff0080,
            -234px -313.66667px #ff005d, -196px -277.66667px #b300ff,
            243px 9.33333px #ff9100, -248px -261.66667px #ff00cc,
            134px 0.33333px #00ff48, -69px -4.66667px #00ddff,
            -6px 34.33333px #ff6600, -37px 45.33333px #d0ff00,
            19px -228.66667px #ff9100, 35px -278.66667px #00ff62,
            -194px -303.66667px #009dff, 230px -208.66667px #00ff22,
            92px -280.66667px #0055ff, 159px -206.66667px #00e5ff,
            36px -345.66667px #1500ff, 226px -342.66667px #ff2600,
            23px -339.66667px #007bff, -76px -302.66667px #003bff,
            45px 15.33333px #44ff00, -230px -223.66667px #ff001a,
            165px -305.66667px #ff006e, 221px 82.33333px #ff8000,
            126px -161.66667px #00ddff, -61px -170.66667px #ff003b,
            -119px -232.66667px #bfff00;
    }
}
@keyframes gravity {
    to {
        transform: translateY(200px);
        opacity: 0;
    }
}
@keyframes position {
    0%,
    19.9% {
        margin-top: 10%;
        margin-left: 40%;
    }
    20%,
    39.9% {
        margin-top: 40%;
        margin-left: 30%;
    }
    40%,
    59.9% {
        margin-top: 20%;
        margin-left: 70%;
    }
    60%,
    79.9% {
        margin-top: 30%;
        margin-left: 20%;
    }
    80%,
    99.9% {
        margin-top: 30%;
        margin-left: 80%;
    }
}
</style>
