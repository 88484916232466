<template>
    <base-button
        class="bg-transparent leading-tight tracking-wider capitalize"
        :class="classes"
        :disabled="disabled"
        :icon="icon"
    >
        <slot></slot>
    </base-button>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
        },
        icon: {
            type: String,
        },
        size: {
            type: String,
        },
        role: {
            type: String,
        },
        hideBorder: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        classes() {
            let classes = [];

            if(this.size?.includes("xsmall")) {
                classes.push("text-xs px-3 py-[3px]");
            } else if (this.size?.includes("small")) {
                classes.push("text-sm px-3 py-1 h-7");
            } else if(this.size?.includes("large")) {
                classes.push("text-lg px-8 py-4");
            } else {
                classes.push("px-6 py-2.5");
            }

            if (this.role?.includes("danger")) {
                classes.push("border-red-400 hover:border-red-300");
            } else {
                classes.push("border-primary-600 hover:border-primary-400");
            }
            
            if (!this.hideBorder) {
                classes.push("border")
            }

            return classes.join(" ");
        },
    },
};
</script>
