<template>
    <div class="w-full">        
        <!-- confirmation modal -->
        <base-modal
            :open="showConfirmation"
            @close="cancelConfirmation"
            class="w-xs"
            title="Please Confirm"
            icon="badge-check"
        >
            <p class="mb-4" v-html="confirmMessage"></p>

            <!-- buttons -->
            <div class="flex items-center justify-end gap-3">
                <primary-button :role="confirmButtonRole" type="button" @click="handleConfirmButton">
                    {{confirmButtonText}}
                </primary-button>
                <secondary-button
                    type="button"
                    @click="cancelConfirmation"
                    :role="cancelButtonRole"
                >
                    {{cancelButtonText}}
                </secondary-button>
            </div>
        </base-modal>
        <label v-on="!fullScreenDrop? dropHandlers : {}" ref="fileLabel" :id="labelId" :for="inputId" class="w-full inline-block relative cursor-pointer btn btn-lg btn-primary">
            <base-card :hideHeader="true" :class="modalComputedClasses" class='py-5 px-5 max-w-full pointer-events-non mw-full w-full rounded-xl mb-0' :hide-shadow="true" :noBodyPadding="true">
                    <div :class="{'uploader--disabled': disabled}" class="pointer-events-none text-center">
                        <transition :duration="100" name="fade" mode="out-in">
                            <i v-if="uploadStatus === 'ready'" class="fa-upload inline-block fa py-2" :class="iconClasses"/>
                            <i v-else-if="uploadStatus == 'holding'" class="fa-file inline-block fa py-2" :class="iconClasses"/>
                            <i v-else-if="uploadStatus == 'uploading'" class="fa-spin fa-spinner inline-block fa py-2" :class="iconClasses"/>
                            <i v-else-if="uploadStatus == 'success'" class=" fa-circle-check text-primary inline-block fa py-2" :class="iconClasses"/>
                            <i v-else-if="uploadStatus == 'error'" class="fa-circle-exclamation text-red-700 inline-block fa py-2" :class="iconClasses"/>
                        </transition>
                        <div :class="{'uploader--disabled': disabled}" class="pointer-events-none text-center">
                            <slot v-if="uploadStatus !== 'holding'"></slot>
                            <div v-else>{{holdingFilename}}</div>
                        </div>
                        
                    </div>
                    <div v-if="fullScreenDrop" v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
                        <h3>Drop files to upload</h3>
                    </div>
                    
            </base-card>

            <transition :duration="100" name="fade" mode="out-in">
                <div v-if="showCompletedCheckmark" class="absolute -top-1 right-1 w-1 h-1 flex justify-center items-center">
                    <i class="fa-duotone fa-circle-check text-status_COMPLETED" :class="completedCheckmarkClasses" />
                </div>
            </transition>

        </label>
        <VueUploadComponent
            :input-id="inputId"
            :disabled="disabled || (uploadStatus !== 'ready' && uploadStatus !== 'holding')"
            class="btn btn-primary"
            ref="upload"
            name="file"
            :drop="fullScreenDrop"
            v-model="fileToUpload"
            @input-file="inputFile"
            @input-filter="inputFilter"
            :multiple="false"
            :drop-directory="false"
        >
        </VueUploadComponent>
        <p v-if="displayAcceptedTypes === true" class="text-sm text-center text-gray-500">Accepted types: {{acceptedTypesString}}. Max size: {{ maxSizeMb }}mb. <span v-if="maxDimensions !== null">Max Dimensions: {{ maxDimensions }}</span></p>

        <div :id="teleportErrorsId">
        </div>
        <base-teleport :to="teleportErrorsTo">
            <div v-if="errors.length > 0">
                <error-alert class="mb-2" v-for="error in errors" :key="error">{{ error }}</error-alert>
            </div>
        </base-teleport>
    </div>

    
</template>

<script>
import VueUploadComponent from 'vue-upload-component';

const DEFAULT_ACCEPTED_TYPES = ['pdf','jpg','jpeg','png','tif','tiff','gif'];
const DEFAULT_MAX_SIZE = 50000000;
export default {
    components: {VueUploadComponent},
    emits: ['uploadSuccess', 'uploadError', 'fileInput', 'uploadReady', 'uploadCleared'],
    data() {
        return {
            fileToUpload: [],
            uploadErrors: [],
            file: null,
            dragging: false,
            dragCount: 0,
            uploadStatus: 'ready',
            holdingFilename: "",
            responseErrors: null,
            showConfirmation: false,
            videoTypes: ['mp4', 'mov', 'wmv']
        }
    },
    props: {
        requiredHighlight: {
            type: Boolean,
            default: false,
        },
        fullScreenDrop: {
            type: Boolean,
            default: true,
            required: false
        },
        endpoint: {
            type: String,
            required: true
        },
        maxSize: {
            // Max Size in Bytes
            type: Number,
            required: false,
            default: DEFAULT_MAX_SIZE
        },
        acceptedTypes: {
            type: Array,
            required: false,
            default: DEFAULT_ACCEPTED_TYPES
        },
        maxDimensions: {
            // String describing the max dimensions of an image upload
            type: String,
            required: false,
            default: null
        },
        maxFiles: {
            // If maxFiles is 0. The check is turned off
            type: Number,
            required: false,
            default: 0 
        },
        currentFiles: {
            required: false,
            default: 0
        },
        displayAcceptedTypes: {
            type: Boolean,
            required: false,
            default: true
        },
        iconSize: {
            type: Number,
            required: false,
            default: 5
        },
        uploadWrapperClass: {
            type: String,
            required: false,
        },
        formBody: {
            type: Object,
            required: false,
            default: () => {return {}}
        },
        setResponseAsApplication: {
            type: Boolean,
            required: false,
            default: true
        },
        teleportErrors: {
            type: String,
            required: false,
            default: null
        },
        holdUpload: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        confirm: {
            type: Object,
            default: null,
            required: false
        },
        customErrors: {
            type: Array,
            default: () => {return []},
            required: false
        },
        showCompletedCheckmark: {
            type: Boolean,
            default: false,
            required: false
        },
        // Passed in string will be concatenated with 'fa-' to create the class
        // For information about the available sizes, see https://fontawesome.com/docs/web/style/size
        completedCheckmarkSize: {
            type: String,
            default: "3x",
            required: false
        },
        //Whether or not we allow mp4/mov/wmv
        video: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    computed: {
        inputId() {
            return `file-${this.$.vnode.key ?? 'upload'}`
        },
        labelId() {
            return `file-label-${this.$.vnode.key ?? 'upload'}`
        },
        teleportErrorsId() {
            return `teleport-errors-${this.$.vnode.key ?? 'upload'}`
        },
        teleportErrorsTo() {
            if (this.teleportErrors) {
                return this.teleportErrors;
            } else {
                return `#${this.teleportErrorsId}`;
            }
        },
        iconClasses() {
            return ['text-' + this.iconSize + 'xl']
        },
        completedCheckmarkClasses() {
            return ['fa-' + this.completedCheckmarkSize]
        },
        acceptedTypesString() {
            return this.computedAcceptedTypes.join(', ');
        },
        modalComputedClasses() {
            let classes = [];
            if (this.fullScreenDrop || !this.dragging) {
                classes = [...classes, "border-4", "border-dashed"];
            } else {
                classes = [...classes, "border-4", "border-primary-600"];
            }

            if(this.requiredHighlight){
                classes.push('border-yellow-300/40');
            }

            if(this.uploadWrapperClass){
                classes.push(this.uploadWrapperClass);
            }
            if(this.disabled) {
                classes.push('cursor-not-allowed');
                classes.push('uploader--disabled');
            }

            return classes;
        },
        dropHandlers() {
            return {
                'dragenter': this.onDragEnter,
                'dragover': this.onDragOver,
                'drop': this.onDragDrop,
                'dragleave': this.onDragLeave,
            }
        },
        maxSizeMb() {
            return this.maxSize / 1000000;
        },
        confirmButtonText() {
            return this.confirm?.confirmButtonText ?? "yes";
        },
        confirmButtonRole() {
            return this.confirm?.confirmButtonRole ?? "danger";
        },
        cancelButtonText() {
            return this.confirm?.cancelButtonText ?? "no";
        },        
        
        cancelButtonRole() {
            return this.confirm?.cancelButtonRole ?? "default";
        },
        confirmMessage() {
            return this.confirm?.message ?? "Are you sure you want to upload this file?"
        },
        errors() {
            return [...this.customErrors, ...this.uploadErrors];
        },
        computedAcceptedTypes() {
            if (this.video) {
                return [
                ...this.acceptedTypes,
                ...this.videoTypes
                ];
             } else {
                return this.acceptedTypes
            }
        }
    },
    methods: {

        uploadFile: function() {
            if ((this.uploadStatus !== 'ready' && this.uploadStatus !== 'holding')) {
                return;
            }
            if (!this.file) {
                this.uploadErrors = ['Please select a file'];
                return;
            }

            // format and append additional form data to the request
            const formData = Object.entries(this.formBody).reduce((formData, [key, value]) => {
                // formData treats null as if it was 'null' and '' as if it was null for
                // some reason, so we need to handle that here 
                if(value === null){
                    value = '';
                }

                formData.append(key, value);
                return formData;
            }, new FormData());

            // add the route name to the form data
            if(!formData.has("_route")){
                formData.append("_route", this.$route.name);
            }

            // Maybe change 'file' field to be received as a prop
            formData.append("file", this.file.file);
            this.uploadStatus = 'uploading';
            this.$http.post(this.endpoint, formData)
            .then(response => {
                this.$emit('uploadSuccess', response);
                this.$refs.upload?.clear();
                //application alias may not exists for unassigned uploads
                if (this.setResponseAsApplication && response.data.alias !== undefined) {
                    this.$store.dispatch('applications/setCurrentApplication', { application: response.data, refreshApplications: true});
                }
                this.uploading = false;
                this.uploadStatus = 'success';
                setTimeout(() => {
                    this.clearFiles();
                }, 5000);
            }).catch((error) => { 
                this.$emit('uploadError', error);
                this.$refs.upload?.clear();
                this.uploadStatus = 'error';
                this.responseErrors = error.response;

                // if a validation error
                if (error.response?.status === 422) {
                    const errors = Object.values(error.response.data?.errors?.validationErrors);
                    if (errors) {
                        const note = { type: 'error', message: errors.join('. ')};
                        this.$store.dispatch('notifications/create', note);
                    }
                }

                // if we errored out, but don't get any error status back then it looks like the request 
                // didn't resolve properly, this is most likely caused by a network error of some kind
                if(!error.response?.status){
                    this.uploadErrors = ["Connection Error: Please refresh your browser and try again"];
                }

                setTimeout(() => {
                    this.clearFiles();
                }, 5000);
            })
        },
        /*
         * Valid file received. Upload file to server
         */
        inputFile: function (newFile) {
            if ((this.uploadStatus !== 'ready' && this.uploadStatus !== 'holding') || newFile == null) {
                return;
            }
            this.file = newFile;
            this.responseErrors = null;
            if (this.holdUpload) {
                this.uploadStatus = 'holding';
                this.holdingFilename = newFile.name
                // Decide what to do with status until confirmed
                this.$emit('uploadReady', this.uploadFile);
            } else if (this.confirm) {
                this.showConfirmation = true;
            } else {
                this.uploadFile();
            }
        },

        /**
         * Pretreatment
         * @param  Object|undefined   newFile   Read and write
         * @param  Object|undefined   oldFile   Read only
         * @param  Function           prevent   Prevent changing
         * @return undefined
         */
        inputFilter: function (newFile, oldFile, prevent) {
            if ((this.uploadStatus !== 'ready' && this.uploadStatus !== 'holding')) return prevent();
            this.clearFiles();
            this.$emit('fileInput')
            const type = newFile.name.split('.').pop();
            const errors = [];
            if (newFile && !oldFile) {
                if (newFile.size > this.maxSize) {
                    // Have to divide Bytes by 1 millon to get the mb. Have to also be careful of rounding errors
                    errors.push("File is too large. Max size: " + this.maxSizeMb + "MB");
                }
                if (!this.computedAcceptedTypes.includes(type.toLowerCase())) {
                    errors.push("File extension " + type + " is not valid, accepted types are: " + this.acceptedTypesString);
                }
                if (this.maxFiles > 0 && this.currentFiles >= this.maxFiles) {
                    errors.push("Maximum files allowed reached ("+ this.maxFiles + "). Please delete some files to upload more")
                }
                this.uploadErrors = errors;
                if (errors.length > 0) return prevent();
            }
        },
        // Handler methods to fix bug on VueUploadComponent
        onDragEnter(e) {
            e.preventDefault();
            this.dragCount++;
        },
        onDragLeave(e) {
            // To avoid flickering, if we are transitioning from the label to the card 
            // we do not change the dragging status
            e.preventDefault();
            this.dragCount--
        },
        onDragDrop(e) {
            e.preventDefault();
            this.dragCount = 0;
            if(this.disabled) {
                return;
            }
            e.dataTransfer && this.$refs.upload.addDataTransfer(e.dataTransfer)
        },
        onDragOver(e) {
            e.preventDefault();
        },
        handleFileDeletion() {
            // TODO: Decide if deleting a file should clear the errors
            // The deletion may cause the files amount to be under the
            // maximum files. So the error could go away in this case
            this.clearErrors();
        },
        handleConfirmButton() {
            this.showConfirmation = false;
            this.uploadFile();
        },
        cancelConfirmation() {
            this.showConfirmation = false;
            this.clearFiles();
        },
        clearErrors() {
            this.uploadErrors = [];
        },
        clearFiles() {
            this.uploadStatus = 'ready';
            this.holdingFilename = '';
            this.$refs.upload?.clear();
            this.file = null;
        },
    },
    watch: {
        dragCount(newVal) {
            if (newVal > 0) {
                this.dragging = true;
            } else {
                this.dragging = false;
            }
        },
        customErrors(errors) {
            if (errors.length > 0) {
                this.uploadErrors = [];
            }
        },
    }
}
</script>

<style scoped>

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.uploader--disabled {
    background: #efefef;
    color: #bbb;
}
</style>