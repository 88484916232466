import objectPropertiesHaveValue from "../services/utils/objectPropertiesHaveValue";
import hasValidGoals from "@/services/utils/hasValidGoals.js"

const nonSignatureSections = [
    "overview",
    "upload",
    "clientSummary",
    "currentEquipment",
    "physicalAbilities",
    "hearingAndVision",
    "languageSkills",
    "literacy",
    "cognitiveAbilities",
    "communicationNeeds",
    "otherApproaches",
    "deviceFeatures",
    "languageFeatures",
    "deviceAccessories",
    "devicesRuledOut",
    "deviceSelected",
    "trialInformation",
    "evaluationData",
    "deviceJustification",
    "planOfCare",
    "treatmentGoals",
];

export default {
    namespaced: true,

    state() {
        return {};
    },

    mutations: {},

    actions: {},

    getters: {
        evaluation(_, __, rootState) {
            return rootState.applications.current?.evaluation_v2_info?.evaluation ?? {};
        },
        sections(_state, getters, _rootState, _rootGetters) {
            //const COMPLETED = rootGetters.getStatusId("COMPLETED");
            //const IN_PROGRESS = rootGetters.getStatusId("IN_PROGRESS");

            let finalize = getters.evaluation?.signatures?.status_id;
            const sections = {
                finalize: finalize,
            };

            // cycle through our non-signature sections and forward to the appropriate getter
            nonSignatureSections.forEach(
                (section) => (sections[section] = getters[section])
            );

            return sections;
        },

        /**
         * Is this evaluation overview complete?
         *
         * @returns int (status_id)
         */
        overview(_state, _getters, _rootState, rootGetters) {
            return rootGetters["applications/evaluationV2Type"] === null
                ? rootGetters.getStatusId("NOT_STARTED")
                : rootGetters.getStatusId("COMPLETED");
        },

        /**
         * Is this evaluation upload complete?
         *
         * @returns int (status_id)
         */
        upload(_state, _getters, rootState, rootGetters) {
            const uploads = rootState.applications.current?.evaluation_v2_info?.uploads ?? [];
            const hasUploads = uploads.length
                && uploads.some(upload => upload.status_id !== rootGetters.getStatusId("REJECTED"));

            return hasUploads ? rootGetters.getStatusId("COMPLETED") : rootGetters.getStatusId("NOT_STARTED");
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        clientSummary(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "medical_history",	
                "current_communication_status",		
                "therapeutic_history",
                "prognosis_for_speech_production",	
                "anticipated_course_of_impairment",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        currentEquipment(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            let properties = [
                "due_to_loss_or_theft",
                "currently_owns_a_funded_sgd",
            ];

            if (evaluation.currently_owns_a_funded_sgd) {
                let additionalProperties = [
                    "device_and_accessories_owned",
                    "device_purchase_date",
                    "device_under_warranty",	
                    "why_replacement_required",
                ];

                properties = [...properties, ...additionalProperties];
            }


            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        physicalAbilities(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "methods_used_to_assess_physical_status",
                "fine_motor_skills_description",
                "gross_motor_skills_description",
                "ambulation_status",
                "mobility_assistance_needed",
                "access_methods_considered",
                "mounting_system_required_for_transport",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        hearingAndVision(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "hearing_status",
                "hearing_information",
                "hearing_sgd_modifications",
                "vision_status",
                "vision_information",
                "vision_sgd_modifications",
            ];

            if (evaluation.hearing_sgd_modifications?.map(item => item.toLowerCase()).includes("other")) {
                properties.push("other_required_hearing_features");
            }

            if (evaluation.vision_sgd_modifications?.map(item => item.toLowerCase()).includes("other")) {
                properties.push("other_required_vision_features");
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        languageSkills(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "methods_used_to_assess_language_skills",
                "methods_of_communication",
                "intelligibility_of_natural_speech",
                "peer_vocabulary",
                "client_expressive_vocabulary",
                "client_understands_verbal",
                "client_understands_following",
                "receptive_language_skills",
                "expressive_and_receptive_language_gap",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        literacy(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "education_status",
                "literacy_level",
                "literacy_skills",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        cognitiveAbilities(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "show_cognition_statement_in_report",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        communicationNeeds(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "communication_partners",
                "communication_functions",
                "communication_environments",
                "communication_situations",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        otherApproaches(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "other_approaches_natural_speech",
                "other_approaches_writing",
                "other_approaches_gestures",
                "other_approaches_analog_device",
                "other_approaches_low_tech_device",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        deviceFeatures(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "device_size",
                "device_portability",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        languageFeatures(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "language_representation_methods",
                "type_of_message_formulation",
                "rate_enhancing_strategies",
                "additional_software_features",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        deviceAccessories(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "sgd_access_methods",
                "justify_sgd_access_method",
            ];

            if (evaluation.sgd_access_methods === "manual direct selection (hands or feet)") {
                properties.push("sgd_keyguard_or_touchguide_needed");
            }

            if (evaluation.sgd_access_methods === "switch scanning") {
                properties.push("sgd_options_switches_needed");
            }

            if (evaluation.sgd_access_methods === "a combination of methods") {
                properties.push("sgd_options_combination_description");
            }

            if (evaluation.device_mount_required?.length) {
                properties.push("justify_device_mount");
            }

            if (evaluation.additional_accessories?.map(item => item.toLowerCase()).includes("additional protective device wrap (not included with base device)")) {
                properties.push("additional_protective_wrap");
            }

            if (evaluation.additional_accessories?.map(item => item.toLowerCase()).includes("additional symbol set (not included with base device)")) {
                properties.push("additional_symbol_set");
            }

            if (evaluation.additional_accessories?.map(item => item.toLowerCase()).includes("additional synthesizer/voices (not included with base device)")) {
                properties.push("additional_synthesizer_or_voices");
            }

            if (evaluation.additional_accessories?.map(item => item.toLowerCase()).includes("other accessory (not included with base device)")) {
                properties.push("additional_other_accessory");
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        devicesRuledOut(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "ipad_ruled_out",
                "device_ruled_out_term",
                "sgd_ruled_out_name_1",
                "sgd_ruled_out_justification_1",
                "sgd_ruled_out_name_2",
                "sgd_ruled_out_justification_2",
            ];

            if (evaluation.sgd_ruled_out_name_3) {
                properties.push("sgd_ruled_out_justification_3");
            }

            if (evaluation.sgd_ruled_out_justification_3) {
                properties.push("sgd_ruled_out_name_3");
            }

            if (evaluation.sgd_ruled_out_name_4) {
                properties.push("sgd_ruled_out_justification_4");
            }

            if (evaluation.sgd_ruled_out_justification_4) {
                properties.push("sgd_ruled_out_name_4");
            }
        
            if (evaluation.sgd_ruled_out_name_5) {
                properties.push("sgd_ruled_out_justification_5");
            }

            if (evaluation.sgd_ruled_out_justification_5) {
                properties.push("sgd_ruled_out_name_5");
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        deviceSelected(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "sgd_selected",
                // "device_accessories", device accessories is optional
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        trialInformation(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            let properties = [
                "trial_not_required",
            ];

            if(!evaluation.trial_not_required){
                properties = [
                    ...properties, 
                    "trial_length_weeks",
                    "trial_device_name",
                    "trial_start_date",
                    "trial_end_date",
                    "trail_device_usage",
                ];
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        evaluationData(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "evaluation_device_set_up",
                "evaluation_client_learned",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        deviceJustification(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "device_justification",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        planOfCare(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "support_persons",
                "client_training_sessions",
                "client_training_topics",
                "client_treatment_sessions",
                "client_treatment_setting",
            ];

            if(evaluation.support_persons?.map(item => item.toLowerCase()).includes("other")){
                properties.push("support_persons_other");
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },
        
        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        treatmentGoals(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [];

            // If written based treatment goal we just check for a value in the field
            if(evaluation.using_written_treatment_goals) {
                properties.push("written_treatment_goals");
                return objectPropertiesHaveValue(evaluation, properties);
            } 
            
            // "id" and "foo" properties are a hacky way to push a 'false' and a 'true' to the array,
            // since an evaluation will always have an id, and it will never have the foo property. 
            if(hasValidGoals(evaluation.treatment_goals)) {
                properties.push("evaluation_v2_info_id");
            } else {
                properties.push("foo");
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },
    },
};
