
const initialParams = {
    orderBy: "created_at",
    date_start: null,
    date_end: null,
    state_filter: [],
    category_filter: null,
};

export default {

    namespaced: true,

    state() {
        return {
            params: { ...initialParams },
            unassignedUploads: [],
            pages: 1,
            per_page: 25,
            page: 1,
            total: 0,
            categories: [
                { value: "NEW_APPLICATION", text: "New Application Document" }, 
                { value: "ADDITIONAL_REQUIRED", text: "Additional Required Document" }, 
                { value: "APPROVAL", text: "Approval" }, 
                { value: "DEFERRAL_DENIAL", text: "Deferral/Denial" },  
                { value: "PURCHASE_ORDER", text: "Purchase Order" }, 
                { value: "REPAIR", text: "Repair" }, 
                { value: "OTHER", text: "Other" },  
                { value: null, text: "None" },  
            ],
        }
    },
    mutations: {
        clearFilters(state) {
            state.params = { ...initialParams };
        },
        setUnassignedUploads(state, unassignedUploads) {
            state.unassignedUploads = unassignedUploads;
        },
        setTotal(state, total) {
            state.total = total;
        },
        setPages(state, pages) {
            state.pages = pages;
        },
        setParam(state, { param, value }) {
            state.params[param] = value;
        },
        setPage(state, page) {
            state.page = page;
        },
        setPerPage(state, per_page) {
            state.per_page = per_page;
            state.page = 1;
        },
    },
    actions: {
        setParam({ commit }, data){
            commit("setParam", data);
        },
        clearFilters({ commit }) {
            commit("clearFilters");
        },
        async loadAll({ commit, state }){
            console.log("load all fired");
            let url = "funding/unassigned/uploads";

            let query = {};

            if (state.page) {
                query.page = state.page;
            }
            if (state.per_page) {
                query.per_page = state.per_page;
            }
            if (state.params.date_start) {
                query.date_start = state.params.date_start;
            }
            if (state.params.date_end) {
                query.date_end = state.params.date_end;
            }
            if (state.params.orderBy) {
                query.sort = state.params.orderBy;
            }
            if (state.params.state_filter.length > 0) {
                query.state = state.params.state_filter;
            }
            if (state.params.category_filter) {
                query.category = state.params.category_filter;
            }
            const unassignedUploads = await this.$http.get(url, { params: query, successNotification: false });
            commit("setUnassignedUploads", unassignedUploads.data.data);
            commit("setTotal", unassignedUploads.data.total);
            commit("setPages", unassignedUploads.data.last_page);
        },

        async addToApplication({ commit, dispatch }, editing){
            
            await this.$http.patch(
                `funding/unassigned/uploads/${editing.file}`,
                editing
            );
            commit("setPage", 1); //maybe remove this? If the file is the only one present on a page after 1 it makes the list look empty
            dispatch("loadAll");
        }

    },
    getters: {
        params(state) {
            return state.params;
        },
        unassignedUploads(state) {
            return state.unassignedUploads;
        },
        categories(state) {
            return state.categories;
        },
        total(state) {
            return state.total;
        },
        page(state) {
            return state.page;
        },
        pages(state) {
            return state.pages;
        },
        per_page(state) {
            return state.per_page;
        },
        //maybe use this later
        show_closed(){
            return false;
        }
    },
};