<template>
   <DropdownSelector
        v-bind="$attrs"
        :selection="selection"
        :selectedWindow="selectedWindow"
        :name="name"
        searchValue="text"
        itemValue="id"
        itemText="text"
        :setTheme="setTheme"
    >                            
        <template #additional-controls>
            <div class="flex gap-1.5 text-xxs flex-wrap px-4 pt-4 pb-2">
                <expiring-button 
                    v-for="expiringWindow in expiringWindows" 
                    :expiringWindow="expiringWindow"
                    :selectedWindow="selectedWindow"
                    :setTheme="setTheme"
                    :key="expiringWindow.value" 
                    @selectExpiringWindow="selectExpiringWindow"
                />
            </div>
        </template>
    </DropdownSelector>
</template>
<script>
import DropdownSelector from "./DropdownSelector";
import ExpiringButton from "./ExpiringButton";
export default {
    components: {
        DropdownSelector,
        ExpiringButton,
    },
    
    data() {
        return {
            expiringWindows: [
                { value: 7, text: "1 week" },
                { value: 14, text: "2 weeks" },
                { value: 30, text: "30 days" },
                { value: 60, text: "60 days" },
            ],
        };
    },
    
    props: {
        selection: {
            default: [],
        },
        setTheme: {
            type: String,
            default: 'default',
        },
        name: {
            type: String,
            default: 'EXPIRING',
        },
        selectedWindow: {
            type: Number,
            default: 14,
        },
    },

    computed: {
        computedClasses(){
            if(this.setTheme === "dark"){
                return "hover:bg-blueGray-900 bg-blueGray-900/60 text-accentBlue";
            }

            return "hover:bg-secondary-100 bg-gray-100";
        },
    },

    methods: {
        selectExpiringWindow(window){
            this.$emit("onSelectExpiringWindow", window);
        },
    },
};
</script>
<style scoped>
</style>
