<template>
    <form-teleport :teleport="teleport"></form-teleport>
</template>

<script>
import FormTeleport from "./FormTeleport.vue";

export default {
    props: ["field"],
    components: {
        FormTeleport,
    },  
    computed: {
        teleport(){
            return {
                id: this.field.id ?? this.field.name,
                class: this.field.class,
            }
        },
    },
};
</script>
