export default [
    {
        path: "",
        name: "application.tandc",
        component: () => import("@/views/application/tandc/overview.vue"),
        meta: {
            title: "Terms and Conditions - Overview",
        },
    },
    {
        path: "upload",
        name: "application.tandc.upload",
        component: () => import("@/views/application/tandc/upload.vue"),
    },
    {
        path: "terms-and-conditions",
        name: "application.tandc.termsAndConditions",
        component: () => import("@/views/application/tandc/tandc.vue"),
        meta: {
            title: "Terms & Conditions",
        },
    },
    {
        path: "roles-and-responsibilities",
        name: "application.tandc.randr",
        component: () => import("@/views/application/tandc/randr.vue"),
        meta: {
            title: "Terms & Conditions - Roles and Responsibilities",
        },
    },
    {
        path: "responsible-party",
        name: "application.tandc.responsibleParty",
        component: () =>
            import("@/views/application/tandc/responsibleParty.vue"),
        meta: {
            title: "Terms & Conditions - Responsible Party",
        },
    },
];
