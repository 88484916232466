<template>
    <base-modal
        :open="true"
        @close="$emit('close')"
        class="w-xs"
        title="Save your current filters as a preset"
        icon="circle-plus"
    >
        <quick-form 
            :schema="schema"
            @success="onSuccess"
            ref="form"
        />
    </base-modal>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: true
        },
        presets: {
            type: Array,
            required: true
        },
    },

    emits: ['save', 'close'],

    computed: {
        schema(){
            return {
                fields: [
                    {
                        name: "name",
                        label: "Preset Name",
                        value: "",
                        required: true,
                        attributes: {
                            maxlength: "25",
                        },
                        validate: ["scaryChars"]
                    },
                ],
            };
        },
    },

    methods: {
        onSuccess({ inputs }){
            this.$emit('save', inputs.name);
            this.$emit('close');
        },
    },
}
</script>

<style>

</style>