<template>
    <!-- button -->
    <component
        :is="component"
        v-bind="$attrs"
        :role="role"
        type="button"
        :disabled="disabled"
        :size="size"
        @click.prevent.stop="showConfirmation = true"
        :class="computedClasses"
    >
        <slot></slot>
    </component>

    <!-- confirmation modal -->
    <base-modal
        :open="showConfirmation"
        @close="showConfirmation = false"
        class="w-xs"
        :title="title"
        :icon="modalIcon"
        :titleRole="titleRole"
    >
        <div class="grid gap-4">
            <p v-if="message" v-html="message"></p>

            <quick-form 
                :schema="schema"
                ref="confirmForm"
                @updated="onUpdated"
                @submit="onSubmit"
                @error="onError"
                @success="onSuccess"
            >
                <template #buttons>
                    <!-- buttons -->
                    <div class="flex items-center justify-end gap-3">
                        
                        <secondary-button
                            type="button"
                            @click="handleNoButton"
                            :role="cancelButtonRole"
                        >
                            {{cancelText}}
                        </secondary-button>
                    </div>
                </template>
            </quick-form>

            
        </div>
    </base-modal>
</template>

<script>
export default {
    emits: ["formUpdated", "formErrors", "formSubmitted", "formSuccess", "declined"],
    data() {
        return {
            showConfirmation: false,
            confirmInput: '',
            fieldInput: '',
        }
    },
    props: {
        component: {
            type: String,
            default: "secondary-button",
        },
        message: {
            type: String,
            default: "Are you sure you want to perform this action?",
        },
        role: {
            type: String,
            default: "danger",
        },
        disabled: {
            type: Boolean,
        },
        size: {
            type: String,
        },
        confirmText: {
            type: String,
            default: "yes",
        },
        cancelText: {
            type: String,
            default: "no",
        },
        typeConfirm: {
            type: String,
        },
        confirmButtonRole: {
            type: String,
            default: "danger",
        },
        cancelButtonRole: {
            type: String,
            default: "default",
        },
        titleRole: {
            type: String,
            default: "default",
        },
        title: {
            type: String,
            default: "Please Confirm",
        },
        modalIcon: {
            type: String,
            default: 'badge-check',
        },
        field: {
            type: Object,
            // {string} label: text label for the field
            // {bool} required: is this field required?
        },
        schema: {
            type: Object,
            required: true
        }
    },
    computed: {
        computedClasses() {
            return this.disabled ? "opacity-70 pointer-events-none" : null;
        },
        canConfirm() {
            let result = true;

            if(!this.typeConfirm && !this.field){
                return result;
            }

            if(this.field?.required && !this.fieldInput){
                result = false;
            }

            if(this.typeConfirm && this.typeConfirm != this.confirmInput){
                result = false;
            }

            return result;;
        }
    },
    methods: {
        onUpdated(event) {
            this.$emit('formUpdated', {event: event, form: this.$refs.confirmForm});
        },
        onSubmit() {
            this.$emit("formSubmitted", {form: this.$refs.confirmForm});
        },
        onError(errors) {
            this.$emit("formErrors", {errors: errors, form: this.$refs.confirmForm});
        },
        onSuccess(response) {
            this.$emit("formSuccess", {response: response, form: this.$refs.confirmForm});
            this.reset();
        },
        handleNoButton() {
            this.$emit('declined');
            this.reset();
        },
        reset() {
            this.$refs.confirmForm.reset();
            this.showConfirmation = false;
        }
    }
};
</script>
