<template>
    <div class="w-full md:w-[196px]">
        <div class="py-2" v-if="$slots.header"><slot name="header"></slot></div>
        <a :href="url" @click.prevent="openFile" :class="loading ? null : 'cursor-pointer'">
            <base-card :hideHeader="true" :noMargin="noMargin" class="shadow-lg w-full">
                <div class="flex flex-col justify-center items-center px-2">
                    <file-icon :showThumbnail="showThumbnail" :type="type" :url="url" :size="size"></file-icon>
                    <div v-if="$slots.filename"><slot name="filename"></slot></div>
                </div>
                <template #footer>
                    <primary-button class="px-2 w-full" :disabled="loading">
                        <i class="mr-1 opacity-75" :class="iconClass"></i>
                        {{ !loading ? 'Download' : '' }}
                    </primary-button>
                </template>
            </base-card>
        </a>
    </div>
</template>

<script>

import fileIconMixin from "./mixins/fileIconMixin.js";
export default {
    mixins: [fileIconMixin], 
    props: {
        showThumbnail: {
            type: Boolean,
            required: false,
            default: true,
        },
        type: {
            type: String,
            required: false,
        },
        url: {
            type: String,
            required: true,
        },
        iconSize: {
            type: Number,
            required: false,
        },
        noMargin: {
            type: Boolean,
            required: false,
            default: false,
        },
        directLink: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return {
            loading: false,
        };
    },
    computed: {
        size(){
            return this.iconSize ?? 9;
        },

        iconClass(){
            if(this.loading){
                return "fas fa-spin fa-spinner-third"
            }

            return "fad fa-download";
        }
    },
    methods: {
        openFile() {
            if(this.loading){
                return;
            }

            if(this.directLink){
                window.open(this.url, '_blank');
                return 
            }

            this.loading = true;

            this.$http.get(this.url, { openInNewTab: true })
                .finally(() => this.loading = false);
        },
    },
};
</script>