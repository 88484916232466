<template>
    <div v-if="receivedSuggestions || hasError || loading" class="w-full order-4 z-10 relative">
        <div class="absolute left-0 right-0 top-0">
            <!-- loading -->
            <div 
                v-if="loading" 
                class="text-sm animate-pulse p-2 border-gray-200 bg-yellow-50 ">
                    Loading suggestions...
            </div>

            <!-- error -->
            <div 
                v-if="hasError" 
                @click="hasError = false"
                class="text-sm text-red-600 p-2 bg-gray-50 border border-gray-200 rounded-b">
                    Autocomplete failed
            </div>
            
            <!-- no results -->
            <div 
                v-if="!suggestions.length && receivedSuggestions" 
                class="bg-gray-50 border border-gray-200 rounded-b"
            >
                <div class="px-3 py-1 cursor-pointer hover:bg-secondary-200" @click="clear">No Results</div>
            </div>

            <!-- suggestions -->
            <div v-if="suggestions.length" class="w-full relative">
                <ul class="bg-gray-50 border border-gray-200 rounded-b" :class="suggestions.length > 8 ? 'overflow-y-scroll h-64' : ''">
                    <autocomplete-option 
                        v-for="(suggestion, suggestionIndex) in suggestions" 
                        :key="suggestionIndex"
                        :option="suggestion"
                        :column="column"
                        :displayTransform="settings.displayTransform"
                        :returnObject="settings.returnObject"
                        :index="index"
                        :optionIndex="suggestionIndex"
                        @selected="itemSelected"
                    />
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import AutocompleteOption from "./AutocompleteOption.vue";
export default {
    components: {
        AutocompleteOption,
    },
    props: {
        settings: {
            type: Object,
        }
    },
    data() {
        return {
            suggestions: [],
            index: 0,
            debounceInterval: null,
            defautDebounceTime: 300,
            hasError: false,
            receivedSuggestions: false,
            loading: null,
            lastValue: '',
        }
    },
    computed: {
        column(){
            return this.settings?.column;
        },

        minCharacters(){
            return this.settings?.minCharacters || 0;
        },

        debounceTime(){
            return this.settings?.debounceTime || this.defautDebounceTime;
        }
    },

    methods: {
         debounceInput(event){
            if(this.debounceInterval) {
                clearTimeout(this.debounceInterval);
            }

            this.debounceInterval = setTimeout(() => {
                this.handleAutocomplete(event)
            }, this.debounceTime);
        },


        async handleAutocomplete(event) {
            let value = event.target.value;

            // if our input is empty clear our autocomplete suggestions and abort
            if(value === '' || value?.trim()?.length < this.minCharacters) {
                this.abort();
                this.clear();
                return;
            }

            // if we pressed a non-alphanumeric, non-space, non-backspace key abort
            if(!/^(Backspace)$|^([a-zA-Z0-9\s])$/.test(event.key)) {
                return;
            }

            // does our current value start with the same string as the last value?
            let newValueStartsWithLast = value?.toLowerCase()?.startsWith(this.lastValue?.toLowerCase());
            this.lastValue = value;

            // if we aren't currently trying to load suggestions
            // and we have at least one real suggestion
            // and but less than our max suggestions from the API (as set by filterResultsUnder) 
            // and the current input starts with the last input
            // don't hit the API again, instead just filter through the current suggestions
            if( !this.loading
                && this.suggestions.length > 0
                && this.suggestions.length < this.settings.filterResultsUnder 
                && newValueStartsWithLast
            ){
                this.filterOptions(value);
                return;
            }

            // if we have no suggestions and the current input starts with the last input
            // then there is no point in hitting the API again
            if( !this.loading && this.suggestions.length === 0 && newValueStartsWithLast){
                return;
            }

            this.makeRequest(value);
        },

        filterOptions(value){
            let suggestions = this.suggestions?.filter(suggestion => {
                return suggestion[this.column]?.toLowerCase().startsWith(value?.toLowerCase());
            });

            this.setSuggestions(suggestions);
        },

        makeRequest(value){
            this.abort();
            this.hasError = false;
            this.receivedSuggestions = false;
            this.loading = new AbortController();

            this.$http.post(
                this.settings.endpoint, 
                { value: value }, 
                { notifications: false, spinner: false, signal: this.loading.signal }
            ).then(response => this.onSuccess(response))
            .catch(error => this.onError(error));
        },

        onSuccess(response){
            this.loading = null;
            let suggestions = response?.data;

            // abort if we didn't get an array back as our data
            if(!Array.isArray(suggestions)){
                this.hasError = true;
                this.clear();
                return;
            }

            this.setSuggestions(suggestions);
        },

        setSuggestions(suggestions){
            this.index = 0;

            if(!Array.isArray(suggestions)){
                suggestions = [];
            }

            this.receivedSuggestions = true;
            this.suggestions = suggestions;
        },

        abort(){
            if(this.loading){
                this.loading.abort();
            }

            this.loading = null;
        },

        onError(error) {
            if(error?.message === "canceled"){
                return;
            }

            this.loading = null;
            this.clear();
            this.hasError = true;
        },

        clear() {
            this.hasError = false;
            this.receivedSuggestions = false;
            this.index = 0;
            this.suggestions = [];
            this.lastValue = null;
        },

        selectCurrent() {
            const selectedOption = this.suggestions[this.index];
            this.itemSelected(selectedOption);
        },

        next() {
            if(this.index + 1 >= this.suggestions.length){
                this.index = this.suggestions.length - 1;
                return;
            }

            this.index++;
        },

        back() {
            if(this.index <= 0){
                this.index = 0;
                return;
            }

            this.index--;
        },

        itemSelected(selectedObject) {
            let value = this.getSelectedValue(selectedObject);
            this.$emit('selected', value);
            this.clear();
        },

        getSelectedValue(selectedObject){
            // if this is a no results object return null
            if(selectedObject.noResults){
                return null;
            }

            // if we want the full object back return it
            if(this.settings?.returnObject){
                return selectedObject;
            }

            // otherwise grab just our column value
            return selectedObject[this.column];
        }
    }
    
}
</script>

<style>

</style>