/**
* Downloads a blob as a file
* 
* @param blob blob 
* @param string name 
* @returns null
*/
export default function downloadFile(blob, name = "file.pdf", options = {}) {
    // create our blob from plain text;
    if(options.plainText){
        blob = new Blob([blob], {
            type: "text/plain;charset=utf-8"
        });
    } else if (options.csv) {
        blob = new Blob([blob], {
            type: "'text/csv'"
        }); 
    }

    const href = URL.createObjectURL(blob);
    const a = Object.assign(document.createElement("a"), {
        href,
        style: "display:none",
        download: name,
    });
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(href);
    a.remove();
}