<template>
   <label
        :for="id"
        class="flex flex-wrap relative items-center mb-2 cursor-pointer"
    >
        <input
            type="checkbox"
            :checked="toggleValue"
            @input="$emit('update:toggleValue', $event.target.checked)"
            :id="id"
            class="sr-only peer"
        />
        <span
            class="w-9 h-5 bg-accentBlue/30 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-transparent after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-secondary-100 after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-accentBlue"
            :class="toggleClasses"
        ></span>
        <span class="ml-3 text-xxs font-medium uppercase">
            {{  title  }}
        </span>
    </label>
</template>

<script>

export default {
    props: {    
        title: {
            type: String,
        }, 
        id: {
            type: String,
        },
        toggleValue: {

        },
        toggleClasses: {
            type: String,
            default: ''
        }
    },

    emits: ['update:toggleValue'],
};
</script>

