export default [
    {
        path: "",
        name: "application.evaluation_v2",
        component: () => import("@/views/application/evaluation_v2/overview.vue"),
        meta: {
            title: "Evaluation - Overview",
        },
    },
    {
        path: "upload",
        name: "application.evaluation_v2.upload",
        component: () => import("@/views/application/evaluation_v2/upload.vue"),
    },
    {
        path: "client-summary",
        name: "application.evaluation_v2.clientSummary",
        component: () => import("@/views/application/evaluation_v2/clientSummary.vue"),
    },
    {
        path: "current-equipment",
        name: "application.evaluation_v2.currentEquipment",
        component: () => import("@/views/application/evaluation_v2/currentEquipment.vue"),
    },
    {
        path: "physical-abilities",
        name: "application.evaluation_v2.physicalAbilities",
        component: () => import("@/views/application/evaluation_v2/physicalAbilities.vue"),
    },
    {
        path: "hearing-and-vision",
        name: "application.evaluation_v2.hearingAndVision",
        component: () => import("@/views/application/evaluation_v2/hearingAndVision.vue"),
    },
    {
        path: "language-skills",
        name: "application.evaluation_v2.languageSkills",
        component: () => import("@/views/application/evaluation_v2/languageSkills.vue"),
    },
    {
        path: "literacy",
        name: "application.evaluation_v2.literacy",
        component: () => import("@/views/application/evaluation_v2/literacy.vue"),
    },
    {
        path: "cognitive-abilities",
        name: "application.evaluation_v2.cognitiveAbilities",
        component: () => import("@/views/application/evaluation_v2/cognitiveAbilities.vue"),
    },
    {
        path: "communication-needs",
        name: "application.evaluation_v2.communicationNeeds",
        component: () => import("@/views/application/evaluation_v2/communicationNeeds.vue"),
    },
    {
        path: "other-approaches",
        name: "application.evaluation_v2.otherApproaches",
        component: () => import("@/views/application/evaluation_v2/otherApproaches.vue"),
    },
    {
        path: "device-features",
        name: "application.evaluation_v2.deviceFeatures",
        component: () => import("@/views/application/evaluation_v2/deviceFeatures.vue"),
    },
    {
        path: "language-features",
        name: "application.evaluation_v2.languageFeatures",
        component: () => import("@/views/application/evaluation_v2/languageFeatures.vue"),
    },
    {
        path: "device-accessories",
        name: "application.evaluation_v2.deviceAccessories",
        component: () => import("@/views/application/evaluation_v2/deviceAccessories.vue"),
    },
    {
        path: "devices-ruled-out",
        name: "application.evaluation_v2.devicesRuledOut",
        component: () => import("@/views/application/evaluation_v2/devicesRuledOut.vue"),
    },
    {
        path: "device-selected",
        name: "application.evaluation_v2.deviceSelected",
        component: () => import("@/views/application/evaluation_v2/deviceSelected.vue"),
    },
    {
        path: "trial-information",
        name: "application.evaluation_v2.trialInformation",
        component: () => import("@/views/application/evaluation_v2/trialInformation.vue"),
    },
    {
        path: "evaluation-data",
        name: "application.evaluation_v2.evaluationData",
        component: () => import("@/views/application/evaluation_v2/evaluationData.vue"),
    },
    {
        path: "device-justification",
        name: "application.evaluation_v2.deviceJustification",
        component: () => import("@/views/application/evaluation_v2/deviceJustification.vue"),
    },
    {
        path: "plan-of-care",
        name: "application.evaluation_v2.planOfCare",
        component: () => import("@/views/application/evaluation_v2/planOfCare.vue"),
    },
    {
        path: "treatment-goals",
        name: "application.evaluation_v2.treatmentGoals",
        component: () => import("@/views/application/evaluation_v2/treatmentGoals.vue"),
    },
    {
        path: "finalize",
        name: "application.evaluation_v2.finalize",
        component: () => import("@/views/application/evaluation_v2/finalize.vue"),
    },
];
