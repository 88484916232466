import startCase from "lodash/startCase";
import hasValue from "./FieldHasValue.js";
import { isObject } from "lodash";

/**
 * Validation Rules
 *
 * A validation rule is a function that takes the value from a form field, and returns either a {bool} true
 * value if the value passes the validation rule, or an error string if field value fails the validation
 *
 * Validation rules are called in a field's schema by passing an array of either rule names, or rule callback functions
 * to that field schema's validate property. For example:
 * { name: "first_name", validate: ["alpha", "min:3"] }
 *
 *
 * @typedef {Object} Context
 * @property {mixed} value - the value of the field being tested.
 * @property {object} fieldSchema - the schema used to build the field being tested.
 * @property {object} formSchema - the schema used to build the entire form
 * @property {object} formValues - An object with name => value pairs for every field in the form.
 * @property {Store} store - the Vuex store for this app.
 *
 * @param {Context} context the first parameter is a context object that has the properties listed above
 * @param {string} [additionalParameters] A test may have any number of additional parameters. when the
 * test is called in a field schema parameters may be passed in by placing a colon after the rule name and
 * and adding a comma separated list of parameter values. For example validating the rule "max:3" will call
 * the max rule and pass "3" as the second parameter after the context object. You may pass any number of
 * parameters in this way via a comma separated list: "between:3,255" would return "3", and "255" as the
 * 2nd and 3rd parameters respectively
 *
 *
 */
const rules = {
    /**
     * Test if the value is set
     *
     * @param {Context} context
     * @returns {true|string}
     */
    required({ value, fieldSchema }) {
        return hasValue(value, fieldSchema) ? true : "This field is required.";
    },

    /**
     * Test if the value is set, but only if another field has content
     *
     * @param {Context} context
     * @param {string} fieldName the name of the field that we are checking has content
     * @returns {true|string}
     */
    requiredWith({ value, fieldSchema, formValues }, fieldName) {
        // check parameter
        if (!hasKey(formValues, fieldName)) {
            throw new TypeError("invalid parameter for rule: requiredWith");
        }

        const fieldHasValue = hasValue(value, fieldSchema);
        const withHasValue = hasValue(formValues[fieldName]);

        if (!withHasValue) {
            return true;
        }

        return fieldHasValue
            ? true
            : `This field is required if ${startCase(fieldName)} is set.`;
    },

    /**
     * Test if the value doesn't contain the following string used for placeholders: xxx 
     *
     * @param {Context} context
     * @returns {true|string}
     */
    placeholders({ value, formValues }, fieldName = null) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        } 

        // if we supply a field name as a parameter, we will only test our primary field
        // for placeholders if the supplied secondary field has a value
        if(fieldName){
            if (!hasKey(formValues, fieldName)) {
                throw new TypeError("invalid parameter for rule: placeholders");
            }

            if(!hasValue(formValues[fieldName])){
                return true;
            };

        }

        return /xxx/.test(value.toLowerCase())
            ? "Placeholder text (xxx) must be replaced."
            : true;
    },

    /**
     * Test if the value contains only letters
     *
     * @param {Context} context
     * @returns {true|string}
     */
    alpha({ value }) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        return /^[a-zA-Z]+$/.test(value) ? true : "Must be letters only.";
    },

    /**
     * Test if the value doesn't contain the following characters: </>\
     *
     * @param {Context} context
     * @returns {true|string}
     */
    scaryChars({ value }) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        return /[<@>]/.test(value)
            ? "A forbidden character <>@ is present."
            : true;
    },

    /**
     * Test if the value contains only letters and numbers
     *
     * @param {Context} context
     * @returns {true|string}
     */
    alphaNum({ value }) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        return /^[0-9a-zA-Z]+$/.test(value) ? true : "Must be alphanumeric.";
    },

    /**
     * Test if the value doesn't contain any hyphens
     *
     * @param {Context} context
     * @returns {true|string}
     */
    noHyphen({ value }) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        return /-/.test(value) ? "Please no hyphens." : true;
    },

    /**
     * Test if the value contains at least one letter
     *
     * @param {Context} context
     * @returns {true|string}
     */
    hasLetter({ value }) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        return /[a-zA-Z]/.test(value) ? true : "Must include a letter.";
    },

    /**
     * Test if the value contains at least one letter
     *
     * @param {Context} context
     * @returns {true|string}
     */
    hasNumber({ value }) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        return /[0-9]/.test(value) ? true : "Must include a number.";
    },

    /**
     * Test if the value contains at least one letter or one number
     *
     * @param {Context} context
     * @returns {true|string}
     */
    hasAlphaNum({ value }) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        return /[a-zA-Z0-9]/.test(value) ? true : "Must include a letter or a number.";
    },

    /**
     * Test if the value is an integer
     *
     * @param {Context} context
     * @returns {true|string}
     */
    integer({ value }) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        return /^[0-9]+$/.test(value) ? true : "Must be a number.";
    },

    /**
     * Test if the value appears to be an email address
     *
     * @param {Context} context
     * @returns {true|string}
     */
    email({ value }) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
            ? true
            : "Must be an email.";
    },

    /**
     * Test if the value appears to be a phone number
     *
     * @param {Context} context
     * @returns {true|string}
     */
    phoneNumber({ value }) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        return /^[\0-9+\s]{0,3}[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(
            value
        )
            ? true
            : "Must be an phone number.";
    },

    /**
     * Test if a field has a minimum number of characters
     *
     * @param {Context} context
     * @returns {true|string}
     */
    minLength({ value }, min) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        // check min parameter
        if (!isPositiveInteger(min)) {
            throw new TypeError("invalid parameter for rule: minLength");
        }

        return `${value}`.length >= min
            ? true
            : `Must be at least ${min} characters.`;
    },

    /**
     * Test is a field doesn't exceed a number of characters
     *
     * @param {Context} context
     * @returns {true|string}
     */
    maxLength({ value }, max) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        // check parameter
        if (!isPositiveInteger(max)) {
            throw new TypeError("invalid parameter for rule: maxLength");
        }

        return `${value}`.length <= max
            ? true
            : `Must be no more than ${max} characters.`;
    },

    /**
     * Test of a field has between min and max characters
     *
     * @param {Context} context
     * @returns {true|string}
     */
    lengthBetween({ value }, min, max) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        // check parameter
        if (!isPositiveInteger(min) || !isPositiveInteger(max)) {
            throw new TypeError("invalid parameter for rule: lengthBetween");
        }

        return `${value}`.length >= min && `${value}`.length <= max
            ? true
            : `Must be between ${min} and ${max} characters.`;
    },

    /**
     * test if a field has as exact number of characters
     *
     * @param {Context} context
     * @returns {true|string}
     */
    length({ value }, length) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        // check parameter
        if (!isPositiveInteger(length)) {
            throw new TypeError("invalid parameter for rule: length");
        }

        return `${value}`.length === +length
            ? true
            : `Must be exactly ${length} characters.`;
    },

    /**
     * test if a field value matches another field's value (used primarily for confirming passwords or emails)
     *
     * @param {Context} context
     * @param {string} fieldName the name of the field this value must match
     * @returns {true|string}
     */
    same({ value, formValues }, fieldName) {
        // if we haven't set a value yet skip this test
        // and let required handle it if it is required
        if (!hasValue(value)) {
            return true;
        }

        // check parameter
        if (!hasKey(formValues, fieldName)) {
            throw new TypeError("invalid parameter for rule: same");
        }

        return value === formValues[fieldName]
            ? true
            : `This field must match ${startCase(fieldName)}.`;
    },

    /**
     * test if a field has a valid Social security number format (AAA-GG-SSSS)
     *
     * @param {Context} context
     * @returns {true|string}
     */
    socialSecurityNumber({ value }) {
        const sanitized = value.trim().replaceAll("-", "");
        return `${sanitized}`.length === 9
            ? true
            : `Must be a valid social security number (AAA-GG-SSSS)`;
    },

    /**
     * test if a field has more than 10,000 characters
     *
     * @param {Context} context
     * @returns {true|string}
     */
    maxText(event) {
        return this.maxLength(event, 10000);
    },

     /**
     * Test if the selected counts are less than or equal to the max count
     *
     * @param {Context} context
     * @returns {true|string}
     */
     maxCount({ value, fieldSchema }, fieldName) {
        if (!Number.isInteger(fieldSchema.maxCount)) {
            return true;
        }

        if(!isObject(value)){
            return `${fieldName} value must be an object`;
        }

        let totalCount = Object.values(value).reduce((acc, val) => acc + val, 0);

        return totalCount <= fieldSchema.maxCount
            ? true
            : `Total selected may not exceed ${fieldSchema.maxCount}`;
    },
};

/**
 *******************
 * Utility Functions
 *******************
 */

/**
 * Test if a value is (or can can be coerced) into a positive integer
 *
 * @param {mixed} value
 * @returns {bool}
 */
function isPositiveInteger(value) {
    return Number.isInteger(+value) && value > 0;
}

/**
 * Checks if an object has a given key (used in cases where hasOwnProperty would fail)
 *
 * @param {object} obj
 * @param {string} key
 * @returns {boolean}
 */
function hasKey(obj, key) {
    return Object.keys(obj).includes(key);
}

export default rules;
