<template>
    <a
        aria-current="page"
        class="relative inline-flex cursor-pointer items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
        v-if="page - 1 > 1"
        @click="onPageChange(1)"
    >
        1
    </a>

    <a
        aria-current="page"
        class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
        v-if="page - 2 > 1"
    >
        ...
    </a>

    <a
        aria-current="page"
        v-if="page - 1 >= 1"
        class="relative inline-flex cursor-pointer items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
        @click="onPageChange(page - 1)"
    >
        {{ page - 1 }}
    </a>
    <a
        aria-current="page"
        class="relative inline-flex cursor-pointer text-black font-semibold items-center border border-gray-300 bg-white px-4 py-2 text-sm hover:bg-gray-50 focus:z-20"
    >
        {{ page }}
    </a>
    <a
        aria-current="page"
        class="relative inline-flex cursor-pointer items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
        @click="onPageChange(page + 1)"
        v-if="page + 1 <= pages"
    >
        {{ page + 1 }}
    </a>
    <a
        aria-current="page"
        class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
        v-if="page + 2 < pages"
    >
        ...
    </a>
    <a
        aria-current="page"
        class="relative inline-flex cursor-pointer items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
        v-if="page + 1 < pages"
        @click="onPageChange(pages)"
    >
        {{ pages }}
    </a>
</template>

<script>
export default {
    methods: {
        onPageChange(page) {
            this.$emit('pageChange', page);
        },
    },
    
    props: {
        page: {
            type: Number,
        },
        pages: {
            type: Number,
        }
    },

    emits: ['pageChange'],
}
</script>

<style scoped>

</style>