<template>
    <div class="">
        <!-- open form button -->
        <secondary-button 
            v-if="showRejectionButton" 
            role="danger" 
            @click="showRejectionForm = !showRejectionForm"
        >
            Show Rejection Form
        </secondary-button>

        <div v-if="showRejectionForm" class="border border-red-500 px-8 py-5 rounded">
            <quick-form 
                :schema="schema"
                @updated="onUpdated"
                @success="onSuccess"
                class="border-red-500"
                ref="form"
            >
                <template #buttons>
                    <secondary-button @click="showRejectionForm = false">cancel</secondary-button>
                </template>
            </quick-form>
        </div>
    </div>
</template>

<script>
import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";

export default {
    props: {
        presets: {
            type: Array,
            required: false,
        },
        parameter: {
            type: Number,
            required: false,
        },  
    },
    data(){
        return {
            showRejectionForm: false,
            formFields: {},
        }
    },
    computed: {
        currentRoute(){
            return this.$route.name;
        },

        relation(){
            return camelCase(this.$route.meta.infoSection);
        },

        title(){
            // if we have manually set the title via a route meta, use that
            if(this.$route.meta.title){
                return this.$route.meta.title;
            }

            // otherwise lets build it from our route name
            let title = this.currentRoute.replace("application.", "");
            title = title.split(".");
            title = title.map(string => startCase(string));
            return title.join(" - ");
        },

        application(){
            return this.$store.getters["applications/current"] ?? {};
        },

        isAdmin(){
            return this.$store.getters.isAdmin;
        },

        showRejectionButton(){
            return this.isAdmin && !this.showRejectionForm;
        },

        presetOptions(){
            if(!this.presets){
                return [];
            }

            return this.presets.map(option => {
                return { value: option, text: option }
            });
        },  

        schema(){
            return {
                endpoint: `funding/application/${this.application.alias}/reject`,
                name: "rejection",
                submitText: "save rejection",
                submitRole: "danger",
                fields: [
                    {
                        name: "presets",
                        label: "Default Reasons",
                        description: "Select any that apply",
                        type: "checkboxes",
                        required: !this.formFields.custom_reason && this.presetOptions.length,
                        hide: !this.presetOptions.length,
                        options: this.presetOptions,
                        shouldFilter: true,
                        value: [],
                    },
                    {
                        name: "custom_reason",
                        type: "richtext",
                        value: "",
                        shouldFilter: true,
                        required: !this.formFields.presets?.length,
                        toolbar: [
                            'bulletedList', 
                        ],
                    },
                    {
                        name: "relation",
                        type: "hidden",
                        value: this.relation,
                    },
                    {
                        name: "route",
                        type: "hidden",
                        value: this.currentRoute,
                    },
                    {
                        name: "title",
                        type: "hidden",
                        value: this.title,
                    },
                    {
                        name: "reasons",
                        type: "hidden",
                        value: "",
                    },
                    {
                        name: "parameter",
                        type: "hidden",
                        value: this.parameter,
                    }
                ]
            }
        },
    },
    methods: {
        onUpdated({fields, name}){
            this.formFields = fields;
            
            // if we are updating any of our potential reasons run our reason merge
            if(name === "custom_reason" || name === "presets"){
                this.mergeReasons();
            }
        },

        mergeReasons(){
            let reasons = "<ul>";

            if(this.formFields.presets?.length){
                this.formFields.presets.forEach(reason => {
                    reasons += `<li>${reason}</li>`;
                })
            }
            
            if(this.formFields.custom_reason){
                let customReason = this.formFields.custom_reason
                    .replace(/<ul>|<\/ul>/g, "")
                    .replace(/<p>/g, "<li>")
                    .replace(/<\/p>/g, "</li>");

                reasons += customReason;
            }
     
            reasons += "</ul>";

            this.$refs.form.updateValue("reasons", reasons);
        },

        onSuccess({ data }){
            this.$store.dispatch('applications/setCurrentApplication', { application: data, refreshApplications: true });
            this.$refs.form.reset();
        },
    },
};
</script>

<style scoped>
</style>
