<template>
    <!-- rejections list -->
    <error-alert v-if="rejections.length" :icon="false" class="mt-2 grid gap-4">
        <!-- rejection -->
        <overview-rejection 
            v-for="rejection in rejections" 
            :rejection="rejection" 
            :sectionParamName="sectionParamName" 
            :key="rejection.route + rejection.parameter" 
        />
    </error-alert>
</template>

<script>
import OverviewRejection from "./OverviewRejection.vue";

export default {
    components: {
        OverviewRejection,
    },

    props: {
        rejections: {
            type: Array,
            required: false,
        },
        sectionParamName: {
            type: String,
            required: false,
        },
    },
};
</script>

<style scoped></style>
