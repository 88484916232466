/**
 *
 * Guest middleware protects routes from logged in users
 *
 */
export default ({ to, redirect, store, middlewareIncludes }) => {
    if (!middlewareIncludes(to, "guest")) {
        return;
    }

    if (store.getters.loggedIn) {
        redirect({ name: "dashboard" });
    }
};
