export default [
    {
        path: "",
        name: "inbox",
        component: () => import("@/views/inbox/Inbox.vue"),
    },
    {
        path: "thread/:id",
        name: "inbox.thread",
        component: () => import("@/views/inbox/Thread.vue"),
    },
    {
        path: "send/:application_alias/:recipient_id?",
        name: "inbox.send",
        component: () => import("@/views/inbox/CreateThread.vue"),
    },
];