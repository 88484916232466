import router from "@/router/index";
import queryService from "@/services/utils/queryService.js";

const initialTaskParams = {
    search: "",
    states: [],
    names: [],
    insurances: [],
    devices: [],
    types: [],
    sort: "due_by",
    priorities: [0, 1],
    show_overdue: false,
    show_all: false,
    show_completed: false,
    show_only_unassigned: false,
    page: 1,
    pages: 1,
    per_page: 5,
};

export default {
    namespaced: true,

    state() {
        return {
            userTasksLoaded: false,
            userTasks: [],
            applicationTasks: [],
            showTaskModal: false,
            editMode: false,
            createMode: false,
            selectedTask: null,
            adminUsers: [],
            total: 0,
            applicationTotal: 0,
            tasksParams: { ...initialTaskParams },
            paramsToIgnoreForSingleApplication: [
                "states",
                "types",
                "insurances",
                "devices",
            ],
            clickedTask: null,
        };
    },

    mutations: {
        setUserTasksLoaded(state, value = true){
            state.userTasksLoaded = value;
        },
        setClickedTask(state, clickedTask) {
            state.clickedTask = clickedTask;
        },
        setTasksParams(state, tasksParams) {
            state.tasksParams = tasksParams;
        },
        setParam(state, data) {
            state.tasksParams[data.param] = data.value;
        },
        setPages(state, value) {
            state.tasksParams.pages = value;
        },
        setPage(state, value) {
            state.tasksParams.page = value;
        },
        setPerPage(state, value) {
            state.tasksParams.per_page = value;
        },
        setUserTasks(state, userTasks) {
            state.userTasksLoaded = true;
            state.userTasks = userTasks;
        },
        setApplicationTasks(state, tasks) {
            state.applicationTasks = tasks;
        },
        setTotal(state, total) {
            state.total = total;
        },
        setApplicationTotal(state, total) {
            state.applicationTotal = total ?? 0;
        },
        setAdminUsers(state, adminUsers) {
            state.adminUsers = adminUsers;
        },
        setShowTaskModal(state, showTaskModal) {
            state.showTaskModal = showTaskModal;
        },
        setEditMode(state, editMode) {
            state.editMode = editMode;
        },
        setCreateMode(state, createMode) {
            state.createMode = createMode;
        },
        setSelectedTask(state, selectedTask) {
            state.selectedTask = selectedTask;
        },
    },

    actions: {
        setPresets({ dispatch, commit }, preset = {}){
            Object.keys(preset).forEach(key => {
                let data = { param: key, value: preset[key] };
                
                // convert null search value to empty string
                if(key === "search" && !preset[key]){
                    data.value = "";
                }

                commit("setParam", data);
            });

            commit("setPage", 1);
            dispatch("getUserTasks");
        },

        setParam({ commit }, data){
            commit("setParam", data);
        },

        async reopen({ dispatch }, taskData) {
            const response = await this.$http.patch(
                `funding/application/${taskData.application.alias}/tasks/${taskData.id}/reopen`,
                taskData,
                {
                    successNotification: false,
                }
            );
            dispatch("getUserTasks");
            return response;
        },

        clearFilters({ commit }) {
            commit("setTasksParams", { ...initialTaskParams });
        },

        clearFiltersAllTasks({ commit }) {
            //slightly different default filters for all tasks view
            initialTaskParams.sort = "due_by";
            initialTaskParams.show_all = false;
            commit("setTasksParams", { ...initialTaskParams });
        },
        
        async getClickedTask({ dispatch, commit }, options) {
            let url = "funding/tasks/" + options.task_click_through_id;
            const response = await this.$http.get(url, {
                successNotification: false,
            });

            const task = response.data;

            // if we set our fromApplication option and the task we grabbed doesn't
            // match that application, don't commit it. Instead clear the clicked task
            if(options.fromApplication && task.application_id !== options.fromApplication){
                dispatch('clearClickedTask');
                return;
            }

            commit("setClickedTask", task);
            return task;
        },

        clearClickedTask({ commit, state }, options = {}) {
            // if we pass an onlyId options clear the selected task if it matches the ID
            if(options.onlyId && options.onlyId !== state.clickedTask?.id){
                return;
            }

            const route = router.currentRoute?.value;
            const query = {...route.query};

            // clear our query param
            if(query.task_click_through){
                delete query.task_click_through;
                router.replace({ query });
            }

            commit("setClickedTask", null);
        },
        
        async getUserTasks({ state, commit, getters, rootGetters }, extraParams) {
            // reset our user tasks;
            commit("setUserTasks", []);

            // flag that we are now loading tasks
            commit("setUserTasksLoaded", false);

            // if we aren't an admin, abort
            if (!rootGetters.isAdmin) {
                return [];
            }

            // update our page param if needed
            if (getters.page && extraParams?.page) {
                commit("setPage", extraParams.page);
            }
            
            // if our search has fewer than 3 characters, clear it
            if(state.tasksParams?.search?.length < 3){
                commit("setParam", { param: "search", value: "" });
            }

            // copy our tasksParams to use to build our query string
            let params = {...state.tasksParams};
            params.application_id = rootGetters['applications/current']?.id;

            // init our filters
            let filters = ["pages"];

            // if this is a single application add in our ignored params
            if(params.application_id){
                filters = [...filters, ...state.paramsToIgnoreForSingleApplication];
            }

            // use the queryService to build our url
            let url = queryService.formatQuery("funding/my-tasks", params, filters);           
            const response = await this.$http.get(url, {
                successNotification: false,
            });

            // update our page count to 1 if needed
            if (extraParams?.pageChange == false){
                commit("setPage", 1);
            }

            // update the store with our new tasks
            let userTasks = response.data;
            commit("setUserTasks", userTasks.data);
            commit("setApplicationTotal", userTasks.application_total);
            commit("setPages", userTasks.last_page);
            commit("setTotal", userTasks.total);
        },

        async getApplicationTasks({ commit, rootGetters }, alias) {
            commit("setApplicationTasks", []);

            if (!rootGetters.isAdmin || !alias) {
                return;
            }

            const response = await this.$http.get(
                `funding/application/${alias}/tasks`,
                {
                    successNotification: false,
                }
            );

            commit("setApplicationTasks", response?.data ?? []);
        },

        clearApplicationTasks({ commit }) {
            commit("setApplicationTasks", []);
        },

        async getAllAdmins({ commit }) {
            const response = await this.$http.get(`funding/users/admin`, {
                successNotification: false,
            });
            let admins = response.data;
            commit("setAdminUsers", admins);
            return response;
        },

        async createTask({ dispatch }, taskData) {
            const response = await this.$http.post(
                `funding/application/${taskData.alias}/tasks`,
                taskData,
                {
                    successNotification: false,
                }
            );
            dispatch("getUserTasks");
            return response;
        },

        async editTask({ dispatch }, taskData) {
            const response = await this.$http.patch(
                `funding/application/${taskData.application.alias}/tasks/${taskData.id}`,
                taskData
            );
            dispatch("clearClickedTask", { onlyId: taskData.id });
            dispatch("getUserTasks");
            return response;
        },

        async markAsDone({ dispatch }, taskData) {
            const response = await this.$http.patch(
                `funding/application/${taskData.application.alias}/tasks/${taskData.id}/done`,
                taskData,
                {
                    successNotification: false,
                }
            );
            dispatch("clearClickedTask",  { onlyId: taskData.id });
            dispatch("getUserTasks");
            return response;
        },

        async deleteTask({ dispatch }, taskData) {
            const response = await this.$http.delete(
                `funding/application/${taskData.application.alias}/tasks/${taskData.id}`,
                taskData
            );
            dispatch("clearClickedTask",  { onlyId: taskData.id });
            dispatch("getUserTasks");
            return response;
        },

        async createTaskComment({ dispatch }, commentData) {
            const response = await this.$http.post(
                `funding/tasks/${commentData.taskId}/comments`,
                commentData
            );
            dispatch("getUserTasks");
            return response.data;
        },

        async deleteTaskComment({ dispatch }, comment) {
            const response = await this.$http.delete(
                `funding/tasks/comments/${comment.commentId}`
            );
            dispatch("getUserTasks");
            return response;
        },

        openTaskModal({ commit }, { selectedTask, createMode, editMode }) {
            commit("setShowTaskModal", true);
            commit("setCreateMode", !!createMode);
            commit("setEditMode", !!editMode);
            if (selectedTask) {
                commit("setSelectedTask", selectedTask);
            }
        },

        closeTaskModal({ commit }) {
            commit("setShowTaskModal", false);
            commit("setCreateMode", false);
            commit("setEditMode", false);
            commit("setSelectedTask", null);
        },
        loadAll({ dispatch }, extraParams) {
            dispatch( "getUserTasks", extraParams );
        }
    },

    getters: {
        activeFiltersCount(state, _getters, _rootState, rootGetters) {
            let count = 0;
            let ignore = ["page", "pages", "per_page", "sort"];

            if(rootGetters['applications/current']?.id){
                ignore = [...ignore, ...state.paramsToIgnoreForSingleApplication];
            }
    
            // loop through our tasksParams comparing them to our initialTaskParams
            Object.entries(state.tasksParams).forEach(([key, value]) => {
                // if this key is on our ignore list continue without counting it
                if(ignore.includes(key)){
                    return;
                }

                // otherwise compare the current value to our initial value
                if(JSON.stringify(initialTaskParams[key]) !== JSON.stringify(value)){
                    count++;
                }
            });

            return count;
        },
        tasksParams(state) {
            return state.tasksParams;
        },
        userTasksLoaded(state) {
            return state.userTasksLoaded;
        },
        taskTypes(_, __, rootState) {
            return rootState.taskTypes;
        },
        clickedTask(state) {
            return state.clickedTask;
        },
        userTasks(state) {
            return state.userTasks;
        },
        applicationTasks(state) {
            return state.applicationTasks;
        },
        showTaskModal(state) {
            return state.showTaskModal;
        },
        application_id(state) {
            return state.tasksParams.application_id;
        },
        selectedTask(state) {
            return state.selectedTask;
        },
        editMode(state) {
            return state.editMode;
        },
        createMode(state) {
            return state.createMode;
        },
        adminUsers(state) {
            return state.adminUsers;
        },
        total(state) {
            return state.total;
        },
        applicationTotal(state) {
            return state.applicationTotal ?? 0;
        },
        per_page(state) {
            return state.tasksParams.per_page;
        },
        page(state) {
            return state.tasksParams.page;
        },
        pages(state) {
            return state.tasksParams.pages;
        },
    },
};
