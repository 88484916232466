<template>
    <div class="relative flex-col w-full">
        <div class="relative z-30 flex w-full mb-3">
            <div class="hidden sm:block text-3xl w-12">
                <div class="w-full h-full flex items-center justify-start">
                    <status-icon :status="status" />
                </div>
            </div>
            <div
                class="relative overflow-hidden w-full rounded-lg drop-shadow-sm"
            >
                <!-- left hand color splash -->
                <div
                    class="absolute h-full w-[10px] left-0 top-0 bottom-0 opacity-40 z-10"
                    :class="getBackgroundHighlightStatusColor(status)"
                ></div>

                <div
                    class="bg-white w-full border border-gray-300 rounded-lg relative overflow-hidden"
                >
                    <div class="relative">
                        <div class="py-3 pl-6 pr-2.5">
                            <div class="flex items-center justify-between">
                                <div
                                    class="whitespace-nowrap text-ellipsis overflow-hidden sm:text-lg md:text-xl leading-tight -ml-0.5 font-medium"
                                >
                                    {{ data.title }}
                                </div>
                                <div v-if="showExpandToggle">
                                    <secondary-button
                                        size="small"
                                        class="ml-2 border-none"
                                        @click="expandAppSections"
                                    >
                                        <i
                                            v-if="mainIsOpen"
                                            class="fa-solid fa-angle-up"
                                        ></i>
                                        <i
                                            v-else
                                            class="fa-solid fa-angle-down"
                                        ></i>
                                    </secondary-button>
                                </div>
                                <div style="display: flex">
                                    <div v-if="data.rollbackButtonText">
                                        <secondary-button
                                            size="small"
                                            class="ml-2"
                                            @click="onRollbackButtonClick"
                                        >
                                            {{ data.rollbackButtonText }}
                                        </secondary-button>
                                    </div>
                                    <div v-if="data.button">
                                        <primary-button
                                            v-if="!data.confirm"
                                            size="small"
                                            class="ml-2 border-none"
                                            @click="onMainButtonClick"
                                        >
                                            {{ data.button }}
                                        </primary-button>
                                        <confirm-button 
                                            v-else
                                            component="primary-button"
                                            size="small"
                                            class="ml-2"
                                            :message="data.confirm"
                                            @confirmed="onMainButtonClick"
                                            role="primary" 
                                            confirmButtonRole="primary"
                                            :confirmText="data.confirmButtonText"
                                        >
                                            {{ data.button }}
                                        </confirm-button>
                                    </div>
                                </div>
                            </div>
                            <div v-if="data.description">
                                <hr class="mt-2" v-if="showDescription" />
                                <div
                                    v-if="data.descriptionNote"
                                    style="font-size: 10px; margin-top: 8px"
                                >
                                    {{ data.descriptionNote }}
                                </div>
                                <div
                                    v-if="showDescription"
                                    v-html="data.description"
                                    class="ck-content"
                                    style="
                                        min-height: auto;
                                        padding-bottom: 16px;
                                    "
                                ></div>
                                <div class="text-right" v-if="showDescription">
                                    <secondary-button
                                        v-if="data.descriptionButton"
                                        @click="onDescriptionButtonClick"
                                        style="margin-bottom: 8px"
                                    >
                                        {{ data.descriptionButton }}
                                    </secondary-button>
                                </div>
                                <div
                                    class="text-center"
                                    style="
                                        font-size: 12px;
                                        text-decoration: underline;
                                        cursor: pointer;
                                    "
                                    @click="showDescription = !showDescription"
                                >
                                    <i
                                        v-if="showDescription"
                                        class="fa-solid fa-chevron-up"
                                    ></i>
                                    <i
                                        v-if="!showDescription"
                                        class="fa-solid fa-chevron-down"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="relative z-20 flex-col w-full pl-8 sm:pl-12"
            style="height: 0"
        >
            <div
                class="absolute rounded-full h-full w-0.5 top-0 left-3.5 bg-gray-200"
            ></div>
            <div
                class="relative z-10 flex w-full mt-3"
                v-for="(section, sIndex) in data.sections"
                :key="sIndex"
            >
                <div class="hidden sm:block text-3xl w-12">
                    <div class="w-full h-full flex items-center justify-start">
                        <status-icon :status="section.statusId" />
                    </div>
                </div>
                <div class="min-w-0 relative w-full rounded-lg overflow-hidden">
                    <!-- left hand color splash -->
                    <div
                        class="absolute h-full w-[10px] left-0 top-0 bottom-0 opacity-40 z-10"
                        :class="
                            getBackgroundHighlightStatusColor(section.statusId)
                        "
                    ></div>

                    <div
                        class="relative bg-white w-full border-2 border-gray-300 rounded-lg"
                    >
                        <div class="py-2 pl-6 pr-2.5">
                            <div class="flex items-center justify-between">
                                <div
                                    class="whitespace-nowrap cursor-pointer text-ellipsis overflow-hidden text-sm md:text-base leading-tight -ml-0.5 font-medium"
                                    @click="toggleSection(sIndex)"
                                >
                                    {{ section.title }}
                                </div>

                                <!-- buttons -->
                                <div class="flex flex-nowrap">
                                    <!-- remove section button -->
                                    <remove-requirement-button
                                        v-if="canRemoveSection(section)"
                                        :requirement="section"
                                    ></remove-requirement-button>

                                    <!-- fix rejections button -->
                                    <router-link
                                        v-if="section.rejectedRoute"
                                        :to="section.rejectedRoute"
                                    >
                                        <secondary-button
                                            size="small"
                                            role="danger"
                                            class="whitespace-nowrap"
                                        >
                                            Fix Issues
                                        </secondary-button>
                                    </router-link>

                                    <!-- view / edit button -->
                                    <router-link :to="{ name: section.route }">
                                        <secondary-button
                                            size="small"
                                            class="ml-2 whitespace-nowrap"
                                        >
                                            {{ editButtonText }}
                                        </secondary-button>
                                    </router-link>

                                    <!-- angled open/close toggle -->
                                    <secondary-button
                                        size="small"
                                        class="ml-2 border-none"
                                        @click="toggleSection(sIndex)"
                                        :icon="
                                            openSections[sIndex]
                                                ? 'angle-up'
                                                : 'angle-down'
                                        "
                                        iconClass="fa-solid"
                                    />
                                </div>
                            </div>

                            <!-- section description -->
                            <transition name="fade">
                                <div
                                    v-if="openSections[sIndex]"
                                    class="border-t border-gray-300 mt-2 mr-2.5"
                                >
                                    <!-- rejections list -->
                                    <overview-rejections
                                        :rejections="section.rejections"
                                        :sectionParamName="section.paramName"
                                    />

                                    <div class="mt-2">
                                        <p v-if="section.description">
                                            {{ section.description }}
                                        </p>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OverviewRejections from "@/components/rejections/OverviewRejections.vue";
import RemoveRequirementButton from "@/components/admin/requirements/RemoveRequirementButton.vue";

export default {
    props: {
        closedByDefault: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object,
            required: true,
        },
        showExpandToggle: {
            type: Boolean,
        },
        forceStatus: {
            type: Number,
        },
        mainAccordionButton: {
            type: String,
        },
    },
    components: {
        OverviewRejections,
        RemoveRequirementButton,
    },

    data() {
        return {
            mainIsOpen: false,
            showDescription: true,
        };
    },

    computed: {
        status() {
            return this.forceStatus ?? this.data.statusId;
        },

        editButtonText() {
            return this.data.statusId ===
                this.$store.getters.getStatusId("UNDER_REVIEW")
                ? "View"
                : "View/Edit";
        },
    },

    methods: {
        onMainButtonClick() {
            this.$emit("onMainButtonClick", this.data.buttonMethod);
        },
        onRollbackButtonClick() {
            this.$emit("onMainButtonClick", this.data.rollbackButtonMethod);
        },
        onDescriptionButtonClick() {
            this.$emit(
                "onDescriptionButtonClick",
                this.data.descriptionButtonMethod
            );
        },

        getBackgroundHighlightStatusColor(statusId) {
            return this.$store.getters.getStatusBackgroundColor(statusId);
        },
    },
};
</script>

<style scoped></style>
