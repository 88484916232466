<template>
    <div v-if="privacyMode" class="">
        <!-- privacy mode alert -->
        <warning-alert 
            :icon="'eye-slash'"
            class="my-4"
        >
            <div class="flex items-center">
                <span>Privacy mode is enabled</span>
                <primary-button
                    class="ml-auto"
                    size="small" 
                    role="danger"
                    @click="this.$store.dispatch('togglePrivacyMode')"
                >
                    disable
                </primary-button>
            </div>
        </warning-alert>

        <!-- exception controls -->
        <div class="p-4 bg-secondary-200 rounded-lg flex-wrap text-secondary-900 flex gap-2 items-center justify-center">
            
            <span class="w-fit">{{ exceptionText }}</span>

            <!-- active exception -->
            <div 
                v-if="privacyException" 
                class="inline-flex items-center gap-2 bg-secondary-400 px-3 rounded"
            >
                <span class="">{{ privacyException.firstName }} {{ privacyException.lastName }}</span>
                <i class="fas fa-times cursor-pointer" @click="$store.dispatch('setPrivacyException', null)"></i>
            </div>
            

            <!-- privacy form -->
            <form
                v-else 
                class="flex items-center flex-wrap gap-2" 
                @submit.prevent="onSubmit"
            >
                <input 
                    v-model="firstName" 
                    class="px-2 border border-secondary-600 grow rounded bg-secondary-50"
                    :class="computedClasses.firstName"
                    @focus="firstNameError = false"
                    name="first_name" 
                    autocomplete="off"
                    placeholder="First Name" 
                />

                <input 
                    v-model="lastName"
                    class="px-2 border border-secondary-600 grow rounded bg-secondary-50" 
                    :class="computedClasses.lastName"
                    @focus="lastNameError = false"
                    name="last_name" 
                    autocomplete="off"
                    placeholder="Last Name" 
                />
                
                <div class="flex items-center gap-2">
                    <button 
                        class="px-2 font-bold bg-secondary-600 text-white rounded" 
                        @click="clearForm" 
                        title="Reset"
                        type="button"
                    >
                        <i class="fas fa-rotate-right"></i>
                    </button>

                    <button class="px-2 font-bold bg-secondary-600 text-white rounded">submit</button>
                </div>
                


            </form>
        </div>
        
    </div>
</template>
<script>
export default {
    props: {
    },
    data(){
        return {
            firstName: "",
            firstNameError: false,
            lastName: "",
            lastNameError: false,
        }
    },
    watch: {
        privacyException(current){
            if(!current){
                this.clearForm();
            }
        },  
    },
    methods: {
        onSubmit(){
            if(!this.firstName){
                this.firstNameError = true;
            }

            if(!this.lastName){
                this.lastNameError = true;
            }

            if(this.firstNameError || this.lastNameError){
                return;
            }

            this.$store.dispatch("setPrivacyException", { firstName: this.firstName.trim(), lastName: this.lastName.trim() });
        },

        clearForm(){
            this.firstName = "";
            this.firstNameError = false;
            this.lastName = "";
            this.lastNameError = false;
        },
    },
    computed: {
        exceptionText(){
            return this.privacyException ? "Showing application for:" : "Enter first and last name to show application";
        },

        computedClasses(){
            return {
                firstName: this.firstNameError ? "!border-red-600" : "",
                lastName: this.lastNameError ? "!border-red-600" : "",
            };
        },

        privacyException(){
            return this.$store.getters.privacyException;
        },

        privacyMode(){
            return this.$store.getters.privacyMode;
        },
    },
};
</script>