<template>
    <i class="fas" :class="classes" />
</template>


<script>
const image_types = ["jpg", "jpeg", "png", "tif", "tiff", "gif"];

export default {
    computed: {
        classes() {
            const classes = [];
            classes.push(this.faIconClass);
            classes.push(this.sizeClass);
            return classes;
        },
        sizeClass() {
            switch(this.size){
                case 0: return "text-xs";
                case 1.5: return "text-base";
                case 1: return "text-xl";
                case 2: return "text-2xl";
                case 3: return "text-3xl";
                case 4: return "text-4xl";
                case 5: return "text-5xl";
                case 6: return "text-6xl";
                case 7: return "text-7xl";
                case 8: return "text-8xl";
                case 9: return "text-9xl";
                case 10: return "text-10xl";
            }
            return "text-xl";  
        },
        faIconClass() {
            if(this.fileType == "pdf"){
                return "fa-file-pdf";
            } else if (image_types.includes(this.fileType)) {
                return "fa-file-image";
            } else {
                return "fa-file"
            }
        },
        fileType() {
            if(this.fileName){
                return this.fileName.split(".").pop();
            }
            
            return this.type;
        },
    },
    props: {
        size: {
            type: Number,
            required: false,
            default: 4
        },
        showThumbnail: {
            type: Boolean,
            required: false,
            default: true,
        },
        type: {
            type: String,
            required: false,
            default: "file"
        },
        fileName: {
            type: String,
            required: false,
        },
        url: {
            type: String,
            required: false,
        },
    },
    mounted() {
        this.validateUrl()
    },
    methods: {
        validateUrl() {
            if(this.showThumbnail && !this.url) {
                console.warn("FileIcon url prop missing if thumbnail prop is set to true");
            }
        }
    }
}
</script>
