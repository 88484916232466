/**
 *  The Errors class maintains and displays any current form errors
 */
export default class Errors {
    /**
     * Create a new Errors instance.
     */
    constructor() {
        this.errors = {};
    }

    /**
     * Determine if an errors exists for the given field.
     *
     * @param {string} field - the field name we are checking for
     */
    has(field) {
        return Object.prototype.hasOwnProperty.call(this.errors, field);
    }

    /**
     * Determine if we have any errors.
     */
    any() {
        return Object.keys(this.errors).length > 0;
    }

    /**
     * Returns all of our errors as a string representation of an object
     *
     * @returns {string}
     */
    allAsString() {
        return JSON.stringify(this.errors);
    }

    /**
     * Retrieve the error message for a field.
     *
     * @param {string} field
     */
    get(field) {
        if (this.errors[field]) {
            return this.errors[field].join(" ");
        }
    }

    /**
     * Manually set a single error field
     *
     * @param {string} field
     * @param {string} message
     */
    set(field, message) {
        if (Array.isArray(this.errors[field])) {
            this.errors[field].push(message);
        } else {
            this.errors[field] = [message];
        }
    }

    /**
     * Record a new set of errors (usually directly from an http response).
     *
     * @param {object} errors - the error object passed from our http response
     */
    record(errors) {
        if (errors) {
            this.errors = errors;
        }
    }

    /**
     * Clear an error field.
     *
     * @param {string} field
     */
    clear(field) {
        delete this.errors[field];
    }

    /**
     * Clear all error fields
     */
    clearAll() {
        this.errors = {};
    }
}
