<template>
    <base-alert class="text-blueGray-600 bg-blueGray-100" :icon="icon" @close="$emit('close')">
        <slot></slot>
    </base-alert>
</template>

<script>
export default {
    emits: ["close"],
    props: {
        icon: {
            default: false,
        },
    },
};
</script>
