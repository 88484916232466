<template>
    <!-- toggle sidebar container -->
    <div
        class="toggle-sidebar relative transition-all bg-blueGray-900 text-secondary-600 pt-6 pb-12"
        :class="computedClasses"
        :style="computedStyles"
    >
        <!-- toggle button -->
        <button
            class="absolute flex items-center transition-all justify-center w-5 h-5 top-4 rounded bg-headerBlue z-20 text-accentBlue"
            :class="toggleClasses"
            @click="closed = !closed"
        >
            <i class="fa-regular fa-xs" :class="iconClass" />
        </button>

        <!-- sidebar content -->
        <div class="w-full grid gap-8">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        position: {
            type: String,
            default: "right",
        },
        width: {
            type: String,
            default: "400px",
        },
        paddingWidth: {
            type: Number,
            default: 26,
        },
        paddingHeight: {
            type: Number,
            default: 32,
        },
        twoSidedSideBar: {
            type: Boolean,
            default: false,
        },
        secondSideBarOpen: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            closed: false,
        };
    },
    watch: {
        closed() {
            this.$emit("onSideBarChange", this.closed);
        },
    },
    computed: {
        computedStyles() {
            return {
                "--width": this.width,
                "--padding-width": this.paddingWidth + "px",
                //"--padding-height": this.paddingHeight + "px",
            };
        },

        toggleClasses() {
            if (this.position === "left") {
                if(this.closed) return "-right-4"
                return "-right-2";
            }

            if(this.closed) return "-left-4"
            return "-left-2";
        },

        computedClasses() {
            if (this.position === "left") {
                return {
                    "close--left":
                        (!this.twoSidedSideBar && this.closed) ||
                        (this.twoSidedSideBar &&
                            !this.secondSideBarOpen &&
                            this.closed),
                    "close--left-extra":
                        this.twoSidedSideBar &&
                        this.secondSideBarOpen &&
                        this.closed,
                    "border-r": true,
                };
            }

            return {
                "close--right":
                    (!this.twoSidedSideBar && this.closed) ||
                    (this.twoSidedSideBar &&
                        !this.secondSideBarOpen &&
                        this.closed),
                "close--right-extra":
                    this.twoSidedSideBar &&
                    this.secondSideBarOpen &&
                    this.closed,
                "border-l": true,
            };
        },

        iconClass() {
            if (this.position === "left") {
                return this.closed ? "fa-angles-right" : "fa-angles-left";
            }

            return this.closed ? "fa-angles-left" : "fa-angles-right";
        },
    },
};
</script>

<style scoped>
.toggle-sidebar {
    width: var(--width);
    padding-left: var(--padding-width);
    padding-right: var(--padding-width);
}

.toggle-sidebar.close--right {
    margin-right: calc(-1 * (var(--width) - 15px));
}

.toggle-sidebar.close--left {
    margin-left: calc(-1 * (var(--width) - 15px));
}
.toggle-sidebar.close--right-extra {
    margin-right: calc(-1 * (var(--width) - 85px));
}

.toggle-sidebar.close--left-extra {
    margin-left: calc(-1 * (var(--width) - 85px));
}
</style>
