import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import store from "@/store";

const hashTimeout = 2000; // maximum amount of time to wait for an element matching our url hash to mount before giving up (in ms) 
const hashInterval = 50; // interval of how often to check for an element matching our url hash (in ms)

// if we have a hash we need to wait for transitions to load before
// we attempt to scroll to it, I'm not sure of a cleaner way to handle 
// this than setting a timeout or interval as nextTick seems to fire too early
// when run from within the router
const checkForHashedElement = (hash) => {
    return new Promise((resolve, reject) => {

        // get our element ID from the hash
        const elementId = hash.substring(1);

        // how long have we been searching for our hash?
        let searchingForHash = 0;
        
        // set an interval that fires off at a rate equal to our hashInterval variable to keep
        // checking if the page has finished rendering the element we are trying to scroll to
        const interval = setInterval(() => {
            
            // increase our searching counter equal to the interval time
            searchingForHash += hashInterval;

            // attempt to find the element in our hash
            const element = document.getElementById(elementId);
            
            // if we found it clear our interval and scroll to the element
            if(element){
                clearInterval(interval);
                return resolve({
                    el: hash,
                    top: 50,
                    behavior: 'smooth',
                });
            }

            // if we haven't found the element after searching for a length of time
            // equal to or greater than our hashTimeout give up and clear the interval
            if(searchingForHash >= hashTimeout){
                clearInterval(interval);
                reject(`Scroll could not be completed, unable to find #${elementId}`);
            }

        }, hashInterval);

    });
};


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior: function (to, _from, savedPosition) {
        if (to.hash) {
            return checkForHashedElement(to.hash);
        }

        if (savedPosition) {
            return savedPosition;
        }
    
        return { top: 0 };
    },

    routes,
});

router.beforeResolve(() => {
    store.dispatch("spinner/increment");
});

router.afterEach(() => {
    store.dispatch("spinner/decrement");
});

export default router;
