<template>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between mb-4">
        
        <!-- page totals -->
        <div>
            <p class="text-sm text-gray-700">
                Showing
                <select
                    class="font-medium"
                    :value="per_page"
                    @change="changePerPage"
                >
                    <option v-for="value in perPage" :key="value"
                        :value="value"
                    >
                        {{value}}
                    </option>
                </select>
                results of {{ totalResults }}
            </p>

            <slot></slot>
        </div>

        <!-- closed warning -->
        <div v-if="show_closed">
            <p class="text-sm text-yellow-700">These are closed</p>
        </div>

        <!-- navigation -->
        <nav
            v-if="pages > 1"
            class="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
        >
            <!-- previous -->
            <a
                @click="onPageChange(page - 1)"
                class="relative inline-flex items-center cursor-pointer rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
                <span class="sr-only">Previous</span>
                <i class="fas fa-angle-left mx-1.5"></i>
            </a>

            <!-- component -->
            <component 
                :is="typeComponent" 
                :page="page" 
                :pages="pages" 
                @pageChange="onPageChange" 
            />

            <!-- next -->
            <a
                @click="onPageChange(page + 1)"
                class="relative inline-flex items-center cursor-pointer rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
            >
                <span class="sr-only">Next</span>
                <i class="fas fa-angle-right mx-1.5"></i>
            </a>
        </nav>
    </div>
</template>

<script>
import LinkPagination from "./LinkPagination.vue";
import SelectPagination from "./SelectPagination.vue";

export default {
    name: "Pagination",

    components: {
        LinkPagination,
        SelectPagination,
    },

    props: {
        storeName: {
            type: String,
            default:'applications',
        },
        perPage: {
            type: Array,
            default: () => [25, 50, 100],
        },
        type: {
            type: String,
            default: 'link',
        },
    },

    methods: {
        onPageChange(page) {
            if (page <= 0 || page > this.pages) return;
            this.page = page;
            this.$store.dispatch(this.storeName+"/loadAll");
        },
        changePerPage(per_page) {
            this.per_page = per_page.target.value;
            this.$store.dispatch(this.storeName+"/loadAll");
        },
    },
    computed: {
        totalResults() {
            return this.$store.getters[this.storeName+"/total"];
  
        },
        page: {
            get() {
                return this.$store.getters[this.storeName+"/page"];
            },
            set(value) {
                this.$store.commit(this.storeName+"/setPage", +value);
            },
        },
        pages: {
            get() {
                return this.$store.getters[this.storeName+"/pages"];
            },
            set(value) {
                this.$store.commit(this.storeName+"/setPages", +value);
            },
        },
        per_page: {
            get() {
                return this.$store.getters[this.storeName+"/per_page"]
            },
            set(value) {
                this.$store.commit(this.storeName+"/setPerPage", +value);
            },
        },  
        //only applies to applications at the moment
        show_closed() {
            return this.$store.getters[this.storeName+"/show_closed"];
        },      
        typeComponent(){
            return `${this.type}-pagination`;
        },
    },
}
</script>

<style scoped>

</style>