function TabIndent( editor ) {
    editor.keystrokes.set( 'Tab', ( _data, cancel ) => {
        const command = editor.commands.get( 'indentBlock' );

        if ( command.isEnabled ) {
            command.execute();
            cancel();
        }
    } );
}

function ShiftTabOutdent( editor ) {
    editor.keystrokes.set( 'Shift+Tab', ( _data, cancel ) => {
        const command = editor.commands.get( 'outdentBlock' );

        if ( command.isEnabled ) {
            command.execute();
            cancel();
        }
    });
}

export { TabIndent, ShiftTabOutdent }