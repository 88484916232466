<template>
    <!-- main wrapper -->
    <transition name="fade">
        <div
            v-if="input.hide !== true"
            class="text-left grid form-element"
            v-bind="input.wrapperAttributes"
            :class="input.wrapperClass"
        >
            <!-- label -->
            <form-label :field="input" :required="isRequired"></form-label>

            <!-- teleport target -->
            <form-teleport :teleport="input.teleport"></form-teleport>

            <!-- radio set -->
            <div
                class="grid gap-3 order-3 mt-2"
                :class="computedClasses"
                v-bind="input.attributes"
            >   
                <template v-if="field.options?.length">
                    <radio-option
                        v-for="option in field.options"
                        :option="option"
                        :field="input"
                        :key="option.value"
                        :modelValue="modelValue[input.name]"
                        :disabled="disabled"
                        @updated="handleInput"
                    />
                </template>
                <div v-else class="p-3 col-span-full border rounded text-center bg-gray-50 text-gray-500" v-html="noOptionsMessage"></div>

                <div class="items-center flex">

                    <action-button
                        v-if="field.removableField"
                        @click.stop="field.onRemoveClick(input.name)"
                        :icon="$store.getters.icons.delete.icon"
                        :iconStyle="$store.getters.icons.delete.style"
                        :title="field.removableFieldButtonTitle ?? 'Remove this option'"
                    />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import FormLabel from "./FormLabel.vue";
import FormTeleport from "./FormTeleport.vue";
import RadioOption from "./RadioOption.vue";

export default {
    props: ["modelValue", "field", "input", "form", "disabled"],
    components: {
        RadioOption,
        FormLabel,
        FormTeleport,
    },

    methods: {
        handleInput(value) {
            this.$emit("updated", this.input.name, value);
        },
    },

    computed: {
        isRequired() {
            return this.input.required || this.input.attributes?.required;
        },

        isDisabled() {
            return this.field.attributes?.disabled || this.disabled;
        },

        computedClasses() {
            let classes = "";

            if (this.input.class) {
                classes += this.input.class;
            }

            if (this.input.attributes?.class) {
                classes += this.input.attributes.class;
            }

            if (this.field.inputsClass) {
                classes += this.field.inputsClass;
            }

            return classes;
        },

        noOptionsMessage() {
            return this.field.noOptionsMessage ?? "No options available..."
        }
    },
};
</script>
