<template>
    <div class="p-2 bg-headerBlue/50 rounded" :class="computedClasses">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        role: {
            type: String,
            default: '',
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },

    data(){
        return {
            roles: {
                error: 'border-red-600',
                warning: 'border-yellow-600',
                success: 'border-green-500',
                info: 'border-secondary-800',
            }
        }
    },

    computed: {
        computedClasses(){
            let classes = this.border;

            if(this.clickable){
                classes += " hover:bg-headerBlue transition-all cursor-pointer";
            }

            return classes;
        },

        border(){
            // if no role is set, return an empty string
            if(!this.role){
                return '';
            }

            let classes = "border-l-4 pl-3 ";

            // if our roles match a preset role
            if(this.roles[this.role]){
                classes += this.roles[this.role];
            } else {
                classes += this.role;
            }

            return classes;
        },
    }
}
</script>