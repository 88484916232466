import store from "@/store/index";

/**
 * Check that the given object has a non-null, nun-undefined, non-empty string value
 * for each property given in the propertiesArray
 * 
 * Note: Calling with  a null properties array will 
 * use Object.keys(object) as properties to check
 *
 * @param {object} object
 * @param {string[]?} propertiesArray
 * @param {object?} options
 * @returns boolean|int
 */
export default function objectPropertiesHaveValue(object, propertiesArray, options = {}) {
    if(propertiesArray === null) propertiesArray = Object.keys(object);
    // make an array of our missing properties
    const missingProperties = getMissingPropertiesArray(object, propertiesArray);

    if(options.returnBoolean){
        return missingProperties.length > 0;
    }

    return getStatusCodeFromMissingProperties(missingProperties, propertiesArray);
}

/**
 * Cycle through the properties array and check that the given object has
 * a value for that property that isn't null, undefined, and empty string, 
 * or an empty.array then return an array of the properties that failed that
 * check.
 * 
 * @param {object} object
 * @param {string[]} propertiesArray
 * @returns string[]
 */
function getMissingPropertiesArray(object, propertiesArray){
    const missingProperties = propertiesArray.filter((property) => {
        const value = object[property];

        return  value === null ||
                value === undefined ||
                value === "" ||
                (Array.isArray(value) && !value.length);
        });

    return missingProperties;
}

/**
 * Given an array of missing properties, and the array of properties we
 * were checking, determine the status code to return
 * 
 * @param {string[]} missingProperties 
 * @param {string[]} propertiesArray 
 * @returns int 
 */
function getStatusCodeFromMissingProperties(missingProperties, propertiesArray){

    // if we have 0 missing properties we are COMPLETE
    if (missingProperties.length === 0) {
        return store.getters.getStatusId("COMPLETED");
    }

    // if all of our properties are missing we are NOT_STARTED
    if (missingProperties.length === propertiesArray.length) {
        return store.getters.getStatusId("NOT_STARTED");
    }

    // somewhere in between we are IN_PROGRESS
    return store.getters.getStatusId("IN_PROGRESS");
}
