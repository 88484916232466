import store from "@/store/index";

export default {
    
    async rotateToken(){
        //dont attempt a rotation if one is already happening
        if(store.getters.isRefreshingToken){
            return;
        }
        const data = {
            //for rotation we want to grab from local storage directly to avoid issues with multiple browser tabs
            accessToken:localStorage.getItem("accessToken"),
            refreshToken:localStorage.getItem("refreshToken"),
        };
        try {
            //set isRefreshing to true fetch new set from backend. Validation will happen there.
            store.commit('setIsRefreshingToken', true); 
            let pendingRequests  = store.getters.pendingRequests;
            const response = await store.$http.post("rotate-tokens", data, { notifications: false }); 
            const accessToken = response.data.accessToken;
            const refreshToken = response.data.refreshToken;

            store.dispatch("setToken", { accessToken, refreshToken});
            
            pendingRequests  = store.getters.pendingRequests;
            while(pendingRequests.length){
                let {resolve, reject, originalRequest} = pendingRequests.pop();
                store.$http(originalRequest)
                .then( (response) => {resolve(response)})
                .catch((error)=> {reject(error)});
            }
            store.commit('setPendingRequests', []);
            store.commit('setIsRefreshingToken', false); 
            
        } catch (error) {
            //token refresh failure should result in logout
            let pendingRequests  = store.getters.pendingRequests;
            while(pendingRequests.length){
                let {reject} = pendingRequests.pop();
                reject(error);
            }
            store.commit('setPendingRequests', []);
            store.commit('setIsRefreshingToken', false); 
            store.dispatch("logout");
        }
        
    },

};