<template>
    <div
        class="relative inline-block text-left"
        ref="dropdown"
    >
        <div>
            <button
                @click="showDropdown = !showDropdown"
                type="button"
                class="w-full rounded-md px-2 py-1 text-sm font-medium shadow-sm
                      bg-transparent text-xxs flex justify-between items-center border"
                :class="theme.button"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
            >
                <div>
                    {{ name }}
                </div>
                <!-- Heroicon name: mini/chevron-down -->
                <svg
                    class="-mr-1 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fill-rule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clip-rule="evenodd"
                    />
                </svg>
                <div
                    v-if="badgeCount"
                    class="absolute rounded-full text-sm font-medium border py-[1px] px-2 top-[-11px] right-[-11px]"
                    :class="theme.badge"
                >
                    {{ badgeCount }}
                </div>
            </button>
        </div>
        <div
            v-if="showDropdown"
            class="z-[99] absolute min-w-[224px] w-fit origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            :class="theme.dropdown"
            ref="dropdownBox"
            role="menu"
            :style="computedStyle"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1"
        >
            <div class="py-1" role="none">
                <div v-if="!hideSearch" class="w-full p-2.5">
                    <input
                        class="h-7 input-width py-px px-2.5 rounded border text-sm w-full"
                        :class="theme.input"
                        placeholder="search"
                        v-model="search"
                    />
                </div>

                <!-- more info -->
                <slot name="more-info"></slot>

                <!-- select all deselect all -->
                <div
                    v-if="!singleSelect && !hideSelectButtons"
                    class="flex justify-between items-center text-xxs underline border-b pb-2"
                    :class="theme.link"
                >
                    <div
                        class="cursor-pointer ml-3"
                        @click="onSelectAll"
                    >
                        Select All
                    </div>
                    <div
                        class="cursor-pointer mr-3"
                        @click="onDeselectAll"
                    >
                        Deselect All
                    </div>
                </div>

                <!-- additional controls  -->
                <slot name="additional-controls"></slot>

                <div class="max-h-[300px] overflow-auto"
                    :class="theme.list"
                >
                    <!-- custom non-checkboxes content -->
                    <template v-if="customContent">
                        <slot name="custom-content"></slot>
                    </template>

                    <!-- default checkboxes content -->
                    <template v-else>
                        <div
                            v-for="item in filtered_items"
                            :key="item[itemValue]"
                            class="py-2 px-3"
                        >
                            <!-- 
                            ** 
                            *  MEGA HAX ALERT: There is probably a better way to do this...
                            *  This is a hack to get the text to wrap in the dropdown, but also allow the width
                            *  of the text to inform the width of the dropdown. Before actually showing the visible
                            *  text (which wraps), we render the text transparently with the whitespace-nowrap set 
                            *  so that it attempts to stretch as wide as it can, in a div with 1px of height and
                            *  a max-width set. Since the invisible text is set to no-wrap it tries to expand as
                            *  wide as possible to the max length of the text (until it hits the max-width) which
                            *  in turn allows us to use width: fit-content on the parent dropdown. 
                            ** 
                            -->
                            <div class="ml-1 mr-4 h-px text-transparent max-w-[350px] overflow-hidden whitespace-nowrap">
                                {{ item[itemText] }}
                            </div>
                            <!--
                            **
                            * END MEGA HAX
                            **
                            -->

                            <div class="flex items-start">
                                <input
                                    type="checkbox"
                                    v-model="selectedItems"
                                    :value="item[itemValue]"
                                    class="w-4 h-4 min-w-4 shrink-0 min-h-4 rounded focus:ring-2"
                                    :class="theme.checkbox"
                                />
                                <div
                                    :title="item[itemText]"
                                    for="checked-checkbox"
                                    class="ml-1 mr-4 text-sm font-medium relative bottom-[1px]" 
                                >
                                    {{ item[itemText] }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import isObject from "@/services/utils/isObject.js";
export default {
    data() {
        return {
            showDropdown: false,
            search: "",
            themes: {
                dark: {
                    button: 'text-secondary-700 border-secondary-700 hover:border-accentBlue hover:text-secondary-900 hover:bg-accentBlue',
                    badge: 'text-black border-black bg-accentBlue',
                    dropdown: 'bg-headerBlue',
                    input: 'border-secondary-700 bg-transparent placeholder-secondary',
                    link: 'text-accentBlue border-secondary-700',
                    list: 'scrollbar-track-secondary-800 scrollbar scrollbar-thumb-secondary-900',
                    checkbox: '[&:not(:checked)]:appearance-none text-blue-600 border accent-accentBlue bg-blueGray-900/40 border-secondary-700 focus:ring-blue-500',
                },
                default: {
                    button: 'text-slate-500 border-slate-500 hover:bg-gray-50',
                    badge: 'text-black border-black bg-white',
                    dropdown: 'bg-white',
                    input: 'border-gray-300',
                    link: 'text-blue-700 border-gray-200',
                    checkbox: 'text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500',
                },
            },
            computedFlag: false
        };
    },
    watch: {
        showDropdown(value) {
            if (value) {
                this.triggerBoxCalculations();
                document.addEventListener("click", this.closeIfClickedOutside);
            }
        },
    },
    props: {
        name: {
            type: String,
            default: "",
        },
        items: {
            default: [],
        },
        hideSearch: {
            type: Boolean,
            default: false,
        },
        searchValue: {
            type: String,
            default: "",
        },
        itemValue: {
            type: String,
            default: "",
        },
        itemText: {
            type: String,
            default: "",
        },
        selection: {
            default: [],
        },
        setTheme: {
            type: String,
            default: 'default',
        },
        customTheme: {
            type: Object,
            default: () => ({}),
        },
        singleSelect: {
            type: Boolean,
            default: false,
        },
        hideSelectButtons: {
            type: Boolean,
            default: false,
        },
        count: {
            type: Number,
            default: null,
        },
        direction: {
            type: String,
            default: 'left',
        }
    },
    methods: {
        async triggerBoxCalculations() {
            await this.$nextTick();
            this.computedFlag = !this.computedFlag;
        },
        closeIfClickedOutside(event) {
            if (!this.$refs.dropdown) return;
            if (!this.$refs.dropdown.contains(event.target)) {
                this.showDropdown = false;

                document.removeEventListener(
                    "click",
                    this.closeIfClickedOutside
                );
            }
        },
        onSelectAll() {
            this.$emit("onSelectAll");
        },
        onDeselectAll() {
            this.$emit("onDeselectAll");
        },
    },
    computed: {
        badgeCount() {
            return this.count ?? this.selection.length;
        },
        customContent() {
            return this.$slots["custom-content"];
        },

        // dropdownBoxStyle() {
        //     this.computedFlag; // Mention this to trigger 
        //     const parent = this.$refs.dropdown;
        //     const box = this.$refs.dropdownBox;
        //     const windowWidth = window.innerWidth - 10;
            
        //     if (!box) return {};
        //     const parentLeft = parent.offsetLeft ?? 0;
        //     const boxWidth = box.offsetWidth;
        //     const overflow = parentLeft + boxWidth - windowWidth;
            
        //     let left = 0;
        //     if (overflow > 0) {
        //         left = -1 * overflow;
        //         if (parentLeft + left < 0) {
        //             left = -1 * parentLeft;
        //         }
        //     }
        //     return {
        //         left: `${left}px`,
        //         width: `${Math.min(boxWidth, windowWidth)}px`
        //     }

        // },
        computedStyle() {
            let styles = {};
            styles[this.direction] = 0;
            return styles;
        },
        theme(){
            // set our base theme
            let theme = this.themes[this.setTheme] ?? {};
            
            // create copy to avoid mutating the original
            theme = { ...theme };

            // add in any custom theme classes
            if(isObject(this.customTheme)){
                Object.keys(this.customTheme).forEach(key => {
                    theme[key] += " " + this.customTheme[key];
                });
            }
            
            return theme;
        },

        filtered_items() {
            if(this.hideSearch){
                return this.items;
            }
            
            return this.items.filter((x) =>
                this.searchValue
                    ? x[this.searchValue]
                          .toLowerCase()
                          .includes(this.search.toLowerCase())
                    : x.toLowerCase().includes(this.search.toLowerCase())
            );
        },
        selectedItems: {
            get() {
                return this.selection;
            },
            set(values) {
                if (this.singleSelect) {
                    values = values.filter(value => !this.selection.includes(value));
                }
                
                this.$emit("onSelectionChange", values);
            },
        },
    },
};
</script>
<style scoped>

</style>
