<template>
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="text-left grid form-element"
            v-bind="field.wrapperAttributes"
            :class="computedWrapperClasses"
        >
            <!-- label -->
            <form-label :field="field" :required="isRequired"></form-label>

            <!-- teleport target -->
            <form-teleport :teleport="field.teleport"></form-teleport>

            <!-- buttons rack -->
            <div v-if="this.field.actions?.length" class="flex gap-3 flex-wrap my-2" :class="computedActionsContainerClasses">
                <secondary-button
                    v-for="action in this.field.actions"
                    size="small"
                    :icon="action.icon"
                    :key="action.text"
                    @click="action.onClick($refs.textarea, insertContentAtCursor)"
                >
                    {{ action.text }}
                </secondary-button>
            </div>

            <!-- input -->
            <textarea
                v-bind="computedAttributes"
                @keydown="handleKeydown"
                @input="handleInput"
                @blur="validate"
                @focus="clearErrors"
                maxlength="10000"
                v-bind:[dataName]="`${field.name}--input`"
                class="form-element__input block w-full text-lg p-2 border border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 order-3"
                :class="computedTextareaClasses"
                ref="textarea"
            ></textarea>

            <!-- errors -->
            <form-error :error="errorText" :field="field" class="order-5 col-span-full"></form-error>
        </div>
    </transition>
</template>

<script>
import fieldMixin from "./mixins/fieldMixin";
import isObject from "@/services/utils/isObject.js";

export default {
    mixins: [fieldMixin],

    data() {
        return {
        };
    },

    methods: {
        handleKeydown(event) {
            if (!this.field.overrideTab || event.key !== "Tab") {
                return;
            }

            event.preventDefault();
            this.insertContentAtCursor("\t");
        },

        insertContentAtCursor(content, index = null) {
            const textarea = this.$refs.textarea;
            const start = index ?? textarea.selectionStart;
            const end = index ?? textarea.selectionEnd;

            textarea.value = textarea.value.substring(0, start) + content + textarea.value.substring(end);
            textarea.selectionStart = textarea.selectionEnd = start + content.length;
            textarea.focus();
            this.clearErrors();
            this.updateValue(this.field.name, textarea.value);
        },
    },

    computed: {
        computedTextareaClasses(){
            let classes = [];

            if(this.field.inline){
                classes.push("md:rounded-r-md");
            } else {
                classes.push("mt-1 rounded-md");
            }

            return classes.join(" ");
        },

        computedWrapperClasses(){
            let classes = [];
            
            if(this.field.inline){
                classes.push("md:grid-cols-2");
            }

            if(this.field.wrapperClass){
                classes.push(this.field.wrapperClass);
            }

            return classes.join(" ");
        },

        defaultRows(){
            return this.field.inline ? 3 : 4;
        },

        computedAttributes() {
            let attributes = {
                // set our default rows
                rows:
                    this.field.rows ??
                    this.field.attributes?.rows ??
                    this.defaultRows,

                // set our name using the name provided by our field schema
                name: this.field.name,

                placeholder: this.field.placeholder,

                // set our disabled status using the isDisabled computed mixin
                disabled: this.isDisabled,

                // set our classes using our computed classes mixin
                class: this.computedClasses,

                // set our model value from our modelValue prop
                value: this.modelValue,
            };

            // merge in any attributes from our field.attributes property
            if (isObject(this.field.attributes)) {
                attributes = { ...attributes, ...this.field.attributes };
            }

            return attributes;
        },

        computedActionsContainerClasses(){
            let classes = [];

            let orderMap = {
                above: "order-2",
                below: "order-4",
            };

            let order = orderMap[this.field.actionsPosition] ?? "order-2";

            classes.push(order);

            return classes.join(" ");
        },
    },
};
</script>
