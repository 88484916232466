export default (http) => {
    const chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;    
    const safariAgent = navigator.userAgent.indexOf("Safari") > -1;
    const isSafari = safariAgent && !chromeAgent;
    let newTab = null;

    const responseCallback = (response) => 
    {
        if (response?.config?.openInNewTab) {
            let fileURL = response.data;
            if (response?.config?.responseType === 'blob') {
                const blob = new Blob([response.data], {type: 'application/pdf'});
                
                fileURL = URL.createObjectURL(blob);
            }
            if (isSafari && newTab) {
                newTab.document.location.href = fileURL;
                newTab = null;
            } else {
                window.open(fileURL);
            }
        }
        return response;
    };

    // for each failed response decrement our spinner request queue (if needed)
    const errorCallback = (error) => {
        if (error.config?.openInNewTab) {
            if (isSafari && newTab) {
                newTab.close();
                newTab = null;
            }
        }
        return Promise.reject(error);
    };

    http.interceptors.request.use(
        (request) => {
            if (request?.openInNewTab) {
                if (isSafari) {
                    newTab = window.open();
                };
            }
            return request;
        },
        (error) => {
            if (error.config?.openInNewTab) {
                if (newTab) {
                    newTab.close();
                    newTab = null;
                }
            }
            return Promise.reject(error);
        }
    );

    http.interceptors.response.use(responseCallback, errorCallback);
};
