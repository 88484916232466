import FormError from "../FormError.vue";
import FormLabel from "../FormLabel.vue";
import FormTeleport from "../FormTeleport.vue";
import hasValue from "@/services/forms/FieldHasValue.js"

export default {
    props: ["modelValue", "field", "form", "disabled"],
    inject: ["updateValue", "dataName", "clearFieldErrors"],

    components: {
        FormError,
        FormLabel,
        FormTeleport,
    },
        
    watch: {
        'field.hide': function(value){
            // clear any errors on this field when hidden
            this.clearFieldErrors(this.field.name);

            // if we need to set a specific value on hidden do so now
            if('setValueOnHidden' in this.field && value){
                this.updateValue(this.field.name, this.field.setValueOnHidden);
            }
        },

        'field.options': function(newOptions, oldOptions){
            if (!this.objArrayEqual(newOptions, oldOptions)) {
                // clear any errors on this field when hidden
                this.clearFieldErrors(this.field.name);
                
                // filter invalid values
                this.filterInvalidValues();
            }
        },
    },

    methods: {
        /**
         * Update an element value
         *
         * @param {Event} event
         */
        handleInput(event) {
            this.clearErrors();
            this.updateValue(this.field.name, event.target.value);
        },

        /**
         * Clear errors from this input
         */
        clearErrors() {
            this.form.errors.clear(this.field.name);
        },

        /**
         * Validate this input
         */
        validate() {
            this.form.validateField(this.field.name);
        },

        onEnter() {
            if(this.field.autocomplete || this.field.type === "address"){
                this.$refs.autocomplete?.selectCurrent();
                return;
            }

            this.$emit("submitForm");
        },

        /*
        * This compares two arrays of objects and makes sure that they 
        * both have the same keys and the same values on the keys
        * the values are expected to be primitives so if the objects
        * have arrays or other objects as value it will not work
        */ 
        objArrayEqual(arr1, arr2){
            if (arr1.length !== arr2.length) {
                return false;
            }

            for (let i = 0; i < arr1.length; i++) {
                if (typeof arr1[i] !== typeof(arr2[i]) || typeof(arr1[i]) !== 'object') {
                    return false;
                }
                Object.keys(arr1[i]).forEach(key => {
                    if (!(key in arr2[i])) {
                        return false;
                    }
                    if (arr1[i][key] !== arr2[i][key]) {
                        return false;
                    }
                })
                return true;
            }
        },

        /**
         * If we change our options dynamically but already have a value set, we need to
         * double check our value still matches a valid option.    
         */
        filterInvalidValues(){
            if(!Array.isArray(this.field.options)){
                return;
            }

            let validValues = this.field.options.map(option => option.value);

            // if our value is stored as an array
            if(Array.isArray(this.modelValue)){
                let values = [...this.modelValue];
                values = values.filter(value => validValues.includes(value));

                if(this.modelValue.length !== values.length){
                    this.updateValue(this.field.name, values);
                }

                return;
            }

            // if our value is a primitive and doesn't exist in our valid values array
            if(this.modelValue !== Object(this.modelValue) 
                && this.modelValue !== null
                && !validValues.find(value => this.modelValue === value)
            ){
                this.updateValue(this.field.name, null);
            }
        }
    },

    computed: {
        /**
         * Disable this element if the disabled attribute is set, or if the form is disabled
         */
        isDisabled() {
            return this.field.attributes?.disabled || this.disabled || this.field.disabled;
        },

        isRequired() {
            return this.field.required || this.field.softRequired || this.field.attributes?.required;
        },

        showRequiredHighlight() {
            return this.isRequired && !hasValue(this.modelValue, this.field);
        },

        /**
         * Gets any error text for this input
         *
         * @returns {string|null}
         */
        errorText() {
            return this.form.errors.get(this.field.name) ?? null;
        },


        computedClasses() {
            let classes = [];

            //if we added a class property to our field add them
            if (this.field.class) {
                classes.push(this.field.class);
            }

            // if we added a class property to our attributes object add them
            if (this.field.attributes?.class) {
                classes.push(this.field.attributes.class);
            }

            // if we have any errors set a red border
            if (this.form.errors.get(this.field.name)) {
                classes.push("form-element__input--error");
            }

            // if we are soft required and need a value show a yellow outline
            if (this.showRequiredHighlight && !this.isDisabled) {
                classes.push("form-element__input--required-highlight");
            }

            // add the padding we need for the little toggle showPassword icon
            if (this.field.type === "password") {
                classes.push("pr-8");
            }

            return classes;
        },
    },
};
