<template>
    <!-- wrapper -->
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="text-left grid form-element"
            v-bind="field.wrapperAttributes"
            :class="field.wrapperClass"
        >
            <!-- label -->
            <form-label
                :field="field"
                tag="p"
                :required="isRequired"
            ></form-label>

            <!-- teleport target -->
            <form-teleport :teleport="field.teleport"></form-teleport>

            <!-- visible element -->
            <label
                :for="computedAttributes.id"
                :disabled="isDisabled"
                class="form-element__option order-3 mt-1 select-none"
                :class="computedClasses"
                tabindex="0"
            >
                <!-- icon -->
                <i
                    v-if="!hideIcon"
                    class="form-element__option-icon text-lg mr-2"
                    :class="checkIcon"
                ></i>

                <!-- text -->
                <span v-html="fieldText"></span>
            </label>

            <!-- hidden input -->
            <input
                type="checkbox"
                @blur="validate"
                @input="handleInput"
                @focus="clearErrors"
                v-bind="computedAttributes"
                class="invisible absolute"
            />

            <!-- errors -->
            <form-error
                :error="errorText"
                :field="field"
                class="order-5"
            ></form-error>
        </div>
    </transition>
</template>

<script>
import fieldMixin from "./mixins/fieldMixin";
import checkIconMixin from "./mixins/checkIconMixin";
import startCase from "lodash/startCase";
import isObject from "@/services/utils/isObject.js";
import hasValue from "@/services/forms/FieldHasValue.js";

export default {
    mixins: [fieldMixin, checkIconMixin],

    methods: {
        handleInput(event) {
            this.clearErrors();

            const modelValue = event.target.checked ? this.value : null;

            this.updateValue(this.field.name, modelValue);
            this.validate();
        },
    },

    computed: {
        isChecked() {
            return this.modelValue !== null;
        },

        value() {
            return this.field.value ?? 1;
        },

        computedAttributes() {
            let attributes = {
                // set id using the name provided by our field schema
                id: this.field.name + "__" + this.dataName,

                // set our name using the name provided by our field schema
                name: this.field.name,

                // set our disabled status using the isDisabled computed mixin
                disabled: this.isDisabled,

                // set our checked prop using the modelValue prop
                checked: this.modelValue,

                // set our model value from our modelValue prop
                value: this.modelValue,
            };

            attributes[this.dataName] = `${this.field.name}--input`;

            // merge in any attributes from our field.attributes property
            if (isObject(this.field.attributes)) {
                attributes = { ...attributes, ...this.field.attributes };
            }

            return attributes;
        },

        computedClasses() {
            let classes = this.modelValue
                ? "border form-element__option--active"
                : "border";

            if (
                (this.field.required || this.field.softRequired)
                && !hasValue(this.modelValue, this.field)
                && !this.isDisabled
            ) {
                classes += " form-element__input--required-highlight";
            }

            if (this.field.class) {
                classes += " " + this.field.class;
            }

            if (this.field.attributes?.class) {
                classes += " " + this.field.attributes.class;
            }

            if (this.form.errors.get(this.field.name)) {
                classes += " border border-red-800";
            }

            return classes;
        },

        fieldText() {
            return this.field.text ?? startCase(this.field.name);
        },
    },
};
</script>
