<template>
    <!-- element -->
    <component 
        :is="tag" 
        v-bind="computedAttributes"
        v-on="events" 
    >
        <span v-if="option.content" v-html="option.content"></span>

        <template
            v-for="(content, name) in slots"
            v-slot:[name]
            :key="name"
        >
            <span :key="name" v-html="content"></span>
        </template>
    </component>
</template>

<script>

export default {
    props: {
        option: {
            type: Object,
            required: true,
        },
    },

    computed: {
        tag() {
            return this.option.tag ?? "div";
        },

        events() {
            return this.option.events ?? {};
        },

        slots() {
            return this.option.slots ?? {};
        },

        computedAttributes() {
            let attributes = this.option.attributes ?? {};
            let props = this.option.props ?? {};

            // make our class stub to build off of if needed
            if(!attributes.class){
                attributes.class = '';
            }

            if(!this.option.inline){
                attributes.class += ' col-span-full';
            }

            if(this.option.class){
                attributes.class += ' ' + this.option.class;
            }

            return {...attributes, ...props};
        }
    },
};
</script>

