export default [
    {
        path: "",
        name: "application.cmn",
        component: () => import("@/views/application/cmn/cmn.vue"),
        meta: {
            title: "CMN",
        },
    },
];
