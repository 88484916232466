<template>
    <div class="pb-2.5 border-b mb-2.5">
        <Transition>
            <div v-show="showFilters">
                <div
                    style="
                        cursor: pointer;
                        font-size: 12px;
                        border: 1px solid black;
                        margin-bottom: 8px;
                        width: fit-content;
                        padding: 4px;
                        border-radius: 8px;
                    "
                    @click="clearFilters"
                >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                    Clear Filters
                </div>

                <div class="flex items-center justify-between gap-8 flex-wrap">
                    <div class="shrink-0 flex flex-col justify-start gap-1">
                        <base-toggle 
                            id="mine-toggle"
                            v-model="show_only_my_apps" 
                            :disabled="!isSuperAdmin"
                            text="SHOW ONLY MY APPS"
                        /> 

                        <base-toggle 
                            id="closed-toggle"
                            v-model="show_closed" 
                            text="SHOW ONLY CLOSED APPS"
                        /> 

                        <base-toggle 
                            v-model="show_only_practice"
                            id="practice-toggle"
                            text="SHOW ONLY PRACTICE APPS"
                        /> 

                        <base-toggle 
                            v-model="has_no_tasks"
                            id="tasks-toggle"
                            text="SHOW APPS WITH NO OPEN TASKS"
                        /> 

                        <base-toggle 
                            v-model="show_converted_trial"
                            id="trial-toggle"
                            text="SHOW ONLY CONVERTED TRIAL TO PURCHASE"
                        /> 

                        <base-toggle 
                            v-model="show_qad"
                            id="qad-toggle"
                            text="SHOW ONLY APPS WITHOUT QAD CID"
                        /> 

                        <base-toggle 
                            v-model="show_wo_qad_son"
                            id="qad-son-toggle"
                            text="SHOW ONLY APPS WITHOUT QAD SON"
                        /> 

                        <base-toggle 
                            v-model="show_wo_rma_number"
                            id="rma-number-toggle"
                            text="SHOW ONLY APPS WITHOUT RMA Number"
                        /> 

                        <base-toggle 
                            v-model="has_custom_item"
                            id="has-custom-item-toggle"
                            text="INCLUDES A CUSTOM ITEM"
                        /> 
                    </div>

                    <!-- date pickers -->
                    <div class="shrink-0">
                        <div class="flex gap-4 w-full flex-wrap justify-center">
                            <p class="text-sm text-yellow-700 text-center w-full mb-1">Default search range is last {{ defaultSearchYearRange }} years</p>

                            <dropdown-selector
                                name="CREATED IN LAST"
                                :items="dateRangeOptions"
                                :hideSearch="true"
                                itemValue="value"
                                itemText="text"
                                :selection="[created_in]"
                                :singleSelect="true"
                                @onSelectionChange="
                                    created_in = $event[0];
                                    if($event[0] !== 'CUSTOM'){
                                        filterApplications();
                                    }
                                "
                            />
                            
                            <dropdown-selector
                                name="UPDATED IN LAST"
                                :items="dateRangeOptions"
                                :hideSearch="true"
                                itemValue="value"
                                itemText="text"
                                :selection="[updated_in]"
                                :singleSelect="true"
                                @onSelectionChange="
                                    updated_in = $event[0];
                                    if($event[0] !== 'CUSTOM'){
                                        filterApplications();
                                    }
                                "
                            />          
                        </div>
                        

                        <!-- CREATED DATE PICKER -->    
                        <div class="flex sm:flex-col ">
                            <date-range 
                                v-show="createdCustom"
                                title="CREATED"
                                :start="creation_date_start"
                                :end="creation_date_end"
                                inputClass="!outline-none !bg-transparent !border-none"
                                @update="$event => onUpdateDateRange('creation', $event)"
                            />

                            <!-- UPDATED DATE PICKER -->
                            <date-range 
                                v-show="updatedCustom"
                                class="sm:flex-row flex-col"
                                title="UPDATED"
                                :start="updated_date_start"
                                :end="updated_date_end"
                                inputClass="!outline-none !bg-transparent !border-none"
                                @update="$event => onUpdateDateRange('updated', $event)"
                            />
                        </div>
                    </div>
                    <div class="shrink flex flex-wrap gap-2.5">
                        <state-dropdown-selector 
                            :items="all_states"
                            :selection="states"
                            @onSelectAll="
                                states = all_states.map((x) => x.value);
                                filterApplications();
                            "
                            @onDeselectAll="
                                states = [];
                                filterApplications();
                            "
                            @onSelectionChange="
                                states = $event;
                                filterApplications();
                            "
                        />
                        <!-- 
                        <DropdownSelector
                            name="STATES"
                            :items="all_states"
                            searchValue="value"
                            itemValue="value"
                            itemText="value"
                            :selection="states"
                            @onSelectAll="
                                states = all_states.map((x) => x.value);
                                filterApplications();
                            "
                            @onDeselectAll="
                                states = [];
                                filterApplications();
                            "
                            @onSelectionChange="
                                states = $event;
                                filterApplications();
                            "
                        ></DropdownSelector>
                        -->

                        <DropdownSelector
                            name="INSURANCES"
                            :items="all_insurances"
                            searchValue="text"
                            itemValue="value"
                            itemText="text"
                            :selection="insurance_types"
                            @onSelectAll="
                                insurance_types = all_insurances.map(
                                    (x) => x.value
                                );
                                filterApplications();
                            "
                            @onDeselectAll="
                                insurance_types = [];
                                filterApplications();
                            "
                            @onSelectionChange="
                                insurance_types = $event;
                                filterApplications();
                            "
                        ></DropdownSelector>
                        <Submitted-in-dropdown-selector
                            :start="submissions_start"
                            :end="submissions_end"
                            @update="
                                submissions_start = $event.start;
                                submissions_end = $event.end;
                                filterApplications();
                            "
                        />
                        <DropdownSelector
                            name="SHIPPED IN THE LAST"
                            :items="all_shipped_options"
                            searchValue="text"
                            itemValue="value"
                            itemText="text"
                            :selection="selected_shipped_options"
                            @onSelectAll="
                                selected_shipped_options = all_shipped_options.map(
                                    (x) => x.value
                                );
                                filterApplications();
                            "
                            @onDeselectAll="
                                selected_shipped_options = [];
                                filterApplications();
                            "
                            @onSelectionChange="
                                selected_shipped_options = $event;
                                filterApplications();
                            "
                        ></DropdownSelector>
                        <DropdownSelector
                            name="PRIORITIES"
                            :items="all_priorities"
                            searchValue="text"
                            itemValue="id"
                            itemText="text"
                            :selection="priorities"
                            @onSelectAll="
                                priorities = all_priorities.map((x) => x.id);
                                filterApplications();
                            "
                            @onDeselectAll="
                                priorities = [];
                                filterApplications();
                            "
                            @onSelectionChange="
                                priorities = $event;
                                filterApplications();
                            "
                        ></DropdownSelector>
                        <DropdownSelector
                            name="OUTCOMES"
                            :items="all_outcomes"
                            searchValue="text"
                            itemValue="value"
                            itemText="text"
                            :selection="outcomes"
                            @onSelectAll="
                                outcomes = all_outcomes.map((x) => x.value);
                                filterApplications();
                            "
                            @onDeselectAll="
                                outcomes = [];
                                filterApplications();
                            "
                            @onSelectionChange="
                                outcomes = $event;
                                filterApplications();
                            "
                        ></DropdownSelector>
                        <expiring-dropdown-selector 
                            :items="all_upload_types"
                            :selection="upload_types"
                            :selectedWindow="expiring_window"
                            @onSelectAll="
                                upload_types = all_upload_types.map(
                                    (x) => x.id
                                );
                                filterApplications();
                            "
                            @onDeselectAll="
                                upload_types = [];
                                filterApplications();
                            "
                            @onSelectionChange="
                                upload_types = $event;
                                filterApplications();
                            "
                            @onSelectExpiringWindow="
                                expiring_window = $event;
                                if(upload_types?.length){
                                    filterApplications();
                                }
                            "
                        />
                        <DropdownSelector
                            name="STATUSES"
                            :items="filtered_statuses"
                            searchValue="text"
                            itemValue="id"
                            itemText="text"
                            :selection="stages"
                            @onSelectAll="
                                stages = filtered_statuses.map(status => status.id);
                                filterApplications();
                            "
                            @onDeselectAll="
                                stages = [];
                                filterApplications();
                            "
                            @onSelectionChange="
                                stages = $event;
                                filterApplications();
                            "
                        >
                            <template #more-info>
                                <status-definition-modal />
                            </template>
                        </DropdownSelector>
                        <DropdownSelector
                            name="TYPES"
                            :items="all_types"
                            searchValue="value"
                            itemValue="value"
                            itemText="value"
                            :selection="types"
                            @onSelectAll="
                                types = all_types.map((x) => x.value);
                                filterApplications();
                            "
                            @onDeselectAll="
                                types = [];
                                filterApplications();
                            "
                            @onSelectionChange="
                                types = $event;
                                filterApplications();
                            "
                        ></DropdownSelector>
                        <DropdownSelector
                            name="DEVICE TYPES"
                            :items="all_devices"
                            searchValue="device"
                            itemValue="id"
                            itemText="device"
                            :selection="device_types"
                            @onSelectAll="
                                device_types = all_devices.map((x) => x.id);
                                filterApplications();
                            "
                            @onDeselectAll="
                                device_types = [];
                                filterApplications();
                            "
                            @onSelectionChange="
                                device_types = $event;
                                filterApplications();
                            "
                        ></DropdownSelector>
                    </div>
                </div>
            </div>
        </Transition>
        <div
            @click="showFilters = !showFilters"
            class="cursor-pointer flex justify-center items-center mt-4"
        >
            <i
                class="fa fa-list"
                aria-hidden="true"
                data-v-85e18fe4=""
                style="cursor: pointer; margin-right: 8px"
            ></i>
            {{ showFilters == false ? "Show" : "Hide" }} Filters
        </div>
    </div>
</template>

<script>
import statesOptions from "@/services/forms/StatesOptions.js";
import DropdownSelector from "./DropdownSelector";
import StateDropdownSelector from "./StateDropdownSelector";
import ExpiringDropdownSelector from "./ExpiringDropdownSelector";
import SubmittedInDropdownSelector from "./SubmittedInDropdownSelector";
import StatusDefinitionModal from "@/components/dashboard/StatusDefinitionModal.vue";
// import FilterPresets from "@/components/filterPresets/FilterPresets";

export default {
    emits: ['clearFilters'],

    data() {
        return {
            active: [
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
            ],
            defaultSearchYearRange: 5,
            statesSearch: "",
            showStatesDropdown: false,
            startWatching: false,
            dateRangeOptions: [
                { text: "Day", value: 1 },
                { text: "Week", value: 7 },
                { text: "Month", value: 31 },
                { text: "Year", value: 365 },
                { text: "5 Years", value: 1825 },
                { text: "Custom Range", value: "CUSTOM" },
            ],
        };
    },
    mounted() {
        /*
        if (
            !this.creation_date_end &&
            !this.updated_date_end &&
            !this.creation_date_start &&
            !this.updated_date_start
        ) {
            let today = new Date();
            let year = today.getFullYear();
            let month = today.getMonth() + 1;
            if (month < 10) {
                month = "0" + month;
            }
            let day = today.getDate();
            if (day < 10) {
                day = "0" + day;
            }
            this.creation_date_end = `${year}-${month}-${day}`;
            this.updated_date_end = `${year}-${month}-${day}`;
            this.creation_date_start = `${year - this.defaultSearchYearRange}-${month}-${day}`;
            this.updated_date_start = `${year - this.defaultSearchYearRange}-${month}-${day}`;
        }
        */
    },
    components: {
        DropdownSelector,
        StateDropdownSelector,
        ExpiringDropdownSelector,
        StatusDefinitionModal,
        SubmittedInDropdownSelector,
        // FilterPresets,
    },
    methods: {
        onUpdateDateRange(column, values){
            this[`${column}_date_start`] = values.start;
            this[`${column}_date_end`] = values.end;
            this.filterApplications();
        },
        filterApplications() {
            this.$store.dispatch("applications/loadAll");
        },
        onExpandCollapseAll(value) {
            this.active = [
                value,
                value,
                value,
                value,
                value,
                value,
                value,
                value,
                value,
                value,
            ];
        },
        onSelectValue(name, event) {
            const value = event.target.value;
            if (this[name].indexOf(value) === -1) {
                this[name].push(value);
                this.$store.dispatch("applications/loadAll");
            }
        },
        onRemoveValue(name, value) {
            let index = this[name].indexOf(value);
            this[name].splice(index, 1);
            this.$store.dispatch("applications/loadAll");
        },
        clearFilters() {
            this.$emit('clearFilters');
            this.startWatching = false;
            this.$store.commit("applications/clearFilters");
            let today = new Date();
            let year = today.getFullYear();
            let month = today.getMonth() + 1;
            if (month < 10) {
                month = "0" + month;
            }
            let day = today.getDate();

            if (day < 10) {
                day = "0" + day;
            }
            this.creation_date_end = `${year}-${month}-${day}`;
            this.updated_date_end = `${year}-${month}-${day}`;
            this.creation_date_start = `${year - this.defaultSearchYearRange}-${month}-${day}`;
            this.updated_date_start = `${year - this.defaultSearchYearRange}-${month}-${day}`;
            setTimeout(() => {
                this.startWatching = true;
                this.$store.dispatch("applications/loadAll");
            }, 200);
        },
    },
    watch: {
        applications() {
            this.startWatching = true;
        },
    },
    computed: {
        isSuperAdmin(){
            return this.$store.getters.isSuperAdmin ?? false;
        },
        showFilters: {
            get() {
                return this.$store.getters[
                    "applications/showApplicationsFilters"
                ];
            },
            set(value) {
                this.$store.commit(
                    "applications/setShowApplicationsFilters",
                    value
                );
            },
        },
        user() {
            return this.$store.getters.user ?? {};
        },
        applications() {
            return this.$store.getters["applications/all"];
        },
        outcomes: {
            get() {
                return this.$store.getters["applications/outcomes"];
            },
            set(value) {
                this.$store.commit("applications/setOutcomes", value);
            },
        },
        all_submissions() {
            return [
                { text: "Day", value: 1 },
                { text: "Week", value: 7 },
                { text: "2 Weeks", value: 14 },
                { text: "Month", value: 31 },
            ];
        },
        all_shipped_options() {
            return [
                { text: "Day", value: 1 },
                { text: "Week", value: 7 },
                { text: "2 Weeks", value: 14 },
                { text: "Month", value: 31 },
            ];
        },
        all_outcomes() {
            return [
                { text: "Open (used if no trial)", value: "Open" },
                { text: "Open Funding Trial", value: "Open - FND Trial" },
                { text: "Open Funding Purchase", value: "Open - FND Purchase" },
                {
                    text: "Won Waiting on Payment",
                    value: "Won - Waiting on Payment",
                },
                { text: "Won", value: "Won" },
                {
                    text: "Lost (This now includes Lost - Other PRC-S Device)",
                    value: "Lost",
                },
                {
                    text: "None Selected",
                    value: "None"
                }
            ];
        },
        filtered_states() {
            if (!this.all_states) return [];
            return this.all_states.filter(
                (x) => this.states.indexOf(x.value) === -1
            );
        },
        filtered_insurances() {
            if (!this.all_insurances) return [];
            return this.all_insurances.filter(
                (x) => this.insurance_types.indexOf(x.value) === -1
            );
        },
        filtered_priorities() {
            if (!this.all_priorities) return [];
            return this.all_priorities.filter(
                (x) => this.priorities.indexOf(x.id.toString()) === -1
            );
        },
        filtered_statuses() {
            return this.$store.getters["applications/statuses"];
            /*
            if (!this.all_statuses) return [];
            return this.all_statuses.filter((x) => x.display_application);
            */
        },
        filtered_devices() {
            if (!this.all_devices) return [];
            return this.all_devices.filter(
                (x) => this.device_types.indexOf(x.id.toString()) == -1
            );
        },
        filtered_types() {
            if (!this.all_types) return [];
            return this.all_types.filter((x) => this.types.indexOf(x) === -1);
        },
        all_priorities() {
            return Object.values(this.$store.getters.application_priorities);
        },
        all_states() {
            return [
                { value: "None" }, 
                ...statesOptions,
            ];
        },
        all_insurances() {
            return [
                { value: "medicare", text: "Medicare" },
                { value: "medicaid", text: "Medicaid" },
                { value: "commercial", text: "Commercial Insurance" },
                { value: "additional", text: "Additional Insurance" },
                { value: "additional2", text: "Additional Insurance 2" },
            ];
        },
        /*
        all_statuses() {
            return Object.values(this.$store.getters.statuses);
        },
        */
        all_devices() {
            if (!this.$store.getters.sortedAllDevices) return [];
            return [{id:-1,device:'Unassigned'}, ...this.$store.getters.sortedAllDevices];
        },
        all_types() {
            return [
                { value: "trial" },
                { value: "purchase" },
                { value: "repair" },
            ];
        },
        all_upload_types() {
            if (!this.$store.getters.uploadTypes) return [];
            return Object.values(this.$store.getters.uploadTypes).sort((a,b) => {
                if (a.text >= b.text) {
                    return 1;
                } else {
                    return -1;
                }
            });
        },
        upload_types: {
            get() {
                return this.$store.getters["applications/upload_types"];
            },
            set(value) {
                this.$store.commit("applications/setUploadTypes", value);
            },
        },
        expiring_window: {
            get() {
                return this.$store.getters["applications/expiring_window"];
            },
            set(value) {
                this.$store.commit("applications/setExpiringWindow", value);
            },
        },
        show_only_my_apps: {
            get() {
                return !this.show_all;
            },
            set(value) {
                this.show_all = !value;
                this.filterApplications();
            },
        },
        show_qad: {
            get() {
                return this.$store.getters["applications/show_qad"];
            },
            set(value) {
                this.$store.commit("applications/setShowQad", value);
                this.filterApplications();
            },
        },
        show_wo_qad_son: {
            get() {
                return this.$store.getters["applications/show_wo_qad_son"];
            },
            set(value) {
                this.$store.commit("applications/setShowWoQadSon", value);
                this.filterApplications();
            }
        },
        show_wo_rma_number: {
            get() {
                return this.$store.getters["applications/show_wo_rma_number"];
            },
            set(value) {
                this.$store.commit("applications/setShowWoRmaNumber", value);
                this.filterApplications();
            }
        },
        has_custom_item: {
            get() {
                return this.$store.getters["applications/show_has_custom_item"];
            },
            set(value) {
                this.$store.commit("applications/setShowHasCustomItem", value);
                this.filterApplications();
            }
        },
        has_no_tasks: {
            get() {
                return this.$store.getters["applications/has_no_tasks"];
            },
            set(value) {
                this.$store.commit("applications/setHasNoTasks", value);
                this.filterApplications();
            },
        },
        show_converted_trial: {
            get() {
                return this.$store.getters["applications/show_converted_trial"];
            },
            set(value) {
                this.$store.commit("applications/setShowConvertedTrial", value);
                this.filterApplications();
            },
        },
        submissions_start: {
            get() {
                return this.$store.getters["applications/applicationParams"]["submissions_start"];
            },
            set(value) {
                this.$store.commit("applications/setParam", { param: "submissions_start", value });
            },
        },
        submissions_end: {
            get() {
                return this.$store.getters["applications/applicationParams"]["submissions_end"];
            },
            set(value) {
                this.$store.commit("applications/setParam", { param: "submissions_end", value });
            },
        },
        selected_shipped_options: {
            get() {
                return this.$store.getters["applications/selected_shipped_options"];
            },
            set(value) {
                this.$store.commit("applications/setSelectedShippedOptions", value);
            },
        },
        show_all: {
            get() {
                return this.$store.getters["applications/show_all"];
            },
            set(value) {
                this.$store.commit("applications/setShowAll", value);
            },
        },
        states: {
            get() {
                return this.$store.getters["applications/states"];
            },
            set(value) {
                this.$store.commit("applications/setStates", value);
            },
        },
        insurance_types: {
            get() {
                return this.$store.getters["applications/insurance_types"];
            },
            set(value) {
                this.$store.commit("applications/setInsuranceTypes", value);
            },
        },
        priorities: {
            get() {
                return this.$store.getters["applications/priorities"];
            },
            set(value) {
                this.$store.commit("applications/setPriorities", value);
            },
        },
        stages: {
            get() {
                return this.$store.getters["applications/stages"];
            },
            set(value) {
                this.$store.commit("applications/setStages", value);
            },
        },
        types: {
            get() {
                return this.$store.getters["applications/types"];
            },
            set(value) {
                this.$store.commit("applications/setTypes", value);
            },
        },
        created_in: {
            get() {
                return this.$store.getters["applications/applicationParams"]["created_in"];
            },
            set(value) {
                this.$store.commit("applications/setParam", { param: "created_in", value });
            },
        },
        createdCustom(){
            return this.$store.getters["applications/applicationParams"]["created_in"] === "CUSTOM";
        },
        creation_date_start: {
            get() {
                return this.$store.getters["applications/creation_date_start"];
            },
            set(value) {
                this.$store.commit("applications/setCreationDateStart", value);
            },
        },
        creation_date_end: {
            get() {
                return this.$store.getters["applications/creation_date_end"];
            },
            set(value) {
                this.$store.commit("applications/setCreationDateEnd", value);
            },
        },
        updated_in: {
            get() {
                return this.$store.getters["applications/applicationParams"]["updated_in"];
            },
            set(value) {
                this.$store.commit("applications/setParam", { param: "updated_in", value });
            },
        },
        updatedCustom(){
            return this.$store.getters["applications/applicationParams"]["updated_in"] === "CUSTOM";
        },
        updated_date_start: {
            get() {
                return this.$store.getters["applications/updated_date_start"];
            },
            set(value) {
                this.$store.commit("applications/setUpdatedDateStart", value);
            },
        },
        updated_date_end: {
            get() {
                return this.$store.getters["applications/updated_date_end"];
            },
            set(value) {
                this.$store.commit("applications/setUpdatedDateEnd", value);
            },
        },
        device_types: {
            get() {
                return this.$store.getters["applications/device_types"];
            },
            set(value) {
                this.$store.commit("applications/setDeviceTypes", value);
            },
        },
        show_closed: {
            get() {
                return this.$store.getters["applications/show_closed"];
            },
            set(value) {
                this.$store.commit("applications/setShowClosed", value);
                this.filterApplications();
            },
        },
        show_only_practice: {
            get() {
                return this.$store.getters["applications/show_only_practice"];
            },
            set(value) {
                this.$store.commit("applications/setShowOnlyPractice", value);
                this.filterApplications();
            },
        },
    },
};
</script>

<style scoped>
input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}
.counter-chip {
    background: #192951;
    color: white;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 10px;
    margin-left: 4px;
}
.v-enter-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
.chip {
    border: 1px solid black;
    display: flex;
    padding: 4px 8px;
    border-radius: 4px;
    /* background: ; */
    align-items: center;
    font-size: 12px;
    margin-right: 8px;
    margin-top: 4px;
}
</style>
