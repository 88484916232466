export default [
{ value: 'Alabama' },
{ value: 'Alaska' },
{ value: 'American Samoa' },
{ value: 'Arizona' },
{ value: 'Arkansas' },
{ value: 'California' },
{ value: 'Colorado' },
{ value: 'Connecticut' },
{ value: 'Delaware' },
{ value: 'District of Columbia' },
{ value: 'Florida' },
{ value: 'Georgia' },
{ value: 'Guam' },
{ value: 'Hawaii' },
{ value: 'Idaho' },
{ value: 'Illinois' },
{ value: 'Indiana' },
{ value: 'Iowa' },
{ value: 'Kansas' },
{ value: 'Kentucky' },
{ value: 'Louisiana' },
{ value: 'Maine' },
{ value: 'Marshall Islands' },
{ value: 'Maryland' },
{ value: 'Massachusetts' },
{ value: 'Michigan' },
{ value: 'Micronesia' },
{ value: 'Minnesota' },
{ value: 'Mississippi' },
{ value: 'Missouri' },
{ value: 'Montana' },
{ value: 'Nebraska' },
{ value: 'Nevada' },
{ value: 'New Hampshire' },
{ value: 'New Jersey' },
{ value: 'New Mexico' },
{ value: 'New York' },
{ value: 'North Carolina' },
{ value: 'North Dakota' },
{ value: 'Northern Mariana Islands' },
{ value: 'Ohio' },
{ value: 'Oklahoma' },
{ value: 'Oregon' },
{ value: 'Palau' },
{ value: 'Pennsylvania' },
{ value: 'Puerto Rico' },
{ value: 'Rhode Island' },
{ value: 'South Carolina' },
{ value: 'South Dakota' },
{ value: 'Tennessee' },
{ value: 'Texas' },
{ value: 'U.S. Armed Forces Americas' },
{ value: 'U.S. Armed Forces Europe' },
{ value: 'U.S. Armed Forces Pacific' },
{ value: 'U.S. Minor Outlying Islands' },
{ value: 'Utah' },
{ value: 'Vermont' },
{ value: 'Virgin Islands, U.S.' },
{ value: 'Virginia' },
{ value: 'Washington' },
{ value: 'West Virginia' },
{ value: 'Wisconsin' },
{ value: 'Wyoming' },
];