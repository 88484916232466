<template>
    <!-- button -->
    <component
        :is="component"
        v-bind="$attrs"
        :role="role"
        type="button"
        :disabled="disabled"
        :size="size"
        @click.prevent.stop="showConfirmation = true"
        :class="computedClasses"
    >
        <slot></slot>
    </component>

    <!-- confirmation modal -->
    <base-modal
        :open="showConfirmation"
        @close="showConfirmation = false"
        class="w-xs"
        :title="title"
        :icon="modalIcon"
        :titleRole="titleRole"
    >
        <div class="grid gap-4">
            <p v-html="message"></p>
            
            <div v-if="field">
                <label class="block">
                    <span class="block text-sm font-medium text-slate-700">
                        <span v-html="field?.label"></span>
                        <span v-if="field?.required" class="!text-red-600">*</span>
                    </span>
                    <input type="text" v-model="fieldInput" class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"/>
                </label>
            </div>

            <div v-if="typeConfirm">
                <label class="block">
                    <span class="block text-sm font-medium text-slate-700">Please type "{{ typeConfirm }}" to confirm.</span>
                    <input type="text" v-model="confirmInput" class="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500"/>
                </label>
            </div>

            <!-- buttons -->
            <div class="flex items-center justify-end gap-3">
                <primary-button 
                    :disabled="!canConfirm" 
                    :role="confirmButtonRole" 
                    type="button" 
                    @click="handleYesButton"
                >
                    {{confirmText}}
                </primary-button>
                <secondary-button
                    type="button"
                    @click="handleNoButton"
                    :role="cancelButtonRole"
                >
                    {{cancelText}}
                </secondary-button>
            </div>
        </div>
    </base-modal>
</template>

<script>
export default {
    emits: ["confirmed", "declined"],
    data() {
        return {
            showConfirmation: false,
            confirmInput: '',
            fieldInput: '',
        }
    },
    props: {
        component: {
            type: String,
            default: "secondary-button",
        },
        message: {
            type: String,
            default: "Are you sure you want to perform this action?",
        },
        role: {
            type: String,
            default: "danger",
        },
        disabled: {
            type: Boolean,
        },
        size: {
            type: String,
        },
        confirmText: {
            type: String,
            default: "yes",
        },
        cancelText: {
            type: String,
            default: "no",
        },
        typeConfirm: {
            type: String,
        },
        confirmButtonRole: {
            type: String,
            default: "danger",
        },
        cancelButtonRole: {
            type: String,
            default: "default",
        },
        titleRole: {
            type: String,
            default: "default",
        },
        title: {
            type: String,
            default: "Please Confirm",
        },
        modalIcon: {
            type: String,
            default: 'badge-check',
        },
        field: {
            type: Object,
            // {string} label: text label for the field
            // {bool} required: is this field required?
        },
    },
    computed: {
        computedClasses() {
            return this.disabled ? "opacity-70 pointer-events-none" : null;
        },
        canConfirm() {
            let result = true;

            if(!this.typeConfirm && !this.field){
                return result;
            }

            if(this.field?.required && !this.fieldInput){
                result = false;
            }

            if(this.typeConfirm && this.typeConfirm != this.confirmInput){
                result = false;
            }

            return result;;
        }
    },
    methods: {
        handleYesButton() {
            this.$emit('confirmed', this.field ? this.fieldInput : null);
            this.reset();
        },

        handleNoButton() {
            this.$emit('declined');
            this.reset();
        },

        reset() {
            this.showConfirmation = false;
            this.confirmInput = '';
            this.fieldInput = '';
        }
    }
};
</script>
