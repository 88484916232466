<template>
    <!-- main wrapper -->
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="text-left grid form-element"
            v-bind="field.wrapperAttributes"
            :class="field.wrapperClass"
        >
            <!-- label -->
            <form-label :field="field" :required="isRequired"></form-label>

            <!-- teleport target -->
            <form-teleport :teleport="field.teleport"></form-teleport>

            <!-- checkbox set -->
            <div
                class="grid gap-3 order-3 mt-2 auto-rows-fr"
                :class="computedClasses"
                v-bind="field.attributes"
                v-bind:[dataName]="`${field.name}--options`"
            >
                <template v-if="field.options?.length">
                    <template v-for="option in field.options" :key="option.value">
                        <!-- if we pass an element in place of an option render it -->
                        <option-element v-if="option.type === 'element'"
                            :option="option"
                        />

                        <!-- otherwise render a checkbox option -->
                        <checkboxes-option
                            v-else
                            :option="option"
                            :field="field"
                            :modelValue="modelValue"
                            @valueUpdated="handleInput"
                            :disabled="disabled"
                        />
                    
                    </template>
                    
                </template>
                <div v-else class="p-3 col-span-full border rounded text-center bg-gray-50 text-gray-500" v-html="noOptionsMessage"></div>
            </div>

            <!-- errors -->
            <form-error :error="errorText" :field="field" class="order-5"></form-error>
        </div>
    </transition>
</template>

<script>

import fieldMixin from "./mixins/fieldMixin";
import checkIconMixin from "./mixins/checkIconMixin";
import CheckboxesOption from "./CheckboxesOption.vue";
import OptionElement from "./OptionElement.vue";
export default {
    mixins: [fieldMixin, checkIconMixin],
    components: {
        CheckboxesOption,
        OptionElement,
    },

    methods: {
        handleInput(optionValue) {
            this.clearErrors();

            let valueArray = this.getNewValueArray(optionValue);

            this.updateValue(this.field.name, valueArray);
            this.validate();
        },

        getNewValueArray(optionValue){
            // if this option is exclusive it can be the only selected option
            // so toggle its state, and return just that value (if appropriate)
            if(this.exclusiveValues.includes(optionValue)){
                return this.modelValue.includes(optionValue) ? [] : [optionValue];
            }

            // copy our checkboxes current value
            let valueArray = [...this.modelValue];

            // remove any exclusive values when we click on a non-exclusive value
            valueArray = valueArray.filter(value => !this.exclusiveValues.includes(value));

            // toggle optionValue in the valueArray
            const index = valueArray.indexOf(optionValue);

            if (index === -1) {
                // if value isn't the array, add it
                valueArray.push(optionValue);
            } else {
                // if the value is already in the array remove it
                valueArray.splice(index, 1);
            }

            return valueArray;
        },
    },

    computed: {
        computedClasses() {
            let classes = "";

            if (this.field.class) {
                classes += ` ${this.field.class} `;
            }

            if (this.field.attributes?.class) {
                classes += ` ${this.field.attributes.class} `;
            }

            return classes;
        },

        exclusiveValues() {
            return this.field.options.map(option => {
                return option.exclusive ? option.value : "NOT EXCLUSIVE";   
            }).filter(value => value !== "NOT EXCLUSIVE");
        },

        noOptionsMessage() {
            return this.field.noOptionsMessage ?? "No options available..."
        }
    },
};
</script>
