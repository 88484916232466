<template>
  <sidebar-section 
        v-if="isAdmin"
        :hideInPrivacyMode="true"
        title="Recently Viewed"
        :count="recentlyViewed.length"
        :showViewMore="recentlyViewed.length > initialAmountToShow && !showingAll"
        :showViewLess="showingAll"
        :showAddItem="false"
        @viewMore="showingAll = true"
        @viewLess="showingAll = false"
    >
        <recently-viewed-item 
            v-for="item in filteredRecentlyViewed"
            :key="item.id"
            :item="item"
        />
    </sidebar-section>
</template>

<script>
import RecentlyViewedItem from "./RecentlyViewedItem.vue";

export default {
    components: {
        RecentlyViewedItem,
    },
    
    data() {
        return {
            showingAll: false,
            initialAmountToShow: 3,
        };
    },

    computed: {
        recentlyViewed() {
            return this.$store.getters["recentlyViewed"];
        },

        filteredRecentlyViewed() {
            if(this.showingAll){
                return this.recentlyViewed;
            }

            return this.recentlyViewed.slice(0, this.initialAmountToShow);
        },

        isAdmin() {
            return this.$store.getters.isAdmin;
        },
    },
}
</script>

<style>

</style>