<template>
    <base-alert type="info" class="text-yellow-700 border-yellow-600 bg-yellow-100" :icon="icon" :pclass="pclass" @close="$emit('close')">
        <slot></slot>
    </base-alert>
</template>

<script>
export default {
    emits: ["close"],
    props: {
        icon: {
            default: "triangle-exclamation",
        },
        pclass: {
            type: String
        }
    },
};
</script>
