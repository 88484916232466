/**
 *
 * Admin middleware protects routes from users who are not admins or super admins
 *
 */

// This doesn't appear to work, because the Vuex store seems to load the
// isAdmin getters after the middleware runs when refreshing the page or 
// navigating directly via the browser url bar 
export default ({ to, redirect, store, middlewareIncludes }) => {
    if (middlewareIncludes(to, "admin") && !store.getters.isAdmin) {
        console.log(`failed admin middleware check: isAdmin = ${store.getters.isAdmin}`);
        redirect({ name: "dashboard" });
    }

    if (middlewareIncludes(to, "superAdmin") && !store.getters.isSuperAdmin) {
        console.log(`failed super admin middleware check: isSuperAdmin = ${store.getters.isSuperAdmin}`);
        redirect({ name: "dashboard" });
    }
};
