<template>
    <form
        @submit.prevent="onSubmit"
        class="w-full"
        ref="quickForm"
        :data-form="name"
    >
        <!-- build our form elements -->
        <form-group
            :schema="schema"
            :form="form"
            :disabled="isDisabled"
            @submitForm="onSubmit"
        />

        <slot name="alert"></slot>

        <!-- error alert -->
        <transition name="fade">
            <error-alert
                v-if="hasErrors && !schema.hideErrorAlert"
                class="mt-3 justify-center"
                v-bind:[dataName]="`error-alert`"
            >
                Please fix the errors shown above
            </error-alert>
        </transition>

        <!-- buttons -->
        <div
            class="quick-form__button-container flex flex-col-reverse md:flex-row md:flex-wrap md:justify-end md:items-center mt-4 gap-3"
            :class="schema?.buttonsClass"
        >
            <slot name="buttons"></slot>
            <slot name="submit-button">
                <primary-button
                    class="order-1"
                    data-submit
                    :disabled="isDisabled || hasErrors || submitDisabled"
                    type="submit"
                    :role="submitRole"
                    v-bind:[dataName]="`submit`"
                    :icon="sending ? 'spinner-third' : ''"
                    :iconClass="sending ? 'fas fa-spin absolute' : ''"
                >
                    <span :class="{ invisible: sending }">
                        {{ submitText }}
                    </span>
                </primary-button>
            </slot>
        </div>

        <!-- confirmation modal -->
        <base-modal
            :open="showConfirmationModal"
            @close="showConfirmationModal = false"
            class="w-xs"
            title="Please Confirm"
            icon="badge-check"
            v-bind:[dataName]="`confirmation--modal`"
            :titleRole="schema?.confirm?.titleRole ?? 'warning'"
        >
            <p class="mb-4" v-html="confirmMessage"></p>

            <!-- buttons -->
            <div class="flex items-stretch justify-end gap-3">
                <primary-button
                    :role="confirmButtonRole"
                    type="button"
                    @click="submitForm"
                    v-bind:[dataName]="`confirmation--confirm`"
                >
                    {{ confirmButtonText }}
                </primary-button>
                <secondary-button
                    type="button"
                    :role="cancelButtonRole"
                    @click="showConfirmationModal = false"
                    v-bind:[dataName]="`confirmation--cancel`"
                >
                    {{ cancelButtonText }}
                </secondary-button>
            </div>
        </base-modal>
    </form>
</template>

<script>
import Form from "@/services/forms/FormService";
import FormGroup from "./formElements/FormGroup.vue";

export default {
    props: ["schema", "disabled", "submitDisabled", "holdSubmit", "holdSubmitCallback", "forceHasErrors"],
    emits: ["success", "error", "submit", "updated"],
    components: {
        FormGroup,
    },

    data() {
        return {
            form: new Form(this.$http, this.schema, this.emitInitUpdate),
            sending: false, // are we currently sending
            showConfirmationModal: false,
        };
    },

    provide() {
        return {
            updateValue: this.updateValue, // update fields within the form
            dataName: this.dataName,
            clearFieldErrors: this.clearFieldErrors,
        };
    },

    watch: {
        // whenever our schema changes, pass those changes to our form
        schema(schema) {
            this.form.setSchema(schema);
        },
    },

    computed: {
        name() {
            return this.schema.name ?? "form";
        },

        dataName() {
            return this.name === "form"
                ? "data-form"
                : `data-form-${this.name}`;
        },

        isDisabled() {
            return this.disabled || this.schema.disabled || this.sending;
        },

        inputs() {
            return this.form.data;
        },

        modalAttributes() {
            const attributes = {};

            return attributes;
        },

        /**
         * The text for our submit button
         */
        submitText() {
            return this.schema.submitText ?? "submit";
        },

        confirmButtonText() {
            return this.schema.confirm?.confirmButtonText ?? "yes";
        },

        confirmButtonRole() {
            return this.schema.confirm?.confirmButtonRole ?? "danger";
        },

        cancelButtonText() {
            return this.schema.confirm?.cancelButtonText ?? "no";
        },

        cancelButtonRole() {
            return this.schema.confirm?.cancelButtonRole ?? "default";
        },

        confirmMessage() {
            return (
                this.schema.confirm?.message ??
                "Are you sure you want to save these changes?"
            );
        },

        /**
         * The role for our submit button
         * can be manaully set with the submitRole schema property otherwise
         * defaults to danger for buttons that require confirmation and null
         * otherwise.
         */
        submitRole() {
            if (this.schema.submitRole) {
                return this.schema.submitRole;
            }

            return this.schema.confirm ? "danger" : null;
        },

        hasErrors() {
            return this.form.errors.any() || this.forceHasErrors;
        },

        /**
         * are we in debug mode?
         */
        debugMode() {
            return process.env.VUE_APP_NODE_ENV === "box";
        },
    },

    methods: {
        validate(){
            return this.form.validateAll();
        },

        /**
         * update a field's value
         */
        updateValue(name, value, emitUpdated = true) {
            //this.form.setSchema(this.schema);
            this.form.updateValue(name, value);
            if (emitUpdated) {
                this.$emit("updated", {
                    name,
                    value,
                    fields: this.form.data,
                });
            }
        },

        /**
         * Manually set all of the errors for this form, overwrites any existing errors
         *
         * @param {object} errors An object containing the field's errors
         * @property {array[string]} fieldName each property of this object must have a key
         *      matching the name of a field, and a value that consists of an array of error strings
         */
        setErrors(errors) {
            this.form.errors.record(errors);
        },

        setFieldError(field, message) {
            this.form.setFieldError(field, message);
        },

        clearFieldErrors(field) {
            this.form.errors.clear(field);
        },

        /**
         * Emit the value updated even with null values like when resetting or initing the form
         */
        emitInitUpdate(fields) {
            this.$emit("updated", {
                name: null,
                value: null,
                fields: fields,
            });
        },

        setSending(value) {
            this.sending = value;
        },

        /**
         * reset our form
         */
        reset() {
            // I legitimately don't know why I have to next tick this
            this.$nextTick(() => {
                //this.form.setSchema(this.schema);
                this.form.reset();
            });
        },

        /**
         * Submit our form
         */
        onSubmit() {
            if (this.isDisabled) {
                return;
            }

            this.$emit("submit");

            // if we have holdSubmit set, take no further action
            if (this.holdSubmit) {
                return;
            }

            // run our holdSubmitCallback if we have one, if it returns boolean false, abort
            if (typeof this.holdSubmitCallback === 'function'){
                let result = this.holdSubmitCallback();
                if(result === false){
                    return;
                }
            }

            // if we have a confirmationMessage set, show the confirmation modal
            if (this.schema.confirm) {
                // validate the form before showing the confirm modal
                // if we have any errors, abort
                if (!this.form.validateAll()) {
                    return;
                }

                this.showConfirmationModal = true;
                return;
            }

            // otherwise just go ahead and submit the form
            this.submitForm();
        },

        submitForm() {
            if (this.isDisabled) {
                return;
            }

            this.showConfirmationModal = false;
            this.setSending(true);

            this.form.submit().then(this.handleSuccess).catch(this.handleError);
        },

        /**
         * Handle a success response
         */
        handleSuccess(response) {
            this.setSending(false);

            // if we are in debug mode output the response to the console
            if (this.debugMode) {
                console.log("quickFormSuccess:", response);
            }

            // show empty required fields warning
            if (
                Array.isArray(response.emptySoftRequiredFields) &&
                response.emptySoftRequiredFields.length
            ) {
                this.$store.dispatch("notifications/create", {
                    message:
                        "Fields required for submission on the previous page were left incomplete",
                    type: "warning",
                    persist: true,
                });
            }

            // emit our success response
            this.$emit("success", response);
        },

        /**
         * Handle an error response
         */
        handleError(errors) {
            this.setSending(false);

            // if we are in debug mode output the response to the console
            if (this.debugMode) {
                console.error("baseFormErrors", errors);
            }

            this.$emit("error", errors);
        },
    },
};
</script>
<style>
/*-----------------------------
    shared options styles
    -----------------------------*/

.form-element__option {
    @apply w-full relative rounded p-3 flex items-center overflow-hidden bg-blueGray-50;
}

.form-element__option__image {
    @apply w-fit relative rounded p-3 flex items-center overflow-hidden bg-blueGray-50 flex-col my-2; 
}

.form-element__option__image__title {
    @apply max-w-[168px] text-center
}

.form-element__option--active {
    @apply bg-secondary-200;
}
.form-element__option__image.form-element__option--active {
    @apply bg-secondary-400;
}

.form-element__option-icon {
    @apply text-gray-300;
}

.form-element__option-text {
    display: inline-block;
}

.form-element__option-text:first-letter {
    text-transform: uppercase
}

.form-element__option--active .form-element__option-icon {
    @apply text-secondary-800;
}

.quick-form__button-container button {
    width: 100%;
}

.orm-element__input.form-element__input--error {
    @apply border-red-800;
}

.form-element__input--required-highlight:not(:focus) {
    @apply outline outline-2 outline-yellow-500/60;
}

@screen md {
    .quick-form__button-container button {
        width: auto;
    }
}

/*--------------------------- 
    night theme
    ----------------------------*/
.theme--night .form-element__input {
    @apply text-white border-blueGray-600;
    background-color: rgba(0, 0, 25, 0.7);
}

.theme--night .form-label__text {
    @apply text-blueGray-300;
}

.theme--night .form-label__description {
    @apply text-blueGray-400;
}

.theme--night .form-element__error {
    @apply text-red-500;
}

.theme--night .form-element__option {
    @apply bg-transparent border-blueGray-600 border;
}

.theme--night .form-element__option--active {
    @apply border-transparent;
    background-color: #263960;
}

.theme--night .form-element__option-icon {
    @apply text-gray-500;
}

.theme--night .form-element__option--active .form-element__option-icon {
    @apply text-secondary-500;
}

.theme--night .form-element__input--required-highlight:not(:focus) {
    @apply outline outline-1 outline-blue-300/70;
}
</style>
