<template>
    <base-modal
        :open="true"
        @close="$emit('close')"
        class="w-sm"
        title="Rename or delete your presets"
        icon="edit"
    >
        <div v-if="presets.length" class="grid gap-2">
            <edit-item 
                v-for="preset in presets"
                :key="preset.id"
                :preset="preset"
                :type="type"
                @close="$emit('close')"
            />
        </div>

        <warning-alert v-else>No filter presets saved for this user</warning-alert>
    </base-modal>
</template>

<script>
import EditItem from "./EditItem";

export default {
    components: {
        EditItem,
    },  
    props: {
        presets: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            required: true,
        },
    },
}
</script>

<style>

</style>