//import router from "@/router/index";

// manages our upload types
export default {
    state() {
        return {};
    },

    mutations: {
    },

    actions: {
    },

    getters: {
        uploadTypes(_, __, rootState){
            return rootState.uploadTypes;
        },
        getUploadTypeId(_, __, rootState){
            return (name) => {
                return Object.values(rootState.uploadTypes).find(uploadType => uploadType.name === name)?.id ?? 0;
            }
        },
        uploadType: (_, __, rootState) => (id) => { 
            if(rootState.uploadTypes){
                return rootState.uploadTypes[id];
            } 
            return null;
        },
        uploadTypeText(){
            return (upload) => {
                // if we don't have an upload type relation, return empty string
                if (!upload.uploadType) {
                    return "";
                }

                // if our upload type is "other", return the other_type
                // otherwise return the uploadType text
                return upload.uploadType.name === "other" 
                    ? upload.other_type
                    : upload.uploadType.text;
            }
        },
    },   
};
