<template>
     <th class="whitespace-nowrap text-left py-2 px-4">
        {{ text }}
        <i v-if="icon" class="fa-solid ml-1" :class="icon"></i>
    </th>
</template>

<script>
export default {
    props: {
        column: {
            type: Object,
            required: true,
        },
        sort: {}
    },
    computed: {
        text() {
            if(this.column.text){
                return this.column.text;
            }

            return this.column.value.charAt(0).toUpperCase() + this.column.value.slice(1);
        },
        icon() {
            if(this.sort === this.column.value){
                return "fa-sort-up";
            }

            if(this.sort === '-' + this.column.value){
                return "fa-sort-down";
            }

            return false;
        }
    },
};
</script>

<style scoped></style>
