import dayjs from "dayjs";
var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat);

export default {
    /*
     * Formats the date to YYYY-MM-DD (Laravel format)
     */
    formatForBackend(date) {
        if (date === null || date === "" || date === undefined) {
            return null;
        }

        return dayjs(date).format("YYYY-MM-DD");
    },

    /*
     * Formats the date to MM/DD/YYYY
     */
    humanReadable(date) {
        if (date === null || date === "") {
            return null;
        }

        return dayjs(date).format("MM/DD/YYYY");
    },

    /*
     * Formats the date to MM/DD/YYYY 10:02am
     */
    humanReadableDateTime(date) {
        if (date === null || date === "") {
            return null;
        }

        return dayjs(date).format("MM/DD/YYYY HH:mm:ss");
    },
    /*
     * Formats the date to January 1st, 1979
     */
    fullText(date) {
        if (date === null || date === "") {
            return null;
        }

        return dayjs(date).format("MMMM Do, YYYY");
    },

    /*
     * Formats the date to January 1st, 1979 10:02am
     */
    fullTextWithTime(date) {
        if (date === null || date === "") {
            return null;
        }

        return dayjs(date).format("MMMM Do, YYYY h:mma");
    },

    /*
     * Formats the date to Jan 1st, 1979
     */
    shortText(date) {
        if (date === null || date === "") {
            return null;
        }

        return dayjs(date).format("MMM Do, YYYY");
    },


    /*
     * Formats the date to Jan 1st, 1979
     */
    shortTextWithTime(date) {
        if (date === null || date === "") {
            return null;
        }

        return dayjs(date).format("MMM Do, YYYY h:mma");
    },

    humanReadableWithTime(date) {
        if (date === null || date === "") {
            return null;
        }

        return dayjs(date).format("MM/DD/YYYY h:mm a");
    },

    // If date is today, returns only time, otherwise returns only the date
    humanReadableDateOrTime(date) {
        if (date === null || date === "") {
            return null;
        }
        
        const isToday = dayjs(date).isSame(dayjs(), 'date'); // dayjs() return current date 
        if (isToday) {
            return dayjs(date).format("hh:mm a");
        }
        return dayjs(date).format("MM/DD/YYYY");
    },

    /*
     *  Returns the difference in years
     *  end parameter defaults to today if not present
     */
    yearsRange(start, end) {
        if (start === null || start === "") {
            return null;
        }

        if (end === null) {
            end = dayjs();
        } else {
            end = dayjs(end);
        }
        return end.diff(start, "year");
    },

    pastDate(unit, value = 1, format = "YYYY-MM-DD HH:mm:ss") {
        return dayjs().subtract(value, unit).format(format);
    },

    sortByCreatedComparator(a, b) {
        return dayjs(b.created_at).diff(dayjs(a.created_at));
    },

    sortByCreatedComparatorDesc(a,b) {
        return dayjs(a.created_at).diff(dayjs(b.created_at));
    }
};
