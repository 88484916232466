<template>
    <div v-if="!field.hideError" class="form-element__error text-red-700 text-sm" v-bind:[dataName]="`${field.name}--error`">
        <span v-if="error" data-error v-html="error.replace('asha','ASHA')"></span>
        &nbsp;
    </div>
</template>
<script>
export default {
    props: ["error", "field"],
    inject: ["dataName"],
};
</script>
