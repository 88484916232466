<template>
    <warning-alert :icon="false">
        <ul class="list-disc px-4">
            <li>
                Terms & Conditions and Roles & Responsibilities must be signed by the client or parent/legal guardian of the client <span class="font-bold">(the Medical Decision Maker).</span>
            </li>
            <li>
                 The Speech-Language Pathologist <span class="font-bold">CANNOT</span> sign as the responsible party.
            </li>
            <li>
                If the client or parent/legal guardian of the client is unable to sign the web form, 
                select the upload option from the <router-link class="link--warning-alert" :to="{ name: 'application.tandc' }">overview</router-link> and upload a signed agreement there.
            </li>
        </ul>
        
    </warning-alert>
</template>
<style scoped>
    .list-disc li {
        margin-bottom: .5rem;
    }

    .list-disc li:last-child {
        margin-bottom: 0;
    }
</style>

