<template>
    <article class="grid gap-4">
        <h2>Terms & Conditions</h2>
        <p>
            Thank You for visiting AACfunding.com. This
            website provides You (&ldquo;You&rdquo; or the &ldquo;User&rdquo;)
            access to resources, funding applications, and other information to
            facilitate your submission of or participation in a funding
            request application for a Speech Generating Device.
        </p>
        <p>
            This website is offered to the User subject to your acceptance of
            the terms, conditions, and notices contained in these Terms of Use.
            These Terms of Use apply to your use of or visit to this website and
            all functions or features of this website, as well as all offers,
            sales, purchases, loans, and rentals of products or services available
            through this website. By accessing and using this website, the User
            signifies your agreement to and acceptance of these Terms of Use, as
            they may be modified from time to time by PRC-Saltillo.
        </p>
        <h4>
            BY USING THIS WEBSITE YOU REPRESENT THAT YOU ARE AT LEAST 18 YEARS
            OLD AND THAT YOU ARE LEGALLY ABLE TO ENTER INTO THESE TERMS OF USE
            AS YOUR BINDING AGREEMENT.
        </h4>
        <p>
            Any use of this site is deemed to constitute acceptance of all terms
            and conditions set forth herein, PRC-Saltillo's
            <a
                class="link--primary"
                :href="$landingSiteUrl+'notice-of-privacy-practices'"
                target="_blank"
                rel="noopener"
            >
                Notice of Privacy Practices
            </a>
            and
            <a
                class="link--primary"
                :href="$landingSiteUrl+'privacy-policy'"
                target="_blank"
                rel="noopener"
            >
                Privacy Policy.
            </a>
            In order to access the website, You
            will be required to read and accept each of the following
            requirements:
        </p>
        <h4>Disclaimer</h4>
        <p>
            PRC-Saltillo assumes no responsibility for any errors or omissions
            in the information provided on this website. PRC-Saltillo makes no
            representations or warranties about the suitability of the
            information for any purpose. All information and documents are
            provided &ldquo;as is,&rdquo; without warranty of any kind. By using
            this site, the User agrees that PRC-Saltillo shall not be liable for
            any special, indirect, or consequential damages or any damages
            whatsoever arising out of, resulting from or in connection with the
            use, misuse, or application of information or documents available
            from this website.
        </p>
        <h4>Account Ownership and Liability</h4>
        <p>
            You will register to create an account. As part of the registration
            process, You will create a Username and password. You are
            responsible for maintaining and promptly updating your account
            information for accuracy and completeness. You are solely
            responsible for maintaining the confidentiality and security of, and
            restricting access to, your account and password. You agree to
            accept full responsibility for all activities that occur under your
            account or password, including use of your account by any third
            party using your Username and password. You must notify PRC-Saltillo
            immediately of any known or suspected unauthorized use of
            your account, or any known or suspected breach of security,
            including loss, theft or unauthorized use or disclosure of your
            Username or password. PRC-Saltillo is not obligated to inquire as to
            the authority or propriety of any use of or action taken under your
            password. PRC-Saltillo is not responsible for any loss to You that
            arises from such use or action or from your failure to comply with
            these provisions.
        </p>
        <h4>
            Health Information Privacy and Portability Act (HIPAA) and Security
        </h4>
        <p>
            You assume full responsibility and liability for all information
            provided by or available to You from any other source. This
            includes, but is not limited to information that is considered
            Personal Health Information (&ldquo;PHI&rdquo;) such as:
        </p>
        <p>Evaluations or statements from:</p>
        <ul>
            <li>Speech Language Pathologists</li>
            <li>Occupational Therapists</li>
            <li>Physicians (including Physician notes and prescriptions)</li>
            <li>Allied Professions.</li>
        </ul>
        <p>
            You agree that You will diligently manage any and all information
            provided. You agree that You are prohibited from making any changes
            to medical records or billing records and may incur civil and/or
            criminal liability for doing so. Please see our
            <a
                class="link--primary"
                :href="$landingSiteUrl+'notice-of-privacy-practices'"
                target="_blank"
                rel="noopener"
            >
                Notice of Privacy Practices
            </a>
            for information about how to request a change in your medical records.
        </p>
        <h4>Disclosure of Information Collected Through This Web Site</h4>
        <p>
            By using this site, You are expressly authorizing the disclosure of
            any information for the purposes of obtaining funding for a Speech
            Generating Device. Please see PRC-Saltillo's
            <a
                class="link--primary"
                :href="$landingSiteUrl+'notice-of-privacy-practices'"
                target="_blank"
                rel="noopener"
            >
                Notice of Privacy Practices
            </a>
            and
            <a
                class="link--primary"
                :href="$landingSiteUrl+'privacy-policy'"
                target="_blank"
                rel="noopener"
            >
                Privacy Policy.
            </a>
            for further information on disclosure of information collected through
            this website.
        </p>
        <p>
            View
            <a
                class="link--primary"
                :href="$landingSiteUrl+'privacy-policy'"
                target="_blank"
                rel="noopener"
            >
                PRC-Saltillo&rsquo;s Privacy Policy.
            </a>
        </p>
        <p>
            You acknowledge that You have read and understand the Privacy
            Policy.
        </p>
        <h4>PROPRIETARY INTERESTS</h4>
        <p>
            You have no rights in or relating to this website or any content,
            data, software, features or services provided on it (collectively,
            &ldquo;PRC-Saltillo Funding Site Content&rdquo;). Except as provided
            otherwise, You may not use, copy, distribute, create derivative works
            or display any PRC-Saltillo Site Content.
        </p>
        <h4>CONSENTS, CHANGES, AND APPLICABLE LAW</h4>
        <p>
            PRC-Saltillo reserves the right to modify or alter this website and
            its contents and the policies and conditions that govern the use of
            this website, including these Terms of Use, at any time. Please
            review this website and these Terms of Use periodically for any
            modifications, alterations, or changes. If You visit or otherwise
            use this website after we change these Terms of Use, the changed
            Terms of Use will be binding and effective as between PRC-Saltillo
            and You.
        </p>
        <h4>SUSPENSION OR TERMINATION OF USAGE</h4>
        <p>
            PRC-Saltillo may suspend or terminate Your account, without notice,
            and refuse any and all current and future use of this website, if
            You have engaged in any conduct that PRC-Saltillo believes is in
            violation of any applicable law or these Terms of Use.
        </p>
        <h4>NO THIRD-PARTY BENEFICIARIES</h4>
        <p>
            You agree that, except as otherwise expressly provided in these
            Terms of Use, there shall be no third-party beneficiaries to this
            agreement.
        </p>
        <h4>ENTIRE AGREEMENT</h4>
        <p>
            You agree that these Terms of Use, PRC-Saltillo's HIPAA Notice of
            Privacy Practices, and the other documents referenced herein
            (including the Privacy Policy), constitute the entire agreement
            between You and PRC-Saltillo with respect to use of this Funding
            website.
        </p>
    </article>
</template>
