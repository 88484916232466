<template>
    <!-- rejection -->
    <div class="font-medium flex gap-2 items-start mb-2 last:mb-0">
        
        <!-- reasons -->
        <div class="grow"> <!-- link -->
            <router-link :to="routeObject" class="link--error-alert mr-1">{{ title }}:</router-link>
            <div class="ck-content" v-html="rejection.reasons"></div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        rejection: {
            type: Object,
            required: true,
        },
        sectionParamName: {
            type: String,
            required: false,
        },
    },

    computed: {
        application() {
            return this.$store.getters["applications/current"] ?? {};
        },

        isAdmin() {
            return this.$store.getters.isAdmin;
        },

        routeObject(){
            const routeObject = {
                name : this.rejection.route, 
                params: { alias: this.application.alias },
            };

            if(this.sectionParamName && this.rejection.parameter !== null){
                routeObject.params[this.sectionParamName] = this.rejection.parameter;
            }

            return routeObject;
        },

        title(){
            // get everything after the dash in the rejection title
            let title = this.rejection.title.split(" - ").pop();

            // if(this.rejection.parameter){
            //     title += ` ${this.rejection.parameter}`;
            // }
            
            //add custom name for title in additional requirements
            if(this.rejection.route == 'application.additionalRequirement'){
                var pertainingAdditionalRequirement = this.additionalRequirements?.find(item => item.id === this.rejection.parameter);
                title += ' - '+pertainingAdditionalRequirement?.custom_name;
            }
            return title;
        },

        additionalRequirements(){
            return this.application?.additional_completion_requirements ?? [];
        }
        
    },

    methods: {
        clearRejection(){
            this.$store.dispatch('applications/clearRejection', this.rejection.id);
        },
    },
};
</script>

<style scoped></style>
