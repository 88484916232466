export default [
    {
        path: "",
        name: "application.evaluation",
        component: () => import("@/views/application/evaluation/overview.vue"),
        meta: {
            title: "Evaluation - Overview",
        },
    },
    {
        path: "upload",
        name: "application.evaluation.upload",
        component: () => import("@/views/application/evaluation/upload.vue"),
    },
    {
        path: "background",
        name: "application.evaluation.background",
        component: () => import("@/views/application/evaluation/background.vue"),
    },
    {
        path: "physical-abilities",
        name: "application.evaluation.physicalAbilities",
        component: () => import("@/views/application/evaluation/physicalAbilities.vue"),
    },
    {
        path: "hearing-and-vision",
        name: "application.evaluation.hearingAndVision",
        component: () => import("@/views/application/evaluation/hearingAndVision.vue"),
    },
    {
        path: "language-skills",
        name: "application.evaluation.languageSkills",
        component: () => import("@/views/application/evaluation/languageSkills.vue"),
    },
    {
        path: "literacy",
        name: "application.evaluation.literacy",
        component: () => import("@/views/application/evaluation/literacy.vue"),
    },
    {
        path: "cognitive-abilities",
        name: "application.evaluation.cognitiveAbilities",
        component: () => import("@/views/application/evaluation/cognitiveAbilities.vue"),
    },
    {
        path: "communication-needs",
        name: "application.evaluation.communicationNeeds",
        component: () => import("@/views/application/evaluation/communicationNeeds.vue"),
    },
    {
        path: "other-approaches",
        name: "application.evaluation.otherApproaches",
        component: () => import("@/views/application/evaluation/otherApproaches.vue"),
    },
    {
        path: "device-features",
        name: "application.evaluation.deviceFeatures",
        component: () => import("@/views/application/evaluation/deviceFeatures.vue"),
    },
    {
        path: "language-features",
        name: "application.evaluation.languageFeatures",
        component: () => import("@/views/application/evaluation/languageFeatures.vue"),
    },
    {
        path: "devices-ruled-out",
        name: "application.evaluation.devicesRuledOut",
        component: () => import("@/views/application/evaluation/devicesRuledOut.vue"),
    },
    {
        path: "device-selected",
        name: "application.evaluation.deviceSelected",
        component: () => import("@/views/application/evaluation/deviceSelected.vue"),
    },
    {
        path: "treatment-goals",
        name: "application.evaluation.treatmentGoals",
        component: () => import("@/views/application/evaluation/treatmentGoals.vue"),
    },
    {
        path: "finalize",
        name: "application.evaluation.finalize",
        component: () => import("@/views/application/evaluation/finalize.vue"),
    },
];
