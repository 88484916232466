<template>
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="text-left grid form-element"
            v-bind="field.wrapperAttributes"
            :class="field.wrapperClass"
        >
            <!-- label -->
            <form-label :field="field" :required="isRequired"></form-label>

            <!-- teleport -->
            <form-teleport :teleport="field.teleport"></form-teleport>

            <!-- select input -->
            <select
                class="form-element__input w-full mt-1 block text-lg p-2 rounded-md border border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50 order-3"
                @blur="validate"
                @input="handleInput"
                @keydown.enter.prevent="onEnter"
                @focus="clearErrors"
                v-bind="computedAttributes"
                v-bind:[dataName]="`${field.name}--input`"
            >
                <!-- options -->
                <select-option
                    v-for="option in computedOptions"
                    :option="option"
                    :key="option.value"
                />
            </select>

            <!-- errors -->
            <form-error :error="errorText" :field="field" class="order-5"></form-error>
        </div>
    </transition>
</template>

<script>
import fieldMixin from "./mixins/fieldMixin";
import SelectOption from "./SelectOption.vue";
import isObject from "@/services/utils/isObject.js";

export default {
    mixins: [fieldMixin],
    components: {
        SelectOption,
    },
    computed: {
        computedAttributes() {
            let attributes = {
                // set our name using the name provided by our field schema
                name: this.field.name,

                // set our disabled status using the isDisabled computed mixin
                disabled: this.isDisabled,

                // set our classes using our computed classes mixin
                class: this.computedClasses,

                // set our model value from our modelValue prop
                value: this.modelValue,
            };

            // merge in any attributes from our field.attributes property
            if (isObject(this.field.attributes)) {
                attributes = { ...attributes, ...this.field.attributes };
            }

            return attributes;
        },

        computedOptions(){
            const options = Array.isArray(this.field.options) 
                ? [...this.field.options] 
                : [{ value: null, text: "No options available", disabled: "true"}];

            // insert our placeholder if we have one set
            if(this.field.placeholder){
                const placeholderOption = {
                    value: "",
                    text: this.field.placeholder,
                    attributes: {
                        disabled: true,
                        //hidden: true,
                    },
                };

                options.unshift(placeholderOption);
            }

            return options;
        }
    },
};
</script>
