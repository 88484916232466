<template>
    <!-- main content-->
    <div 
        ref="content" 
        v-bind="$attrs"
        :class="computedClamp"
        v-html="content"
    >
    </div>

    <!-- view more button -->
    <div class="w-full flex justify-center mt-3" v-if="isClamped">
        <div @click="disableClamp = !disableClamp" 
            class="cursor-pointer inline-flex items-center border py-1 px-2 rounded justify-center gap-1 text-blueGray-600 text-sm hover:text-secondary-800 transition-all"
        >
            <i class="fas" :class="viewToggleIcon"></i><span>{{ viewToggleText }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        clampClass: {
            type: String,
            default: "line-clamp-8",
        },
        content: {
            type: String,
        }
    },

    data(){
        return {
            disableClamp: false,
            isClamped: null,
        }
    },
    
    watch: {
        content: function(){
            this.$nextTick(() => this.checkIsClamped());
        }
    },

    mounted(){
        this.checkIsClamped();
    },

    methods: {
        checkIsClamped(){
            this.isClamped = this.$refs.content && this.$refs.content.scrollHeight > this.$refs.content.clientHeight;
        },
    },

    computed: {
       computedClamp(){
            if(this.disableClamp){
                return;
            }     

            return this.clampClass;
       },

       viewToggleIcon(){
            return this.disableClamp ? 'fa-chevron-up' : 'fa-chevron-down';
        },

        viewToggleText(){
            return this.disableClamp ? 'view less' : 'view more';
        },
    },
};
</script>

<style>

</style>
