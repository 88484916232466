<template>
    <router-link :to="{ name: 'application.overview', params: { alias: alias } }">
        <sidebar-item :clickable="true" class="px-3">
            <sidebar-title>
                {{ alias }}
            </sidebar-title>

            <sidebar-text>
                {{ basicInfo.first_name }} {{ basicInfo.last_name }}
            </sidebar-text>
        </sidebar-item>
    </router-link>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },

    computed: {
        alias() {
            return this.item.application.alias;
        },

        basicInfo() {
            return this.item.application.basic_client_info;
        },
    },
}
</script>

<style>

</style>