<template>
    <i :class="computedIcons + ' ' + computedClasses" :title="title"></i>
</template>

<!-- should we switch to vue -->
<!-- <template>
    <font-awesome-icon
        :icon="computedIcons"
        :class="computedClasses"
        :title="title"
    ></font-awesome-icon>
</template> -->

<script>
export default {
    props: {
        status: {
            required: true,
        },
    },
    computed: {
        statusObject() {
            return (
                this.$store.getters.statuses[this.status] ?? {
                    name: "NOT_STARTED",
                    text: "not started",
                }
            );
        },

        title() {
            return this.statusObject.text;
        },

        computedIcons() {
            switch (this.statusObject.name) {
                case "PREPARING_CONFIRMATION":
                    return "fa-duotone fa-circle-ellipsis";
                case "INSURANCE_PROCESSING":
                    return "fa-duotone fa-circle-ellipsis";
                case "SHIPPED":
                    return "fa-duotone fa-truck-fast";
                case "ORDER_CONFIRMATION_PROCESSING":
                    return "fa-duotone fa-circle-ellipsis";
                case "ORDER_PROCESSING":
                    return "fa-duotone fa-circle-ellipsis";
                case "COMPLETED":
                    return "fa-duotone fa-circle-check";
                case "IN_PROGRESS":
                    return "fa-duotone fa-spinner-third";
                case "UNDER_REVIEW":
                    return "fa-duotone fa-circle-ellipsis";
                case "REJECTED":
                    return "fa-duotone fa-circle-xmark";
                default:
                    return "fa-regular fa-circle-dashed";
            }
        },

        computedClasses() {
            switch (this.statusObject.name) {
                case "PREPARING_CONFIRMATION":
                    return "text-status_UNDER_REVIEW";
                case "INSURANCE_PROCESSING":
                    return "text-status_UNDER_REVIEW";
                case "SHIPPED":
                    return "text-status_COMPLETED";
                case "ORDER_CONFIRMATION_PROCESSING":
                    return "text-status_UNDER_REVIEW";
                case "ORDER_PROCESSING":
                    return "text-status_UNDER_REVIEW";
                case "COMPLETED":
                    return "text-status_COMPLETED";
                case "IN_PROGRESS":
                    return "text-status_IN_PROGRESS";
                case "UNDER_REVIEW":
                    return "text-status_UNDER_REVIEW";
                case "REJECTED":
                    return "text-status_REJECTED";
                default:
                    return "text-status_NOT_STARTED";
            }
        },
    },
};
</script>

<style></style>
