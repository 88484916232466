<template>
    <nav
        id="primary-nav"
        class="primary-nav py-1 px-4 md:px-12 flex justify-between items-center font-semibold text-white overflow-hidden"
        :class="computedClasses"
        style="background-image: url(/img/landing_background.svg)"
    >
        <!-- logo & home link -->
        <router-link v-if="loggedIn" :to="{ name: 'dashboard' }" class="block mr-auto">
            <img src="/img/logo.svg" class="w-1/2 logo"/>
        </router-link>
        <a v-else :href="landingSiteUrl">
            <img src="/img/logo.svg" class="w-1/2 logo" />
        </a>

        <!-- links -->
        <div class="flex items-center">
            <!-- logged in links -->
            <template v-if="loggedIn">
                <nav-link
                    :to="{ name: 'dashboard' }"
                    icon="home"
                    text="My Applications"
                />
                <nav-link
                    v-if="$store.getters.isDev"
                    :to="{ name: 'docs.home' }"
                    icon="file-code"
                    text="Docs"
                />
                <nav-link
                    v-if="$store.getters.isAdmin"
                    :to="{ name: 'admin.index' }"
                    icon="user-lock"
                    text="Admin"
                />
            </template>

            <!-- contact -->
            <nav-link
                :to="{ name: 'help' }"
                icon="comment-question"
                text="Help"
            />

            <!-- logged in -->
            <template v-if="loggedIn">

                <!-- Inbox -->
                <nav-link
                    v-if="$store.getters['inbox/INBOX_ENABLED']"
                    :to="{ name: 'inbox' }"
                    icon="envelope-open-text"
                    text="Inbox"
                    :count="unreadCount"
                    @click="clickedInbox"
                />

                <!-- pending invites -->
                <nav-link
                    :to="{ name: 'invites' }"
                    icon="handshake"
                    text="Invites"
                    :count="inviteCount"
                />

                <!-- privacy mode toggle -->
                <privacy-toggle />

                <!-- account button -->
                <nav-link
                    :to="{ name: 'account' }"
                    icon="user-circle"
                    :text="$store.getters.user?.first_name"
                />

                <!-- logout button -->
                <secondary-button
                    @click="logout"
                    id="logout-button"
                    size="small"
                    class="ml-5"
                >
                    logout
                </secondary-button>
            </template>

            <!-- logged out -->
            <router-link v-else :to="{ name: 'login' }">
                <secondary-button
                    id="login-button"
                    size="small"
                    class="ml-5"
                >
                    login
                </secondary-button>
            </router-link>  
        </div>
    </nav>
</template>

<script>
import NavLink from "./NavLink";
import PrivacyToggle from "./PrivacyToggle";
export default {
    components: {
        NavLink,
        PrivacyToggle,
    },
    computed: {
        loggedIn() {
            return this.$store.getters.loggedIn;
        },
        landingSiteUrl() {
            return process.env.VUE_APP_LANDING_SITE_URL;
        },
        computedClasses() {
            let classes = "";

            if (this.$route.meta.theme !== "night") {
                classes += "shadow ";
            }
            if(this.$route.meta.transparentNav)
            {
                classes += "transparentNav ";
            }
            if(this.$route.meta.hideNav)
            {
                classes += "hideNav ";
            }

            return classes;
        },
        isAdmin(){
            return this.$store.getters.isAdmin;
        },

        inviteCount(){
            return this.$store.getters['invites/count'];
        },
        unreadCount() {
            return this.$store.getters['inbox/unread'];
        },
        currentApplication() {
            return this.$store.getters["applications/current"];
        },
    },
    methods: {
        clickedInbox() {
            // If we are inside an application, we automatically add the application alias in the inbox search bar
            if (this.currentApplication) {
                this.$store.commit("inbox/setSearch", this.currentApplication.alias);
            }
        },
        logout() {
            this.$store.dispatch("logout");
        },
    },
};
</script>
<style scoped>
.primary-nav {
    background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
}
.theme--night .primary-nav {
    background-image: none;
}
.transparentNav {
    background:none !important;
    background-image:none !important;
    box-shadow:none;
    z-index:3;
    position:absolute;
    top:0;
    width:100%;
}
.hideNav {
    display:none;
}
.logo{
    padding-top:5px;
    padding-bottom:5px;
}
</style>
