//import router from "@/router/index";

// manages our user state
export default {
    namespaced: true,
    
    state() {
        return {
            loading: false,
        };
    },

    mutations: {
        setLoading(state, value = true) {
            state.loading = value;  
        },
    },

    actions: {
        setExamples({ commit }, { model, examples }) {
            commit("setExamples", { model, examples }, { root: true });
        },

        async loadExamples({ dispatch, getters, state, commit }, { model, reload }) {
            // if we have already loaded these examples, and we are not reloading, then abort
            let modelExamples = getters.examples[model];
            if(state.loading || (modelExamples && !reload) ) {
                return;
            }
            
            commit("setLoading");

            // get examples from the API
            let examples = await this.$http.get(
                `funding/examples/${model}`, {
                    spinner : false,
                    successNotification: false,
            });

            commit("setLoading", false);

            if (!examples.data) {
                return console.error(`Failed to load examples`);
            }

            dispatch("setExamples", { model, examples: examples.data });
        },
    },

    getters: {
        examples(_, __, _rootState, rootGetters) {
            return rootGetters.examples;
        },
    },
};
