// manages our loaded application
export default {
    namespaced: true,

    state() {
        return {
            selectedNote: null,
            mode: "view",
        };
    },

    mutations: {
        setSelectedNote(state, note) {
            state.selectedNote = note;
        },

        setMode(state, mode) {
            state.mode = mode;
        },
    },

    actions: {
        setSelectedNote({ commit, dispatch }, { note, mode }) {
            commit("setSelectedNote", note);
            dispatch("setMode", mode);
        },

        setMode({ commit }, mode) {
            mode = mode ?? "view";
            commit("setMode", mode);
        },
    },

    getters: {
        selectedNote(state) {
            return state.selectedNote;
        },

        mode(state) {
            return state.mode;
        },
    },
};
