import store from "@/store/index";
/*
 * Uploads Service
 * Source of truth functions for when an upload is hard/soft locked
 *
 * Disclaimer: These functions are only meant to be used for front-end, hard lock check
 * must be implemented in the back end as well.
 */
export default {
    // getUploadType(upload) {
    //     //console.log(upload);
    //     console.log(store.getters.uploadTypes[upload.data_upload_type_id]);
    //     return store.getters.uploadTypes[upload.data_upload_type_id];
    // },

    getStatus(upload) {
        return store.getters.statuses[upload.status_id];
    },
    isHardLocked(upload, application, applicationIsLocked) {
    
        const uploadType = store.getters['uploadType'] (upload.data_upload_type_id);
        if(uploadType === null){
            return false;
        }

        /*
         * Application wide locks
         */
        if (applicationIsLocked) {
            return true;
        }

        /*
         * Type specific locks
         */
        switch (uploadType.name) {
            case "medicare_front":
            case "medicare_back":
            case "medicaid_front":
            case "medicaid_back":
            case "commercial_front":
            case "commercial_back":
            case "additional_front":
            case "additional_back":
            case "additional2_front":
            case "additional2_back":
            case "aob":
                return application
                    ? application.insurance_info?.status_id ===
                          store.getters.getStatusId("UNDER_REVIEW")
                    : false;
            default:
                break;
        }
        return false;
    },
    softLockedModal(upload, application) {
        
        const uploadType = store.getters['uploadType'] (upload.data_upload_type_id);

        if(uploadType === null){
            return false;
        }
        
        const status = this.getStatus(upload);
        /*
         * Application wide locks
         */

        /*
         * Status specific locks
         */
        switch (status.name) {
            case "ACCEPTED":
                return {
                    message:
                        "The upload has already been reviewed and accepted by our funding staff. Are you sure you want to delete it?",
                    confirmButtonText: "Yes, delete",
                    cancelButtonText: "Cancel",
                    titleRole: "warning",
                };
            default:
                break;
        }

        /*
         * Type specific locks
         */
        switch (uploadType.name) {
            case "medicare_front":
            case "medicare_back":
            case "medicaid_front":
            case "medicaid_back":
            case "commercial_front":
            case "commercial_back":
            case "additional_front":
            case "additional_back":
            case "additional2_front":
            case "additional2_back":
                if (
                    application.insurance_info?.status_id ===
                    store.getters.getStatusId("COMPLETED")
                ) {
                    return {
                        message:
                            "The insurance section has been reviewed and locked by our staff. Deleting this upload will unlock it and another review will be required. Are you sure to want to unlock this section?",
                        confirmButtonText: "Yes, Unlock",
                        cancelButtonText: "Cancel",
                    };
                }
                break;
            default:
                break;
        }
        return null;
    },
};
