<template>
    <div>
        <!-- if we have a rejection for this page show it -->
        <show-rejection v-if="rejection"
            :rejection="rejection"
        />
        <!-- otherwise show the add rejection form (if appropriate) -->
        <add-rejection v-else
            :presets="presets"
            :parameter="parameter"
        />
    </div>
</template>

<script>
import ShowRejection from "./ShowRejection.vue";
import AddRejection from "./AddRejection.vue";

export default {
    props: {
        presets: {
            type: Array,
            required: false,
        },
        parameter: {
            type: Number,
            required: false,
        },  
    },
    components: {
        ShowRejection,
        AddRejection,
    },
    computed: {
        application(){
            return this.$store.getters["applications/current"] ?? {};
        },
        
        currentRoute(){
            return this.$route.name;
        },

        rejection(){
            const rejections = this.application.rejections ?? [];
            return rejections.find(rejection => rejection.route === this.currentRoute && (this.parameter === undefined || rejection.parameter === this.parameter));
        },
    },
};
</script>

<style scoped>
</style>
