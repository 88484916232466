//import router from "@/router/index";

// manages our user state
export default {
    namespaced: true,

    state() {
        return {
            titles: null,
            emails: null,
            phoneNumbers: null,
        };
    },

    mutations: {
        // set our titles
        setTitles(state, titles) {
            state.titles = titles;
        },

        // set our emails
        setEmails(state, emails) {
            state.emails = emails;
        },

        // set our phoneNumbers
        setPhoneNumbers(state, phoneNumbers) {
            state.phoneNumbers = phoneNumbers;
        },
    },

    actions: {
        /**
         * Load user title data from the API
         *
         * @param {Context} context
         */
        async loadTitles({ dispatch }) {
            let response;

            try {
                response = await this.$http.get("funding/titles", {
                    successNotification: false,
                });
            } catch (error) {
                if (process.env.VUE_APP_NODE_ENV !== "production") {
                    console.error(
                        `Failed to load title data: ${JSON.stringify(response)}`
                    );
                }
                return;
            }

            // commit our titles
            dispatch("setTitles", response?.data);
        },

        /**
         * Set our titles in the store
         *
         * @param {object} context
         * @param {object} titles
         */
        setTitles({ commit }, titles) {
            commit("setTitles", titles);
        },

        /**
         * Load user secondary emails data from the API
         *
         * @param {Context} context
         */
        async loadEmails({ dispatch }) {
            let response;

            try {
                response = await this.$http.get("funding/emails", {
                    successNotification: false,
                });
            } catch (error) {
                if (process.env.VUE_APP_NODE_ENV !== "production") {
                    console.error(
                        `Failed to load email data: ${JSON.stringify(response)}`
                    );
                }
                return;
            }

            // set our emails state
            dispatch("setEmails", response?.data);
        },

        /**
         * Set our emails in the store
         *
         * @param {object} context
         * @param {object} emails
         */
        setEmails({ commit, dispatch }, emails) {
            commit("setEmails", emails);

            //make sure the primary email in the user object stays in sync
            if (emails?.primary?.email) {
                dispatch("setPrimaryEmail", emails?.primary?.email, {
                    root: true,
                });
            }
        },

        /**
         * Delete the given secondary email
         *
         * @param {Context} context
         */
        async deleteEmail({ dispatch }, email) {
            const data = { email };
            const config = {
                successNotification: "email deleted",
                errorNotification: "could not delete email",
            };

            try {
                await this.$http.post("delete-secondary-email", data, config);
            } catch (error) {
                if (process.env.VUE_APP_NODE_ENV !== "production") {
                    console.error(`Failed to delete email`, error);
                }
                return;
            }

            // reload our emails
            await dispatch("loadEmails");
        },

        /**
         * Promote the given secondary email
         *
         * @param {Context} context
         */
        async promoteEmail({ dispatch }, email) {
            const data = { email };
            const config = {
                successNotification: "email promoted",
                errorNotification: "could not promote email",
            };

            try {
                await this.$http.post("promote-secondary-email", data, config);
            } catch (error) {
                if (process.env.VUE_APP_NODE_ENV !== "production") {
                    console.error(`Failed to promote email`, error);
                }
                return;
            }

            // reload our emails
            await dispatch("loadEmails");
        },

        /**
         * Load user phone number data from the API
         *
         * @param {Context} context
         */
        async loadPhoneNumbers({ dispatch }) {
            let response;

            try {
                response = await this.$http.get("account/phone", {
                    successNotification: false,
                });
            } catch (error) {
                if (process.env.VUE_APP_NODE_ENV !== "production") {
                    console.error(
                        `Failed to load email data: ${JSON.stringify(response)}`
                    );
                }
                return;
            }

            // set our phone numbers state
            dispatch("setPhoneNumbers", response?.data);
        },

        /**
         * Set our phone numbers in the store
         *
         * @param {object} context
         * @param {object} phoneNumbers
         */
        setPhoneNumbers({ commit, rootGetters, dispatch }, phoneNumbers) {
            commit("setPhoneNumbers", phoneNumbers);

            //make sure the primary phone number in the user object stays in sync
            let primaryPhoneNumber = phoneNumbers.find(number => number.is_primary);
            if (rootGetters.user.primary_phone !== primaryPhoneNumber?.number) {
                dispatch("setPrimaryPhoneNumber", primaryPhoneNumber?.number, {
                    root: true,
                });
            }   
        },

        /**
         * Delete the given phone number
         *
         * @param {Context} context
         */
        async deletePhoneNumber({ dispatch }, phoneNumber) {
            let response;

            const config = {
                successNotification: "phone number deleted",
                errorNotification: "could not delete phone number",
            };

            try {
                response = await this.$http.delete(`account/phone/${phoneNumber}`, config);
            } catch (error) {
                if (process.env.VUE_APP_NODE_ENV !== "production") {
                    console.error(`Failed to delete phone number`, error);
                }
                return;
            }

            // set our phone numbers state
            dispatch("setPhoneNumbers", response?.data);
        },
    },

    getters: {
        titles(state) {
            return state.titles;
        },

        emails(state) {
            return state.emails;
        },

        phoneNumbers(state) {
            return state.phoneNumbers;
        },
    },
};
