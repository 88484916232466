<template>
    <!-- sidebar section header -->
    <header class="border-accentBlue text text-secondary-300 flex items-center gap-2">

        <!-- title -->
        <span class="uppercase font-semibold tracking-wide">{{ title }}</span>

        <!-- count -->
        <span v-if="count > 0" class="p-1 bg-headerBlue text-xs rounded text-secondary-600 font-bold">{{ count }}</span>

        <!-- buttons -->
        <div class="flex items-center gap-2 ml-auto">
            <!-- additional button slot -->
            <slot name="sidebar-header-button"></slot>

            <!-- add item button -->
            <button 
                v-if="showAddItem"
                @click="$emit('addItem')" 
                :title="`Add ${title}`"
                class="fas fa-circle-plus text-lg text-primary/80 hover:text-primary transition-all">
            </button>
        </div>

    </header>
</template>

<script>
export default {
    props: {
        count: {
            type: Number,
            default: 0,
        },
        title: {
            type: String,
            required: true,
        },
        showAddItem: {
            type: Boolean,
            default: true,
        },
    }
}
</script>