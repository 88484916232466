<template>
    <!-- contributors -->
    <div class="flex items-center">
        <input
            type="checkbox"
            :value="value"
            v-model="search_options"
            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300"
        />
        <label
            for="checked-checkbox"
            class="text-sm ml-1.5 font-medium"
        >
            {{ text }}
        </label>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        }
    },
    computed: {
        search_options: {
            get() {
                return this.$store.getters["applications/search_options"];
            },
            set(value) {
                this.$store.commit("applications/setSearchOptions", value);
            },
        },
    },
};
</script>