<template>
    <div class="p-3 border rounded">
        <!-- title -->
        <div class="border-b pb-1 mb-2">
            <span class="font-bold review-section__title" v-html="title"></span>
            <router-link v-if="route" :to="{ name: route }" class="link--primary">
                <span class="fad fa-arrow-up-right-from-square ml-2"></span>
            </router-link>
        </div>

        <!-- address validation -->
        <review-section-address-validation v-if="address" :address="address" />

        <!-- items -->
        <ul class="text-sm">
            <review-item v-for="item in filteredItems" 
                :key="item.name"
                :item="item"
            />
        </ul>

        <!-- default slot -->
        <slot></slot>
    </div>
</template>

<script>
import ReviewItem from "./ReviewItem.vue";
import ReviewSectionAddressValidation from "./ReviewSectionAddressValidation.vue";

export default {
    components: {
        ReviewItem,
        ReviewSectionAddressValidation,
    },

    props: {
        title: {
            type: String,
        },
        route: {
            type: String,
        },
        items: {
            type: Array,
        },
        filterNull: {
            type: Boolean,
            default: true,
        },
        address: {
            type: Object,
            default: null,
        },
    },
    
    computed: { 
        filteredItems() {
            if(!this.filterNull){
                return this.items;
            }

            return this.items.filter(item => item.value !== null);
        },
      
    },

    methods: {
     
    },
};
</script>
<style scoped>
    .review-section__title {
        font-size: .9em;
    }
</style>

