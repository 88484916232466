export default {
    namespaced: true,

    state() {
        return {};
    },

    mutations: {
        set(state, payload) {
            localStorage.setItem('deviceUUID', payload.deviceUUID)
        },

        unset() {
            localStorage.removeItem('deviceUUID')
        },
    },

    getters: {
        get() {
            return localStorage.getItem('deviceUUID')
        },
    },
};
