export default [
    {
        path: "",
        name: "admin.index",
        component: () => import("@/views/admin/Index.vue"),
    },
    {
        path: "specialist-regions",
        name: "admin.specialistRegions",
        component: () => import("@/views/admin/SpecialistRegions.vue"),
    },
    {
        path: "consultant-regions",
        name: "admin.consultantRegions",
        component: () => import("@/views/admin/ConsultantRegions.vue"),
    },
    {
        path: "manage-task-types",
        name: "admin.taskTypes",
        component: () => import("@/views/admin/TaskTypes.vue"),
        meta: {
            width: "full",
        },
    },
    {
        path: "manage-documents",
        name: "admin.manageDocuments",
        component: () => import("@/views/admin/ManageDocuments.vue"),
        meta: {
            width: "full",
        },
    },
    {
        path: "tasks",
        name: "admin.tasks",
        component: () => import("@/views/admin/Tasks.vue")
    },
    {
        path: "system-admin",
        name: "admin.systemAdmin",
        component: () => import("@/views/admin/SystemAdmin.vue"),
    },
];
