<template>
    <!-- modal to display is the store is still loading our user -->
    <base-modal
        :open="open"
        :hideClose="true"
        :icon="icon"
        title="loading..."
        class="w-10/12 md:w-xs"
    >
        <error-alert v-if="fundingDataFailed">
            Error loading funding data, try refreshing the page. If the error persists
            please <router-link :to="{ name: 'help' }" class="link--error-alert">contact us</router-link>.
        </error-alert>
        <div v-else>{{ message }}</div>
    </base-modal>
</template>

<script>
export default {
    data(){
        return {
        };
    },
    mounted(){
    },
    computed: {
        icon(){
            if(this.$store.getters.loadingUser){
                return "user-circle";
            }

            return "gears";
        },

        open(){
            if(this.fundingDataFailed){
                return true;
            }

            return this.$store.getters.loadingUser || !this.$store.getters.fundingDataLoaded;
        },

        fundingDataFailed(){
            return this.$store.getters.fundingDataFailed;
        },

        message(){
            if(this.$store.getters.loadingUser){
                return "Loading user data";
            }

            return "Loading funding data";
        },
    },
}
</script>
