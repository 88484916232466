<template>
    <!-- main wrapper -->
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="text-left grid form-element"
            v-bind="field.wrapperAttributes"
            :class="field.wrapperClass"
        >
            <!-- label -->
            <form-label :field="field" :required="isRequired"></form-label>

            <!-- teleport target -->
            <form-teleport :teleport="field.teleport"></form-teleport>

            <!-- checkbox set -->
            <div
                class="grid gap-5 order-3 mt-2"
                :class="computedClasses"
                v-bind="field.attributes"
                v-bind:[dataName]="`${field.name}--radio-sets`"
            >
                <template v-if="field.inputs?.length">
                    <radios-input
                        v-for="input in field.inputs"
                        :input="input"
                        :field="field"
                        :key="input.name"
                        :modelValue="modelValue"
                        @updated="handleInput"
                        :disabled="disabled"
                    />
                </template>
                <div v-else class="p-3 col-span-full border rounded text-center bg-gray-50 text-gray-500" v-html="noOptionsMessage"></div>
            </div>

            <!-- errors -->
            <form-error :error="errorText" :field="field" class="order-5"></form-error>
        </div>
    </transition>
</template>

<script>
import fieldMixin from "./mixins/fieldMixin";
import RadiosInput from "./RadiosInput.vue";
export default {
    mixins: [fieldMixin],
    components: {
        RadiosInput,
    },

    methods: {
        handleInput(inputName, inputValue) {
            this.clearErrors();
            
            const valueObject = {...this.modelValue};
            valueObject[inputName] = inputValue;

            this.updateValue(this.field.name, valueObject);
            this.validate();
        },
    },

    computed: {
        computedClasses() {
            let classes = "";

            if (this.field.class) {
                classes += this.field.class;
            }

            if (this.field.attributes?.class) {
                classes += this.field.attributes.class;
            }

            return classes;
        },

        noOptionsMessage() {
            return this.field.noOptionsMessage ?? "No options available..."
        }
    },
};
</script>
