<template>
    <!-- our spinner element -->
    <div class="loader-bar w-full fixed h-1 z-50" v-if="requests > 0">
        <div class="loader-bar__streak"></div>
    </div>
</template>

<script>
export default {
    name: "base-spinner",
    computed: {
        // how many requests are currently waiting for responses
        requests() {
            return this.$store.getters["spinner/requests"];
        },
    },
};
</script>

<style scoped>
.loader-bar__streak {
    position: relative;
    height: 100%;
    animation-name: loader-animation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes loader-animation {
    0% {
        width: 0%;
        background-color: #ff00fa;
    }
    25% {
        width: 100%;
        left: 0%;
    }
    50% {
        left: 100%;
        width: 0;
        background-color: gold;
    }
    75% {
        left: 0%;
        width: 100%;
    }
    100% {
        left: 0%;
        width: 0%;
        background-color: #ff00fa;
    }
}
</style>
