<template>
    <div class="relative inline-flex cursor-pointer items-center border focus:z-20 border-gray-300 bg-white px-2 py-2 text-sm font-semibold text-gray-500 hover:bg-gray-50">
        <select @change="onPageChange($event.target.value)" class=" bg-transparent outline-none">
            <option v-for="index in pages" :key="index" :value="index" :selected="index === page">
                {{ index }}
            </option>
        </select>
    </div>

</template>

<script>
export default {
    methods: {
        onPageChange(page) {
            this.$emit('pageChange', page);
        },
    },
    
    props: {
        page: {
            type: Number,
        },
        pages: {
            type: Number,
        }
    },

    emits: ['pageChange'],
}
</script>

<style scoped>

</style>