import startCase from "lodash/startCase";

// Has the device type options to populate the equipment selection dropdown
export default {
    state() {
        return {
            all_device_types: []
        };
    },

    getters: {
        device_types(_, __, rootState) {
            return rootState.deviceTypes;
        },
        sortedDeviceTypes(_, __, rootState) {
            return Object.values(rootState.deviceTypes).sort(deviceComparator);
        },
        allDevices(state) {
            return state.all_device_types
        },
        sortedAllDevices(_state, getters) {
            return getters.allDevices?.sort(deviceComparator) ?? [];
        }
    },
    actions: {
        async getAllDevices({ commit }) {
            const response =  await this.$http.get("funding/all_device_types", { successNotification: false });
            commit("setAllDeviceTypes", response.data);
        }
    },
    mutations: {
        setAllDeviceTypes(state, device_types) {
            state.all_device_types = device_types;
        }
    }
}

function firstWord(string){
    string = startCase(string);
    return string.split(" ")[0];
}

function deviceComparator(a, b) { 
    // ok, so I had an issue with sorting, where sorting strings that end in numbers wouldn't
    // sort correctly like so: NovaChat10, NovaChat12, NovaChat3, NovaChat5. So in order to
    // get around this while sorting, instead I'm converting the name strings to start case
    // (so NovaChat8 would be converted to Nova Chat 8), then taking the first word of that
    // string and using that for the comparison. If we have two matching entries like Nova
    // and Nova, then instead we use the ID of the device to sort (since later IDs match 
    // higher numbered device releases). There is probably a better way to do all of this...  
    
    // get our first word
    let aText = firstWord(a.device).toLowerCase();
    let bText = firstWord(b.device).toLowerCase();

    // if our first words are tied, use device IDs instead 
    if(aText === bText){
        aText = a.id;
        bText = b.id;
    }   

    if(aText > bText){
        return 1;
    }

    if(aText < bText){
        return -1;
    }                

    return 0;
}