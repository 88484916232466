import router from "@/router/index";
import isEqual from "lodash/isEqual";
//import merge from "lodash/merge";
import queryService from "@/services/utils/queryService.js";

/*
const getDefaultDates = () => {
    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let day = today.getDate();
    if (day < 10) {
        day = "0" + day;
    }
    const defaultSearchYearRange = 5;
    return {
        creation_date_end: `${year}-${month}-${day}`,
        updated_date_end: `${year}-${month}-${day}`,
        creation_date_start: `${year - defaultSearchYearRange}-${month}-${day}`,
        updated_date_start: `${year - defaultSearchYearRange}-${month}-${day}`
    }
}
*/

// const defaultDates = getDefaultDates();
const initialApplicationParams = {
    sort: "-created_at",
    show_all: false,
    show_qad: false,
    states: [],
    insurance_types: [],
    priorities: [],
    stages: [],
    types: [],
    created_in: 1825, // days, 5 years
    creation_date_start: null, // defaultDates.creation_date_start,
    creation_date_end: null, // defaultDates.creation_date_end,
    updated_in: 1825, // days, 5 years
    updated_date_start: null, // defaultDates.updated_date_start,
    updated_date_end: null, // defaultDates.updated_date_end,
    device_types: [],
    upload_types: [],
    expiring_window: 14, // days
    show_closed: false,
    show_only_practice: false,
    search: "",
    search_options: ["client", "contributor", "insurance", "alias", "qad", "sales_order", "rma", "slp", "brightree", "ca_number"],
    has_tasks: false,
    has_no_tasks: false,
    outcomes: [],
    pages: 1,
    per_page: 25,
    page: 1,
    total: 0,
    show_converted_trial: false,
    submissions: [],
    submissions_start: null,
    submissions_end: null,
    selected_shipped_options: [],
    show_has_custom_item: false,
};

// our application params includes some properties that are not filters 
// (such as our current total number of applications and the pagination info) 
// and should not be included when checking if we have active filters
const paginationParams = [
    'pages',
    'per_page',
    'page',
    'total',
];

const removePaginationParams = object => {
    let filteredObject = {};
    Object.keys(object).forEach(key => {
        if (!paginationParams.includes(key)) {
            filteredObject[key] = object[key];
        }
    });
    return filteredObject;
}

// manages our loaded application
export default {
    namespaced: true,

    state() {
        return {
            current: null,
            all: null,
            showApplicationsFilters: false,
            overrideLock: null,
            applicationsParams: { ...initialApplicationParams },
            lastUsedApplicationParams : { ...initialApplicationParams },

            // a list of all of the parent sections in an application, in the order they
            // should be displayed in the cards and sidebar
            sections: [
                {
                    relation: "client_info",
                    route: "application.clientInfo",
                    name: "client",
                    title: "Client Info",
                    description:
                        "Provide information about the client and client's evaluation team.",
                },
                {
                    relation: "insurance_info",
                    route: "application.insuranceInfo",
                    name: "insurance",
                    title: "Insurance / AOB",
                    description:
                        "Provide information about ALL available health insurances.",
                },
                {
                    relation: "evaluation_info",
                    route: "application.evaluation",
                    name: "eval",
                    title: "AAC Evaluation (legacy)",
                    canBeDisabled: true,
                    description:
                        "This section must be completed by the evaluating Speech-Language Pathologist.",
                },
                {
                    relation: "evaluation_v2_info",
                    route: "application.evaluation_v2",
                    name: "eval_v2",
                    title: "AAC Evaluation",
                    canBeDisabled: true,
                    description:
                        "This section must be completed by the evaluating Speech-Language Pathologist.",
                },
                {
                    relation: "equipment_selection_info",
                    route: "application.equipmentSelection",
                    name: "equipment",
                    title: "Equipment Selection",
                    description:
                        "Complete equipment selection sheet(s) based on what was recommended in the AAC evaluation.",
                },
                {
                    relation: "repair_evaluation_info",
                    route: "application.repairEvaluation",
                    name: "repair_evaluation",
                    title: "Repair Evaluation",
                    canBeDisabled: true,
                    description:
                        "This section must be completed by the evaluating Speech-Language Pathologist.",
                },
                {
                    relation: "slp_addendum_info",
                    route: "application.slpAddendum",
                    name: "slp_addendum",
                    title: "SLP Addendum",
                    canBeDisabled: true,
                    description:
                        "This section must be completed by the evaluating Speech-Language Pathologist.",
                },
                {
                    relation: "cmn_info",
                    route: "application.cmn",
                    name: "cmn",
                    title: "Certificate of Medical Necessity (CMN)",
                    canBeDisabled: true,
                    description:
                        "Obtain the CMN/prescription from the client's treating physician.",
                },
                {
                    relation: "trial_terms_and_conditions_info",
                    route: "application.tandc",
                    name: "tandc",
                    title: "Terms & Conditions",
                    description: "List of Terms & Conditions",
                },
                {
                    relation: "trial_equipment_selection_info",
                    route: "application.trialEquipmentSelection",
                    name: "trial_equipment",
                    title: "Trial Equipment Selection",
                    description:
                        "Complete trial equipment selection sheet(s) based on what was recommended in the AAC evaluation.",
                },
                {
                    relation: "additional_completion_requirement_info",
                    route: "application.additionalRequirement.info",
                    name: "ad_hoc",
                    title: "Additional Requirements",
                    description:
                        "Other items that must be completed for this application.",
                },
            ],
        };
    },

    mutations: {
        setShowConvertedTrial(state, show_converted_trial) {
            state.applicationsParams.show_converted_trial =
                show_converted_trial;
        },
        setSubmissions(state, submissions) {
            state.applicationsParams.submissions = submissions;
        },
        setSelectedShippedOptions(state, selected_shipped_options) {
            state.applicationsParams.selected_shipped_options = selected_shipped_options;
        },
        setCurrentApplication(state, application) {
            state.current = application;
        },
        enableOverrideLock(state) {
            state.overrideLock = state.current?.alias;
        },
        disableOverrideLock(state) {
            state.overrideLock = null;
        },
        setShowApplicationsFilters(state, showApplicationsFilters) {
            state.showApplicationsFilters = showApplicationsFilters;
        },
        setParam(state, data) {
            state.applicationsParams[data.param] = data.value;
        },
        setPages(state, pages) {
            state.applicationsParams.pages = pages;
        },
        setPage(state, page) {
            state.applicationsParams.page = page;
        },
        setOutcomes(state, outcomes) {
            state.applicationsParams.outcomes = outcomes;
        },
        setUploadTypes(state, upload_types) {
            state.applicationsParams.upload_types = upload_types;
        },
        setExpiringWindow(state, window) {
            state.applicationsParams.expiring_window = window;
        },
        setShowQad(state, show_qad) {
            state.applicationsParams.show_qad = show_qad;
        },
        setShowWoQadSon(state, show_wo_qad_son) {
            state.applicationsParams.show_wo_qad_son = show_wo_qad_son;
        },
        setShowWoRmaNumber(state, show_wo_rma_number) {
            state.applicationsParams.show_wo_rma_number = show_wo_rma_number;
        },
        setShowHasCustomItem(state, show_has_custom_item) {
            state.applicationsParams.show_has_custom_item = show_has_custom_item;
        },
        setPerPage(state, per_page) {
            state.applicationsParams.per_page = per_page;
            state.applicationsParams.page = 1;
        },

        setAllApplications(state, applications) {
            state.all = applications;
        },

        clearCurrentApplication(state) {
            state.current = null;
        },

        clearAllApplications(state) {
            state.all = null;
        },

        clearFilters(state) {
            state.applicationsParams = { ...initialApplicationParams };
        },
        setSort(state, sort) {
            state.applicationsParams.sort = sort;
            state.applicationsParams.page = 1;
        },
        setShowAll(state, show_all) {
            state.applicationsParams.show_all = show_all;
            state.applicationsParams.page = 1;
        },
        setStates(state, states) {
            state.applicationsParams.states = states;
            state.applicationsParams.page = 1;
        },
        setInsuranceTypes(state, insurance_types) {
            state.applicationsParams.insurance_types = insurance_types;
            state.applicationsParams.page = 1;
        },
        setPriorities(state, priorities) {
            state.applicationsParams.priorities = priorities;
            state.applicationsParams.page = 1;
        },
        setStages(state, stages) {
            state.applicationsParams.stages = stages;
            state.applicationsParams.page = 1;
        },
        setTypes(state, types) {
            state.applicationsParams.types = types;
            state.applicationsParams.page = 1;
        },
        setCreationDateStart(state, creation_date_start) {
            state.applicationsParams.creation_date_start = creation_date_start;
            state.applicationsParams.page = 1;
        },
        setCreationDateEnd(state, creation_date_end) {
            state.applicationsParams.creation_date_end = creation_date_end;
            state.applicationsParams.page = 1;
        },
        setUpdatedDateStart(state, updated_date_start) {
            state.applicationsParams.updated_date_start = updated_date_start;
            state.applicationsParams.page = 1;
        },
        setUpdatedDateEnd(state, updated_date_end) {
            state.applicationsParams.updated_date_end = updated_date_end;
            state.applicationsParams.page = 1;
        },
        setDeviceTypes(state, device_types) {
            state.applicationsParams.device_types = device_types;
            state.applicationsParams.page = 1;
        },
        setShowClosed(state, show_closed) {
            state.applicationsParams.show_closed = show_closed;
            state.applicationsParams.page = 1;
        },
        setShowOnlyPractice(state, show_only_practice) {
            state.applicationsParams.show_only_practice = show_only_practice;
            state.applicationsParams.page = 1;
        },
        clearSearch(state) {
            state.applicationsParams.search = "";
        },
        setSearch(state, search) {
            state.applicationsParams.search = search;
            state.applicationsParams.page = 1;
        },
        setSearchOptions(state, search_options) {
            state.applicationsParams.search_options = search_options;
        },
        setHasTasks(state, has_tasks) {
            state.applicationsParams.has_tasks = has_tasks;
        },
        setHasNoTasks(state, has_no_tasks) {
            state.applicationsParams.has_no_tasks = has_no_tasks;
        },
        setTotal(state, total) {
            state.applicationsParams.total = total;
        },
        setLastUsedApplicationParams(state, params) {
            state.lastUsedApplicationParams = params;
        },
    },

    actions: {
        clearFilters({ commit }){
            commit("clearFilters");
        },

        setPresets({ dispatch, commit }, preset = {}){
            Object.keys(preset).forEach(key => {
                let data = { param: key, value: preset[key] };
                
                // convert null search value to empty string
                if(key === "search" && !preset[key]){
                    data.value = "";
                }

                commit("setParam", data);
            });

            commit("setPage", 1);
            dispatch("loadAll");
        },

        convert({ dispatch }, { alias, type }) {
            this.$http
                .patch(`funding/application/${alias}/convert/${type}`, {
                    notifications: false,
                })
                .then(() => {
                    dispatch("loadCurrent", alias);
                });
        },

        removeAccessoryOnly({ dispatch }, alias) {
            this.$http
                .patch(`funding/application/${alias}/remove-accessory-only`, {
                    notifications: false,
                })
                .then(() => {
                    dispatch("loadCurrent", alias);
                });
        },

        enableOverrideLock({ commit }) {
            commit("enableOverrideLock");
        },

        disableOverrideLock({ commit }) {
            commit("disableOverrideLock");
        },

        /**
         * Load the application with the given alias and set it as our current application
         *
         * @param {object} context
         * @param {string} alias
         */
        async loadCurrent({ commit, dispatch }, alias) {
            // clear our previous application (if any)
            commit("clearCurrentApplication");

            if (!alias) {
                router.push({ name: "dashboard" });
                return console.error(
                    `Missing alias, cannot load this application`
                );
            }

            // load our new application
            const response = await this.$http.get(
                `funding/application/${alias}`,
                { notifications: false }
            );

            dispatch("setCurrentApplication", { application: response?.data });
            //dispatch("tasks/clearFilters", {}, { root: true });
            dispatch("tasks/getUserTasks", { page: 1 }, { root: true });
        },

        /**
         * Set the current application data
         *
         * @param {object} context
         * @param {object} application
         * @param {object} options
         */

        setCurrentApplication({ commit }, { application }) {
            if (!application?.alias) {
                return console.error(
                    `Invalid application passed to setCurrentApplication`,
                    application
                );
            }
    
            // set our current application
            commit("setCurrentApplication", application);
        },

        /**
         * Load all of the user's applications
         *
         * @param {object} context
         */
        async loadAll(
            { commit, state },
            options = {}
        ) {

            // if our search has fewer than 3 characters, clear it
            if(state.applicationsParams.search.length < 3){
                commit("clearSearch");
            }

            let url = queryService.applicationQuery("funding/applications", state.applicationsParams);

            const response = await this.$http.get(url, {
                ...options,
                successNotification: false,
            });

            commit("setLastUsedApplicationParams", { ...state.applicationsParams});

            const applications = response?.data;
            if (applications.current_page) {
                commit("setPages", applications.last_page);
                commit("setTotal", applications.total);
                if (!Array.isArray(applications.data)) {
                    return console.error(`Failed to load applications`);
                }
                commit("setAllApplications", applications.data);
            } else {
                if (!Array.isArray(applications)) {
                    return console.error(`Failed to load applications`);
                }

                commit("setAllApplications", applications);
            }
           
            /*
            if (rootGetters.isAdmin) {
                dispatch("tasks/getUserTasks", { page: 1 }, { root: true });
            }
            */ 
        },

        async approveApplication({ dispatch, state }) {
            const response = await this.$http.post(
                `funding/application/${state.current.alias}/approve-application`,
                { notifications: false }
            );
            dispatch("setCurrentApplication", { application: response?.data });
        },

        /**
         * Set this current application to null
         *
         * @param {object} context
         */
        clearCurrentApplication({ commit }) {

            commit("clearCurrentApplication");
        },

        setCurrentApplicationPriority({ commit, state }, priority) {
            let currentApp = state.current;
            currentApp.priority = priority;
            commit("setCurrentApplication", currentApp);
        },
        setCurrentApplicationInsuranceSubmission(
            { commit, state },
            insurance_submission
        ) {
            let currentApp = state.current;
            currentApp.insurance_submission = insurance_submission;
            commit("setCurrentApplication", currentApp);
        },

        /**
         * Set this current application to null
         *
         * @param {object} context
         */
        async deleteCurrent({ commit, dispatch, state }) {
            try {
                await this.$http.delete(
                    `funding/application/${state.current.alias}`
                );
            } catch {
                console.error("failed to delete application!");
                return;
            }
            dispatch("loadAll", { spinner: false });
            commit("clearCurrentApplication");
            router.push({ name: "dashboard" });
        },

        /**
         * Restore current application
         *
         * @param {object} context
         */
        async restoreCurrent({ commit, dispatch, state }) {
            let response;
            try {
                response = await this.$http.patch(
                    `funding/application/${state.current.alias}/restore`
                );
            } catch {
                console.error("failed to restore application!");
                return;
            }
            commit("setCurrentApplication", response?.data);
            dispatch("tasks/getUserTasks", { page: 1 }, { root: true });
        },

        /**
         * Set this all applications to null
         *
         * @param {object} context
         */
        clearAllApplications({ commit }) {
            commit("clearAllApplications");
        },

        /**
         * Clear a rejection from the current application with the given ID
         */
        async clearRejection({ dispatch, state }, rejectionId) {
            const response = await this.$http.delete(
                `funding/application/${state.current.alias}/reject/${rejectionId}`
            );
            dispatch("setCurrentApplication", {
                application: response.data,
                refreshApplications: true,
            });
        },
    },

    getters: {
        hasActiveFilters(state) {
            return !isEqual(
                removePaginationParams(state.lastUsedApplicationParams), 
                removePaginationParams(initialApplicationParams)
            );
        },
        statuses(_state, _getters, _rootState, rootGetters) {
            let statuses = Object.values(rootGetters.statuses ?? {});
            return statuses.filter(status  => status.display_application);
        },
        previousRoute(state){
            return (section_name) => {
                let sections = state.current?.sections;

                if(!sections){
                    return null;
                }

                const sectionIndex = sections.findIndex(section => section.name === section_name);

                if (sections[sectionIndex - 1] === undefined) {
                    return "application.overview";
                }

                return sections[sectionIndex - 1].route;
            };
        },
        nextRoute(state){
            return (section_name) => {
                let sections = state.current?.sections;

                if(!sections){
                    return null;
                }

                const sectionIndex = sections.findIndex(section => section.name === section_name);

                if (sections[sectionIndex + 1] === undefined) {
                    return "application.overview";
                }

                return sections[sectionIndex + 1].route;
            };
        },
        showApplicationsFilters(state) {
            return state.showApplicationsFilters;
        },
        current(state, getters) {
            let application = state?.current;

            if (application === null) {
                return application;
            }

            application.sections =
                getters.getActiveApplicationSections(application);
            return application;
        },
        show_converted_trial(state) {
            return state.applicationsParams.show_converted_trial;
        },
        submissions(state) {
            return state.applicationsParams.submissions;
        },
        selected_shipped_options(state) {
            return state.applicationsParams.selected_shipped_options;
        },
        sections(state) {
            return state.sections;
        },
        show_qad(state) {
            return state.applicationsParams.show_qad;
        },
        show_wo_qad_son(state) {
            return state.applicationsParams.show_wo_qad_son;
        },
        show_wo_rma_number(state) {
            return state.applicationsParams.show_wo_rma_number;
        },
        show_has_custom_item(state) {
            return state.applicationsParams.show_has_custom_item;
        },
        sort(state) {
            return state.applicationsParams.sort;
        },
        has_tasks(state) {
            return state.applicationsParams.has_tasks;
        },
        has_no_tasks(state) {
            return state.applicationsParams.has_no_tasks;
        },
        applicationParams(state){
            return state.applicationsParams;
        },
        page(state) {
            return state.applicationsParams.page;
        },
        pages(state) {
            return state.applicationsParams.pages;
        },
        per_page(state) {
            return state.applicationsParams.per_page;
        },
        show_all(state) {
            return state.applicationsParams.show_all;
        },

        states(state) {
            return state.applicationsParams.states;
        },
        insurance_types(state) {
            return state.applicationsParams.insurance_types;
        },
        priorities(state) {
            return state.applicationsParams.priorities;
        },
        stages(state) {
            return state.applicationsParams.stages;
        },
        types(state) {
            return state.applicationsParams.types;
        },
        creation_date_start(state) {
            return state.applicationsParams.creation_date_start;
        },
        creation_date_end(state) {
            return state.applicationsParams.creation_date_end;
        },
        updated_date_start(state) {
            return state.applicationsParams.updated_date_start;
        },
        updated_date_end(state) {
            return state.applicationsParams.updated_date_end;
        },
        device_types(state) {
            return state.applicationsParams.device_types;
        },
        outcomes(state) {
            return state.applicationsParams.outcomes;
        },
        show_closed(state) {
            return state.applicationsParams.show_closed;
        },
        show_only_practice(state) {
            return state.applicationsParams.show_only_practice;
        },
        search(state) {
            return state.applicationsParams.search;
        },
        search_options(state) {
            return state.applicationsParams.search_options;
        },
        all(state) {
            return state.all;
        },
        total(state) {
            return state.applicationsParams.total;
        },
        upload_types(state) {
            return state.applicationsParams.upload_types;
        },
        expiring_window(state) {
            return state.applicationsParams.expiring_window;
        },
        applicationsParams(state) {
            return state.applicationsParams;
        },

        currentRoles(state, _getters, _rootState, rootGetters) {
            // get all of the contributors from the store
            let contributors = state.current.contributors ?? [];

            // filter only the contributor records for me
            let myContributors = contributors.filter(
                (contributor) => contributor.user_id === rootGetters.user?.id
            );

            // if I have no contributor records for this application return an empty array
            if (!myContributors.length) {
                return [];
            }

            // otherwise map my contributor records to their role names
            return myContributors.map((contributor) =>
                rootGetters["roleNameById"](contributor.role_id)
            );
        },

        isMDM(_state, getters){
            return getters.currentRoles.includes("MDM") || getters.currentRoles.includes("CLIENT_MDM");
        },

        currentIsLocked(state, getters) {
            return state.current?.locked && !getters.overrideLock;
        },
        currentIsClosed(state) {
            return state.current?.closed;
        },
        currentIsSubmitted(state) {
            return state.current?.submitted;
        },
        overrideLock(state, getters) {
            // if we are closed, our override should always be null, otherwise return
            // the current state of our overrideLock property
            return getters.currentIsClosed ? null : state.overrideLock;
        },

        evaluationType(state) {
            switch (state.current?.evaluation_info?.external_evaluation) {
                case true:
                    return "upload";
                case false:
                    return "form";
                default:
                    return null;
            }
        },

        evaluationV2Type(state) {
            switch (state.current?.evaluation_v2_info?.external_evaluation) {
                case true:
                    return "upload";
                case false:
                    return "form";
                default:
                    return null;
            }
        },

        tandcType(state) {
            switch (state.current?.trial_terms_and_conditions_info?.external_terms) {
                case true:
                    return "upload";
                case false:
                    return "form";
                default:
                    return null;
            }
        },

        getActiveApplicationSections(state) {
            return (application) => {
                // map over all possible sections
                return state.sections
                    .map((section) => {
                        // if this section is disabled (or doesn't exist at all) return null
                        if (
                            (!application[section.relation] && !application[section.relation + "_status"]) ||
                            application[section.relation + "_disabled"] ||
                            application[section.relation]?.disabled
                        ) {
                            return null;
                        }

                        // otherwise return the details of our active section
                        return {
                            statusId:
                                application[section.relation + "_status"] ??
                                application[section.relation]?.status_id,
                            ...section,
                        };
                    })
                    .filter((section) => section); // filter any null values
            };
        },
    },
};
