<template>
    <div
        class="fixed bottom-4 right-4 md:max-w-sm max-w-10/12 text-current z-50"
    >
        <!-- notification items -->
        <transition-group name="slide-from-right" tag="ul">
            <notification-item
                v-for="note in queue"
                :key="note.id"
                :note="note"
            />
        </transition-group>
    </div>
</template>

<script>
import NotificationItem from "./NotificationItem.vue";

export default {
    name: "notify-queue",
    components: {
        NotificationItem,
    },
    watch: {
        $route: {
            handler: function () {
                this.$store.dispatch("notifications/clear", 2);
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        queue() {
            return this.$store.getters["notifications/queue"];
        },
    },
};
</script>
