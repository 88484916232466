<template>
    <!-- confirm button -->
    <confirm-button 
        v-if="$store.getters.primaryNotice?.showWhenCreatingApplication"
        title="Attention!"
        v-bind="$attrs"
        role=""
        modalIcon="circle-exclamation"
        component="primary-button"
        :message="$store.getters.primaryNotice?.message"
        class="text-sm h-7"
        @confirmed="$emit('confirmed')"
        confirmText="Start New Application"
        cancelText="Cancel"
        confirmButtonRole="default"
        cancelButtonRole="danger"
    >
        New Application
    </confirm-button>

    <!-- non-confirm button -->
    <primary-button 
        v-else
        class="text-sm h-7"
        v-bind="$attrs"
        @click="$emit('confirmed')"
    >
        New Application
    </primary-button>
</template>

<script>
export default {
    emits: ['confirmed'],
}
</script>

<style>

</style>