// add bearer token to outbound requests
export default (http, { store }) => {
    http.interceptors.request.use((request) => {
        // check if token is present, then apply it if it exists
        const accessToken = store.getters.accessToken; 
        
        if (accessToken) {
            request.headers["Authorization"] = "Bearer " + accessToken;
        }
        
        return request;
    });
};
