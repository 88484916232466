<template>
    <div class="flex items-center gap-2 text-sm">
        <div class="text-ellipsis line-clamp-2 font-semibold text-secondary-500 grow">
            <slot></slot>
        </div>

        <!-- status icon -->
        <i v-if="icon"
            class="fa shrink-0"
            :class="computedIconClasses"
            aria-hidden="true"
        ></i>
    </div>
</template>
<script>
export default {
    props: {
        icon: {
            type: String,
        },
        role: {
            type: String,
            default: 'warning',
        },
        iconClass: {
            type: String,
            default: '',
        }
    },

    data(){
        return {
            roles: {
                error: 'text-red-500',
                warning: 'text-yellow-500',
                success: 'text-green-500',
                info: 'text-secondary-500',
            }
        }
    },

    computed: {
        computedIconClasses(){
            if(!this.icon){
                return;
            }

            let classes = `fa-${this.icon} `;

            if(this.iconClass){
                return classes += this.iconClass;
            }

            return classes += this.roles[this.role] ?? this.role;
        }
    }
}
</script>