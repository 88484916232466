export default {
    namespaced: true,

    state() {
        return {};
    },

    mutations: {},

    actions: {},

    getters: {
        repairEvaluation(_, __, rootState) {
            return rootState.applications.current?.repair_evaluation_info?.repair_evaluation ?? {};
        },

        repairEvaluationInfo(_, __, rootState) {
            return rootState.applications.current?.repair_evaluation_info ?? {};
        },

        sections(_state, getters, _rootState, _rootGetters) {
            const sections = {
                overview: getters.repairEvaluationInfo?.status_id,
            };

            return sections;
        },
    },
};
