<template>
    <button
        class="mx-2 md:mx-5  transition-all flex items-center gap-2 whitespace-nowrap"
        :class="{ 'text-yellow-300': privacyMode, 'hover:text-primary-500': !privacyMode }"
        @click="togglePrivacyMode"
    >
        <!-- toggle icon -->
        <i
            class="fad text-xl -my-4"
            :class="toggleClasses"
            aria-hidden="true"
            
        ></i>

        <!-- eye icon -->
        <!--
        <i
            class="fad opacity-75"
            :class="`fa-${eyeIcon}`"
            aria-hidden="true"
        ></i>
         -->

        <span class="hidden lg:inline">Privacy Mode</span>

    </button>
</template>

<script>
export default {
    props: {
    },
    computed: {
        privacyMode(){
            return this.$store.getters.privacyMode;
        },
        eyeIcon(){
            return this.privacyMode ? "eye-slash" : "eye";
        },    
        toggleClasses(){
            if(this.privacyMode){
                return "fa-toggle-large-on";
            }

            return "fa-toggle-large-off";
        },  
    },
    methods: {
        togglePrivacyMode(){
            this.$store.dispatch("togglePrivacyMode");
        }
    },
};
</script>
