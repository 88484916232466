
const insurance_types = [
    {
        key: 'medicare',
        label: 'Medicare'
    },
    {
        key: 'medicaid',
        label: 'Medicaid'
    },
    {
        key: 'commercial',
        label: 'Commercial Insurance'
    },
    {
        key: 'additional',
        label: 'Additional Insurance'
    },
    {
        key: 'additional2',
        label: 'Additional Insurance 2'
    },
]

const routes = [
    {
        path: "",
        name: "application.insuranceInfo",
        component: () => import(/* webpackChunkName: "insurance-routes" */ "@/views/application/insuranceInfo/insuranceTypes.vue"),
        meta: {
            title: "Insurance Info - Select Types",
        },
    },
    {
        path: "approve",
        name: "application.insuranceInfo.approve",
        component: () => import(/* webpackChunkName: "insurance-routes" */ "@/views/application/insuranceInfo/insuranceApprove.vue"),
    },
];

insurance_types.forEach(type => {
    routes.push({
        path: `${type.key}-info`,
        name: `application.insuranceInfo.${type.key}Info`,
        component: () => import(/* webpackChunkName: "insurance-routes" */ "@/views/application/insuranceInfo/insuranceInfo.vue"),
        props: {insuranceKey: type.key, insuranceLabel: type.label}
    })

    routes.push({
        path: `${type.key}-aob`,
        name: `application.insuranceInfo.${type.key}Aob`,
        component: () => import(/* webpackChunkName: "insurance-routes" */ "@/views/application/insuranceInfo/insuranceAob.vue"),
        props: {insuranceKey: type.key, insuranceLabel: type.label}
    })
});

export default routes;
