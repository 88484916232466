<template>
    <span v-if="isOwnerAdmin" class="flex float-right">
        <router-link
            :to="{
                 name: 'application.admin.audit-logs',
                 query: { 'category': section ?? 'clientInfo' },
             }"
            
            class="mx-1 flex"
        >
            <secondary-button size="small">Audit logs</secondary-button>
        </router-link>
    </span>
</template>
<script>
export default {
    props: {
        section: {
            type: String
        }
    },
    computed: {
        isOwnerAdmin() {
            return this.$store.getters.isOwnerAdmin;
        },
    }
}
</script>