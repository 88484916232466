// manages our "waiting on an ajax call" http state
export default {
    namespaced: true,
    state() {
        return {
            requests: [],
        };
    },
    mutations: {
        increment(state) {
            state.requests.push(null);
        },
        decrement(state) {
            state.requests.pop();
        },
    },
    actions: {
        increment(context) {
            context.commit("increment");
        },
        decrement(context) {
            context.commit("decrement");
        },
    },
    getters: {
        requests(state) {
            return state.requests.length;
        },
    },
};
