<template>
 <warning-banner
        v-if="
            loggedIn &&
            hasLoadedUser &&
            hasLoadedEmails &&
            userBackupEmailStatus !== 'loading' &&
            userBackupEmailStatus !== 'verified'
        "
    >
        <div class="font-medium">
            <router-link
                :to="{ name: 'account', hash: '#manage-emails' }"
                class="font-medium hover:underline"
            >
                <span v-if="userBackupEmailStatus === 'missing'">
                    Add a backup email to avoid getting locked out of your
                    account.
                </span>
                <span v-else-if="userBackupEmailStatus === 'unverified'">
                    Verify your backup email to avoid getting locked out of
                    your account.
                </span>
                <span v-else>
                    There is a problem with your backup email.
                </span>
            </router-link>
        </div>
    </warning-banner>
</template>

<script>

export default {
    props: ["hasLoadedUser"],

    data() {
        return {
            hasLoadedEmails: false,
        };
    },

    watch: {
        loggedIn(value) {
            if (value) {
                this.loadEmails();
            }
        },
    },

    methods: {
        async loadEmails() {
            try {
                await this.$store.dispatch("account/loadEmails");
                this.hasLoadedEmails = true;
            }    
            catch(e) {
                console.error(e);
            }
        },
    },

    computed: {
        loggedIn() {
            return this.$store.getters.loggedIn;
        },

        userBackupEmailStatus() {
            let emails = this.$store.getters["account/emails"]?.secondary;
            let status = "loading";

            if (!emails || !this.hasLoadedEmails) {
                return status;
            }

            status = "missing";
            if (emails.length === 0) {
                return status;
            }

            status = "unverified";
            for (const email of emails) {
                if (email?.is_verified) {
                    status = "verified";
                    break;
                }
            }

            return status;
        },
    },
};
</script>
<style scoped></style>

