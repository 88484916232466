<template>
    <!-- input -->
    <input
        type="hidden"
        :name="field.name"
        :value="modelValue"
        v-bind="field.attributes"
        v-bind:[dataName]="`${field.name}--input`"
    />
</template>

<script>
export default {
    props: ["modelValue", "field", "form"],
    inject: ["dataName"],
};
</script>
