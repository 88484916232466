<template>
<!-- include deleted -->
<button @click="onToggle" class="inline-flex items-center">
    <i class="fas text-3xl mr-2 leading-6" :class="toggleClass"></i> 
    <div class="text-gray-700 font-medium text-xxs tracking-wide uppercase" :class="textClass">
        {{ text }}
        <slot></slot>
    </div>
</button>
</template>

<script>
export default {
    props: {
        textClass: {
            type: String,
            required: false,
        },

        text: {
            type: String,
            required: false,
            default: '',
        },
        
        color: {
            type: String,
            default: 'text-blue-600',
        },

        modelValue: Boolean,
    },

    emits: ['update:modelValue', 'toggle'],

    computed: {
        toggleClass(){
            if(this.modelValue){
                return [
                    'fa-solid',
                    'fa-toggle-on',
                    this.color,
                ];
            }

            return [
                'fa-thin',
                'fa-toggle-off',
                'text-gray-400',
            ];
        },
    },

    methods: {
        onToggle(){
            this.$emit('update:modelValue', !this.modelValue);
            this.$emit('toggle');
        },
    },
};
</script>

<style>
    button:disabled .fa-toggle-on,
    button:disabled .fa-toggle-off {
        color:#ddd;
    }
</style>
