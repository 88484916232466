import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";

export default [
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: {
            public: true,
            theme: "night",
            middleware: ["guest"],
        },
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
            public: true,
            theme: "night",
            middleware: ["guest"],
        },
    },
    {
        path: "/verify-email",
        name: "verifyEmail",
        component: () => import("@/views/auth/VerifyEmail.vue"),
        meta: {
            public: true,
            theme: "night",
        },
    },
    {
        path: "/resend-email-verification",
        name: "resendEmailVerification",
        component: () => import("@/views/auth/ResendEmailVerification.vue"),
        meta: {
            public: true,
            theme: "night",
        },
        props: true,
    },
    {
        path: "/reset-password",
        name: "resetPassword",
        component: () => import("@/views/auth/ResetPassword.vue"),
        meta: {
            public: true,
            theme: "night",
            middleware: ["forceLogout"],
        },
    },
    {
        path: "/send-password-reset",
        name: "sendPasswordReset",
        component: () => import("@/views/auth/SendPasswordReset.vue"),
        meta: {
            public: true,
            theme: "night",
        },
        props: true,
    },
];
