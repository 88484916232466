export default [
    {
        path: "",
        name: "application.clientInfo",
        component: () => import("@/views/application/clientInfo/about.vue"),
        meta: {
            title: "Client Info - About",
        },
    },
    {
        path: "delivery",
        name: "application.clientInfo.delivery",
        component: () => import("@/views/application/clientInfo/delivery.vue"),
    },
    {
        path: "diagnosis",
        name: "application.clientInfo.diagnosis",
        component: () => import("@/views/application/clientInfo/diagnosis.vue"),
    },
    {
        path: "equipment",
        name: "application.clientInfo.equipment",
        component: () => import("@/views/application/clientInfo/equipment.vue"),
    },
    {
        path: "decision-maker",
        name: "application.clientInfo.mdm",
        component: () => import("@/views/application/clientInfo/mdm.vue"),
        meta: {
            title: "Client Info - Decision Maker",
        },
    },
    {
        path: "physician",
        name: "application.clientInfo.physician",
        component: () => import("@/views/application/clientInfo/physician.vue"),
    },
    {
        path: "residence",
        name: "application.clientInfo.residence",
        component: () => import("@/views/application/clientInfo/residence.vue"),
    },
    {
        path: "slp",
        name: "application.clientInfo.slp",
        component: () => import("@/views/application/clientInfo/slp.vue"),
        meta: {
            title: "Client Info - SLP",
        },
    },
];
