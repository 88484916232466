<template>
    <review-section
        :title="`Validated Candidate #${index + 1}`"
        :items="items"
    >
        <div class="flex justify-center mt-4">
            <primary-button size="xsmall" @click="$emit('selected', candidate)">
                save this address
            </primary-button>
        </div>
    </review-section>
</template>

<script>
export default {
    components: {
    },

    props: {
        candidate: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        }
    },

    emits: ['selected'],
    
    computed: {
        items(){
            return [
                {
                    name: "address_1",
                    label: "Address",
                    value: this.candidate.address_1,
                },
                {
                    name: "address_2",
                    label: "Address 2",
                    value: this.candidate.address_2 ? this.candidate.address_2 : null,
                },
                {
                    name: "city",
                    value: this.candidate.city,
                },
                {
                    name: "state",
                    value: this.candidate.state,
                },
                {
                    name: "zip",
                    value: this.candidate.zip,
                },
            ];
        },
    },
}
</script>

<style>

</style>