import router from "@/router/index";
import cloneDeep from "lodash/cloneDeep";

// manages our WIP create application
export default {
    namespaced: true,

    state() {
        return {
            application: null,
            startingNewApplication: false,
            applicationCreated: false,
        };
    },

    mutations: {
        init(state) {
            state.application = {
                role: null,
                type: null,
                ca_number: null,
                slp_name: null,
                slp_email: null,
                slp_asha_number: null,
                assistant_name: null,
                assistant_email: null,
                mdm_name: null,
                mdm_email: null,
                contributors: [],
                first_name: null,
                last_name: null,
                gender: null,
                dob: null,
                zip_code: null,
                authorized: null,
                accessory_only: null,
            };
        },

        update(state, fields) {
            state.application = { ...state.application, ...fields };
        },

        addContributor(state, contributor) {
            state.application.contributors.push(contributor);
        },

        removeContributor(state, contributor) {
            state.application.contributors =
                state.application.contributors.filter(
                    (item) => item.email !== contributor.email
                );
        },

        clearApplication(state) {
            state.application = null;
        },

        clearContributors(state) {
            state.application.slp_name = null;
            state.application.slp_email = null;
            state.application.assistant_name = null;
            state.application.assistant_email = null;
            state.application.mdm_name = null;
            state.application.mdm_email = null;
        },

        setStartingNewApplication(state, value) {
            state.startingNewApplication = value;
        },

        setApplicationCreated(state, value) {
            state.applicationCreated = value;
        },
    },

    actions: {
        /**
         * Build this application object and return it to its default state
         *
         * @param {object} context
         */
        init({ commit }) {
            commit("init");
        },

        /**
         * Build this application object and return it to its default state
         *
         * @param {object} context
         */
        setStartingNewApplication({ commit }, value) {
            commit("setStartingNewApplication", value);
        },

        /**
         * Clear our application created state
         *
         * @param {object} context
         * @param {boolean} value
         */
        clearApplicationCreated({ commit }) {
            commit("setApplicationCreated", false);
        },

        /**
         * Remove the data related to contributors for this application
         *
         * @param {object} context
         */
        clearContributors({ commit }) {
            commit("clearContributors");
        },

        /**
         * Update our application object
         *
         * @param {object} context
         * @param {object} fields
         */
        update({ commit, state }, fields) {
            // don't attempt to update if it hasn't been initiated
            if (state.application === null) {
                return;
            }
            commit("update", fields);
        },

        /**
         * Cancel this application
         *
         * @param {object} context
         */
        cancel({ commit }) {
            // delete this application data
            commit("clearApplication");

            // redirect to the dashboard
            router.push({ name: "dashboard" });
        },

        /**
         * Submit this application to the create application endpoint
         *
         * @param {object} context
         * @returns {Promise}
         */
        submit({ commit, state, rootGetters }, httpOptions) {
            // format our input data
            const data = prepareSubmission(state.application, rootGetters);

            return new Promise((resolve, reject) => {
                this.$http
                    .post("funding/application", data, httpOptions)
                    .then((response) => {
                        commit("clearApplication");
                        commit("setApplicationCreated", true);
                        resolve(response?.data);
                    })
                    .catch((errors) => {
                        reject(errors?.response);
                    });
            });
        },
    },

    getters: {
        application(state) {
            return state.application;
        },

        applicationCreated(state) {
            return state.applicationCreated;
        },

        canInviteSLP(state, _, __, rootGetters) {
            return (
                state.application &&
                +state.application.role !==
                    rootGetters.roleIdByName("EVALUATING_SLP")
            );
        },

        canInviteMDM(state, _, __, rootGetters) {
            const blackList = [
                rootGetters.roleIdByName("MDM"),
                rootGetters.roleIdByName("CLIENT_MDM"),
            ];
            return (
                state.application && !blackList.includes(state.application.role)
            );
        },

        canInviteAssistant(state, _, __, rootGetters) {
            const whiteList = [
                rootGetters.roleIdByName("EVALUATING_SLP"),
                rootGetters.roleIdByName("EMPLOYEE"),
            ];
            return (
                state.application && whiteList.includes(state.application.role)
            );
        },

        isMDM(state, _, __, rootGetters) {
            const whiteList = [
                rootGetters.roleIdByName("MDM"),
                rootGetters.roleIdByName("CLIENT_MDM"),
            ];
            return (
                state.application && whiteList.includes(state.application.role)
            );
        },

        isSLP(state, _, __, rootGetters) {
            return (
                state.application &&
                +state.application.role ===
                    rootGetters.roleIdByName("EVALUATING_SLP")
            );
        },

        isOtherMedical(state, _, __, rootGetters) {
            return (
              state.application &&
              +state.application.role ===
                rootGetters.roleIdByName("OTHER_MEDICAL")
            )
        },

        isAuthorizedToAddContributors(state) {
            return !!state.application && state.application.authorized;
        },

        startingNewApplication(state) {
            return state.startingNewApplication;
        },
    },
};

/**
 * Format our application object into the final state
 * we will post to the API
 *
 * @param {object} application
 * @returns {object}
 */
function prepareSubmission(application, rootGetters) {
    // make a copy of application
    const data = cloneDeep(application);
    // add mdm to contributors
    if (data.mdm_email && data.mdm_name) {
        data.contributors.push({
            name: data.mdm_name,
            email: data.mdm_email,
            role: rootGetters.roleIdByName("MDM"),
        });
    }

    // add slp to contributors
    if (data.slp_email && data.slp_name) {
        data.contributors.push({
            name: data.slp_name,
            email: data.slp_email,
            role: rootGetters.roleIdByName("EVALUATING_SLP"),
        });
    }

    // add assistant to contributors
    if (data.assistant_email && data.assistant_name) {
        data.contributors.push({
            name: data.assistant_name,
            email: data.assistant_email,
            role: rootGetters.roleIdByName("SLP_ASSISTANT"),
        });
    }

    if (!data.slp_asha_number) {
        delete data.slp_asha_number;
    }

    // delete extraneous properties
    delete data.mdm_name;
    delete data.mdm_email;
    delete data.slp_name;
    delete data.slp_email;
    delete data.assistant_name;
    delete data.assistant_email;

    // return our data
    return data;
}
