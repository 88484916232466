<template>
    <div class="border rounded-sm border-secondary-900 shadow">
        <div class="font-bold bg-secondary-100 text-secondary-900 p-3 uppercase flex gap-4">
            <!-- title -->
            <span class="mr-auto">{{ example.title }}</span>
            
            <!-- action buttons -->
            <div v-if="isOwnerAdmin" class="flex gap-2">
                <!-- edit button -->
                <action-button @click="openEditModal = true" icon="edit" />
                
                <!-- delete button -->
                <confirm-button
                    component="action-button"
                    title="Delete this example"
                    class="text-red-500"
                    icon="trash"
                    @confirmed="deleteExample"
                    :message="`Are you sure you want to delete this example?`"
                />
            </div>
        </div>

        <!-- content -->
        <div class="p-3 text-gray-500 grid gap-4 ck-content" v-html="example.content"></div>
    </div>

    <example-modal 
        v-if="openEditModal"
        :example="example"
        :field="field"
        @close="openEditModal = false"
    />
</template>

<script>
import ExampleModal from './ExampleModal.vue';

export default {
    components: {
        ExampleModal,
    },

    props: {
        example: {
            type: Object,
            required: true,
        },
        field: {
            type: Object,
            required: true,
        },
    },

    data(){
        return {
            openEditModal: false,
        };  
    },

    computed: {
        isOwnerAdmin(){
            return this.$store.getters.isOwnerAdmin;
        },
    },

    methods: {
        deleteExample(){
            this.$http.delete(`funding/example/${this.example.id}`)
                .then(response => {
                    this.$store.dispatch('example/setExamples', { model: this.field.examplesModel, examples: response?.data });
                });
        },
    },
}
</script>

<style>

</style>