export default {
    props: {
        showThumbnail: {
            type: Boolean,
            required: false,
            default: false,
        },
        url: {
            type: String,
            required: false,
        },
    },
}