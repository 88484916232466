export default [
    {
        path: "",
        name: "application.repairEvaluation",
        component: () => import("@/views/application/repairEvaluation/overview.vue"),
        meta: {
            title: "Repair Evaluation - Overview",
        },
    },
];
