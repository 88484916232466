<template>
    <base-main>
        <base-card
            :title="cardTitle"
            icon="user-circle"
            class="w-full md:flex-grow-0 md:w-sm"
        >
            <!-- success -->
            <success-alert id="register-success" v-if="created === true">
                Your account has been created and a verification email has been
                sent to the email you provided. You must verify your email
                before logging in.
            </success-alert>

            <!-- error -->
            <error-alert
                v-if="created === false"
                id="register-failed"
                class="mb-4"
            >
                {{ errorMessage }}.
            </error-alert>

            <!-- registration form -->
            <quick-form
                v-if="created !== true"
                id="register-form"
                :schema="registerSchema"
                class="w-full"
                autocomplete="off"
                @success="registerSuccess"
                @error="registerError"
                @submit="resetResults"
                @updated="onUpdated"
            />

            <!-- footer -->
            <template #footer>
                <router-link
                    id="return-login"
                    :to="{ name: 'login' }"
                    class="underline"
                >
                    <span>return to login</span>
                </router-link>
            </template>
        </base-card>

        <base-teleport to="#password-strength">
            <password-strength :password="password" :minLength="10" />
        </base-teleport>

        <!-- terms and conditions modal -->
        <base-teleport to="#register-terms">
            <p class="mb-3">
                Please read our
                <a class="link--primary" @click.prevent="openTerms = true">
                    Terms of Service
                </a>
            </p>
        </base-teleport>

        <base-modal :open="openTerms" @close="openTerms = false">
            <register-terms class="w-full md:w-sm" />
        </base-modal>
    </base-main>
</template>

<script>
import RegisterTerms from "@/components/auth/RegisterTerms.vue";
import PasswordStrength from "@/components/global/PasswordStrength.vue";
export default {
    components: {
        RegisterTerms,
        PasswordStrength,
    },
    data() {
        return {
            openTerms: false, // open our terms and conditions modal
            created: null, // have we successfully created an account?
            errorMessage: null,
            password: "",
            registerSchema: {
                name: "register",
                endpoint: "register",
                class: "md:grid-cols-2",
                fields: [
                    {
                        name: "first_name",
                        required: true,
                        validate: ["hasLetter", "scaryChars"],
                    },
                    {
                        name: "last_name",
                        required: true,
                        validate: ["hasLetter", "scaryChars"],
                    },
                    {
                        name: "email",
                        type: "email",
                        value: this.$route.query.email ?? "",
                        required: true,
                        wrapperClass: "md:col-span-2",
                        validate: ["email"],
                    },
                    {
                        name: "username",
                        description:
                            "Letters & numbers only, must include a letter (Optional)",
                        wrapperClass: "md:col-span-2",
                        validate: ["alphaNum", "hasLetter"],
                    },
                    {
                        name: "asha_number",
                        label: "ASHA Number",
                        description:
                            "If you have an ASHA number you may use that to log in (Optional)",
                        wrapperClass: "md:col-span-2",
                        validate: ["length:8", "integer"],
                        attributes: {
                            maxLength: 8,
                        },
                    },
                    {
                        name: "password",
                        type: "password",
                        required: true,
                        description: "Minimum 10 characters",
                        teleport: "password-strength",
                        wrapperClass: "md:col-span-2",
                        validate: ["minLength:10"],
                        attributes: {
                            autocomplete: "new-password",
                        },
                    },
                    {
                        name: "password_confirmation",
                        type: "password",
                        required: true,
                        label: "Confirm Password",
                        wrapperClass: "md:col-span-2",
                        validate: ["same:password"],
                    },
                    {
                        name: "handler",
                        type: "hidden",
                        value: "funding",
                    },
                    {
                        name: "agree_to_terms",
                        label: false,
                        required: true,
                        teleport: "register-terms",
                        type: "checkbox",
                        wrapperClass: "md:col-span-2",
                        text: "I accept the Terms of Service",
                        attributes: {
                            id: "agree_to_terms", 
                        }
                    },
                ],
            },
        };
    },

    computed: {
        cardTitle() {
            return this.created === true
                ? "Please Validate your Email"
                : "Create an Account to Get Started";
        },
    },

    methods: {
        /**
         * Handle a successful response
         */
        registerSuccess() {
            this.created = true;
        },

        /**
         * Handle an error response
         */
        registerError(error) {
            // let the form handle validation errors
            if (error?.status === 422) {
                return;
            }

            // If we get error 403 it means that we got blocked by the country IP
            if (error?.status === 403) {
                this.created = false;
                this.errorMessage = "We are sorry, but this website is only available for users in The United States";
                return;
            }

            if(error?.data?.errors?.message){
                this.created = false;
                this.errorMessage = error.data.errors.message;
                return;
            }

            this.created = false;
            this.errorMessage = "There was a problem creating this account";
        },

        /**
         * Clear our results upon resending
         */
        resetResults() {
            this.created = null;
            this.errorMessage = null;
        },
        onUpdated({ name, value }) {
            if (name === "password") {
                this.password = value;
            }
        },
    },
};
</script>
