<template>
    <!-- main wrapper -->
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="text-left grid form-element"
            v-bind="field.wrapperAttributes"
            :class="field.wrapperClass"
        >
            <!-- label -->
            <form-label :field="field" :required="isRequired"></form-label>

            <!-- teleport target -->
            <form-teleport :teleport="field.teleport"></form-teleport>

            <!-- radio set -->
            <div
                class="mt-2 order-3"
                :class="computedClasses"
                v-bind="field.attributes"
                v-bind:[dataName]="`${field.name}--options`"
            >   
                <!-- Regular options rendering -->
                <template v-if="field.options?.length && !field.groupBy">
                    <component
                        :is="optionComponent"
                        v-for="option in field.options"
                        :option="option"
                        :field="field"
                        defaultCheckedIcon="fad fa-circle-dot"
                        defaultUncheckedIcon="fa-regular fa-circle"
                        :key="option.value"
                        :modelValue="modelValue"
                        :disabled="disabled"
                        @updated="handleInput"
                    />
                </template>

                <!-- Rendering group by value -->
                <template v-if="field.options?.length && field.groupBy">
                    <div v-for="(groupOptions, groupName) in groupedOptions" :key="groupName">
                        <h3>{{groupName}}</h3>
                        <div class="flex flex-wrap gap-4">
                            <component
                                :is="optionComponent"
                                v-for="option in groupOptions"
                                :option="option"
                                :field="field"
                                defaultCheckedIcon="fad fa-circle-dot"
                                defaultUncheckedIcon="fa-regular fa-circle"
                                :key="option.value"
                                :modelValue="modelValue"
                                :disabled="disabled"
                                @updated="handleInput"
                            />
                        </div>
                    </div>
                </template>
                
                <div v-if="!field.options?.length"  class="p-3 col-span-full border rounded text-center bg-gray-50 text-gray-500" v-html="noOptionsMessage"></div>
            </div>

            <!-- errors -->
            <form-error :error="errorText" :field="field" class="order-5"></form-error>
        </div>
    </transition>
</template>

<script>
import fieldMixin from "./mixins/fieldMixin";
import RadioOption from "./RadioOption.vue";
import RadioOptionImage from "./RadioOptionImage.vue";
export default {
    mixins: [fieldMixin],
    components: {
        RadioOption,
        RadioOptionImage
    },

    methods: {
        handleInput(value){
            this.clearErrors();
            this.updateValue(this.field.name, value);
        }
    },

    computed: {
        computedClasses() {
            let classes = "";

            if (this.field.class) {
                classes += ` ${this.field.class}`;
            }

            if (this.field.attributes?.class) {
                classes += ` ${this.field.attributes.class}`;
            }
            classes += " grid gap-3"

            return classes;
        },
        optionComponent() {
            if (this.field.imaged) {
                return 'radio-option-image'
            } else {
                return 'radio-option';
            }
        },
        noOptionsMessage() {
            return this.field.noOptionsMessage ?? "No options available..."
        },
        groupedOptions() {
            if (!this.field.groupBy) return {};
            return this.field.options.reduce((groups, item) => {
                const groupBy = item[this.field.groupBy];
                if (!groupBy) return groups;
                const group = (groups[groupBy] || []);
                group.push(item);
                groups[groupBy] = group;
                return groups
            }, {});
        }
    },
};
</script>
