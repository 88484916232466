<template>
    <div>
        <!-- application filters -->
        <ApplicationsFilters @clearFilters="$emit('clearFilters')"></ApplicationsFilters>

        <!-- top pagination -->
        <Pagination storeName="applications">
            <action-button
                icon="file-csv"
                title="Export this table to csv file. Max: 1000"
                @click="exportCsvFile"
            ></action-button>
        </Pagination>

        <!-- practice alert -->
        <practice-alert class="mt-4" v-if="show_only_practice">These are practice applications</practice-alert>

        <!-- main table -->
       <privacy-mode-controls />

        <div class="flex justify-center my-5 border rounded overflow-hidden">
            <div
                class="w-full overflow-auto"
                ref="tableContainer"
                v-dragscroll
                @dragscrollstart="isDragging = true"
                @dragscrollend="isDragging = false"
            >
                <table class="w-full" :class="cursor">
                    <thead>
                        <tr class="border-b">
                            <th class="whitespace-nowrap text-left py-2 px-4">
                                Actions
                            </th>

                            <!-- build table header -->
                            <application-table-header-column 
                                v-for="column in columns"
                                :key="column.value"
                                :sort="sort"
                                :column="column"
                                @click="onClickHeader(column.value)"
                            />
                            
                        </tr>
                    </thead>
                    <tbody>
                        <!-- table rows -->
                        <application-table-row
                            v-for="application in applications"
                            :key="application.id"
                            :application="application"
                            :privacyMode="privacyMode"
                            :privacyException="privacyException"
                            class="border-b"
                        />
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <!-- bottom pagination -->
    <Pagination
        storeName="applications"
    ></Pagination>
</template>

<script>
import ApplicationTableRow from "@/components/dashboard/ApplicationTableRow.vue";
import ApplicationTableHeaderColumn from "@/components/dashboard/ApplicationTableHeaderColumn.vue";
import ApplicationsFilters from "@/components/dashboard/ApplicationsFilters";
import statesOptions from "@/services/forms/StatesOptions.js";
import ActionButton from "@/components/global/buttons/ActionButton";
import queryService from "@/services/utils/queryService";
import PrivacyModeControls from "@/components/dashboard/PrivacyModeControls";
import downloadFile from "@/services/utils/downloadFile";

export default {
    components: {
        ActionButton,
        ApplicationTableRow,
        ApplicationsFilters,
        ApplicationTableHeaderColumn,
        PrivacyModeControls,
    },
    emits: ['clearFilters'],
    data() {
        return {
            isDragging: false,
            searchDisabled: true,
            columns: [
                { value: 'alias' },
                { value: 'name' },
                { value: 'state' },
                { value: 'dob', text: 'DOB' },
                { value: 'status' },
                { value: 'type' },
                { value: 'priority' },
                { value: 'created_at', text: 'Started' },
                { value: 'updated_at', text: 'Updated' },
                { value: 'latest_submission_date', text: 'Submitted to PRC-S' },
            ],
        };
    },

    methods: {
        onClickHeader(name) {
            if (this.sort.indexOf(name) == -1) {
                this.$store.commit("applications/setSort", "-" + name);
            } else if (
                this.sort.indexOf(name) > -1 &&
                this.sort.indexOf("-") > -1
            ) {
                this.$store.commit("applications/setSort", name);
            } else {
                this.$store.commit("applications/setSort", "-" + name);
            }
            this.$store.dispatch("applications/loadAll");
        },
        startSearch() {
            this.$store.dispatch("applications/loadAll");
        },
        goToApplication() {
            this.$store.dispatch(
                "createApplication/setStartingNewApplication",
                true
            );
            this.$router.push({ name: "application.create" });
        },

        async exportCsvFile() {
            const endpoint = queryService.applicationQuery( 'funding/export/application-table-csv', this.$store.getters['applications/applicationsParams'] );
            const response = await this.$http.get(endpoint, {
                successNotification: false
            });
            downloadFile(response.data, "App_Table_Export.csv", {csv: true});
        },
    },
    computed: {
        privacyMode(){
            return this.$store.getters.privacyMode;
        },
        privacyException(){
            return this.$store.getters.privacyException;
        },
        cursor() {
            return this.isDragging ? "cursor-grabbing" : "cursor-grab";
        },
        all_states() {
            return statesOptions;
        },
        states: {
            get() {
                return this.$store.getters["applications/states"];
            },
            set(value) {
                this.$store.commit("applications/setStates", value);
            },
        },
        applications() {
            return this.$store.getters["applications/all"];
        },
        sort() {
            return this.$store.getters["applications/sort"];
        },
        searchInputTooShort(){
            return this.search?.length < 2;
        },
        search_options: {
            get() {
                return this.$store.getters["applications/search_options"];
            },
            set(value) {
                this.$store.commit("applications/setSearchOptions", value);
            },
        },
        search: {
            get() {
                return this.$store.getters["applications/search"];
            },
            set(value) {
                this.$store.commit("applications/setSearch", value);
            },
        },
        show_only_practice() {
            return this.$store.getters["applications/show_only_practice"];
        },    
    },
};
</script>

<style scoped>
th {
    cursor: pointer;
}
th i {
    margin-left: 8px;
}
</style>
