<template>
    <pending-invites-modal />
    <task-modal />
</template>

<script>
import PendingInvitesModal from "@/components/invites/PendingInvitesModal";
import TaskModal from "@/components/task/TaskModal.vue";


export default {
    components: {
        TaskModal,
        PendingInvitesModal,
    },
}
</script>
