<template>
    <div class="flex justify-center items-center text-xs bg-gray-100 py-2 text-blue-700 mb-2">
        <button class="underline" @click="showStatusDescriptions = true">
            <i class="fad fa-circle-info"></i> Status Definitions
        </button>
        <base-modal 
            :open="showStatusDescriptions"
            title="Status Descriptions"
            @close="showStatusDescriptions = false"
            class="xm:w-md"
            icon="circle-info"
        >
            <div class="">
                <div v-for="status in definitions" :key="status.name" class="odd:bg-blueGray-50 p-4">
                    <span class="uppercase font-bold">{{ status.name }}</span>:
                    {{ status.text }}
                </div>
            </div>
        </base-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showStatusDescriptions: false,
            definitions: [
                { name: 'In Progress', text: 'Required documents have not been provided.  The application has not been submitted to PRC-Saltillo for review.' },
                { name: 'Completed', text: "All documents have been added to the application, however, the application has not been submitted to PRC-Saltillo.  Please hit the green “Review for Submission” button." },
                { name: 'Rejected', text: "PRC-Saltillo has reviewed the information and determined that additional information is required." },
                { name: 'Under Review', text: "PRC-Saltillo is reviewing the application." },
                { name: 'Accepted', text: "PRC-Saltillo has reviewed the documents and accepted application." },
                { name: 'Insurance Processing', text: "The application is processing with the insurance company." },
                { name: 'Order Confirmation Processing', text: "PRC-Saltillo is awaiting final confirmation of the order from the Medical Decision Maker.  The Medical Decision Medical needs to review and “Accept” or “Reject” the confirmation." },
                { name: 'Order Processing', text: 'Order has been placed with the PRC-Saltillo Manufacturing team and is awaiting build and shipment.' },
                { name: 'Shipped', text: 'The equipment has shipped!  Tracking information will be available within 24 hours.' },
            
            ],
        };
    },
}
</script>

<style>

</style>