<template>
    <div
        class="ft-card border border-gray-300 rounded-lg overflow-hidden"
        :class="{ 'shadow': !hideShadow, 'mb-5': !noMargin }"
    >
        <div class="h-full">
            <div
                v-if="$slots.header && !(title || icon) && !hideHeader"
                class="ft-card__header py-3 px-5 flex items-center justify-between border-b bg-gray-50"
                :class="headerClass"
            >
                <slot name="header"></slot>
            </div>
            <div
                v-else-if="!hideHeader"
                class="ft-card__header py-3 px-5 flex items-center justify-between border-b"
                :class="titleClass"
            >
                <div class="flex items-center justify-center text-3xl">
                    <div v-if="icon" :class="titleIconClass" class="mr-3">
                        <i :class="`fa-${icon} ${iconStyle}`"></i>
                    </div>
                    <div v-if="title" class="text-xl leading-tight capitalize">
                        {{ title }}
                    </div>
                </div>
                
                <slot name="title"></slot>
            </div>
            <div
                class="ft-card__body bg-white h-full relative"
                :class="{ 'py-5 px-5': !noBodyPadding }"
            >
                <slot></slot>
            </div>
            <div
                v-if="$slots.footer"
                class="ft-card__footer py-5 px-5 border-t bg-gray-50 flex items-center justify-around"
            >
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        "title": {},
        "icon": {},
        "hideShadow": {},
        "noBodyPadding": {},
        "noMargin": {},
        "hideHeader": {},
        "headerClass": {},
        "titleIconClass": {},
        "iconStyle": {
            default: "fad",
        },
        "titleRole": {},
    },
    computed: {
        titleClass(){
            let classes = "";

            if(this.headerClass){
                classes += this.headerClass + " ";
            }

            switch (this.titleRole) {
                case "warning":
                    classes += " bg-yellow-100 text-yellow-800 border-yellow-300";
                    break;
                default:
                    classes += " bg-gray-50";
                    break;
            }

            return classes;
        },
    },
};
</script>

<style>
.theme--night .ft-card {
    @apply border-gray-900;
    @apply text-gray-300;
}

.theme--night .ft-card__header {
    background: rgba(0, 0, 0, 0.6);
    @apply border-gray-800;
}

.theme--night .ft-card__body {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5));
    @apply text-blueGray-300;
}

.theme--night .ft-card__footer {
    background: rgba(0, 0, 0, 0.6);
    @apply border-gray-800;
}

.theme--night .ft-card__footer .button--underline {
    @apply text-blueGray-400;
}
</style>
