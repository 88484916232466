import rotateTokensService from "../rotateTokensService";
export default (http, { store }) => {
    // return successful response
    const responseCallback = (response) => {
        return response;
    };

    // for each failed response if we get a 401 error while the toolkit still thinks we are logged in, then attempt to use the refresh token to rotate and fetch a new access token. Failures from the rotation result in logout.
    const errorCallback = (error) => {
        if (error?.response?.status === 401 && store.getters.loggedIn) {
            if(!error.request.responseURL.includes('rotate-tokens')){
                let pendingRequests  = store.getters.pendingRequests;
                let originalRequest = error.config;
                //pending go here while we rotate the tokens
                const requestPromise =  new Promise((resolve, reject) =>{
                    pendingRequests.push({resolve, reject, originalRequest});
                });
                store.commit('setPendingRequests', pendingRequests);
                rotateTokensService.rotateToken();
                return requestPromise;
            }
        }

        return Promise.reject(error);
    };

    // apply response interceptors
    http.interceptors.response.use(responseCallback, errorCallback);
};
