<template>
    <section class="grid gap-3">
        <!-- sidebar header -->
        <sidebar-header
            :count="count"
            :title="title"
            :showAddItem="showAddItem"
            @addItem="$emit('addItem')"
        >
            <template #sidebar-header-button>
                <slot name="sidebar-header-button"></slot>
            </template>
        </sidebar-header>

        <div v-if="showViewAll" class="-mt-4 -mb-1">
            <a 
                class="cursor-pointer mt-2 py-1 px-2 rounded flex items-center text-xs w-fit border border-secondary-700" 
                @click="$emit('viewAll')"
            >
                <span >View All</span>
                <span v-if="viewAllCount > 0" class="ml-2 rounded text-secondary-400 font-bold">{{ viewAllCount }}</span>
            </a>
        </div>


        <!-- sidebar main content -->
        <ul class="grid gap-2" v-if="!shouldHide">
            <slot></slot>
        </ul>
        <div v-else class="flex flex-col items-center gap-4 p-6 pb-10 bg-headerBlue/50 rounded-lg">
            <span class="text-sm">hidden in privacy mode</span>
            <span class="opacity-60">
                <i class="fad fa-eye-slash text-8xl"></i>
            </span>
            
        </div>

        <!-- view more / less -->
        <div class="flex gap-3 items-center justify-center" v-if="!shouldHide">
            <!-- view more button -->
            <div
                v-if="showViewMore"
                class="text-center cursor-pointer text-sm"
                @click="$emit('viewMore')"
            >
                more <i class="fad fa-circle-plus text-xxs"></i>
            </div>

            <!-- view less button -->
            <div
                v-if="showViewLess"
                class="text-center cursor-pointer text-sm"
                @click="$emit('viewLess')"
            >
                less <i class="fad fa-circle-minus text-xxs"></i>
            </div>
        </div>

    </section>
</template>

<script>
import SidebarHeader from './SidebarHeader.vue';

export default {
    components: {
        SidebarHeader,
    },
    props: {
        count: {
            type: Number,
        },
        title: {
            type: String,
            required: true,
        },
        showViewMore: {
            type: Boolean,
            default: false,
        },
        showViewLess: {
            type: Boolean,
            default: false,
        },
        showAddItem: {
            type: Boolean,
            default: true,
        },
        showViewAll: {
            type: Boolean,
            default: false,
        },
        viewAllCount: {
            type: Number,
            default: 0,
        },
        hideInPrivacyMode: {
            type: Boolean,
            default: false,
        },
    },

    emits: [
        'viewMore',
        'viewLess',
        'addItem',
        'viewAll',
    ],

    computed: {
        shouldHide(){
            return this.hideInPrivacyMode && this.$store.getters.privacyMode;
        }
    }
}
</script>