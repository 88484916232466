/**
 *
 * Auth middleware protects routes from logged out users
 *
 */

export default ({ to, redirect, store }) => {
    const options = {};
    if (!store.getters.loggedIn && !to.meta?.public) {
        // add our redirect if needed
        if (to.name !== "dashboard") {
            options.redirect = to.name;
            
            if(to.params && Object.keys(to.params).length){
                options.params = JSON.stringify(to.params); 
            }
        }

        redirect({ name: "login", query: options });
    }
    //if accessToken is missing from local storage and user is logged in, then an other browser tab must have logged out and we must logout everywhere
    if(store.getters.loggedIn && localStorage.getItem("accessToken") === null){
        if (to.name !== "dashboard") {
            options.redirect = to.name;
            
            if(to.params && Object.keys(to.params).length){
                options.params = JSON.stringify(to.params); 
            }
        }
        store.dispatch("logout", options);
    }
};
