<template>
    <div class="flex items-center gap-3 border border-blueGray-200 p-2 rounded border-l-6">
        <span class="text-baseText mr-auto">{{ preset.name }}</span>
        
        <!-- rename button -->
        <secondary-button
            class="!text-xs"
            size="small"
            @click="openRename = true"
            icon="edit"
        >
            rename
        </secondary-button>

        <!-- delete button -->
        <confirm-button 
            class="text-red-800 !text-xs"
            icon="trash"
            size="small"
            message='Are you sure you want to delete this preset?'
            @confirmed="onDelete"
        >
            delete
        </confirm-button>

        <base-modal
            :open="openRename"
            @close="openRename = false"
            class="w-xs"
            title="Rename your preset"
            icon="edit"
        >
            <quick-form 
                :schema="schema"
                @success="onEditName"
                ref="form"
            />
        </base-modal>
    </div>
</template>

<script>

export default {
    emit: ['close'],

    props: {
        preset: {
            type: Object,
            default: () => {},
        },
        type: {
            type: String,
            required: true,
        },
    },

    data(){
        return {
            openRename: false,
        };
    },  

    methods: {
        onDelete(){
            this.$http.delete(`funding/filter-preset/${this.preset.id}`)
                .then(({data}) => {
                    this.$store.dispatch("setFilterPresets", data);
                });
        },  

        onEditName({ inputs }){
            let data = {
                filters: this.preset.filters,
                name: inputs.name,
            };

            this.$http.patch(`funding/filter-preset/${this.preset.id}`, data)
                .then(({data}) => {
                    this.$store.dispatch("setFilterPresets", data);
                    this.openRename = false;
                });
        },
    },

    computed: {
        schema(){
            return {
                resetOnSuccess: false,
                fields: [
                    {
                        name: "name",
                        label: "Preset Name",
                        value: this.preset.name,
                        required: true,
                        attributes: {
                            maxlength: "25",
                        },
                        validate: ["scaryChars"]
                    },
                ],
            };
        },
    },
}
</script>

<style>

</style>