//import router from "@/router/index";

// manages our roles
export default {
    state() {
        return {
            baseRoles: [
                {
                    name: "CLIENT",
                    text: "Person who uses AAC",
                },
                {
                    name: "CLIENT_MDM",
                    text: "Person who uses AAC and is Medical Decision Maker",
                },
                {
                    name: "MDM",
                    text: "Medical Decision Maker for person who uses AAC",
                },
                {
                    name: "EVALUATING_SLP",
                    text: "Evaluating Speech-Language Pathologist (SLP) of the person who uses AAC",
                },
                {
                    name: "SLP_ASSISTANT",
                    text: "Administrative assistant acting on behalf of SLP",
                },
                {
                    name: "ADDITIONAL_SLP",
                    text: "Additional SLP of the person who uses AAC",
                },
                {
                    name: "CLINICAL_SLP_A",
                    text: "Clinical SLP Assistant of the person who uses AAC",
                },
                {
                    name: "OTHER_MEDICAL",
                    text: "Other therapist or healthcare provider of person who uses AAC",
                },
            ],
            otherRoles: [
                {
                    name: "OTHER_CAREGIVER",
                    text: "Other family member/caregiver of person who uses AAC",
                },
                {
                    name: "OTHER",
                    text: "Other role",
                },
            ],
            internalRoles: [
                {
                    name: "EMPLOYEE",
                    text: "PRC-Saltillo Employee",
                    required_role: "funding_admin",
                    assignable: false,
                },
                {
                    name: "CONSULTANT",
                    text: "PRC-Saltillo Consultant",
                    required_role: "funding_admin",
                    assignable: true,
                },
                {
                    name: "FUNDING_SPECIALIST",
                    text: "PRC-Saltillo Funding Specialist",
                    required_role: "funding_superadmin",
                    assignable: true,
                },
                {
                    name: "FUNDING_TRIAL_SPECIALIST",
                    text: "PRC-Saltillo Trial Program Specialist",
                    required_role: "funding_trial_admin",
                    assignable: true,
                },
                {
                    name: "LEGAL_DEPT",
                    text: "PRC-Saltillo Legal Dept",
                    required_role: "funding_admin",
                    assignable: true,
                },
            ],
        };
    },

    actions: {
    },

    getters: {
        allRoles(_, __, rootState) {
            return rootState.roles;
        },
        roleById(_, __, rootState) {
            return (id) => {
                return rootState.roles[id];
            }
        },
        roleByName(_, __, rootState) {
            return (name) => {
                return Object.values(rootState.roles).find(role => role.name === name) ?? {};
            }
        },
        roleIdByName(_, __, rootState) {
            return (name) => {
                return Object.values(rootState.roles).find(role => role.name === name)?.id ?? 0;
            }
        },
        roleNameById(_, __, rootState) {
            return (id) => {
                return rootState.roles[id]?.name;
            }
        },
        roleTextById(_, __, rootState) {
            return (id) => {
                return rootState.roles[id]?.text;
            }
        },
        roleTextByName(_, __, rootState) {
            return (name) => {
                return Object.values(rootState.roles).find(role => role.name === name)?.text;
            }
        },
        allBaseRoles(state, __, rootState) {
            return state.baseRoles.map(baseRole => ({
                ...baseRole,
                value: Object.values(rootState.roles).find(rootRoles => rootRoles.name === baseRole.name)?.id ?? 0
            }));
        },
        allOtherRoles(state, __, rootState) {
            return state.otherRoles.map(otherRole => ({
                ...otherRole,
                value: Object.values(rootState.roles).find(rootRoles => rootRoles.name === otherRole.name)?.id ?? 0
            }));
        },
        allInternalRoles(state, __, rootState) {
            return state.internalRoles.map(internalRole => ({
                ...internalRole,
                value: Object.values(rootState.roles).find(rootRoles => rootRoles.name === internalRole.name)?.id ?? 0
            }));
        },
        internalRoleByName(state) {
            return (name) => {
                return state.internalRoles.find(role => role.name === name) ?? {};
            }
        },
        roleIsInternal(state) {
            return (name) => {
                return state.internalRoles.some(role => role.name === name);
            }
        },
    },
};
