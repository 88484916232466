<template>
    <base-main>
        <base-card
            title="Login"
            icon="user-circle"
            class="w-full md:flex-grow-0 md:w-xss"
        >
            <!-- error messages -->
            <error-alert v-if="errorMessage" id="login-error" class="mb-5">
                
                <!-- password expired error --> 
                <template v-if="errorMessage === 'expiredPassword'">
                    Password expired. Please set a new password <router-link :to="{ name: 'sendPasswordReset' }" class="link--error-alert">here</router-link>.
                </template>
                
                <!-- other errors -->
                <template v-else>
                    {{ errorMessage }}
                </template>

            </error-alert>

            <!-- verify MFA -->
            <div v-if="verifyMFA">
                <div v-if="verifyMFA" class="border p-2 rounded-md">
                    <p class="text-sm text-center">
                        A verification code has been sent to your email. Didn't
                        get it?
                        <base-button
                            v-if="!MFACodeResent"
                            class="text-primary"
                            @click="resendMFACode"
                        >
                            Click here to resend.
                        </base-button>
                        <span v-else>
                            Resend again in {{ countdownResendMFA }}
                        </span>
                    </p>
                </div>
                <br />
            </div>

            <!-- login form -->
            <quick-form
                v-if="!verifyMFA"
                ref="loginForm"
                id="login-form"
                :schema="schema"
                class="w-full"
                @success="loginSuccess"
                @error="loginError"
                @submit="errorMessage = null"
            />

            <!-- login mfa form -->
            <quick-form
                v-else
                ref="loginMFAForm"
                id="login-mfa-form"
                :schema="schemaMFA"
                class="w-full"
                @success="loginSuccess"
                @error="loginError"
                @submit="errorMessage = null"
            >
                <template #buttons>
                    <secondary-button @click.prevent="showLoginForm">
                        Go Back
                    </secondary-button>
                </template>
            </quick-form>

            <!-- footer -->
            <template #footer>
                <router-link
                    :to="{ name: 'register' }"
                    class="underline text-sm"
                >
                    <span id="register-link">Register</span>
                </router-link>
                <a role="button" class="underline text-sm" @click="loginTroubleShow = true">Trouble Logging In?</a>
                <router-link
                    :to="{ name: 'sendPasswordReset' }"
                    class="underline text-sm"
                >
                    <span id="reset-password">Forgot password?</span>
                </router-link>
            </template>
        </base-card>
        <base-modal
            :open="loginTroubleShow"
            @close="loginTroubleShow = false"
            class="w-lg"
            title="Login Trouble"
            icon="question"
        >
            <h2>Having trouble logging in?</h2>
            <br />
            <ul>
                <li>Please make sure you are using your PRC-Saltillo account credentials. These same credentials are used for logging into The AAC Language Lab, Realize Language, AAC Learning Journey etc.</li>

                <li>If you are seeing this error: <b>"The credentials do not match our records."</b>
                    <ul>
                        <li>This error message indicates that either you do not have an account associated with the login used, or the password is incorrect. Please try to first <b>reset your password</b> by clicking on the "Forgot password?" link. Please check your junk/spam folders as well as your primary inbox for the password reset email.</li>

                        <li>If you do not receive a reset your password email within a few minutes, please <b>create a new account</b>. To do this, you can either click on the "Register" link on the login screen, or the "Create an account" link on the homepage.</li>
                    </ul>
                </li>

                <li>If you are seeing this error when creating a new account: <b>"There was a problem creating this account."</b>
                    <ul>
                        <li>This error message may indicate that you have tried to register for a new account using an email that is already associated with an existing account. Please try to <b>reset your password</b> using that email address. You can reset your password by clicking on the "Forgot password?" link. Please check your junk/spam folders as well as your primary inbox for the password reset email.</li>
                    </ul>
                </li>
            </ul>
            <p>If you still cannot log into your account, please email <a class="underline" href="mailto:funding@prc-saltillo.com?subject=Trouble Logging In">funding@prc-saltillo.com</a></p>

            <div class="flex items-center justify-end gap-3">
                <primary-button @click="loginTroubleShow = false">OK</primary-button>
            </div>
        </base-modal>

        <!-- primaryNotice -->
        <base-card
            :title="notice.title"
            icon="siren-on"
            class="w-full md:flex-grow-0 md:w-xss"
            headerClass="!bg-status_UNDER_REVIEW/60"
            titleIconClass="text-white"
            v-if="notice.showOnLogin"
        >
            <div v-html="notice.message" class=""></div>
        </base-card>
    </base-main>
</template>

<script>
export default {
    data() {
        return {
            verifyMFA: false,
            MFACodeResent: false,
            countdownResendMFA: 0, // seconds
            userLogin: null,
            userPassword: null,
            errorMessage: null,
            loginTroubleShow: false,
            schema: {
                name: "login",
                endpoint: "authenticate",
                config: {
                    errorNotification: false,
                },
                fields: [
                    {
                        name: "login",
                        label: "Username, Email, or ASHA#",
                        required: true,
                        hideRequiredAsterisk: true,
                        validate: ["lengthBetween:3,255"],
                    },
                    {
                        name: "password",
                        type: "password",
                        hideRequiredAsterisk: true,
                        required: true,
                    },
                    {
                        name: "device_uuid",
                        type: "hidden",
                        value: this.$store.getters["deviceUUID/get"],
                    },
                    {
                        name: "handler",
                        type: "hidden",
                        value: "funding",
                    },
                ],
            },
        };
    },

    computed: {
        notice() {
            return this.$store.getters.primaryNotice;
        },
        schemaMFA() {
            return {
                name: "mfa",
                endpoint: "authenticate",
                config: {
                    errorNotification: false,
                },
                fields: [
                    {
                        name: "login",
                        type: "hidden",
                        value: this.userLogin,
                    },
                    {
                        name: "password",
                        type: "hidden",
                        value: this.userPassword,
                    },
                    {
                        name: "mfa_code",
                        label: "Verification Code",
                        description: "Enter the 6 digit code",
                        required: true,
                        validate: ["integer", "length:6"],
                    },
                    {
                        name: "device_uuid",
                        type: "hidden",
                        value: this.$store.getters["deviceUUID/get"],
                    },
                    {
                        name: "remember_device",
                        type: "checkbox",
                        value: "1",
                        text: "Remember this device",
                    },
                    {
                        name: "handler",
                        type: "hidden",
                        value: "funding",
                    },
                ],
            };
        },
    },

    methods: {
        showLoginForm() {
            this.verifyMFA = false;
            this.userLogin = null;
            this.userPassword = null;
            this.errorMessage = null;
        },

        showLoginMFAForm(userLogin, userPassword) {
            this.verifyMFA = true;
            this.userLogin = userLogin;
            this.userPassword = userPassword;
        },

        loginSuccess(response) {
            /**
             * Set device UUID to bypass MFA
             */
            if (response?.data?.deviceUUID) {
                this.$store.commit("deviceUUID/set", {
                    deviceUUID: response.data.deviceUUID,
                });
            }

            /**
             * Abort if we are missing the access token
             */
            if (!response?.data?.accessToken) {
                this.errorMessage = "Unable to log in, missing access token";
                return;
            }

            // make sure our invites modal is shown if we have invites present
            this.$store.dispatch("invites/setHideModal", false);

            /**
             * Login
             */
            this.$store.dispatch("login", {
                tokens: { 
                    accessToken: response?.data?.accessToken,
                    refreshToken: response?.data?.refreshToken, 
                },
                redirect: this.$route.query.redirect ?? "dashboard",
                params: this.$route.query.params,
            });
        },

        loginError(error) {
            // expired password
            if(error.status === 451) {
                return this.errorMessage = "expiredPassword";
            }

            /**
             * MFA step
             */
            if (error.status === 401) {
                /**
                 * An "MFA Required" response with status 401 does not throw an error message like the other scenarios
                 * It prompts the MFA form
                 * No customer facing error code will be provided in this case
                 */
                if (error.data.errors.message === "MFA Required") {
                    this.showLoginMFAForm(
                        error.inputs.login,
                        error.inputs.password
                    );
                    return;
                }
                if(error?.data?.errors?.message !== "MFA Required"){
                    this.errorMessage = error.data.errors.message;
                    return;
                }
            }

            if (error.status === 403) {

                /**
                 * 403 Status can either be unverified email or Country IP Blocked
                 * We assume the Country IP Blocked response if the default 403 forbidden is returned
                 * Otherwise the email must be unverified and we display that message provided by the api
                 */
                if(error?.data?.errors?.message === "Forbidden"){
                    this.errorMessage = "We are sorry, but this website is only available for users in The United States";
                    return;
                }

                if(error?.data?.errors?.message !== "Forbidden"){
                    //this.resendVerificationEmail(error.inputs.login);
                    this.errorMessage = error.data.errors.message;
                    return;
                }
            }

            /**
             * Throttling
             */
            if (error.status === 429) {
                this.errorMessage =
                    "Too many login attempts. Please try again later.";
                return;
            }

            /**
             * Default
             */
            this.errorMessage = "There was an problem logging in.";
        },

        async resendMFACode() {
            this.MFACodeResent = true;
            this.restartCountdownResendMFA();
            try {
                await this.$http.post(
                    "authenticate",
                    {
                        login: this.userLogin,
                        password: this.userPassword,
                        device_uuid: this.$store.getters["deviceUUID/get"],
                    },
                    { silent: true }
                );
            } catch (error) {
                if (
                    error?.response?.status === 401 &&
                    error?.response?.data?.errors?.message === "MFA Required"
                ) {
                    this.MFACodeResent = true;
                }
            }
        },

        restartCountdownResendMFA() {
            /**
             * This is not an accurate timer... do not extrapolate
             */
            this.countdownResendMFA = 59;
            let countdown = setInterval(() => {
                this.countdownResendMFA--;
                if (this.countdownResendMFA <= 0) {
                    this.MFACodeResent = false;
                    this.countdownResendMFA = 0;
                    clearInterval(countdown);
                }
            }, 1000);
        },

        async resendVerificationEmail(login) {
            this.$refs.loginForm.setSending(true);

            try {
                await this.$http.post(
                    "send-new-account-verification",
                    {
                        handler: "funding",
                        login: login,
                    },
                    { notifications: false }
                );

                this.$refs.loginForm.sending = false;
                this.errorMessage =
                    "This account has not been verified. A new verification email has been sent to the primary address for this account.";
            } catch (error) {
                this.$refs.loginForm.sending = false;
                this.errorMessage =
                    "This account has not been verified, and we were unable to send a new verification email to the primary address for this account.";
            }

            const note = {
                type: "error",
                message: "Unverified Account",
            };
            this.$store.dispatch("notifications/create", note);
        },
    },
};
</script>
<style scoped>
ul {
    padding-left:1.5em;
}
ul li {
    list-style-type:disc;
    margin-bottom:1em;
}
</style>