<template>
    <div 
        v-if="$route.name !== '404'" 
        class="flex items-center justify-center w-full py-2 px-8"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
