<template>
    <!-- wrapper -->
    <transition name="fade">
        <div
            v-if="!field.hide"
            class="form-element text-left w-full grid min-w-full text-lg"
            v-bind="field.wrapperAttributes"
            :class="field.wrapperClass"
        >
            <!-- label -->
            <form-label :field="field"></form-label>

            <!-- element -->
            <component 
                :is="tag" 
                v-bind="computedAttributes"
                v-on="events" 
                class="order-3"
                v-bind:[dataName]="`${field.name}--element`"
            >
                <span v-if="field.content" v-html="field.content"></span>

                <template
                    v-for="(content, name) in slots"
                    v-slot:[name]
                    :key="name"
                >
                    <span :key="name" v-html="content"></span>
                </template>
            </component>

            <!-- placeholder div for error, need it for some grid alignment stuff -->
            <div class="order-5"></div>

        </div>
    </transition>
</template>

<script>
import FormLabel from "./FormLabel.vue";

export default {
    props: ["field"],
    inject: ["dataName"],

    components: {
        FormLabel,
    },

    computed: {
        tag() {
            return this.field.tag ?? "div";
        },

        events() {
            return this.field.events ?? {};
        },

        slots() {
            return this.field.slots ?? {};
        },

        computedAttributes() {
            let attributes = this.field.attributes ?? {};
            let props = this.field.props ?? {};

            if(this.field.class){
                attributes.class = attributes.class ?? '';
                attributes.class += ' ' + this.field.class;
            }

            return {...attributes, ...props};
        }
    },
};
</script>

