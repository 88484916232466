<template>
    <button 
        class="rounded py-1 px-2 transition-all"
        :class="computedClasses" 
        @click="$emit('selectExpiringWindow', expiringWindow.value)"
    >
        {{ expiringWindow.text }}
    </button>
</template>
<script>
import isEqual from "lodash/isEqual";

export default {
    emits: ["selectExpiringWindow"],

    data() {
        return {
        };
    },
    
    props: {
        expiringWindow: {
            type: Object,
            required: true,
        },
        selectedWindow: {
            type: Number,
        },
        setTheme: {
            type: String,
            default: 'default',
        },
    },

    computed: {
        isSelected(){
            return isEqual(this.expiringWindow.value, this.selectedWindow);
        },

        computedClasses(){
            if(this.setTheme === "dark"){
                return [ 
                    "text-accentBlue",
                    "hover:bg-[#004887]", 
                    this.isSelected ? "bg-[#004887]" : "bg-blueGray-900/60", 
                ];
            }

            return [
                "hover:bg-secondary-100", 
                this.isSelected ? "bg-secondary-100" : "bg-gray-100",
            ];
        },
    },

    methods: {

    },
};
</script>
<style scoped>
</style>
