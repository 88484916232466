<template>
    <template v-if="isLoaded">
        <main
            class="relative app-main min-h-screen flex flex-col"
            :class="computedClasses"
            :style="computedStyles"
        >
            <override-lock-banner />
            <email-warning-banner :hasLoadedUser="hasLoadedUser" />

            <warning-banner
                v-if="appEnv && appEnv !== 'production'"
                class="text-center"
            >
                <p>{{ appEnv.toUpperCase() }} ENVIRONMENT</p>
            </warning-banner>
            <primary-nav />
            <router-view />
        </main>
        <primary-footer />

        <!-- utilities (after funding data and user loaded) -->
        <idle-timer />

        <!-- place future core modals in the AppModals component -->
        <app-modals />
    </template>

    <!-- utilities (before funding data and user loaded) -->
    <loading-spinner />
    <notification-queue />
    <loading-initial-data v-if="!$route.meta.public" />
</template>

<script>
import PrimaryNav from "./components/nav/PrimaryNav";
import PrimaryFooter from "./components/footer/PrimaryFooter.vue";
import LoadingSpinner from "./components/LoadingSpinner";
import NotificationQueue from "./components/notifications/NotificationQueue.vue";
import IdleTimer from "./components/IdleTimer.vue";
import LoadingInitialData from "./components/LoadingInitialData.vue";
import AppModals from "./components/AppModals.vue";
import OverrideLockBanner from "./components/banners/OverrideLockBanner.vue";
import EmailWarningBanner from "./components/banners/EmailWarningBanner.vue";

export default {
    components: {
        PrimaryNav,
        NotificationQueue,
        LoadingSpinner,
        IdleTimer,
        LoadingInitialData,
        PrimaryFooter,
        AppModals,
        OverrideLockBanner,
        EmailWarningBanner,
    },

    data() {
        return {
            hasLoadedUser: false
        };
    },

    async created() {
        this.$store.dispatch("loadData");
        await this.$store.dispatch("checkForLocalUser");
        this.hasLoadedUser = true;
    },

    computed: {
        userLoaded() {
            return !!this.$store.getters.user;
        },

        isLoaded() {
            if (this.$route.meta.public) {
                return this.hasLoadedUser;
            }

            return (
                this.hasLoadedUser &&
                !this.$store.getters.fundingDataFailed &&
                this.$store.getters.fundingDataLoaded
            );
        },

        computedClasses() {
            let classes = "";

            // check for themed pages (like the auth screens)
            if (this.$route.meta.theme) {
                classes += `theme--${this.$route.meta.theme}`;
            }

            return classes;
        },

        computedStyles() {
            if (this.$route.meta.theme === "night") {
                return {
                    "background-image": "url(/img/landing_background.svg)",
                };
            }

            return {};
        },
        appEnv() {
            return process.env.VUE_APP_NODE_ENV;
        },
    },
};
</script>

<style>
/*-------------------------------

    global styles & imports

--------------------------------*/

/* core */
@import "./css/variables.css";
@import "./css/animations.css";
@import "./css/main.css";

/* transitions */
@import "./css/transitions/fade.css";
@import "./css/transitions/slide-from-right.css";
@import "./css/transitions/overview-sections.css";
/* @import './css/transitions/slide-next.css'; */
/* @import './css/transitions/slide-back.css'; */

/* partials */
@import "./css/partials/links.css";
</style>

<style scoped>
/*-----------------------------------

    scoped styles for this component

-----------------------------------*/
.app-main.theme--night {
    background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: #283a65;
}
</style>
