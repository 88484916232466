<template>
    <li class="text-ellipsis overflow-hidden" v-if="!hidden">
        <span v-if="name" class="font-bold mr-1 inline-block" v-html="name"></span>
        <span v-html="value" class="inline-block" :class="item.class"></span>
    </li>
</template>

<script>
import startCase from "lodash/startCase";
import isObject from "@/services/utils/isObject.js";

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    
    computed: {
        hidden() {
            // Doing this weird null/undefined check in case the value is actually 0, and we want to show the 0
            return (this.item.value == null || this.item.value == undefined) && this.item.hideOnNull
        },
        name() {
            return this.item.label ?? startCase(this.item.name);
        },

        value() {
            let value = this.item.value;

            // convert arrays to comma separated strings
            if(Array.isArray(value)){
                value = value.join(", ");
            }

            // convert boolean values to yes/no
            if(this.item.boolToText){
                 if(value === 0 || value === "0"){
                    value = "no";
                 }

                 if(value === 1 || value === "1"){
                    value = "yes";
                 }
            }

            if(isObject(value)){
                let text = [];
                for(let key in value){
                    if(value[key]){
                        text.push(`${key}: ${value[key]}`);
                    }
                }

                value = text.length ? text.join(", ") : "--";
            }

            if(value === null || value === undefined || value === ""){
                value = "--";
            }

            // pass can text flag to phone numbers that need it
            if('canText' in this.item && value !== "--"){
                let text = this.item.canText ? "can text" : "no text";
                 value += ` <span class="text-amber-700">${text}</span>`;
            }

            // replace underscores with spaces
            if (!this.item.keep_underscores) {
                value = value.replaceAll("_", " ");
            }

            return value;
        }
    },

    methods: {
    
    },
};
</script>
<style scoped></style>

