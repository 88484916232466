/**
 *
 * Auth middleware protects routes from logged out users
 *
 */

export default ({ to, store, middlewareIncludes }) => {
    if (!middlewareIncludes(to, "forceLogout")) {
        return;
    }

    if (store.getters.loggedIn) {
        // if we are logged in force a logout without a redirect
        store.dispatch("logout", false);
    }
};
