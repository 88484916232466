<template>
    <base-alert type="info" 
        class="text-info-800 border-info-700 bg-info-200" 
        :icon="icon" 
        @close="$emit('close')"
    >
        <slot></slot>
    </base-alert>
</template>

<script>
export default {
    emits: ["close"],
    props: {
        icon: {
            default: "info-circle",
        },
    },
};
</script>
