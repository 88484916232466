import authMiddleware from "./authMiddleware";
import guestMiddleware from "./guestMiddleware";
import forceLogoutMiddleware from "./forceLogoutMiddleware";
import adminMiddleware from "./adminMiddleware";
import clearOverrideLockMiddleware from "./clearOverrideLockMiddleware";
import checkServiceWorkerMiddleware from "./checkServiceWorkerMiddleware";

export default [
    authMiddleware, 
    guestMiddleware, 
    forceLogoutMiddleware,
    adminMiddleware,
    clearOverrideLockMiddleware,
    checkServiceWorkerMiddleware,
];
