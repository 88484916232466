<template>
    <base-alert type="error" class="text-accent-700 border-accent-700 bg-accent-200" :icon="icon" @close="$emit('close')">
        <slot>
            This is a <span class="font-bold">practice</span> application
        </slot>
    </base-alert>
</template>

<script>
export default {
    emits: ["close"],
    props: {
        icon: {
            default: "bullseye-arrow",
        },
    },
};
</script>
