// import single views
import Dashboard from "@/views/Dashboard.vue";

//import route groups
import AuthRoutes from "@/router/routes/authRoutes.js";
import CreateApplicationRoutes from "@/router/routes/createApplicationRoutes.js";
import ApplicationRoutes from "@/router/routes/applicationRoutes.js";
import DevRoutes from "@/router/routes/devRoutes.js";
import AdminRoutes from "@/router/routes/adminRoutes.js";
import InboxRoutes from "@/router/routes/inboxRoutes.js";

export default [
    ...AuthRoutes,
    ...CreateApplicationRoutes,
    ...DevRoutes,
    {
        path: "/application",
        component: () => import("@/views/application/Application.vue"),
        children: [
            ...ApplicationRoutes
        ],
    },
    {
        path: "/admin",
        component: () => import("@/views/admin/Admin.vue"),
        children: [
            ...AdminRoutes
        ],
    },
    {
        path: "/inbox",
        children: [
            ...InboxRoutes
        ],
    },
    {
        path: "/help",
        name: "help",
        component: () => import("@/views/Help.vue"),
        meta: { public: true, transparentNav: true },
    },
    {
        path: "/invites",
        name: "invites",
        component: () => import("@/views/Invites.vue"),
    },
    {
        path: "/",
        name: "dashboard",
        component: Dashboard,
    },
    {
        path: "/account",
        name: "account",
        component: () => import("@/views/Account.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "404",
        component: () => import("@/views/404.vue"),
        meta: { public: true, transparentNav: true, hideFooter: true },
    },
];
