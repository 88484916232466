<template>
    <component :is="element" ref="observable">
        <slot></slot>
    </component>
</template>
<script>
export default {
    emits: ["observed", "unobserved"],
    props: {
        element: {
            type: String,
            default: "div",
        },
        threshold: {
            type: Number,
            default: 0,
        },
        margin: {
            type: String,
            default: "-50%",
        },
        identifier: {
            type: String,
        },
    },
    data() {
        return {
            observer: null,
        };
    },
    mounted() {
        this.initObserver();
    },
    computed: {
        computedMargin() {
            // split the array by spaces, so we can try and figure out
            // what we passed, there is probably a better way to do this
            const marginArray = this.margin.split(" ");

            // if we passed just one measurement (i.e. 50%) assume it was the bottom margin
            if (marginArray.length === 1) {
                return `0% 0% ${this.margin} 0%`;
            }

            // otherwise just pass back the given string
            return this.margin;
        },
    },
    methods: {
        /**
         * Initialize our intersection observer
         */
        initObserver() {
            this.observer?.disconnect();

            const options = {
                rootMargin: this.computedMargin,
                threshold: this.threshold,
            };

            this.observer = new IntersectionObserver(this.onObserver, options);

            this.observer.observe(this.$refs.observable);
        },

        /**
         * Handle the intersection observer callback
         */
        onObserver(entries) {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    this.$emit("observed", this.identifier);
                } else {
                    this.$emit("unobserved", this.identifier);
                }
            }
        },
    },
};
</script>
