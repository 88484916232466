<template>
    <!-- modal to display when we are nearing our logout time -->
    <base-modal :open="idle" :hideClose="true" icon="stopwatch" class="w-xs">
        <div class="">
            You have been idle for a while, if you don't resume activity you
            will be logged out shortly
        </div>
    </base-modal>
</template>

<script>
import throttle from "lodash/throttle";

export default {
    data() {
        return {
            // are we currently idle
            idle: false,

            // seconds to wait until logging the user out for inactivity (30 minutes)
            logoutTimeout: 60 * process.env.VUE_APP_IDLE_LOGOUT_MINUTES,

            // seconds to show the warning popup before logging out
            warningTimeout: process.env.VUE_APP_IDLE_WARNING_SECONDS,

            // holds our interval reference
            idleInterval: null,

            // the events we want to monitor to see if a user is idle
            monitoredEvents: [
                "keypress",
                "mousemove",
                "mousedown",
                "touchmove",
            ],
        };
    },

    watch: {
        // activate our monitor only when logged in
        "$store.getters.loggedIn": function (loggedIn) {
            if (loggedIn) {
                this.startTimer();
            } else {
                this.endTimer();
            }
        },
    },

    created() {
        // if we are already logged in when the app boots up, start the timer
        if (this.$store.getters.loggedIn) {
            this.startTimer();
        }
    },

    methods: {
        /**
         * Start our idle timer
         */
        startTimer() {
            this.setListeners();
            this.idleInterval = setInterval(this.checkTime, 1000);
        },

        /**
         * Set the event listeners we will use to determine if a user is taking actions
         */
        setListeners() {
            for (let event of this.monitoredEvents) {
                document.addEventListener(event, this.setExpiredTime, false);
            }
        },

        /**
         * Set our expired time in local storage
         */
        setExpiredTime: throttle(function () {
            //this fixes the issue with multiple tabs
            //dont set the idle timer unless we are actually logged in with an access token
            if(localStorage.getItem("accessToken")){
                localStorage.setItem(
                    "_idleTime",
                    Date.now() + this.logoutTimeout * 1000 + 1000
                );
            }
            
        }, 500),

        /**
         * Check how long we've been idle
         */
        checkTime() {
            const idleTime = parseInt(localStorage.getItem("_idleTime"), 10);

            // set our idle warning if we are within our warningTimeout
            this.idle = idleTime - this.warningTimeout * 1000 < Date.now();

            // logout if we are past our logoutTimeout
            if (idleTime < Date.now()) {
                this.handleLogout();
            }
        },

        /**
         * End our timer
         */
        endTimer() {
            this.removeListeners();
            localStorage.removeItem("_idleTime");
            this.idle = false;
            clearInterval(this.idleInterval);
        },

        /**
         * Remove our event listeners
         */
        removeListeners() {
            for (let event of this.monitoredEvents) {
                document.removeEventListener(event, this.setExpiredTime, false);
            }
        },

        /**
         * Logout our user
         */
        handleLogout() {
            let redirectOptions = { redirect: this.$route.name };

            // if we have any params add them to the redirect
            if(Object.keys(this.$route.params).length){
                redirectOptions.params = JSON.stringify(this.$route.params);
            }
            
            this.$store.dispatch("logout", redirectOptions);
        },
    },
};
</script>
