<template>
    <div class="flex flex-wrap justify-center my-0.5 p-2">
        <div class="flex items-center my-1 mx-2.5">
            <status-icon :status="getStatusId('NOT_STARTED')" />
            <p class="ml-1 text-xs whitespace-nowrap">Not Started</p>
        </div>
        <div class="flex items-center my-1 mx-2.5">
            <status-icon :status="getStatusId('IN_PROGRESS')" />
            <p class="ml-1 text-xs whitespace-nowrap">In Progress</p>
        </div>
        <div class="flex items-center my-1 mx-2.5">
            <status-icon :status="getStatusId('UNDER_REVIEW')" />
            <p class="ml-1 text-xs whitespace-nowrap">Under Review</p>
        </div>
        <div class="flex items-center my-1 mx-2.5">
            <status-icon :status="getStatusId('REJECTED')" />
            <p class="ml-1 text-xs whitespace-nowrap">Rejected</p>
        </div>
        <div class="flex items-center my-1 mx-2.5">
            <status-icon :status="getStatusId('COMPLETED')" />
            <p class="ml-1 text-xs whitespace-nowrap">Completed</p>
        </div>
    </div>
</template>

<script>
//import statusService from "@/services/statusService.js";
export default {
    computed: {
        getStatusId() {
            return this.$store.getters.getStatusId;
            //return statusService.id;
        },
    },
};
</script>
