<template>
    <li
        class="notify-item shadow relative text-white border-t-1 bg-gradient-to-br w-full tracking-wide rounded mt-2 flex items-stretch cursor-pointer"
        :class="computedClasses"
        @click="removeNote"
    >
        <!-- main content -->
        <div class="px-4 py-2 flex justify-center items-center flex-grow">
            <!-- icon -->
            <i class="text-2xl fas" :class="icon"></i>

            <!-- message-->
            <span
                v-if="note.message"
                class="notify-item__message hidden md:inline pl-2 text-ellipsis line-clamp-3 text-center"
                v-html="note.message"
            ></span>
        </div>

        <!-- close icon for persist notes -->
        <div
            v-if="note.persist"
            class="p-2 flex items-center bg-black bg-opacity-10"
        >
            <i class="fas fa-times opacity-8"></i>
        </div>
    </li>
</template>

<script>
export default {
    name: "notify-item",
    props: ["note"],

    methods: {
        /**
         *  remove this note from the queue
         */
        removeNote() {
            this.$store.dispatch("notifications/remove", this.note);
        },
    },

    computed: {
        // figure out our font awesome icon classname
        icon() {
            if (this.note.type === "error") {
                return "fa-exclamation-triangle";
            }

            if (this.note.type === "warning") {
                return "fa-exclamation-triangle";
            }

            if (this.note.type === "info") {
                return "fa-circle-info";
            }

            return "fa-check";
        },

        // set our note classes
        computedClasses() {
            let classes = [];
            classes = [...classes, ...this.colors];

            return classes;
        },

        // determine our color classes based on note type
        colors() {
            if (this.note.type === "error") {
                return ["border-red-500", "from-red-500", "to-rose-700"];
            }

            if (this.note.type === "warning") {
                return ["border-yellow-400",  "from-yellow-400", "to-yellow-600"];
            }

            if (this.note.type === "info") {
                return ["border-accentBlue-500",  "from-accentBlue-500", "to-accentBlue-700"];
            }

            return ["border-primary-500", "from-primary-500", "to-primary-700"];
        },
    },
};
</script>
