<template>
    <div class="flex items-center justify-center w-fit gap-2 sm:flex-row flex-col">
        <!-- title -->
        <div v-if="title" :class="titleClass">{{ title }}:</div>

        <!-- start date -->
        <div class="relative">
            <div class="text-center sm:hidden">From</div>
            <div class="relative">
                <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
                >
                    <svg
                        aria-hidden="true"
                        class="w-5 h-5 text-gray-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </div>
            
                <input
                    datepicker
                    type="date"
                    :value="start"
                    @input="$event => debounceInput('start', $event.target.value)"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5 w-[160px]"
                    :class="inputClass"
                    placeholder="Select date"
                    :max="maxDate"
                />
            </div>
        </div>

        <!-- arrow -->
        <i class="fa-regular fa-arrow-right hidden sm:inline"></i>
        
        <!-- end date -->
        <div class="relative">
            <div class="text-center sm:hidden">To</div>
            <div class="relative">
                <div
                    class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
                >
                    <svg
                        aria-hidden="true"
                        class="w-5 h-5 text-gray-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clip-rule="evenodd"
                        ></path>
                    </svg>
                </div>
                <input
                    datepicker
                    type="date"
                    :value="end"
                    @input="$event => debounceInput('end', $event.target.value)"
                    class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5 w-[160px]"
                    :class="inputClass"
                    placeholder="Select date"
                    :max="maxDate"
                />
            </div>
        </div>

        <!-- clear button -->
        <button title="reset this date range to its default values" size="small" class="text-xs cursor-pointer border reset-button border-gray-300 text-gray-900 rounded-md px-2 py-[3px]" @click="reset">reset</button>
    </div>
</template>

<script>
import dateService from '@/services/utils/dateFormatterService';
export default {
    data() {
        return {
            maxDate: dateService.formatForBackend(new Date()),
            debounceInterval: {
                start: null,
                end: null,
            },
            debounceTime: 750,
            initial: {
                start: null,
                end: null,
            },
        };
    },
    emits: ['update'],
    props: {
        start: {
            type: String,
        },
        end: {
            type: String,
        },
        title: {
            type: String,
        },
        titleClass: {
            type: String,
            default: 'text-xxs',
        },
        inputClass: {
            type: String,
        },
    },
    created(){
        this.initial.start = this.start;
        this.initial.end = this.end;
    },
    methods: {
        debounceInput(name, value){
            let updatedValues = { start: this.start, end: this.end };
            updatedValues[name] = value;

            if(this.debounceInterval[name]) {
                clearTimeout(this.debounceInterval[name]);
            }

            this.debounceInterval[name] = setTimeout(() => {
                this.$emit(`update`, updatedValues);
            }, this.debounceTime);
        },
        reset(){
            if(this.initial.start !== this.start || this.initial.end !== this.end){
                this.$emit(`update`, this.initial);
            }
        },
    },
};
</script>
<style scoped>
.reset-button {
    background-color: #f5faff;
}
</style>