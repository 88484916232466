// used to display our notification feedback after each request
export default (http, { store }) => {
    /**
     * for each successful response add our notification (if applicable)
     *
     * @param {AxiosResponse} response
     * @returns AxiosResponse
     */
    const responseCallback = (response) => {
        // abort if we dont want to show this notification
        if (
            response?.config?.silent ||
            response?.config?.notifications === false ||
            response?.config?.successNotification === false ||
            response?.config?.silentStatuses?.includes(response?.status)
        ) {
            return response;
        }

        const options = { type: "success" };

        // if we included a manual successNotification message apply it
        if (response?.config?.successNotification) {
            options.message = response?.config?.successNotification;
        }

        // if we want to persist, apply it
        if (response?.config?.persistNotification) {
            options.persist = true;
        }

        store.dispatch("notifications/create", options);
        return response;
    };

    /**
     * Show our error notification (is applicable)
     *
     * @param {AxiosError} error
     * @returns
     */
    const errorCallback = (error) => {
        // abort if we dont want to show this notification
        if (
            error?.config?.silent ||
            error?.config?.notifications === false ||
            error?.config?.errorNotification === false ||
            error?.response?.status === 422 ||
            error?.config?.silentStatuses?.includes(error?.response?.status) ||
            error?.message === "canceled"
        ) {
            return Promise.reject(error);
        }

        if (error?.response?.status === 401) {
           
            if(store.getters.loggedIn && !error.request.responseURL.includes('rotate-tokens')){
                return Promise.reject(error);
            }
        }

        const options = { type: "error" };

        // get our error message
        options.message = getErrorMessage(error);

        // if we want to persist, apply it
        if (error?.config?.persistNotification === false) {
            options.persist = false;
        }
        store.dispatch("notifications/create", options);

        return Promise.reject(error);
    };

    /**
     * returns our error message string
     *
     * @param {AxiosError} error
     * @returns Promise
     */
    const getErrorMessage = (error) => {
        // if we included a manual successNotification message apply it
        if (error?.config?.errorNotification) {
            return error.config.errorNotification;
        }

        if (error?.response?.status === 401) {
           
            if(!store.getters.loggedIn || error.request.responseURL.includes('rotate-tokens')){
                return "Authorization failed";
            }else{
                return null;
            }
        }

        if (error?.response?.status === 403) {
            return "This action is forbidden";
        }

        /*
        if (error?.response?.data?.errors?.message) {
            return error.response.data.errors.message;
        }

        return String(error);
        */

        return "An error occurred";
    };

    // apply response interceptors
    http.interceptors.response.use(responseCallback, errorCallback);
};
