<template>
     <div class="relative w-full rounded-lg drop-shadow-sm overflow-hidden">

        <!-- left hand color splash -->
        <div
            class="absolute h-full w-[10px] left-0 top-0 bottom-0 opacity-40 z-10"
            :class="statusColor"
        ></div>

        <!-- main bar wrapper -->
        <div class="relative bg-white w-full border border-gray-300 rounded-lg">
                
            <div class="py-3 pl-6 pr-4">

                <div v-if="title" class="flex items-center justify-between border-b pb-3 mb-3">
                    <div class="text-xl font-medium whitespace-nowrap text-ellipsis overflow-hidden">
                        <i v-if="icon" class="fad text-3x mr-2" :class="iconClasses" :title="iconTitle"></i>
                        <span v-html="title"></span>
                    </div>

                    <slot name="title"></slot>
                </div>

                <slot></slot>
            </div>

            <div v-if="$slots['footer']" class="text-sm bg-gray-50 px-3 py-2 rounded-b-lg">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        icon: {
            type: String,
        },
        iconClass: {
            type: String,
        },
        iconTitle: {
            type: String,
        },
        title: {
            type: String,
        },
        status: {
            type: [String, Number],       
            default: 'neutral',
        },
    },

    data(){
        return {
            colors: {
                warning: 'bg-status_UNDER_REVIEW-500',
                error: 'bg-status_REJECTED-500',
                info: 'bg-status_IN_PROGRESS-500',
                success: 'bg-status_COMPLETED-500',
                completed: 'bg-status_COMPLETED-500',
                neutral: 'bg-status_NOT_STARTED-200',
            },
        }
    },
   
    computed: {
        iconClasses(){
            if(!this.icon){
                return;
            }

            let classes = `fa-${this.icon} `;

            if(this.iconClass){
                classes += this.iconClass;
            }

            return classes;
        },

        statusColor() {
            if(typeof this.status === 'number'){
                return this.$store.getters.getStatusBackgroundColor(this.status);
            }
            
            if(this.colors[this.status]){
                return this.colors[this.status];
            }

            return this.status;
        },
    },
}
</script>

<style scoped>
</style>