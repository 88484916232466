// add bearer token to outbound requests
export default (http, { store }) => {
    http.interceptors.request.use((request) => {
        // check if application.overrideLock is enabled, then add the appropriate header if it is
        const override = store.getters["applications/overrideLock"];

        if (override) {
            request.headers["X-Override-Application-Lock"] = override;
        }

        return request;
    });
};
