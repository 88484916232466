<template>
    <button
        class="transition-all py-1 text-sm items-center flex gap-2"
        :class="computedClasses"
        :disabled="disabled"
        type="button"
    >
        <i
            v-if="icon"
            class="pointer-events-none"
            :class="`fa-${iconStyle} fa-${icon}`"
            aria-hidden="true"
        ></i>
        <slot></slot>
    </button>
</template>
<script>
export default {
    props: {
        icon: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
        },
        minimal: {
            type: Boolean,
            default: false,
        },
        iconStyle: {
            type: String,
            default: "solid",
        },
    },
    computed: {
        computedClasses(){
            let classes = "";

            if(this.disabled){
                classes += "opacity-40 pointer-events-none";  
            }
            
            if(this.minimal){
                classes += "px-1";
            } else {
                classes += "bg-gray-100 hover:bg-gray-200 shadow px-2";
            }

            return classes;
        }
    }
}
</script>
