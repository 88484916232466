<template>
    <div class="border-l-4 bg-blueGray-50 border-blueGray-200 p-6 rounded w-full overflow-hidden">
        
        <!-- functions form -->
        <quick-form 
            :schema="schema"
            @success="onSuccess"
            :disabled="isDisabled"
            ref="form"
        >
            <template #submit-button>
                <secondary-button
                    class="!bg-primary-50 grow md:py-3 md:-mt-1 !px-3"
                    data-submit
                    type="submit"
                    icon="circle-plus"
                    iconClass="text-primary-700"
                    :disabled="isDisabled"
                >
                    Add Function
                </secondary-button>
            </template>
        </quick-form>

        <!-- functions table -->
        <div class="mb-4" style="margin-top:1rem;">
            <div v-if="items.length" class="flex bg-white justify-center mb-8 border rounded overflow-hidden">
                <div
                    class="w-full overflow-auto"
                    ref="tableContainer"
                    v-dragscroll
                    @dragscrollstart="isDragging = true"
                    @dragscrollend="isDragging = false"
                >
                    <table class="w-full" :class="cursor">
                        <!-- header -->
                        <thead class="bg-yellow-50">
                            <tr class="border-b">
                                <!-- loop through column names -->
                                <th v-for="column in columns" 
                                    :key="column" 
                                    class="whitespace-nowrap text-left py-2 px-4"
                                >
                                    {{ column }}
                                </th>
                            </tr>
                        </thead>
                        <!-- body -->
                        <tbody>
                            <functions-item 
                                v-for="item in items" 
                                :item="item" 
                                :key="item.id"
                                class="border-b"
                                @deleted="onDeleted"
                            />
                        </tbody>
                    </table>
                </div>
            </div>


            <!-- no items alert -->
            <info-alert v-else>No functions added</info-alert>
        </div>

    </div>
</template>

<script>
import FunctionsItem from "./FunctionsItem.vue";

export default {
    components: {
        FunctionsItem,
    },

    props: {
        items: {
            type: Array,
            default: () => {
                return [];
            },
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['updated'],

    data(){
        return {
            maxItems: 25,
            isDragging: false,
            columns: [
                "Actions",
                "Function",
                "What was said",
                "Partner",
                "Environment",
                "Independence",
                "Baseline",
                "Endline",
            ],
        }
    },

    computed: {
        cursor() {
            return this.isDragging ? "cursor-grabbing" : "cursor-grab";
        },

        isDisabled(){
            return this.items.length >= this.maxItems || this.disabled;
        },

        schema(){
            return {
                name: "add-client-function",
                class: "md:grid-cols-6",
                hideErrorAlert: true,
                fields: [
                    { 
                        name: "function",
                        description: "Why did the client communicate?",
                        descriptionClass: "!text-xs",
                        labelClass: "!text-base",
                        placeholder: "Greeting",
                        wrapperClass: "md:col-span-2",
                        required: true,
                        validate: ['hasLetter', 'scaryChars']
                    },
                    { 
                        name: "what_was_said",
                        description: "What did the client say using the trial device?",
                        descriptionClass: "!text-xs",
                        labelClass: "!text-base",
                        placeholder: "Hello",
                        required: true,
                        wrapperClass: "md:col-span-2",
                        validate: ['hasLetter', 'scaryChars']
                    },
                    { 
                        name: "partner",
                        description: "Who did the client speak to with the trial device?",
                        placeholder: "Mom",
                        descriptionClass: "!text-xs",
                        labelClass: "!text-base",
                        wrapperClass: "md:col-span-2",
                        required: true,
                        validate: ['hasLetter', 'scaryChars']
                    },
                    { 
                        name: "environment",
                        description: "Where did the client use the trial device?<br>&nbsp;",
                        placeholder: "At home",
                        descriptionClass: "!text-xs",
                        labelClass: "!text-base",
                        wrapperClass: "md:col-span-3",
                        required: true,
                        validate: ['hasLetter', 'scaryChars']
                    },
                    { 
                        name: "independence",
                        label: "Level of independence to use device",
                        description: "Type and number of prompts. How the client demonstrated at least intermittent independent use of the device to functionally communicate",
                        placeholder: "Fully independent",
                        descriptionClass: "!text-xs",
                        labelClass: "!text-base",
                        wrapperClass: "md:col-span-3",
                        required: true,
                        validate: ['hasLetter', 'scaryChars']
                    },
                    { 
                        name: "baseline",
                        description: "Clearly state how the client used the the trial device at the beginning",
                        placeholder: "Did not greet using device day one of the trial",
                        wrapperClass: "md:col-span-6",
                        labelClass: "!text-base",
                        descriptionClass: "!text-xs",
                        required: true,
                        validate: ['hasLetter', 'scaryChars']
                    },
                    { 
                        name: "endline",
                        description: "Clearly state how the client used the trial device at the end. ",
                        placeholder: "Greeted mom regularly with device by the end of the trial",
                        wrapperClass: "md:col-span-6",
                        labelClass: "!text-base",
                        descriptionClass: "!text-xs",
                        required: true,
                        validate: ['hasLetter', 'scaryChars']
                    },
                ]
            }
        },
    },

    methods: {
        onSuccess({ inputs }){
            // add a timestamp to use as an id
            let item = { ...inputs, id: Date.now() };

            let newItems = [...this.items, item];
            this.$emit('updated', newItems);
        },

        onDeleted(id){
            let newItems = this.items.filter(item => item.id !== id);
            this.$emit('updated', newItems);
        },
    },
};
</script>
<style scoped>
</style>