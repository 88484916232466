<template>
    <Teleport to="body" >
        <transition name="fade">
            <!-- backdrop -->
            <div
                @keyup.esc="$emit('close')" tabindex="0" v-focus.stop
                v-if="open"
                @mousedown.self="$emit('close')"
                style="z-index: 100"
                class="modal__backdrop fixed z-40 bg-blue-900 bg-opacity-40 mt-0 flex justify-center items-center top-0 left-0 bottom-0 right-0 p-5"
            >
                <div
                    ref="modal"
                    class="modal__content border-2 border-gray-300 rounded-lg shadow-2xl mb-5 max-h-[95vh] max-w-full overflow-auto"
                    v-bind="$attrs"
                    :style="modalStyle"
                >
                    <div>
                        <!-- header slot -->
                        <div
                            ref="modal-header-custom"
                            v-if="$slots.header && !(title || icon)"
                            class="py-3 px-5 flex items-center justify-between border-b bg-blueGray-50" :class="draggable? 'cursor-grab' : ''"
                        >
                            <slot name="header"></slot>
                        </div>

                        <div
                            v-else
                            ref="modal-header"
                            class="py-3 px-5 flex items-center justify-between border-b"
                            :class="titleClass"
                        >
                            <div
                                class="flex items-center justify-center text-3xl"
                            >
                                <div
                                    v-if="icon"
                                    class="mr-3"
                                    :class="titleIconClass"
                                    @click="$emit('iconClicked')"
                                >
                                    <i class="fad" :class="`fa-${icon}`"></i>
                                </div>
                                <div
                                    v-if="title"
                                    class="text-xl leading-tight capitalize"
                                >
                                    {{ title }}
                                </div>
                                <div
                                    v-if="icon2"
                                    class="ml-3 green-checkmark"
                                    :class="titleIconClass"
                                >
                                    <i class="fad" :class="`fa-${icon2}`"></i>
                                </div>
                            </div>
                            <div>
                                <base-button
                                    v-if="!hideClose"
                                    size="small"
                                    class="text-xl"
                                    @click="$emit('close')"
                                >
                                    <i class="fas fa-times"></i>
                                </base-button>
                            </div>
                        </div>
                        <div
                            class="bg-white"
                            :class="{ 'py-5 px-5': !noBodyPadding }"
                        >
                            <slot></slot>
                        </div>
                        <div
                            v-if="$slots.footer"
                            class="py-5 px-5 border-t bg-gray-50"
                        >
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </Teleport>
</template>

<script>
import ResizeObserver from "@/services/utils/resizeObserver";
import {
    inRange,
    isInput,
    getTouchEvent,
    validateNumber,
    windowWidthWithoutScrollbar,
} from './utils/modalResizeUtils';
export default {
    props: {
        // should we show the modal?
        
        open: {
            default: false,
        },
        // should we hide the close button?
        hideClose: {
            default: false,
        },
        // optional title
        title: {
            type: String,
        },
        // optional icon
        icon: {
            type: String,
        },
        icon2: {
            type: String,
        },
        iconClass: {
            type: String,
        },
        iconClickable: {
            type: Boolean,
            default: false,
        },
        draggable: {
            type: Boolean,
            default: false
        },
        noBodyPadding: {
            type: Boolean,
            default: false,
        },
        titleRole: {
            type: String,
        },
        shiftX: {
            type: Number,
            default: 0.5,
            validator(value) {
                return value >= 0 && value <= 1
            }
        },
        shiftY: {
            type: Number,
            default: 0.5,
            validator(value) {
                return value >= 0 && value <= 1
            }
        },
        minWidth: {
            type: Number,
            default: 0,
            validator(value) {
                return value >= 0
            }
        },
        minHeight: {
            type: Number,
            default: 0,
            validator(value) {
                return value >= 0
            }
        },
        maxWidth: {
            type: Number,
            default: Number.MAX_SAFE_INTEGER
        },
        maxHeight: {
            type: Number,
            default: Number.MAX_SAFE_INTEGER
        },
        width: {
            type: [Number, String],
            default: 600,
            validator(value) {
                return value === 'auto' || validateNumber(value)
            }
        },
        height: {
            type: [Number, String],
            default: 300,
            validator(value) {
                return value === 'auto' || validateNumber(value)
            }
        },
    },

    data() {
        return {
            resizeObserver: null,
            refreshModalDimensions: false,
            shiftLeft: 0,
            shiftTop: 0,

            modal: {
                width: 0,
                widthType: 'px',
                height: 0,
                heightType: 'px',
                renderedHeight: 0,
                renderedWidth: 0,
            },

            viewportHeight: 0,
            viewportWidth: 0
        }
    },

    emits: ["close", "iconClicked"],

    inheritAttrs: false,

    watch: {
        // when the modal opens prevent the backing page from scrolling
        open(newVal) {
            this.$nextTick(() => {
                if (newVal) {
                    this.resizeObserver.observe(this.$refs.modal);
                    this.preventBodyScroll();
                    if (this.draggable) {
                        this.addDraggableListeners();
                        window.addEventListener('resize', this.onWindowResize)
                        window.addEventListener('orientationchange', this.onWindowResize)
                        this.onWindowResize()
                        this.initializePositions();
                    }
                }
                else {
                    if (this.resizeObserver) {
                        this.resizeObserver.disconnect();
                    }
                    this.allowBodyScroll();
                }
            });
        },
    },

    // make sure we always allow scrolling again once we unmount this component
    mounted() {
        this.resizeObserver = new ResizeObserver(_entries => {
            this.onWindowResize();
        })

    },
    unmounted() {
        this.allowBodyScroll();
    },

    computed: {
        titleClass() {
            let titleClass = "";
            switch (this.titleRole) {
                case "warning":
                    titleClass += "bg-yellow-100 text-yellow-800 border-yellow-300 ";
                    break;
                default:
                    titleClass += "bg-gray-50 ";
            }

            if (this.draggable) {
                titleClass += " cursor-grab";
            }

            return titleClass;
        },

        titleIconClass() {
            let classes = this.iconClass ?? this.titleRoleClass;

            if (this.iconClickable) {
                classes += " cursor-pointer";
            }

            return classes;
        },

        titleRoleClass() {
            switch (this.titleRole) {
                case "warning":
                    return "text-yellow-900";
                default:
                    return "text-secondary-900";
            }
        },
        // Draggable modal computed properties
        /**
         * Returns pixel width (if set with %) and makes sure that modal size
         * fits the window
         */
        trueModalWidth() {
            return this.modal.renderedWidth;
        },
        /**
         * Returns pixel height (if set with %) and makes sure that modal size
         * fits the window.
         *
         * Returns modal.renderedHeight if height set as "auto"
         */
        trueModalHeight() {
            return this.modal.renderedHeight;

        },
        isAutoHeight() {
            return this.modal.heightType === 'auto'
        },
        position() {
            const {
                viewportHeight,
                viewportWidth,
                shiftLeft,
                shiftTop,
                shiftX,
                shiftY,
                trueModalWidth,
                trueModalHeight
            } = this

            const maxLeft = viewportWidth - trueModalWidth
            const maxTop = Math.max(viewportHeight - trueModalHeight, 0)
            const left = shiftLeft + shiftX * maxLeft
            const top = shiftTop + shiftY * maxTop

            return {
                left: parseInt(inRange(0, maxLeft, left)),
                top:
                !trueModalHeight && this.isAutoHeight
                    ? undefined
                    : parseInt(inRange(0, maxTop, top))
            }
        },
        modalStyle() {
            return this.draggable? {
                position: 'absolute',
                top: this.position.top + 'px',
                left: this.position.left + 'px',
            } : {
                position: 'relative'
            };
        },
    },


    methods: {
        ensureShiftInWindowBounds() {
            const {
                viewportHeight,
                viewportWidth,
                shiftLeft,
                shiftTop,
                shiftX,
                shiftY,
                trueModalWidth,
                trueModalHeight
            } = this

            const maxLeft = viewportWidth - trueModalWidth
            const maxTop = Math.max(viewportHeight - trueModalHeight, 0)

            const left = shiftLeft + shiftX * maxLeft
            const top = shiftTop + shiftY * maxTop

            this.shiftLeft -= left - inRange(0, maxLeft, left)
            this.shiftTop -= top - inRange(0, maxTop, top)
        },
        onWindowResize() {
            this.viewportWidth = windowWidthWithoutScrollbar();
            this.viewportHeight = window.innerHeight
            this.recalculateModalDimensions();
            this.ensureShiftInWindowBounds();
        },
        initializePositions() {
            this.shiftLeft = 0;
            this.shiftTop = 0;
        },
        recalculateModalDimensions() {
            this.modal.renderedHeight = this.$refs.modal?.offsetHeight?? 0;
            this.modal.renderedWidth = this.$refs.modal?.offsetWidth?? 0;
        },
        getDraggableElement() {
            // return null;
            if (this.draggable === true) {
                return this.$refs['modal-header'] ?? this.$refs['modal-header-custom'];
            }

            if (typeof this.draggable === 'string') {
                return this.$refs.modal.querySelector(this.draggable)
            }
            return null
        },
        addDraggableListeners() {
            const dragger = this.getDraggableElement();
            if (dragger) {
                let startX = 0
                let startY = 0
                let initialShiftLeft = 0
                let initialShiftTop = 0

                const handleDraggableMousedown = event => {
                    let target = event.target

                    if (isInput(target)) {
                        return
                    }

                    let { clientX, clientY } = getTouchEvent(event)

                    document.addEventListener('mousemove', handleDraggableMousemove)
                    document.addEventListener('touchmove', handleDraggableMousemove)

                    document.addEventListener('mouseup', handleDraggableMouseup)
                    document.addEventListener('touchend', handleDraggableMouseup)

                    startX = clientX
                    startY = clientY

                    initialShiftLeft = this.shiftLeft
                    initialShiftTop = this.shiftTop
                }

                const handleDraggableMousemove = event => {
                    let { clientX, clientY } = getTouchEvent(event)

                    this.shiftLeft = initialShiftLeft + clientX - startX
                    this.shiftTop = initialShiftTop + clientY - startY
                    event.preventDefault()
                }

                const handleDraggableMouseup = event => {
                    this.ensureShiftInWindowBounds()

                    document.removeEventListener('mousemove', handleDraggableMousemove)
                    document.removeEventListener('touchmove', handleDraggableMousemove)

                    document.removeEventListener('mouseup', handleDraggableMouseup)
                    document.removeEventListener('touchend', handleDraggableMouseup)

                    event.preventDefault()
                }

                dragger.addEventListener('mousedown', handleDraggableMousedown)
                dragger.addEventListener('touchstart', handleDraggableMousedown)
            }
        },
        // prevent the underlying page from scrolling
        preventBodyScroll() {
            const html = document.querySelector("html");
            const hasScroll = html.scrollHeight > html.clientHeight;

            // if we have a scrollbar add some padding to the primary content to prevent layout shift
            if (hasScroll) {
                const primaryNav = document.getElementById("primary-nav");
                primaryNav.classList.add("-mr-4");
                document.body.classList.add("pr-4");
            }

            html.style.overflow = "hidden";

            //document.body.style.position = 'fixed';
        },

        // allow the underlying page to scroll again
        allowBodyScroll() {
            const html = document.querySelector("html");
            html.style["scrollbar-gutter"] = "initial";
            html.style.overflow = "";

            document.body.classList.remove("pr-4");

            const primaryNav = document.getElementById("primary-nav");
            if (primaryNav) {
                primaryNav.classList.remove("-mr-4");
            }
            //document.body.style.position = 'relative';
        },
    },
};
</script>
<style scoped>
.green-checkmark {
    color: rgb(120, 210, 22);
}
</style>
