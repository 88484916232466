<template>
    <div>
        <div
            class="password__strength-meter relative h-1 bg-gray-50 mx-auto my-2 rounded-sm"
        >
            <div
                class="password__strength-meter--fill absolute h-1"
                :data-score="strength"
            ></div>
        </div>
    </div>
</template>
<script>
import zxcvbn from "zxcvbn";
export default {
    props: {
        password: {
            type: String,
        },
        minLength: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            strength: 0,
        };
    },
    watch: {
        password(value) {
            if (value.length < this.minLength) {
                this.strength = 0;
                return;
            }

            this.strength = zxcvbn(value)?.score;
        },
    },
};
</script>

<style scoped>
.password__strength-meter:before,
.password__strength-meter:after {
    content: "";
    height: inherit;
    background: transparent;
    display: block;
    border-color: #fff;
    border-style: solid;
    border-width: 0 5px 0 5px;
    position: absolute;
    width: 20%;
    z-index: 10;
}

.theme--night .password__strength-meter:before,
.theme--night .password__strength-meter:after {
    border-color: #111827;
}

.theme--night .password__strength-meter {
    background-color: #3a4f7c;
}

.password__strength-meter:before {
    left: 20%;
}

.password__strength-meter:after {
    right: 20%;
}

.password__strength-meter--fill {
    width: 0;
    transition: width 0.5s ease-in-out, background 0.25s;
}

.password__strength-meter--fill[data-score="0"] {
    background: darkred;
    width: 20%;
}

.password__strength-meter--fill[data-score="1"] {
    background: orangered;
    width: 40%;
}

.password__strength-meter--fill[data-score="2"] {
    background: orange;
    width: 60%;
}

.password__strength-meter--fill[data-score="3"] {
    background: yellowgreen;
    width: 80%;
}

.password__strength-meter--fill[data-score="4"] {
    background: green;
    width: 100%;
}
</style>
