<template>
    <base-card :class="{ blur : shouldHide }">
        <!-- header -->
        <template #header>
            <!-- status -->
            <div class="flex items-center justify-center">
                <div class="flex items-center justify-center text-3xl">
                    <status-icon :status="application.status_id" />
                </div>
                <div class="flex flex-col mx-2.5">
                    <div class="text-xxs">STATUS</div>
                    <div class="text-lg leading-tight capitalize">
                        {{ statusText }}
                    </div>
                </div>
            </div>

            <!-- alias -->
            <div>
                <div
                    class="py-0.5 px-1.5 text-smaller text-gray-600 bg-gray-500/10 font-mono"
                >
                    {{ application.alias }}
                </div>
            </div>
        </template>

        <!-- main body -->
        <div class="relative z-10">
            <!-- content -->
            <div>
                <div class="text-xxs">CLIENT</div>
                <div class="text-3xl leading-tight -ml-0.5 font-medium">
                    {{ application.first_name + " " + application.last_name }}
                </div>
                <div class="">
                    <span class="capitalize">{{ application.gender }},</span>
                    &nbsp;{{ age }}
                </div>
            </div>
            <div class="flex flex-wrap items-end justify-between mt-5">
                <div class="flex">
                    <div class="mr-3">
                        <div class="text-xxs">STARTED</div>
                        <div
                            class="py-0.5 px-1.5 text-smaller text-gray-600 bg-gray-500/10"
                        >
                            {{ started }}
                        </div>
                    </div>
                    <div class="mr-3">
                        <div class="text-xxs">UPDATED</div>
                        <div
                            class="py-0.5 px-1.5 text-smaller text-gray-600 bg-gray-500/10"
                        >
                            {{ updated }}
                        </div>
                    </div>
                    <div class="mr-3">
                        <div class="text-xxs">TYPE</div>
                        <div
                            class="py-0.5 px-1.5 text-smaller text-gray-600 bg-gray-500/10 capitalize"
                        >
                            {{ application.type }}
                        </div>
                    </div>
                </div>
                <div style="margin-top: 16px">
                    <router-link
                        :to="{
                            name: 'application.overview',
                            params: { alias: application.alias },
                        }"
                    >
                        <primary-button>View Application</primary-button>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- practice watermark -->
        <div 
            v-if="application.type === 'practice'"
            class="absolute text-9xl -rotate-6 text-accent-100 font-black z-0 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        >
            PRACTICE
        </div>

        <!-- section statuses -->
        <template #footer>
            <div class="grid gap-3 w-full grid-cols-3" :class="gridCols">
                <status-button
                    v-for="section in activeSections"
                    :key="section.relation"
                    :application="application"
                    :section="section"
                />
            </div>
        </template>
    </base-card>
</template>

<script>
import dateService from "@/services/utils/dateFormatterService";
import StatusButton from "@/components/dashboard/StatusButton.vue";
export default {
    components: {
        StatusButton,
    },
    props: {
        application: {
            type: Object,
            required: true,
        },
        privacyMode: {
            type: Boolean,
            default: false,
        },
        privacyException: {
            type: Object,
        },
    },

    computed: {
        shouldHide(){
            const exceptionMatches = this.privacyException?.firstName?.toLowerCase() === this.application.first_name?.toLowerCase() 
                                  && this.privacyException?.lastName?.toLowerCase()  === this.application.last_name?.toLowerCase();

            return this.privacyMode && !exceptionMatches;
        },

        statuses() {
            return this.$store.getters.statuses;
        },

        sections() {
            return this.$store.getters["applications/sections"];
        },

        activeSections() {
            // get our application's active sections
            return this.$store.getters[
                "applications/getActiveApplicationSections"
            ](this.application);
        },

        age() {
            return dateService.yearsRange(this.application.dob);
        },

        started() {
            return dateService.humanReadable(this.application.created_at);
        },

        updated() {
            return dateService.humanReadable(this.application.updated_at);
        },

        isTrial() {
            return this.application.type.toLowerCase() === "trial";
        },

        gridCols() {
            let ret = "";
            switch (this.activeSections.length) {
                case 3:
                    ret = "xm:grid-cols-3";
                    break;
                case 4:
                    ret = "xm:grid-cols-4";
                    break;
                case 5:
                    ret = "xm:grid-cols-5";
                    break;
                case 6:
                    ret = "xm:grid-cols-6";
                    break;
                case 7:
                    ret = "xm:grid-cols-7";
                    break;
            }
            return ret;
        },

        statusText() {
            // if we are in completed state, display ready to be submitted instead of default
            // status text for completed
            if (
                this.application.status_id ===
                this.$store.getters.getStatusId("COMPLETED")
            ) {
                return "Ready to be Submitted";
            }

            return this.statuses[this.application.status_id]?.text;
        },
    },
};
</script>

<style scoped></style>
