import objectPropertiesHaveValue from "../services/utils/objectPropertiesHaveValue";

export default {
    namespaced: true,

    state() {
        return {};
    },

    mutations: {},

    actions: {},

    getters: {
        sections(_state, getters, rootState, _rootGetters) {
            let delivery = rootState.applications.current?.client_info?.delivery?.status_id;
            let mdm = rootState.applications.current?.client_info?.advocate?.status_id;
            let slp = rootState.applications.current?.client_info?.slp?.status_id;
            let physician = rootState.applications.current?.client_info?.physician?.status_id;
            return {
                about: getters.about,

                residence: getters.residence,

                delivery: delivery,

                diagnosis: getters.diagnosis,

                equipment: getters.equipment,

                mdm: mdm,

                slp: slp,

                physician: physician,
            };
        },

        /**
         * Is the client info about section complete?
         *
         * @returns int (status_id)
         */
        about(_state, _getters, rootState, _rootGetters) {
            const basicInfo = rootState.applications.current?.client_info.basic_info;
            const properties = ["first_name", "last_name", "dob", "gender"];

            return objectPropertiesHaveValue(basicInfo, properties);
        },

        /**
         * Is the client info residence section complete?
         *
         * @returns int (status_id)
         */
        residence(_state, _getters, rootState, rootGetters) {
            const basicInfo = rootState.applications.current?.client_info.basic_info;
            const address = rootState.applications.current?.client_info.basic_info.address;

            const basicProperties = ["place_of_residence", "phone"];
            const addressProperties = ["address_1", "city", "state", "zip"];

            // if our place of residence isn't home or other require the address includes
            // a facility
            if (basicInfo?.place_of_residence !== "12-Home") {
                addressProperties.push("facility");
            }
            
            const completionArray = [
                objectPropertiesHaveValue(basicInfo, basicProperties),
                objectPropertiesHaveValue(address, addressProperties),
            ];
            
            return rootGetters.getStatusIdFromCompletionArray(completionArray);
        },

        /**
         * Is the client info diagnosis section complete?
         *
         * @returns int (status_id)
         */
        diagnosis(_state, _getters, rootState, _rootGetters) {
            const basicInfo =
                rootState.applications.current?.client_info.basic_info;
            const properties = [
                "in_hospice",
                "medical_diagnosis",
                "medical_diagnosis_date",
                "speech_diagnosis",
                "speech_diagnosis_date",
                "result_of_accident",
            ];

            if (basicInfo.result_of_accident) {
                properties.push("accident_type");
                properties.push("date_of_accident");
            }

            if (
                basicInfo.result_of_accident &&
                basicInfo.accident_type === "auto"
            ) {
                properties.push("accident_state");
            }

            return objectPropertiesHaveValue(basicInfo, properties);
        },

        /**
         * Is the client info equipment section complete?
         *
         * @returns int (status_id)
         */
        equipment(_state, _getters, rootState, _rootGetters) {
            const basicInfo =
                rootState.applications.current?.client_info.basic_info;
            const properties = ["has_sgd", "has_mount"];

            if (basicInfo.has_sgd) {
                properties.push("sgd_purchase_date");
            }

            if (basicInfo.has_mount) {
                properties.push("mount_purchase_date");
            }

            return objectPropertiesHaveValue(basicInfo, properties);
        },
    },
};
