<template>
    <warning-alert v-if="isClosed" icon="lock">This application was closed on {{ closeDate }} and may not be edited</warning-alert>
    <warning-alert v-else-if="isLocked" icon="lock">
        <div class="flex items-center">
            This application was submitted on {{ submitDate }} and may not be edited 
            <confirm-button 
                v-if="isAdmin && !preventOverride"
                role="danger" 
                size="small" 
                class="ml-auto" 
                component="primary-button"
                message="<span class='font-bold'>Are you sure you want to override the locked status of this application?</span> No status changes will take place from edits made in this manner (except adding or removing rejections), which could result in unforeseen consequences."
                confirmText="Yes, override the lock"
                @confirmed="enterOverrideMode"
            >
                override
            </confirm-button>
        </div>
      </warning-alert>
</template>

<script>
import dateService from "@/services/utils/dateFormatterService";

export default {
    props: {
        preventOverride : {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        enterOverrideMode(){
            this.$store.dispatch("applications/enableOverrideLock");
        },
    },

    computed: {
        application(){
            return this.$store.getters["applications/current"] ?? {};
        },
        isLocked(){
            return this.$store.getters["applications/currentIsLocked"];
        },
        isClosed(){
            return this.$store.getters["applications/currentIsClosed"];
        },
        isSubmitted(){
            return this.$store.getters["applications/currentIsSubmitted"];
        },
        closeDate(){
            return dateService.humanReadable(this.application?.deleted_at);
        },
        submitDate(){
            return dateService.humanReadable(this.application?.latest_submission_since_conversion?.created_at);
        },
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
    }
};
</script>
<style scoped></style>

