//import router from "@/router/index";
const US_CODE = '840';

// manages our user state
export default {
    namespaced: true,

    state() {
        return {
            regionMap: {
                "Pacific": ["AK", "CA", "HI", "ID", "NV", "OR", "UT", "WA"],
                "Mountain": ["AZ", "CO", "MT", "ND", "NM", "SD", "WY"],
                "Midwest": ["IA", "IL", "KS", "MN", "MO", 'NE', 'WI'],
                "South Central": ['AR', 'LA', 'MS', 'OK', 'TX'],
                "Mid-America": ['IN', 'KY', 'MI', 'OH'],
                "Appalachian": ['DC', 'NC', 'TN', 'VA'],
                "Southeast": ['AL', 'FL', 'GA', 'SC', 'PR'],
                "Mid-Atlantic": ['DE', 'MD', 'NJ', 'PA', 'WV'],
                "Northeast": ['CT', 'MA', 'ME', 'NH', 'NY', 'RI', 'VT'],
            },
        };
    },

    mutations: {
    },

    actions: {
    },

    getters: {
        regionMap(state) {
            return state.regionMap;
        },

        regions(_, __, rootState) {
            return rootState.regions;
        },

        filteredRegions(state, getters){
            // Return only regions in our region map
            let mappedRegions = Object.values(state.regionMap).reduce((acc, val) => [...acc, ...val], []);
            return getters.regions.filter(region => mappedRegions.includes(region.code) && region.country_code === US_CODE);
        },

        /**
         * Generate an object that mirrors our regionMap except with the 
         * state codes replaced by the region IDs from the database
         */
        regionMapIds(_state, getters){
            return getters.regionMapAsProperty('id');
        },

        /**
         * Generate an object that mirrors our regionMap except with the 
         * state codes replaced by the region names from the database
         */
        regionMapNames(_state, getters){
           return getters.regionMapAsProperty('name');
        },

        /**
         * Generate an object that mirrors our regionMap except with the 
         * state codes replaced by the given property of each region from the database
         */
        regionMapAsProperty(state, getters){
            return (property) => {
                let map = {};

                Object.entries(state.regionMap).forEach(([regionName, codes]) => {
                    let values = codes.map(code => getters.getByCode(code)[property]);
                    map[regionName] = values; 
                });

                return map;
            };
        },

        getIdFromCode(_, getters) {
            return (code, inUSA = true) => {
                return getters.getByCode(code, inUSA).id ?? 0;
            }
        },

        getNameFromCode(_, getters) {
            return (code, inUSA = true) => {
                return getters.getByCode(code, inUSA).name ?? "";
            }
        },

        getByCode(_, __, rootState) {
            return (code, inUSA = true) => {
                return Object.values(rootState.regions).find(region => region.code === code && (!inUSA || region.country_code === US_CODE)) ?? {};
            }
        },

        getById(_, __, rootState) {
            return (id, inUSA = true) => {
                return Object.values(rootState.regions).find(region => region.id === id && (!inUSA || region.country_code === US_CODE)) ?? {};
            }
        },
    },
};
