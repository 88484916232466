<template>

    <info-alert :icon="false" class="py-6 px-8 leading-normal rounded-lg relative grow">
        <div class="px-8">
            <h3>Terms and conditions</h3>
            <ul class="list-disc">
                <li>
                    I, as the financially Responsible Party, request that payment of authorized health care benefits be
                    made on my behalf to Prentke Romich Company d/b/a PRC-Saltillo (PRC-Saltillo), 1022 Heyl
                    Road, Wooster, Ohio 44691, for any equipment or services
                    provided to me by PRC-Saltillo. I authorize the release of any
                    medical or other information necessary to determine these
                    benefits or the benefits payable for related equipment or
                    services.
                </li>
                <li>
                    I understand PRC-Saltillo's return policy gives me thirty (30) days from the
                    date of shipment to provide notification to PRC-Saltillo of any item(s)
                    being returned. At that time, a return authorization number
                    will be issued and I will have fifteen (15) days from the date of
                    the notification to return the item(s) for a full refund.
                    The refund will be issued to the paying source. PRC-Saltillo will use
                    their discretion to accept any returns beyond thirty (30) days from the date of shipment. A restocking fee may apply.
                </li>
                <li>
                    I acknowledge that I have received and understand PRC-Saltillo's Privacy Policy, Notice of Privacy Practices,
                    and Client Bill of Rights, Supplier Standards.
                </li>
                <li>
                    I acknowledge that I have been instructed to direct
                    questions, complaints or concerns regarding the performance
                    of my equipment, supplies and/or service to PRC-Saltillo at (800)
                    262-1990 (8:00 AM - 7:00 PM EST). I have been advised that
                    PRC-Saltillo is responsible for resolving my questions or concerns
                    and it is PRC-Saltillo's goal to respond to questions and concerns
                    within fourteen (14) business days of my contact to PRC-Saltillo.
                </li>
                <li>
                    I understand that I am financially responsible for any
                    charges not covered by my health care benefits. (PRC-Saltillo will
                    contact me prior to shipping order to confirm payment and shipping information.)
                </li>
                <li>
                    I understand that it is my responsibility to notify PRC-Saltillo of
                    any new insurance or changes in my health care coverage. If a
                    change in my health care coverage is not reported prior to
                    the services being provided, I understand that I am
                    financially responsible for any charges if payment is
                    denied.
                </li>
                <li>
                    I acknowledge that I have received and understand the
                    <a class="underline" :href="landingSiteUrl+'forms'" target="_blank">Durable Medical Equipment, Prosthetics/Orthotics & Supplies
                    (DMEPOS) Supplier Standards. (Medicare recipients only.)</a>
                </li>
                <li>
                    I confirm that I am not receiving in-home or facility-based
                    hospice care, skilled nursing or hospital-based care. I
                    understand that if the Medicare part B claim denies due to
                    enrollment in the above listed types of care, I assume full
                    financial responsibility for the equipment provided by PRC-Saltillo.
                    (Medicare recipients only.)
                </li>
            </ul>
            <p class="pt-4">
                I agree that I am the Responsible Party. Not all services and/or equipment may be covered or paid for by
                the Responsible Party's (primary policy holder's) private
                insurance. The Responsible Party agrees to pay all deductibles,
                co-pay, non-covered services/equipment, and any portion of
                covered services not paid in full by private insurance, when
                applicable. The Responsible Party understands that payments are
                due immediately upon presentation of the bill. The Responsible
                Party(ies) agree that PRC-Saltillo may use any
                information provided herein for collection purposes.
            </p>
            <p class="pt-4">
                By signing below, I agree that this Contract shall be governed
                by the laws of the State of Ohio, without regard to the
                principles of conflicts of laws. The venue for any disputes will
                be exclusively with the appropriate court in Wayne County, Ohio.
            </p>
        </div>
    </info-alert>
</template>

<script>


export default {
    props: {
        preventOverride : {
            type: Boolean,
            default: false,
        },
    },
    methods: {

    },

    computed: {
        landingSiteUrl() {
            return process.env.VUE_APP_LANDING_SITE_URL;
        },
    }
};
</script>
<style scoped></style>