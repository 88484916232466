export default [
    {
        path: "",
        name: "application.slpAddendum",
        component: () => import("@/views/application/slpAddendum/overview.vue"),
        meta: {
            title: "SLP Addendum - Overview",
        },
    },
];
