export default {
    format(phone) {
        if(typeof phone !== 'string')
        {
            return;
        }
        const phoneReg = new RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
        phone = phone.trim();
        const results = phoneReg.exec(phone);
        if(results !== null && results.length > 8)
        {
            return '(' + results[3] + ') ' + results[4] + '-' + results[5] + (typeof results[8] !== 'undefined' ? ' x' + results[8] : '');
        }
        else
        {
            return phone;
        }
    }, 
};   