export default [
    {
        path: "/application/create/",
        name: "application.create",
        component: () => import("@/views/application/create/Role.vue"),
    },
    {
        path: "/application/create/add-asha",
        name: "application.create.addAsha",
        component: () => import("@/views/application/create/AddAsha.vue"),
    },
    {
        path: "/application/create/repair-code",
        name: "application.create.repairCode",
        component: () => import("@/views/application/create/RepairCode.vue"),
    },
    {
        path: "/application/create/type",
        name: "application.create.type",
        component: () => import("@/views/application/create/Type.vue"),
    },
    {
        path: "/application/create/accessory-only",
        name: "application.create.accessoryOnly",
        component: () => import("@/views/application/create/AccessoryOnly.vue"),
    },
    {
        path: "/application/create/find-consultant",
        name: "application.create.findConsultant",
        props: true,
        component: () =>
            import("@/views/application/create/FindConsultant.vue"),
    },
    {
        path: "/application/create/primary-contributors",
        name: "application.create.primaryContributors",
        component: () =>
            import("@/views/application/create/PrimaryContributors.vue"),
    },
    {
        path: "/application/create/client-info",
        name: "application.create.clientInfo",
        component: () => import("@/views/application/create/ClientInfo.vue"),
    },
    {
        path: "/application/create/complete",
        name: "application.create.complete",
        component: () => import("@/views/application/create/Complete.vue"),
    },
];
