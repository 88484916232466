<template>
    <button
        class="inline-flex items-center justify-center disabled:opacity-50 disabled:pointer-events-none disabled:cursor-not-allowed transition-all rounded ripple focus:outline-none relative"
        :disabled="disabled"
        :type="type"
    >
        <i
            v-if="icon"
            class="mr-2 opacity-75"
            :class="computedClasses"
            aria-hidden="true"
        ></i>
        <slot></slot>
    </button>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
        },
        icon: {
            type: String,
        },
        type: {
            type: String,
            default: "button",
        },
        iconClass: {
            type: String,
        },
        iconStyle: {
            type: String,
            default: "solid",
        }
    },
    computed: {
        computedClasses(){
            // set icon
            let classes = `fa-${this.iconStyle} fa-${this.icon} `;

            if(this.iconClass){
                classes += ` ${this.iconClass}`;
            }

            return classes;
        }
    }
};
</script>
