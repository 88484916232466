import { isObject } from "lodash";

/**
 * Check if the given field has a value
 *
 * @param {mixed} value
 * @returns {boolean}
 */
function hasValue(value, fieldSchema = null) {
    let result = true;

    // strip tags from rich text before checking for content
    if (fieldSchema && fieldSchema.type === "richtext") {
        let contentWithoutTags = value.replace(/<[^>]*>/g, "");
        contentWithoutTags = contentWithoutTags.trim();
        value = contentWithoutTags;
    }

    // check for null undefined or 0 length
    if (value === null || value === undefined || value.length === 0) {
        result = false;
    }

    // required checkboxes need to be checked
    if (fieldSchema && fieldSchema.type === "checkbox" && !value) {
        result = false;
    }

    // required counts need to have at least one count
    if (fieldSchema && fieldSchema.type === "counts") {
        if(isObject(value)){
            result = false;
            Object.values(value).forEach(count => {
                if(count > 0) {
                    result = true;
                }
            });
        }
    }

    return result;
}

export default hasValue;