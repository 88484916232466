// used to keep track of the amount of request we're currently handling
export default (http, { store }) => {
   
    // for each successful response decrement our spinner request queue (if needed)
    const responseCallback = (response) => {
    
        if(response?.data?.has_new_task){
            store.dispatch("tasks/getUserTasks");
        }

        return response;
    };

    // for each failed response decrement our spinner request queue (if needed)
    const errorCallback = (error) => {

        if(error?.data?.has_new_task){
            store.dispatch("tasks/getUserTasks");
        }

        return Promise.reject(error);
    };

    // apply response interceptors
    http.interceptors.response.use(responseCallback, errorCallback);
};
