export default { 


    formatQuery(endpoint, data, filteredProps = []){
        // build our base query
        const query = this.buildQueryObject(data, filteredProps);

        // return the formatted url 
        return this.buildUrl(endpoint, query);
    },

    /**
     * Format the application query 
     * 
     * @param {object} data 
     * @returns 
     */
    applicationQuery(endpoint, data, filteredProps = []){
        // delete the total, as we don't need to send that to the API
        filteredProps.push("total");

        // if we haven't set a search, delete the search options 
        if(!data.search){
            delete filteredProps.push("search_options");
        }

        return this.formatQuery(endpoint, data, filteredProps);
    },

    /**
     * Build our query object, but don't convert it to a parameter string yet,
     * to allow additional processing.
     * 
     * @param {object} data 
     * @returns 
     */
    buildQueryObject(data, filteredProps = []){
        const query = {};

        Object.entries(data).forEach(([key, value]) => {
            // if our key exists in our filteredProps array do nothing
            if(filteredProps.includes(key)){
                return;
            }

            // if our value is falsy or an empty array do nothing
            if(!value || (Array.isArray(value) && !value.length)){
                return;
            }

            // if our value is an array join the array 
            if(Array.isArray(value)){
                return query[key] = value.join(',');
            }

            // if our value is {boolean} true convert it to {string} true
            if(value === true){
                return query[key] = "true";
            }

            // otherwise just add it to our query
            return query[key] = value;
        });

        return query;
    },

    /**
     * Remove the given properties from the given data
     * 
     * @param {object} query
     * @param {array|string} filtered an array of props (or a single prop as a string) to delete from the data 
     * @returns {object}
     */
    filterQuery(query, filtered){
        // if we passed a string, wrap it in an array
        if(!Array.isArray(filtered)){
            filtered = [filtered];
        }

        // delete the properties given in our filter
        filtered.forEach(prop => delete query[prop]);

        return query;
    },

    buildUrl(endpoint, query){
        return endpoint + "?" + new URLSearchParams(query).toString();
    },
};

