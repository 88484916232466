<template>
    <error-banner v-if="overrideIsEnabled" class="fixed top-0 left-0 right-0 z-[100000]">
        <div class="flex items-center gap-2">
            Application lock override is enabled
            <primary-button icon="times" size="xsmall" role="neutral" @click="exitOverrideMode">exit</primary-button>
        </div>
    </error-banner>
</template>

<script>

export default {
    methods: {
        exitOverrideMode(){
            this.$store.dispatch("applications/disableOverrideLock");
        },
    },

    computed: {
        overrideIsEnabled(){
            return this.$store.getters['applications/overrideLock'];
        },
    }
};
</script>
<style scoped></style>

