<template>
    <confirm-button
        :message="deleteMessage"
        class="text-red-400 pointer-events-auto"
        @confirmed="deleteFile"
        :field="{
            label: 'Reason for Deletion',
            required: true,    
        }"
        :icon="$store.getters.icons.delete.icon"
        :iconStyle="$store.getters.icons.delete.style"
        component="action-button"
        :confirmText="confirmText"
        cancelText="cancel"
        :disabled="isDeletionDisabled"
        :class="deleteButtonClasses"
    />
</template>

<script>
import uploadService from "@/services/uploadsService";

export default {
    inject: {
        deleteConfirmationMessage: {
            default: "Are you sure you want to delete this file?",
        },
        applicationAlias: {
            default: null,
        }, // No default value. No way to make it required
    },
    emits: ["fileDeleted"],
    computed: {
        application() {
            return this.$store.getters["applications/current"];
        },
        applicationIsLocked() {
            return this.$store.getters["applications/currentIsLocked"];
        },
        fileUrl() {
            return this.computedApplicationAlias
                ? `funding/application/${this.computedApplicationAlias}/uploads/${this.file.file}`
                : `funding/unassigned/uploads/${this.file.file}`;
        },
        isDeletionDisabled() {
            return (
                uploadService.isHardLocked(
                    this.file,
                    this.application,
                    this.applicationIsLocked
                ) || this.disabled
            );
        },
        softLockedModal() {
            return uploadService.softLockedModal(this.file, this.application);
        },
        confirmText() {
            if (this.softLockedModal) {
                return this.softLockedModal.confirmButtonText;
            }
            return "Yes, Delete";
        },
        deleteMessage() {
            if (this.softLockedModal) {
                return this.softLockedModal.message;
            }
            return this.deleteConfirmationMessage;
        },
        computedApplicationAlias() {
            return (
                this.applicationAlias ??
                this.$store.getters["applications/current"]?.alias
            );
        },
        deleteButtonClasses() {
            const classes = [];
            if (this.isDeletionDisabled) {
                classes.push("cursor-not-allowed");
            }
            return classes;
        },
    },
    props: {
        file: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        deleteFile(reason) {
            this.$http.delete(this.fileUrl, { data: { deleted_reason : reason } })
                .then((response) => {
                    // Since deleting a file could alter the Application statuses. We
                    // get the full application back from this operation
                    // and update the store with it
                    if (this.computedApplicationAlias) {
                        this.$store.dispatch("applications/setCurrentApplication", {
                            application: response.data,
                            refreshApplications: true,
                        });
                    }
                    this.$emit("fileDeleted");
                })
                .catch(() => {
                    const note = { 
                        type: 'error', 
                        message: 'There was a problem deleting this file' 
                    };
                    this.$store.dispatch('notifications/create', note);
                });
        },
    },
};
</script>

<style scoped>
.fas::before {
    vertical-align: middle;
}
</style>
