export default {
    namespaced: true,

    state() {
        return {
            mails: [],
            unread: 0,
            filters: {
                search: "",
                only_my_applications: false
            },
            pages: 1,
            per_page: 25,
            page: 1,
            total: 0,
            INBOX_ENABLED: false // This is a temporary meassure to disable inbox for demo
        }
    },

    getters: {
        mails(state) {
            return state.mails ?? [];
        },
        unread(state) {
            return state.unread ?? 0;
        },
        thread: (state) => (id) => state.mails.find(thread => thread.id === id) ?? null,
        search(state) {
            return state.filters.search;
        },
        onlyMyApplications(state) {
            return state.filters.only_my_applications;
        }, 
        // Pagination
        total(state) {
            return state.total;
        },
        page(state) {
            return state.page;
        },
        pages(state) {
            return state.pages;
        },
        per_page(state) {
            return state.per_page;
        },
        INBOX_ENABLED(state) {
            return state.INBOX_ENABLED;
        }
    },
    actions: {
        async loadAll({ dispatch, getters, rootGetters}) {
            const user = rootGetters.user;
            if (!user) return;
            let url = "funding/mail";
            // Build query parameters for filters
            const queryParamsBuilder = {};
            if (getters.search.length > 0) {
             queryParamsBuilder['search'] = getters.search;
            }
            if (getters.onlyMyApplications) {
                queryParamsBuilder['only_my_applications'] = 1;
            }

            if (getters.per_page) {
                queryParamsBuilder['per_page'] = getters.per_page;
            }
            if (getters.page) {
                queryParamsBuilder['page'] = getters.page;
            }
            const queryParams = new URLSearchParams(queryParamsBuilder).toString();
            if (queryParams.length > 0) {
                url += `?${queryParams}`;
            }
            const response = await this.$http.get(url, {
                successNotification: false,
            });
            const data = response.data?.data;
            const unread = response.data?.unread;
            const total = response.data?.total;
            const last_page = response.data?.last_page;
            const paginatedMails = {data, unread, total, last_page};
            dispatch('setMails', paginatedMails);
            
        },
        setMails({commit}, {data, unread, total, last_page}) {
            commit("setMails", data);
            commit("setUnread", unread);
            commit("setTotal", total);
            commit("setPages", last_page);
        },
        async initialize({ dispatch, _commit , _rootGetters}) {
            
            dispatch("loadAll");
            // Here we can add a timer to automatically refresh mails if we want to.
        },
    },
    mutations: {
        setMails(state, mails) {
            state.mails = mails;
        },
        setUnread(state, unread) {
            state.unread = unread;
        },  
        setSearch(state, search) {
            state.filters.search = search;
        },
        setOnlyMyApplications(state, value) {
            state.filters.only_my_applications = value;
            state.page = 1;
        },
        // Pagination
        setTotal(state, total) {
            state.total = total;
        },
        setPages(state, pages) {
            state.pages = pages;
        },
        setPage(state, page) {
            state.page = page;
        },
        setPerPage(state, per_page) {
            state.per_page = per_page;
            state.page = 1;
        },
    },

}