export default [
    {
        path: "",
        name: "application.trialEquipmentSelection",
        component: () =>
            import(
                "@/views/application/trialEquipmentSelection/trialEquipmentSelection.vue"
            ),
    },
];
