<template>
    <div
        v-if="!hide"
        class="px-4 py-3 leading-normal rounded-lg flex items-center relative"
        role="alert"
        :data-error="type === 'error'"
    >
        <!-- close button -->
        <i
            v-if="closeable"
            @click="$emit('close')"
            class="absolute top-2 right-2 cursor-pointer"
            :class="closeIconClass"
        ></i>

        <!-- icon -->
        <i
            v-if="icon"
            class="fad mr-3"
            :class="iconClasses"
            aria-hidden="true"
        ></i>

        <!-- content -->
        <p class="grow" :class="pclass">
            <slot></slot>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: "success",
        },
        icon: {
            type: [String, Boolean],
        },
        iconClass: {
            type: String,
        },
        closeIcon: {
            type: String,
        },
        closeIconStyle: {
            type: String,
            default: "solid",
        },
        timeout: {
            type: Number,
        },
        closeable: {
            type: Boolean,
        },
        hide: {
            type: Boolean,
            default: false,
        },
        pclass: {
            type: String,
        }
    },
    emits: ["close"],

    mounted() {
        if (this.timeout) {
            const _this = this;
            setTimeout(() => _this.$emit("close"), this.timeout * 1000);
        }
    },

    computed: {
        iconClasses() {
            let classes = `fa-${this.icon}`;

            if(this.iconClass){
                classes += " " + this.iconClass;
            }

            return classes; 
        },
        closeIconClass(){
            if(!this.closeIcon){
                return `fa-${this.closeIconStyle} fa-times`;
            }

            return `fa-${this.closeIconStyle} fa-${this.closeIcon}`; 
        }
    }
};
</script>
