<template>
    <div>
        <!-- legend -->
        <div class="border rounded my-4">
            <status-legend></status-legend>
        </div>

        <!-- upper pagination -->
        <Pagination
            storeName="applications"
            class="pb-1 mb-4"
        ></Pagination>

        <!-- privacy mode controls -->
        <privacy-mode-controls class="mb-4" />

        <!-- application list -->
        <application-card 
            v-for="application in applications" 
            :key="application.id"
            :application="application" 
            :privacyMode="privacyMode" 
            :privacyException="privacyException"
        />
  
        <!-- no results warning -->
        <div v-if="!applications.length">
            <p class="text-center">No results have been found.</p>
        </div>

        <!-- lower pagination -->
        <Pagination v-else
            storeName="applications"
        ></Pagination>
    </div>
</template>

<script>
import ApplicationCard from "@/components/dashboard/ApplicationCard.vue";
import StatusLegend from "@/components/dashboard/StatusLegend.vue";
import PrivacyModeControls from "@/components/dashboard/PrivacyModeControls";

//import statusService from "@/services/statusService.js";
export default {
    components: {
        ApplicationCard,
        StatusLegend,
        PrivacyModeControls,
    },
    computed: {
        applications() {
            return this.$store.getters["applications/all"];
        },
        privacyMode(){
            return this.$store.getters.privacyMode;
        },
        privacyException(){
            return this.$store.getters.privacyException;
        },
    },
    methods: {
        goToApplication() {
            this.$store.dispatch(
                'createApplication/setStartingNewApplication',
                true
            );
            this.$router.push({ name: 'application.create' });
        },
    },
};
</script>
