<template>
    <!-- label -->
    <component
        :is="labelTag"
        v-if="field.label !== false"
        class="text-gray-700 block text-lg order-1 form-label"
        :class="computedClasses"
        :for="field.name"
        v-bind="$attrs"
        v-bind:[dataName]="`${field.name}--label`"
    >
        <span class="form-label__text" v-html="labelText"></span>

        <!-- required asterisk -->
        <span
            v-if="showRequiredAsterisk"
            :title="asteriskTitle"
            class="form-label__required"
            :class="asteriskColor"
        >
            *
        </span>

        <!-- view examples from DB -->
        <view-examples
            v-if="field.examplesModel"
            :field="field"
        />

        <!-- view manually added examples -->
        <button 
            v-if="field.examples?.length"
            class="link--primary ml-1 text-sm"
            type="button"
            @click="openExamples = true"
        >
            view examples
        </button>
    </component>

    <!-- examples -->


    <!-- example modals-->
    <base-modal 
        :open="openExamples"
        @close="openExamples = false"
        class="md:w-md"
    >
        <div class="text-left grid gap-5 p-4">
            <!-- description -->
            <div v-if="exampleDescription !== false" class="italic text-blueGray-600 -mt-3">{{ exampleDescription }}</div>
            
            <div v-for="example in field.examples" :key="example.title" class="border rounded-sm border-secondary-900 shadow">
                <div class="font-bold bg-secondary-100 text-secondary-900 p-3 uppercase">{{ example.title }}</div>
                <div class="p-3 text-gray-500 grid gap-4" v-html="example.text"></div>
            </div>
        </div>


    </base-modal>

    <!-- description -->
    <div
        v-if="field.description"
        v-html="field.description"
        class="form-label__description text-gray-500 text-base mb-2 order-1 col-span-full"
        :class="field.descriptionClass"
        v-bind:[dataName]="`${field.name}--description`"
    ></div>

    <!-- max count -->
    <div
        v-if="field.maxCount"
        class="form-label__description text-gray-500 text-base mb-2 order-1 col-span-full"
        :class="field.maxCountClass"
        v-bind:[dataName]="`${field.name}--max-count`"
    >
        Choose up to {{ field.maxCount }}
    </div>

    <!-- alert -->
    <component 
        v-if="alertContent.length" 
        :is="alertTag" 
        :icon="false" 
        class="form-label__alert mt-2 mb-4 text-base order-1 col-span-full" 
        v-bind:[dataName]="`${field.name}--alert`"
    >
        <ul class="list-disc px-5 py-2 grid gap-2">
            <li 
                v-for="content in alertContent" 
                :key="content" 
                v-html="content"
            ></li>
        </ul>
    </component>

</template>
<script>
import startCase from "lodash/startCase";
import ViewExamples from "@/components/global/formElements/examples/ViewExamples.vue";

export default {
    props: ["field", "tag", "required"],
    inject: ["dataName"],
    components: {
        ViewExamples,
    },
    inheritAttrs: false,
    data(){
        return {
            openExamples: false,
        }
    },

    computed: {
        computedClasses(){
            let classes = [];

            if(this.field.inline){
                classes.push("border border-secondary-400 bg-secondary-50 md:rounded-l-md text-center place-content-center md:border-r-0 p-4 md:p-2");
            } else {
                classes.push("col-span-full");
            }

            if(this.field.labelClass){
                classes.push(this.field.labelClass);
            }

            return classes.join(" ");
        },

        showRequiredAsterisk(){
            return this.required && !this.field.hideRequiredAsterisk;
        },

        asteriskTitle() {
            if(this.field.softRequired){
                return "This field must be filled out to complete this application";
            }

            return "This field must be filled out to continue";
        },

        asteriskColor() {
            /*
            if(this.field.softRequired){
                return "text-yellow-400";
            }
            */

            return "text-red-600";
        },

        labelTag() {
            return this.tag ?? "label";
        },

        // if we have manually entered a label use that,
        // otherwise use the field name to generate the label text
        labelText() {
            return this.field.label
                ? this.field.label
                : startCase(this.field.name);
        },

        exampleDescription() {
            return this.field.exampleDescription ?? "Example text is provided to help therapists understand the intention of the question and shouldn't be used in place of your own words when writing a clinical evaluation.";
        },

        alertTag(){
            return this.field.alertTag ?? "info-alert";
        },

        alertContent(){
            if(!this.field.alert){
                return [];
            }

            if(typeof this.field.alert === "string"){
                return [this.field.alert];
            }

            if(Array.isArray(this.field.alert)){
                return this.field.alert;
            }

            console.error("Invalid Alert Content");
            return [];
        },
    },
};
</script>
