<template>
<transition :name="transition" appear>
    <div class="relative z-10 flex w-full mt-3 items-center">

        <!-- status icon -->
        <div class="hidden sm:block text-3xl w-12">
            <status-icon :status="status" />
        </div>
        
        
        <div class="min-w-0 relative w-full rounded-lg overflow-hidden drop-shadow-sm">

            <!-- left hand color splash -->
            <div
                class="absolute h-full w-[10px] left-0 top-0 bottom-0 opacity-40 z-10"
                :class="backgroundStatusColor"
            ></div>
            
            <!-- main bar wrapper -->
            <div class="relative bg-white w-full border border-gray-300 rounded-lg">
                <div class="pl-6 pr-2.5" :class="computedClasses.padding">
                    <div class="flex items-center justify-between">
                        <div
                            class="whitespace-nowrap text-ellipsis overflow-hidden leading-tight -ml-0.5 font-medium"
                            :class="computedClasses.title"
                            @click="$emit('toggle')"
                        >
                            {{ titleText }}
                        </div>

                        <slot name="buttons"></slot>
                    </div>

                  <slot name="content"></slot>
                </div>
            </div>
        </div>
    </div>
</transition>
</template>

<script>
export default {
    emits: ["toggle"],
    props: {
        section: {
            type: Object,
            required: true,
        },
        transition: {
            type: String,
            default: "overview-sections",
        },
        title: {
            type: String,
        },
        size: {
            type: String,
            default: "small",
        },
        clickableTitle: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
        
        };
    },

    computed: {
        computedClasses() {
            // defaults to small size
            let classes = {
                padding: "py-2",
                title: "text-sm md:text-base",
            };

            if(this.size === "large") {
                classes.padding = "py-3",
                classes.title = "sm:text-lg md:text-xl";
            }

            if(this.clickableTitle){
                classes.title += " cursor-pointer";
            }

            return classes;
        },

        backgroundStatusColor() {
            return this.$store.getters.getStatusBackgroundColor(this.status);
        },

        titleText() {
            return this.title ?? this.section.title;
        },

        status() {
            return this.section.statusId ?? this.section.status_id;
        },
    },

    methods: {
    },
};
</script>