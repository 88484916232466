<template>
    <error-alert :icon="false" :closeIcon="$store.getters.icons.delete.icon" :closeIconStyle="$store.getters.icons.delete.style" :closeable="isAdmin" @close="deleteRejection">
        <div class="w-full">
            <div class="flex items-center gap-2">
                <i class="fad fa-bomb"></i>
                <span class="font-bold mt-1">Section Rejected by Funding Staff</span>
                <span class="text-sm mt-1">{{ humanReadableDate }}</span>
            </div>
            <div>
                <span class="font-bold mr-1">Reasons:</span> 
                <button v-if="isAdmin" class="ml-1" title="Edit Rejection Reasons" @click="showEditModal = true"><i class="fa-light fa-pen-to-square"></i></button>
                <div class="ck-content" v-html="rejection.reasons"></div>
            </div>
            <div class="text-center italic text-sm mt-2">
                Please correct the described section and re-save the section
            </div>
        </div>

        <base-modal
            :open="showEditModal"
            @close="showEditModal = false"
            icon="pen-to-square"
            title="Edit Rejection Reasons"
            class="sm:w-md"
        >
            <quick-form 
                :schema="schema"
                @success="onEdit"
                ref="form"
            />
        </base-modal>
    </error-alert>
</template>

<script>
import dateService from "@/services/utils/dateFormatterService";

export default {
    props: {
        rejection: {
            type: Object,
            required: true,
        },
    },
    data(){
        return {
            showEditModal: false,
        }
    },
    computed: {
        application(){
            return this.$store.getters["applications/current"] ?? {};
        },

        isAdmin(){
            return this.$store.getters.isAdmin;
        },

        humanReadableDate(){
            return dateService.shortText(this.rejection.created_at);
        },

        schema(){
            return {
                endpoint: `funding/application/${this.application.alias}/reject/${this.rejection.id}`,
                method: "PATCH",
                name: "edit-rejection",
                resetOnSuccess: false,
                fields: [
                    {
                        name: "reasons",
                        label: "Rejection Reasons",
                        type: "richtext",
                        value: this.rejection.reasons,
                        required: true,
                        toolbar: [
                            'bulletedList', 
                        ],
                    },
                ],
            };
        },
    },
    methods: {
        deleteRejection(){
            this.$store.dispatch('applications/clearRejection', this.rejection.id);
        },

        onEdit({ data }){
            this.showEditModal = false;
            this.$store.dispatch("applications/setCurrentApplication", {
                application: data,
                refreshApplications: true,
            });
        },
    },
};
</script>

<style scoped>
</style>
