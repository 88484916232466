<template>
    <div class="text-xs border p-2 my-2 rounded" :class="theme.border">
        <div class="flex gap-2 items-center">
            <span class="mr-auto">PRESETS</span>
            <confirm-button 
                v-if="canUpdatePreset" 
                title="Update Preset" 
                :class="theme.button"
                icon="save"
                component="minimal-button"
                :message="`Update the <span class='font-semibold'>${selectedPreset.name}</span> preset with your current filters?`"
                @confirmed="onUpdatePreset"
            >
            </confirm-button>

            <button 
                v-if="hasPresets" 
                title="Edit Presets" 
                :class="theme.button"
                @click="showModal = 'edit-presets'" 
            >
                <i class="fas fa-edit"></i>
            </button>
            
            <button 
                title="Add New Preset" 
                :class="theme.button"
                @click="showModal = 'add-preset'" 
            >
                <i class="fas fa-circle-plus"></i>
            </button>
        </div>

        <select 
            v-if="hasPresets" 
            v-model="selectValue"
            class="capitalize w-full mt-2 !outline-none"
            :class="theme.dropdown"
            @change="onPresetSelected($event)"
        >
            <option value="" disabled selected>-- select a preset --</option>
            <option value="clear">-- Clear Filters --</option>
            <option 
                v-for="preset in presets" 
                :key="preset.id" 
                :value="preset.id" 
                >
                    {{ preset.name }}
            </option>
        </select>

        <component v-if="showModal" :is="showModal" 
            @close="showModal = null" 
            @save="onSavePreset" 
            :type="type" 
            :presets="presets" 
        />
    </div>

</template>

<script>
import EditPresets from "./EditPresets.vue";
import AddPreset from "./AddPreset.vue";
import isEqual from "lodash/isEqual";

export default {
    components: {
        EditPresets,
        AddPreset,
    },

    props: {
        type: {
            type: String,
            required: true,
        },
        setTheme: {
            type: String,
            default: 'default',
        },
    },

    data() {
        return {
            showModal: null,
            selectValue: "",
            themes: {
                dark: {
                    button: 'text-secondary-700 border-secondary-700 hover:text-accentBlue',
                    dropdown: 'bg-headerBlue',
                    border: 'border-secondary-700',
                },
                default: {
                    button: 'text-slate-500 border-slate-500 hover:bg-gray-50',
                    border: 'border-gray-300',
                    dropdown: 'bg-white',
                },
            },
        };
    },

    methods: {
        clearSelect(){
            this.selectValue = "";
        },

        onPresetSelected(event){
            let value = event.target.value;

            // if we have no value then return
            if(!value){
                return;
            };

            // clear our filters if we have a clear value
            if(value === "clear"){
                this.clearFilters();
                return;
            }

            // otherwise load our presets
            let preset = this.presets.find(preset => preset.id == value);
            this.$store.dispatch(`${this.type}/setPresets`, preset?.filters);
        },


        clearFilters(){
            this.clearSelect();
            this.$store.dispatch(`${this.type}/clearFilters`);

            let actions = {
                tasks: "tasks/getUserTasks",
                applications: "applications/loadAll",
            };

            this.$store.dispatch(actions[this.type]);
        },

        onSavePreset(name){
            let data = {
                name: name,
                type: this.type,
                filters: this.filteredParams,
            };

            this.$http.post("funding/filter-preset", data)
                .then(({data}) => {
                    this.$store.dispatch("setFilterPresets", data);
                    let preset = this.presets.find(preset => preset.name === name);
                    this.selectValue = preset.id;
                });
        },

        onUpdatePreset(){
            let data = {
                name: this.selectedPreset.name,
                filters: this.filteredParams,
            };

            this.$http.patch(`funding/filter-preset/${this.selectedPreset.id}`, data)
                .then(({data}) => {
                    this.$store.dispatch("setFilterPresets", data);
                });
        },
    },

    computed: {
        user(){
            return this.$store.getters.user ?? {};
        },

        presets(){
            let presets = this.user.filter_presets?.filter(preset => preset.type === this.type) ?? [];

            presets.sort((a, b) => a.name.localeCompare(b.name));

            return presets;
        },

        hasPresets(){
            return this.presets.length > 0;
        },

        selectedPreset(){
            return this.presets.find(preset => preset.id == this.selectValue);
        },

        storeParams(){
            let getters = {
                tasks: "tasks/tasksParams",
                applications: "applications/applicationParams",
            };

            if(!getters[this.type]){
                return {};
            }

            return this.$store.getters[getters[this.type]];
        },

        filteredParams(){
            let params = {};
            let ignored = ["page", "pages", "total"];

            Object.keys(this.storeParams).forEach(key => {
                if(!ignored.includes(key)){
                    params[key] = this.storeParams[key];
                }
            });

            return params;
        },

        theme(){
            // set our base theme
            return this.themes[this.setTheme] ?? {};
        },

        canUpdatePreset(){
            if(!this.selectedPreset){
                return false;
            }

            for (const key in this.filteredParams) {
                let savedValue = this.selectedPreset.filters[key];
                
                // need to convert null searches into empty strings
                if(key === 'search' && !savedValue){
                    savedValue = "";
                }

                if(!isEqual(this.filteredParams[key], savedValue)){
                    return true;
                }
            }

            return false;
        },
    },
}
</script>

<style>

</style>