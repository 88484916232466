<template>
    <div>
        <div class="border-b-2">
            <slot name="title"><span class="font-bold">Uploads</span></slot>
        </div>

        <hipaa-warning class="mt-4" v-if="showHipaaWarning" />

        <div class="my-5 border rounded w-full max-w-full overflow-auto">
            <table :class="tableClass">
                <thead>
                    <tr class="border-b">
                        <th
                            class="whitespace-nowrap text-left py-2"
                            :class="thClasses"
                        >
                            Actions
                        </th>
                        <th
                            class="whitespace-nowrap text-left py-2"
                            :class="thClasses"
                        >
                            Name <span class="text-xxs text-gray-600 ml-1 font-normal">hover over <i class="fa-regular fa-comment text-xxs cursor-pointer text-gray-500"></i>
             to view comment</span>
                        </th>
                        <th
                            v-if="extraColumns"
                            class="min-w-[170px] whitespace-nowrap text-left py-2"
                            :class="thClasses"
                        >
                            Type
                        </th>
                        <th
                            v-if="showCategory"
                            class="whitespace-nowrap text-left py-2 px-3"
                        >
                            Category
                        </th>
                        <th
                            class="whitespace-nowrap text-left py-2"
                            :class="thClasses"
                        >
                            Uploaded By
                        </th>
                        <th
                            class="whitespace-nowrap text-left py-2"
                            :class="thClasses"
                        >
                            Date
                        </th>
                        <th
                            v-if="extraColumns"
                            class="whitespace-nowrap text-left py-2"
                            :class="thClasses"
                        >
                            Status
                        </th>
                        <th
                            v-if="extraColumns && !hideInternalAndExpDate"
                            class="whitespace-nowrap text-left py-2"
                            :class="thClasses"
                        >
                            Expiration Date
                        </th>
                        <th
                            v-if="showPageNumber"
                            class="whitespace-nowrap text-left py-2"
                            :class="thClasses"
                        >
                            Page #
                        </th>
                        <th
                            v-if="
                                extraColumns &&
                                isAdmin &&
                                !hideInternalAndExpDate
                            "
                            class="text-break whitespace-nowrap text-left py-2"
                            :class="thClasses"
                        >
                            Internal Only
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <file-collection-item
                        @fileDeleted="$emit('fileDeleted')"
                        @editFile="handleEditClick"
                        v-for="file in sortedFiles"
                        :disabled="disabled"
                        :file="file"
                        :key="file.file"
                        :extraColumns="extraColumns"
                        :showCategory="showCategory"
                        :showPageNumber="showPageNumber"
                        :hideInternalAndExpDate="hideInternalAndExpDate"
                    ></file-collection-item>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import FileCollectionItem from "./FileCollectionItem.vue";
import dateService from "@/services/utils/dateFormatterService";

export default {
    emits: ["fileDeleted", "editFile"],
    components: { FileCollectionItem },
    props: {
        hideInternalAndExpDate: {
            type: Boolean,
            default: false,
        },
        files: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        extraColumns: {
            type: Boolean,
            required: false,
            default: false,
        },
        showCategory: {
            type: Boolean,
            required: false,
            default: false,
        },
        orderBy: {
            type: String,
            default: "date",
        },
        showHipaaWarning: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        sortedFiles() {
            if(!this.orderBy){
                return this.files.slice();
            }
            if (!this.files) return [];
            if (this.orderBy == "date") {
                return this.files
                    .slice()
                    .sort(dateService.sortByCreatedComparator, "asc");
            } else if (this.orderBy == "typeString") {
                return this.files.slice().sort((a, b) => {
                    let av =
                        a.typeString === "Other" ? a.other_type : a.typeString;
                    let bv =
                        b.typeString === "Other" ? b.other_type : b.typeString;
                    if (av >= bv) {
                        return 1;
                    } else if (av < bv) {
                        return -1;
                    }
                });
            } else {
                return this.files.slice().sort((a, b) => {
                    if (a[this.orderBy] >= b[this.orderBy]) {
                        return 1;
                    } else if (a[this.orderBy] < b[this.orderBy]) {
                        return -1;
                    }
                });
            }
        },
        thClasses() {
            const classes = [];
            if (this.extraColumns) {
                classes.push("px-1");
            } else {
                classes.push("px-4");
            }
            return classes;
        },
        tableClass() {
            if (this.extraColumns) {
                return "text-xs";
            } else {
                return "";
            }
        },
        showPageNumber() {
            // Check if at least one file has the "page" attribute
            return this.extraColumns && this.files.some((file) => file.page);
        },
    },
    methods: {
        handleEditClick(id) {
            this.$emit("editFile", id);
        },
    },
};
</script>
