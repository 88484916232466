// used to keep track of the amount of request we're currently handling
export default (http, { store }) => {
    // for each request that is made, increment our spinner request queue
    http.interceptors.request.use((request) => {
        store.dispatch("http/increment");
        return request;
    }, (error) => {
        store.dispatch("http/decrement");
        return Promise.reject(error);
    });

    // for each successful response decrement our spinner request queue (if needed)
    const responseCallback = (response) => {
        store.dispatch("http/decrement");
        return response;
    };

    // for each failed response decrement our spinner request queue (if needed)
    const errorCallback = (error) => {
        store.dispatch("http/decrement");
        return Promise.reject(error);
    };

    // apply response interceptors
    http.interceptors.response.use(responseCallback, errorCallback);
};
