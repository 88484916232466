<template>
    <li class="text-ellipsis overflow-hidden">
        <span v-if="item.title" class="font-bold mr-1 inline-block">{{ item.title }}: </span>
        <a v-if="item.link" :href="item.link" target="_blank" >
            {{ computedValue }}
        </a>
        <span v-else v-html="computedValue" class="inline-block"></span>
    </li>
</template>

<script>
import dateService from "@/services/utils/dateFormatterService";

export default {
    name: "SummaryCardItem",

    props: {
        item: {
            type: Object,
            required: true,
        }
    },

    computed: {
        computedValue(){
            if(this.item.date){
                return dateService.humanReadable(this.item.value);
            }

            return this.item.value;
        }
    },
}
</script>

<style scoped>
a{
    color:#0891B2;
    font-weight: bold;
    text-decoration: underline;
}
</style>