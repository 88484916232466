const sectionList = [
    "upload",
    "overview",
    "termsAndConditions",
    "rolesAndResponsibilities",
    "responsibleParty",
];

export default {
    namespaced: true,

    state() {
        return {};
    },

    mutations: {},

    actions: {},

    getters: {
        tandcInfo(_, __, rootState) {
            return rootState.applications.current?.trial_terms_and_conditions_info ?? {};
        },

        sections(_state, getters, _rootState, _rootGetters) {
            const sections = {};

            // cycle through our non-signature sections and forward to the appropriate getter
            sectionList.forEach(
                (section) => (sections[section] = getters[section])
            );

            return sections;
        },

        /**
         * Is this overview complete?
         *
         * @returns int (status_id)
         */
        overview(_state, _getters, _rootState, rootGetters) {
            return rootGetters["applications/tandcType"] === null
                ? rootGetters.getStatusId("NOT_STARTED")
                : rootGetters.getStatusId("COMPLETED");
        },

        /**
         * Is this upload complete?
         *
         * @returns int (status_id)
         */
        upload(_state, _getters, rootState, rootGetters) {
            const uploads = rootState.applications.current?.trial_terms_and_conditions_info?.uploads ?? [];
            const hasUploads = uploads.length
                && uploads.some(upload => upload.status_id !== rootGetters.getStatusId("REJECTED"));

            return hasUploads ? rootGetters.getStatusId("COMPLETED") : rootGetters.getStatusId("NOT_STARTED");
        },

        /**
         * Is this tandc section complete?
         *
         * @returns int (status_id)
         */
        termsAndConditions(_state, getters, _rootState, _rootGetters) {
            return getters.tandcInfo.trial_terms_and_conditions?.status_id;
        },

        /**
         * Is this tandc section complete?
         *
         * @returns int (status_id)
         */
        rolesAndResponsibilities(_state, getters, _rootState, _rootGetters) {
            return getters.tandcInfo.trial_roles_and_responsibilities?.status_id;
        },

        /**
         * Is this tandc section complete?
         *
         * @returns int (status_id)
         */
        responsibleParty(_state, getters, _rootState, _rootGetters) {
            return getters.tandcInfo.trial_responsible_party?.status_id;
        },
    },
};
