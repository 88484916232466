function hasValidGoals(goals, minShortTerm = 2, minLongTerm = 2) {
    // make sure we have an array to avoid breaking things
    goals = Array.isArray(goals) ? goals : [];
       
    let shortTerm = 0;
    let longTerm = 0;

    goals.forEach(item => {
        // do we have a truthy value set for term, goal, and goal doesn't contain 'xxx'?
        if(!item.term || !item.goal || /xxx/.test(item.goal.toLowerCase())){
            return;
        }
            
        // increment the appropriate term
        if(item.term === "short term"){
            shortTerm++;
            return;
        }
        
        if(item.term === "long term"){
            longTerm++;
        }
    });
    
    // if our valid goals are greater than or equal to the minCount we win the prize!
    return shortTerm >= minShortTerm && longTerm >= minLongTerm;
}

export default hasValidGoals;