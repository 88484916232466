<template>
    <footer class="footer px-12 lg:px-8 pb-8 pt-14 relative text-white text-sm" v-if="!hideFooter">
        <!-- swoopty -->
        <img
            src="/img/footer_wave.svg"
            class="swoopty hidden lg:block absolute"
        />

        <!-- main content -->
        <div
            class="grid gap-4 md:grid-cols-2 lg:grid-cols-4 items-start max-w-screen-lg m-auto"
        >
            <!-- logo -->
            <div class="self-center">
                <router-link v-if="$store.getters.loggedIn" :to="{ name: 'dashboard' }">
                    <img src="/img/logo.svg" class="w-3/5 m-auto" />
                </router-link>
                <a v-else :href="landingSiteUrl">
                    <img src="/img/logo.svg" class="w-3/5 m-auto" />
                </a>
            </div>

            <div class="grid gap-1">
                <label class="mb-3 uppercase text-secondary-300 text-base">
                    Other
                </label>
                <a
                    class="link--footer"
                    :href="`${this.landingSiteUrl}funding-101`"
                >
                    About AAC Funding
                </a>
                <a
                    class="link--footer"
                    href="https://www.prc-saltillo.com/terms-of-use"
                >
                    Terms &amp; Conditions
                </a>
                <a
                    class="link--footer"
                    href="https://www.prc-saltillo.com/privacy-policy"
                >
                    Privacy Policy
                </a>
                <a
                    class="link--footer"
                    :href="`${this.landingSiteUrl}notice-of-privacy-practices`"
                >
                    Notice of Privacy Practices
                </a>
                <a
                    class="link--footer"
                    href="https://www.prc-saltillo.com/contact"
                >
                    Need another language?
                </a>
                <a
                    class="link--footer"
                    href="https://www.prc-saltillo.com/returns"
                >
                    Device Returns Admin
                </a>
                <br />
                <GoogleTranslateSelect
                    :languages="languages"
                    default-language-code="en"
                    default-page-language-code="en"
                    :fetch-browser-language="true"
                    trigger="click"
                />
            </div>

            <div class="grid gap-1">
                <label class="mb-3 uppercase text-secondary-300 text-base">
                    Contact
                </label>
                <p>
                    PRC-Saltillo
                    <br />
                    1022 Heyl Road
                    <br />
                    Wooster, OH 44691
                    <br />
                    <i class="fa-duotone fa-phone-volume"></i>
                    &ensp;(800) 268-5224
                    <br />
                    <i class="fad fa-fax"></i>
                    &ensp;(330) 202-5840
                    <br />
                    <i class="fad fa-paper-plane"></i>
                    &ensp;
                    <a
                        class="link--footer"
                        href="mailto:funding@prc-saltillo.com"
                    >
                        funding@prc-saltillo.com
                    </a>
                </p>
            </div>

            <div class="grid gap-1">
                <label class="mb-3 uppercase text-secondary-300 text-base">
                    Legal
                </label>
                <p>
                    &copy; <span>{{ getYear }}</span> PRC-Saltillo. Please use an encrypted email
                    service to send documents with PHI. Regular email does not
                    meet HIPAA requirements for protecting PHI.
                </p>
                <p>App version: {{appVersion}}</p>
            </div>
        </div>
    </footer>
</template>

<script>
import GoogleTranslateSelect from '@google-translate-select/vue3';
import packageJson  from "../../../package.json";

export default {
    data() {
        return {
            options: null,
            languages: [
                {
                    code: 'af',
                    name: 'Afrikaans',
                    cname: '南非语',
                    ename: 'Afrikaans',
                },
                {
                    code: 'ar',
                    name: 'العربية',
                    cname: '阿拉伯语',
                    ename: 'Arabic',
                },
                {
                    code: 'hy',
                    name: 'Հայերեն',
                    cname: '亚美尼亚语',
                    ename: 'Armenian',
                },
                {
                    code: 'az',
                    name: 'Азәрбајҹан дили',
                    cname: '阿塞拜疆语',
                    ename: 'Azerbaijani',
                },
                {
                    code: 'be',
                    name: 'беларуская мова',
                    cname: '白俄罗斯语',
                    ename: 'Belarusian',
                },
                {
                    code: 'bg',
                    name: 'български език',
                    cname: '保加利亚语',
                    ename: 'Bulgarian',
                },
                {
                    code: 'ca',
                    name: 'Català',
                    cname: '加泰罗尼亚语',
                    ename: 'Catalan',
                },
                {
                    code: 'cs',
                    name: 'čeština',
                    cname: '捷克语',
                    ename: 'Czech',
                },
                {
                    code: 'zh-CN',
                    name: 'Chinese (Simplified)',
                    cname: '中文 (简体)',
                    ename: 'Chinese (Simplified)',
                },
                {
                    code: 'zh-TW',
                    name: 'Chinese (Traditional)',
                    cname: '中文 (繁体)',
                    ename: 'Chinese (Traditional)',
                },
                {
                    code: 'hr',
                    name: 'Српскохрватски језик',
                    cname: '克罗地亚语',
                    ename: 'Croatian',
                },
                {
                    code: 'da',
                    name: 'Danmark',
                    cname: '丹麦语',
                    ename: 'Danish',
                },
                {
                    code: 'de',
                    name: 'Deutsch',
                    cname: '德语',
                    ename: 'German',
                },
                {
                    code: 'et',
                    name: 'eesti keel',
                    cname: '爱沙尼亚语',
                    ename: 'Estonian',
                },
                {
                    code: 'en',
                    name: 'English',
                    cname: '英语',
                    ename: 'English',
                },
                {
                    code: 'eu',
                    name: 'Euskara',
                    cname: '巴斯克语',
                    ename: 'Basque',
                },
                {
                    code: 'es',
                    name: 'Español',
                    cname: '西班牙语',
                    ename: 'Spanish',
                },
                {
                    code: 'tl',
                    name: 'Filipino',
                    cname: '菲律宾语',
                    ename: 'Filipino',
                },
                {
                    code: 'fi',
                    name: 'Finnish',
                    cname: '芬兰语',
                    ename: 'Finnish',
                },
                {
                    code: 'fr',
                    name: 'Français',
                    cname: '法语',
                    ename: 'French',
                },
                {
                    code: 'sq',
                    name: 'Gjuha shqipe',
                    cname: '阿尔巴尼亚语',
                    ename: 'Albanian',
                },
                {
                    code: 'el',
                    name: 'Ελληνικά',
                    cname: '希腊语',
                    ename: 'Greek',
                },
                {
                    code: 'id',
                    name: 'Indonesia',
                    cname: '印度尼西亚语',
                    ename: 'Indonesian',
                },
                {
                    code: 'ga',
                    name: 'Irish',
                    cname: '爱尔兰语',
                    ename: 'Irish',
                },
                {
                    code: 'it',
                    name: 'Italiano',
                    cname: '意大利语',
                    ename: 'Italian',
                },
                {
                    code: 'ja',
                    name: 'にほんご',
                    cname: '日语',
                    ename: 'Japanese',
                },
                {
                    code: 'ko',
                    name: '한국어',
                    cname: '韩语',
                    ename: 'Korean',
                },
                {
                    code: 'lt',
                    name: 'lietuvių kalba',
                    cname: '立陶宛语',
                    ename: 'Lithuanian',
                },
                {
                    code: 'ro',
                    name: 'limba română',
                    cname: '罗马尼亚语',
                    ename: 'Romanian',
                },
                {
                    code: 'hu',
                    name: 'magyar',
                    cname: '匈牙利语',
                    ename: 'Hungarian',
                },
                {
                    code: 'ms',
                    name: 'Malay',
                    cname: '马来西亚语',
                    ename: 'Malay',
                },
                {
                    code: 'nl',
                    name: 'Nederlands',
                    cname: '荷兰语',
                    ename: 'Dutch',
                },
                {
                    code: 'no',
                    name: 'norsk',
                    cname: '挪威语',
                    ename: 'Norwegian',
                },
                {
                    code: 'pl',
                    name: 'Polski',
                    cname: '波兰语',
                    ename: 'Polish',
                },
                {
                    code: 'pt',
                    name: 'Português',
                    cname: '葡萄牙语',
                    ename: 'Portuguese',
                },
                {
                    code: 'ru',
                    name: 'Русский',
                    cname: '俄语',
                    ename: 'Russian',
                },
                {
                    code: 'sv',
                    name: 'Swedish',
                    cname: '瑞典语',
                    ename: 'Swedish',
                },
                {
                    code: 'th',
                    name: 'ภาษาไทย',
                    cname: '泰语',
                    ename: 'Thai',
                },
                {
                    code: 'tr',
                    name: 'Turkish',
                    cname: '土耳其语',
                    ename: 'Turkish',
                },
                {
                    code: 'uk',
                    name: 'українська мова',
                    cname: '乌克兰语',
                    ename: 'Ukrainian',
                },
            ]
        }
    },
    components: {
        GoogleTranslateSelect
    },
    computed: {
        landingSiteUrl() {
            return process.env.VUE_APP_LANDING_SITE_URL;
        },
        hideFooter() {
          return this.$route.meta.hideFooter;
        },
        getYear() {
            return new Date().getFullYear();
        },
        appVersion() {
            console.log('App version: '+packageJson.version);
            return packageJson.version;
        }
    },
};
</script>
<style>
.footer {
    background-color: #2c2d3c;
    min-height: 300px;
}
.swoopty {
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    min-width: 100%;
    pointer-events: none;
}
.google-translate-select {
    border:1px solid #666;
    border-radius: 6px;
    margin-top: 1em;
}
.google-translate-select-dropdown__menu {
    width: 100.5%;
    top: 0;
    transform: translateY(-100%);
}
/* attempt to hide google translate loady icon thing */
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
    display:none;
}
</style>