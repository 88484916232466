<template>
    <base-banner class="bg-status_UNDER_REVIEW/75" v-show="$route.name !== 'help'">
        <slot></slot>
    </base-banner>
</template>

<script>
export default {};
</script>

<style scoped></style>
