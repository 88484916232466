<template>
    <div class="w-full max-w-full">
        <!-- uploader -->
        <base-uploader
            @uploadSuccess="(response) => this.$emit('uploadSuccess', response)"
            @uploadReady="(upload) => this.$emit('uploadReady', upload)"
            @fileInput="() => this.$emit('fileInput')"
            v-bind="$props"
            :currentFiles="files?.length"
            :key="baseUploaderKey"
        >
            <slot></slot>
        </base-uploader>
        
        <!-- file collection -->
        <div v-if="files && files.length > 0" class="overflow-auto pt-8">
            <file-collection 
                @fileDeleted="handleFileDeletion" 
                :disabled="disabled" 
                :files="files" 
                :showHipaaWarning="showHipaaWarning"
                @editFile="(id) => $emit('editFile', id)"
            >
                <template #title>
                    <span class="font-bold">{{ collectionTitle }}</span>
                </template>
            </file-collection>
        </div>
    </div>
</template>

<script>
import FileCollection from "./FileCollection.vue";
import BaseUploader from "./BaseUploader.vue"
import { getCurrentInstance } from 'vue';
const DEFAULT_ACCEPTED_TYPES = ['pdf','jpg','jpeg','png','tif','tiff','gif'];
const DEFAULT_MAX_SIZE = 50000000;
export default {
    emits: ['uploadReady','uploadSuccess','fileDeleted', 'editFile', 'fileInput'],
    components: {FileCollection, BaseUploader},
    data() {
        return {
            fileToUpload: [],
            errors: [],
            file: null,
            dragging: false,
            uploadStatus: 'ready',
        }
    },
    props: {
        holdUpload: {
            type: Boolean,
            required: false,
            default: false,
        },
        fullScreenDrop: {
            type: Boolean,
            default: true,
            required: false
        },
        endpoint: {
            type: String,
            required: true
        },
        files: {
            type: Array,
            required: false
        },
        collectionTitle: {
            type: String,
            required: false
        },
        maxSize: {
            // Max Size in MB
            type: Number,
            required: false,
            default: DEFAULT_MAX_SIZE
        },
        maxDimensions: {
            // String describing the max dimensions of an image upload
            type: String,
            required: false,
            default: null
        },
        acceptedTypes: {
            type: Array,
            required: false,
            default: DEFAULT_ACCEPTED_TYPES
        },
        maxFiles: {
            // If maxFiles is 0. The check is turned off
            type: Number,
            required: false,
            default: 0 
        },
        confirm: {
            type: Object,
            default: null,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        formBody: {
            type: Object,
            default: () => {return {}},
            required: false,
        }, 
        showHipaaWarning: {
            type: Boolean,
            default: true,
        },
        showCompletedCheckmark: {
            type: Boolean,
            default: false,
            required: false,
        },
        completedCheckmarkSize: {
            type: String,
            default: "3x",
            required: false
        },
        //Whether or not we allow mp4/mov/wmv
        video: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    computed: {
        componentKey() {
            const instance = getCurrentInstance();
            return instance.vnode.key;
        },
        baseUploaderKey() {
            const key = this.componentKey + '-base-uploader';
            console.log('key', key);
            return key;
        },
        modalComputedClasses() {
            let classes = [];
            if (this.fullScreenDrop || !this.dragging) {
                classes = [...classes, "border-4", "border-dashed"];
            } else {
                classes = [...classes, "border-4", "border-primary-600"];
            }

            return classes;
        },
    },
    methods: {
        handleFileDeletion() {
            // TODO: Decide if deleting a file should clear the errors
            // The deletion may cause the files amount to be under the
            // maximum files. So the error could go away in this case
            this.errors = [];
            this.$emit("fileDeleted");
        }
    },
}
</script>

<style scoped>

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>