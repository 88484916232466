<template>
    <base-modal 
        :open="true"
        :title="title"
        :icon="icon"
        class="xm:w-md"
    >
        <quick-form
            :schema="schema"
            @success="onSuccess"
            ref="form"
        >
            <template #buttons>
                <secondary-button @click="$emit('close')" class="mr-auto">close</secondary-button>
            </template>

        </quick-form>
    </base-modal>
</template>

<script>
export default {
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        example: {
            type: Object,
            required: false,
        },
        field: {
            type: Object,
            required: true,
        },
    },

    methods: {
        onSuccess({ data }){
            this.$store.dispatch('example/setExamples', { model: this.field.examplesModel, examples: data });
            this.$emit('close');
        },
    },

    computed: {
        icon(){
            return this.example ? `edit` : 'circle-plus';
        },

        title(){
            return this.example ? `Edit Example` : 'Create New Example';
        },

        endpoint(){
            // are we editing or creating new?
            return this.example ? `funding/example/${this.example.id}` : 'funding/example';
        },

        method(){
            // are we editing or creating new?
            return this.example ? `patch` : 'post';
        },
        
        name(){
            return this.field?.examplesForceName ?? this.field?.name;
        },

        schema(){
            return {
                endpoint: this.endpoint,
                method: this.method,
                name: "edit-example",
                submitText: "save",
                fields: [
                    {
                        name: "model",
                        type: "hidden",
                        value: this.field?.examplesModel,
                    },
                    {
                        name: "column",
                        type: "hidden",
                        value: this.name,
                    },
                    {
                        name: "title",
                        value: this.example?.title ?? '',
                        required: true,
                        validate: ["hasLetter", "scaryChars"],
                        focused: true,
                    },
                    {
                        name: "content",
                        type: "richtext",
                        value: this.example?.content ?? '',
                        required: true,
                        toolbar: [
                            'bulletedList', 
                        ],
                    },
                ],
            }
        },
    },
}
</script>

<style>

</style>