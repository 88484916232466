<template>
    <!-- modal to display when we are nearing our logout time -->
    <base-modal
        :draggable="true"
        :open="showModal"
        :hideClose="false"
        class="w-lg"
        icon="clipboard-list-check"
        :icon2="taskData.completed_at ? 'circle-check' : ''"
        @close="onClose"
        :title="headerTitle"
    >
        <p v-if="clientName">Client: {{ clientName }}</p>
        <div v-if="createMode || editMode">
            <div>
                Task:
                <span style="color: red">*</span>

                <select
                    @change="onSelectTaskType($event)"
                    id="task"
                    :value="taskData.name"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                >
                    <option style="display: none" selected>Select Task</option>
                    <option
                        v-for="task in taskTypes"
                        :key="task.type"
                        :value="task.type"
                    >
                        {{ task.type }}
                    </option>
                </select>
                <input
                    class="mt-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    type="text"
                    placeholder="Task name"
                    v-model="taskData.name"
                    v-if="showCustomNameInput"
                />
                <div
                    v-if="!validName && showErrors"
                    style="color: red; font-size: 12px; font-style: italic"
                >
                    Name can not be empty
                </div>
            </div>
            <div class="mt-4">
                Description:
                <br />
                <textarea
                    v-model="taskData.message"
                    id="message"
                    rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Description"
                ></textarea>
            </div>
            <div class="mt-4">
                  Assignees:
                  <span style="color: red">*</span>
                
                <div class="flex">
                    <select
                        @change="onSelectAssignee($event)"
                        id="task"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-4/5 mr-4 p-2.5"
                    >
                        <option style="display: none" selected>
                            Select Assignees
                        </option>
                        <option
                            v-for="admin in filteredAdmins"
                            :key="admin.id"
                            :value="admin.id"
                        >
                            {{ admin?.full_name }}
                        </option>
                    </select>
                    <primary-button class="rounded-lg w-1/5" @click="onClickAssignToMe()">Assign to me</primary-button>
                </div>
                <div
                    v-if="!validAssignees && showErrors"
                    style="color: red; font-size: 12px; font-style: italic"
                >
                    Assignees can not be empty
                </div>
                <div class="flex flex-wrap gap-2 mt-2">
                    <div
                        class="chip"
                        v-for="admin in taskData.assignees"
                        :key="admin"
                    >
                        <span v-if="adminUsers.length > 0">
                            {{ editAndCreateAdminName(admin) }}
                        </span>
                        <i
                            class="fa fa-times cursor-pointer ml-2"
                            @click="onRemoveAssignee(admin)"
                            aria-hidden="true"
                        ></i>
                    </div>
                </div>
            </div>
            <div class="mt-4 flex items-end">
                <div>
                    Due Date:
                    <span style="color: red">*</span>

                    <br />
                    <div class="relative">
                        <div
                            class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
                        >
                            <svg
                                aria-hidden="true"
                                class="w-5 h-5 text-gray-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </div>
                        <input
                            datepicker
                            type="date"
                            v-model="taskData.due_by"
                            class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                            placeholder="Select date"
                        />
                    </div>
                    <div
                        v-if="!validDueDate && showErrors"
                        style="color: red; font-size: 12px; font-style: italic"
                    >
                        Due date can not be empty
                    </div>
                </div>
                <div>
                    <div class="flex items-center mb-4 ml-4">
                        <input
                            id="default-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 accent-pink-500"
                            v-model="taskData.priority"
                        />
                        <label
                            for="default-checkbox"
                            class="ml-2 text-sm font-medium text-gray-900"
                        >
                            High Priority
                        </label>
                    </div>
                </div>
                <div v-if="createMode">
                    <div class="flex items-center mb-4 ml-4">
                        <input
                            id="completed-checkbox"
                            type="checkbox"
                            value=""
                            class="w-4 h-4 accent-pink-500"
                            v-model="taskData.completed"
                        />
                        <label
                            for="completed-checkbox"
                            class="ml-2 text-sm font-medium text-gray-900"
                        >
                            Mark as Completed
                        </label>
                    </div>
                </div>
            </div>
            <div class="mt-4 flex items-center justify-end">
                <secondary-button size="small" class="ml-2" @click="onClose">
                    Cancel
                </secondary-button>
                <primary-button size="small" class="ml-2" @click="onSubmit()">
                    Save
                </primary-button>
            </div>
        </div>
        <div v-else>
            <div
                class="created-by-text mb-1"
                v-if="taskData.initiation_type"
            >
                Created By:
                {{
                    taskData.initiation_type == "human"
                        ? taskData.initiator?.full_name
                        : "SYSTEM"
                }}
                - {{ readableDate(taskData.created_at) }}
            </div>
            <div class="created-by-text" v-if="taskData.reopened_at">
                Reopened At:
                {{ readableDate(taskData.reopened_at) }}
            </div>
            <div>
                Task:
                <div class="read-only-name">
                    {{ taskData.name }}
                </div>
            </div>
            <div class="mt-4 text-[#78d218]" v-if="taskData.completed_by">
                Completed by: 
                  {{ taskData.completer?.full_name }} on {{ completedDate }}
                
            </div>
            <div class="mt-4">
                Description:
                <div class="read-only-description">
                    {{
                        taskData.message?.length > 0
                            ? taskData.message
                            : "No description"
                    }}
                </div>
            </div>
            <div class="mt-4">
                Assignees:
                <div class="flex flex-wrap gap-2 mt-2">
                    <div
                        class="chip"
                        v-for="admin in taskData.assignees"
                        :key="admin"
                    >
                        <span v-if="admin.user">
                            {{ admin.user?.full_name }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="mt-4 flex items-end">
                <div class="read-only-due">
                    Due Date:
                    {{ taskData.due_by }}
                </div>
                <span class="priority-icon" v-if="taskData.priority">
                    <i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                    ></i>
                    High Priority
                </span>
            </div>
            <div class="mt-4">
                <div
                    class="mb-2"
                    v-if="taskData?.comments?.length > 0"
                >
                    Comments:
                </div>
                <Transition
                    v-for="comment in taskData.comments"
                    :key="comment.id"
                >
                    <div class="comment">
                        {{ comment.message }}
                        <br />
                        <div class="comment-footer flex justify-between items-center">
                            <div>{{ comment.user?.full_name }}</div>
                            <div>{{ readableDate(comment.created_at) }}</div>
                            <div class="trash-icon">
                                <confirm-button
                                    v-if="commentIsUsers(comment)"
                                    @confirmed="onDeleteComment(comment)"
                                    icon="trash"
                                    title="Delete this comment"
                                    class="text-red-500"
                                    message="Are you sure you want to delete your comment?"
                                    :hideBorder="true"
                                >
                                    Delete
                                </confirm-button>
                            </div>
                        </div>
                    </div>
                </Transition>
                <div v-if="!showAddComment" class="text-center">
                    <primary-button
                        class="text-sm"
                        @click="clickedAddComment"
                        icon="plus"
                    >
                        Add comment
                    </primary-button>
                </div>
                <info-alert v-else :icon="false">
                    <textarea
                        v-model="commentText"
                        id="message"
                        rows="4"
                        class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Comment..."
                        :disabled="isClosed"
                    ></textarea>
                    <div class="text-center">
                        <primary-button
                            size="small"
                            class="ml-2 mt-4"
                            @click="onSubmitComment()"
                            :disabled="
                                isClosed || commentText.length == 0 || sending
                            "
                        >
                            Save Comment
                        </primary-button>
                        <secondary-button
                            size="small"
                            class="ml-2 sm:bg-secondary-100"
                            :disabled="isClosed"
                            @click="showAddComment = false"
                        >
                            Cancel
                        </secondary-button>
                    </div>
                </info-alert>
            </div>
            <div
                class="mt-4 flex items-center jusify-end"
                ref="button-container"
            >
                <confirm-button
                    role="danger"
                    size="small"
                    class="ml-2"
                    :disabled="isClosed"
                    @confirmed="onClickDeleteTask()"
                    component="primary-button"
                    title="Delete this task"
                    message="Are you sure you want to delete this task?"
                    confirmText="Yes, Delete"
                >
                    Delete
                </confirm-button>
                <secondary-button
                    size="small"
                    class="ml-2"
                    :disabled="isClosed"
                    @click="onClickEditTask()"
                >
                    Edit
                </secondary-button>
                <primary-button
                    size="small"
                    class="ml-2"
                    :disabled="isClosed"
                    @click="onClickMarkAsDone()"
                    v-if="!taskData.completed_at"
                >
                    Mark as Completed
                </primary-button>
                <primary-button
                    size="small"
                    class="ml-2"
                    @click="onClickReopen()"
                    v-else
                >
                    Re-open
                </primary-button>
                <secondary-button
                    size="small"
                    class="ml-2"
                    @click="onClickGoToApp()"
                >
                    Go to Application
                </secondary-button>

                <!-- Go to Application in a new tab -->
                <router-link
                        target="_blank"
                        :to="{
                            name: 'application.overview',
                            params: { alias: taskData?.application?.alias },
                        }"
                    >
                        <span class="fad fa-arrow-up-right-from-square ml-2"></span>
                </router-link>
            </div>
        </div>
    </base-modal>
    <confirmation-modal
        :open="showConfirmModal"
        @declined="() => (showConfirmModal = false)"
        @confirmed="onCloseConfirmed"
        message="Are you sure you want to close? You will lose your changes"
        confirmText="Close and Discard Changes"
        cancelText="Cancel"
        confirmButtonRole="primary"
        cancelButtonRole="secondary"
    ></confirmation-modal>
</template>

<script>
import dateService from "@/services/utils/dateFormatterService";

export default {
    data() {
        return {
            showConfirmModal: false,
            selectTaskName: "",
            commentText: "",
            showCustomNameInput: false,
            showErrors: false,
            showAddComment: false,
            sending: false,
            formUpdated: false,
            taskData: {
                name: "",
                message: "",
                assignees: [],
                priority: false,
                due_by: null,
                alias: null,
                application_id: null,
                completed: false,
            },
            initialTaskData: {
                name: "",
                message: "",
                assignees: [],
                priority: false,
                due_by: null,
                alias: null,
                application_id: null,
                completed: false
            },
        };
    },
    methods: {
        async clickedAddComment() {
            this.showAddComment = true;
            await this.$nextTick();
            this.scrollToSubmitButton();
        },
        scrollToSubmitButton() {
            const btnContainer = this.$refs["button-container"];
            if (btnContainer) {
                btnContainer.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }
        },
        editAndCreateAdminName(admin) {
            let user = admin.user ? admin.user : admin;
            return user.full_name;
        },
        commentIsUsers(comment) {
            return comment.user?.id === this.user?.id;
        },
        readableDate(date) {
            return dateService.humanReadableWithTime(date);
        },
        async onSubmitComment() {
            let body = {
                taskId: this.taskData.id,
                message: this.commentText,
            };
            if (this.commentText.length <= 0) {
                return;
            }
            this.sending = true;
            let response = await this.$store.dispatch(
                "tasks/createTaskComment",
                body
            );
            this.sending = false;
            this.showAddComment = false;
            this.commentText = "";
            this.taskData.comments.unshift(response);
        },
        async onDeleteComment(comment) {
            let body = {
                commentId: comment.id,
            };
            await this.$store.dispatch("tasks/deleteTaskComment", body);
            let index = this.taskData.comments.findIndex(
                (x) => x.id == comment.id
            );
            this.taskData.comments.splice(index, 1);
        },
        onSelectAssignee(event) {
            let value = parseInt(event.target.value);
            value = this.adminUsers.find((x) => x.id == value);
            this.taskData.assignees.push(value);
        },
        onClickAssignToMe() {
            if (!this.taskData.assignees.find((x) => x.id == this.user.id)) {
                this.taskData.assignees.push(this.user);
            }
        },
        onRemoveAssignee(value) {
            let index = this.taskData.assignees.indexOf(value);
            this.taskData.assignees.splice(index, 1);
        },
        onSelectTaskType(selectedType) {
            let value = selectedType.target.value;
            this.selectTaskName = value;
            if (value === "Other") {
                this.showCustomNameInput = true;
                this.taskData.name = "";
                this.taskData.message = "";
            } else {
                this.showCustomNameInput = false;
                this.taskData.name = value;
                this.taskData.message =
                    this.taskTypes.find((x) => x.type == value).description ??
                    "";
            }
        },
        onClickEditTask() {
            this.$store.commit("tasks/setEditMode", true);
        },

        onClickGoToApp() {
            this.$store.dispatch("tasks/getClickedTask", {
                task_click_through_id: this.taskData.id,
                fromApplication: this.taskData?.application.id,
            });

            this.$router.push({
                name: "application.overview",
                params: { alias: this.taskData?.application.alias },
                query: { task_click_through: this.taskData.id },
            });

            this.onCloseConfirmed();
        },
        onClickMarkAsDone() {
            this.$store.dispatch("tasks/markAsDone", this.taskData);
            this.onCloseConfirmed();
        },
        onClickReopen() {
            this.taskData.assignees = this.taskData.assignees.map((x) =>
                x.user_id ? x.user_id : x.id
            );
            this.$store.dispatch("tasks/reopen", this.taskData);
            this.onCloseConfirmed();
        },
        onClickDeleteTask() {
            this.$store.dispatch("tasks/deleteTask", this.taskData);
            this.onCloseConfirmed();
        },
        onSubmit() {
            if (!this.valid) {
                this.showErrors = true;
                return;
            }
            this.taskData.assignees = this.taskData.assignees.map((x) =>
                x.user_id ? x.user_id : x.id
            );
            if (this.editMode) {
                this.$store.dispatch("tasks/editTask", this.taskData);
            }
            if (this.createMode) {
                this.$store.dispatch("tasks/createTask", this.taskData);
            }
            this.onCloseConfirmed();
        },
        onClose() {
            if (this.dataHasChanged) {
                this.showConfirmModal = true;
            } else {
                this.onCloseConfirmed();
            }
        },
        onCloseConfirmed() {
            this.showConfirmModal = false;
            this.commentText = "";
            this.showErrors = false;
            this.$store.dispatch("tasks/closeTaskModal");
        },
        reset() {
            // At the moment we have only discovered that we must reset the showSubmitComment. If we discover
            // that we must reset other data, it should be done here
            this.sending = false;
            this.showAddComment = false;
        },
    },
    watch: {
        selectedTask: {
            handler(taskValue) {
                let value = JSON.parse(JSON.stringify(taskValue));
                if (!value) return;
                this.taskData = {
                    name: null,
                    message: "",
                    assignees: [],
                    priority: false,
                    due_by: null,
                    alias: null,
                    application_id: null,
                    completed: false,
                };
                let keys = Object.keys(value);
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    if (key == "assignees") {

                        //remove null users from assignees list
                        const filteredAssignees = value[key].filter(item => item.user !== null);
                        this.taskData[key] = filteredAssignees;                                  
                        continue;
                    }
                    if (key == "due_by") {
                        this.taskData[key] = dateService.formatForBackend(
                            value[key]
                        );
                        continue;
                    }
                    if (key == "priority") {
                        this.taskData[key] = !!value[key];
                        continue;
                    }

                    //clean any html tags before displaying text
                    if (key == "message" && this.taskData[key] != '') {
                        this.taskData[key] = value[key].replace(/(<([^>]+)>)/gi, '');
                        continue;
                    }

                    this.taskData[key] = value[key];
                }
                this.initialTaskData = { ...this.taskData };
                
                // Need to specifically deep copy the assignees array
                this.initialTaskData['assignees'] = [...this.taskData.assignees];
            },
            deep: true,
        },
        showModal(value) {
            // Since we are not re-mounting the component every time we
            // open/close it, we have to watch this property and launch a reset
            // of the component internal data here
            this.reset();
            if (value) {
                this.taskData = {
                    name: null,
                    message: "",
                    assignees: [],
                    priority: false,
                    due_by: null,
                    alias: null,
                    application_id: null,
                    completed: false,
                };
                this.$store.dispatch("tasks/getAllAdmins");
            }
        },
    },
    computed: {
        taskTypes() {
            return this.$store.getters["tasks/taskTypes"];
        },
        dataHasChanged() {
            return (
                this.taskData.name !== this.initialTaskData.name ||
                this.taskData.message !== this.initialTaskData.message ||
                this.taskData.priority !== this.initialTaskData.priority ||
                this.taskData.due_by !== this.initialTaskData.due_by ||
                this.taskData.alias !== this.initialTaskData.alias ||
                this.taskData.completed !== this.initialTaskData.completed ||
                this.taskData.application_id !==
                    this.initialTaskData.application_id ||
                JSON.stringify(this.taskData.assignees) !== JSON.stringify(this.initialTaskData.assignees)
                
            );
        },
        valid() {
            return this.validAssignees
                && this.validDueDate 
                && this.validName;
        },
        createAndEditAssignees() {
            if (this.editMode) {
                return this.taskData.assignees.map((x) =>
                    x.user_id ? x.user_id : x
                );
            }
            return this.taskData.assignees;
        },
        isClosed() {
            return this.$store.getters["applications/currentIsClosed"];
        },
        validName() {
            return this.taskData.name && this.taskData.name.length > 0;
        },
        validAssignees() {
            return this.taskData.assignees.length > 0;
        },
        validDueDate() {
            return !!this.taskData.due_by;
        },
        completedDate(){
            return dateService.fullText(this.taskData.completed_at);
        },
        filteredAdmins() {
            if (!this.adminUsers) return [];
            let assignees = this.taskData.assignees.map((x) =>
                x.user_id ? x.user_id : x.id
            );
            return this.adminUsers
                .filter((x) => assignees.indexOf(x.id) === -1)
                .sort((a, b) => {
                    if (
                        a.first_name.toLowerCase() < b.first_name.toLowerCase()
                    ) {
                        return -1;
                    }
                    if (
                        a.first_name.toLowerCase() > b.first_name.toLowerCase()
                    ) {
                        return 1;
                    }
                    return 0;
                });
        },
        user() {
            return this.$store.getters.user;
        },
        showModal() {
            return this.$store.getters["tasks/showTaskModal"];
        },
        selectedTask() {
            return this.$store.getters["tasks/selectedTask"];
        },
        editMode() {
            return this.$store.getters["tasks/editMode"];
        },
        createMode() {
            return this.$store.getters["tasks/createMode"];
        },
        adminUsers() {
            if (!this.$store.getters["tasks/adminUsers"]) return [];
            return this.$store.getters["tasks/adminUsers"];
        },
        headerTitle() {
            if (this.createMode) {
                return "Create Task";
            }
            if (this.editMode) {
                return "Edit Task";
            }
            if (this.selectedTask && this.selectedTask.name) {
                return this.selectedTask.name;
            }
            return "";
        },
        clientName(){
            if(this.taskData.application?.basic_client_info){
                return this.taskData.application.basic_client_info.first_name + ' ' + this.taskData.application.basic_client_info.last_name;
            }
            return false;
        },
    },
};
</script>
<style scoped>
.chip {
    border: 1px solid rgb(204, 239, 169);
    padding: 8px;
    border-radius: 8px;
}
.read-only-name {
    font-weight: 700;
    margin-top: 4px;
}
.read-only-description {
    margin-top: 4px;
    font-size: 14px;
    color: gray;
    white-space: pre-line;
}
.read-only-due {
    margin-top: 4px;
    font-size: 14px;
    color: gray;
    font-style: italic;
}

.priority-icon {
    position: absolute;
    top: 70px;
    right: 8px;
    color: red;
}
.comment {
    border: 1px solid rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 16px;
    box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.07);
    white-space: pre-wrap;
    position: relative;
}
.comment-footer {
    font-size: 12px;
    font-style: italic;
    margin-top: 16px;
}
.v-enter-active {
    transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
.trash-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    color: red;
    cursor: pointer;
}
.trash-icon:hover {
    text-decoration: underline;
}
.created-by-text {
    margin-bottom: 12px;
    font-size: 10px;
    color: gray;
}
</style>
