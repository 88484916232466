export default {
    namespaced: true,

    state() {
        return {};
    },

    mutations: {},

    actions: {},

    getters: {
        slpAddendum(_, __, rootState) {
            return rootState.applications.current?.slp_addendum_info?.slp_addendum ?? {};
        },

        slpAddendumInfo(_, __, rootState) {
            return rootState.applications.current?.slp_addendum_info ?? {};
        },

        sections(_state, getters, _rootState, _rootGetters) {
            const sections = {
                overview: getters.slpAddendumInfo?.status_id,
            };

            return sections;
        },
    },
};
