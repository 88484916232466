<template>
    <button 
        v-if="showButton"
        class="link--primary ml-1 text-sm"
        :class="{ 'text-red-500': !examples.length }"
        type="button"
        @click="openExamples = true"
    >
        {{ linkText }}
    </button>

    <!-- example modals-->
    <base-modal 
        :open="openExamples"
        @close="openExamples = false"
        class="md:w-md"
    >
        <template #header v-if="isOwnerAdmin">
            <div class="flex gap-4 w-full">
                <primary-button 
                    size="small"
                    @click="openNewModal = true"
                    class="mr-auto"
                >
                    Create New Example
                </primary-button>

                <base-button
                    size="small"
                    class="text-xl"
                    @click="openExamples = false"
                >
                    <i class="fas fa-times"></i>
                </base-button>
            </div>

        </template>

        <div class="text-left grid gap-5 pb-4">
            <!-- description -->
            <div v-if="exampleDescription !== false" class="italic text-blueGray-600">{{ exampleDescription }}</div>
            
            <!-- items -->
            <example-item 
                v-for="example in examples" 
                :key="example.id"
                :field="field"
                :example="example"        
            />
        </div>
    </base-modal>

    <!-- create new example modal -->
    <example-modal 
        v-if="openNewModal"
        :field="field"
        @close="openNewModal = false"
    />
</template>

<script>
import ExampleItem from './ExampleItem.vue';
import ExampleModal from './ExampleModal.vue';

export default {
    components: {
        ExampleItem,
        ExampleModal,
    },

    props: {
        field: {
            type: Object,
            required: true,
        },
    },

    data(){
        return {
            openExamples: false,
            openNewModal: false,
        };
    },

    created(){
        this.$store.dispatch('example/loadExamples', { model: this.field.examplesModel });
    },

    computed: {
        name(){
            return this.field?.examplesForceName ?? this.field?.name;
        },

        examples(){
            return this.$store.getters['example/examples'][this.field.examplesModel]?.[this.name] ?? [];
        },

        linkText(){
            return this.examples?.length ? 'View Examples' : 'Add Examples';
        },

        isOwnerAdmin(){
            return this.$store.getters.isOwnerAdmin;
        },
        
        showButton(){
            return this.isOwnerAdmin || this.examples?.length;
        },

        exampleDescription() {
            return this.field.exampleDescription ?? "Example text is provided to help therapists understand the intention of the question and shouldn't be used in place of your own words when writing a clinical evaluation.";
        },
    },
}
</script>

<style>

</style>