import axios from "axios";

// import and register interceptor services
import spinnerInterceptorService from "./httpInterceptors/spinnerInterceptorService";
import notificationInterceptorService from "./httpInterceptors/notificationInterceptorService";
import bearerTokenInterceptor from "./httpInterceptors/bearerTokenInterceptor";
import overrideApplicationLockInterceptor from "./httpInterceptors/overrideApplicationLockInterceptor";
import openSignedUrlInterceptor from "./httpInterceptors/openSignedUrlInterceptor";
import unauthorizedRedirectInterceptor from "./httpInterceptors/unauthorizedRedirectInterceptor";
import httpCounterInterceptor from "./httpInterceptors/httpCounterInterceptor";
import hasNewTaskInterceptor from "./httpInterceptors/hasNewTaskInterceptor";

const interceptors = [
    spinnerInterceptorService,
    notificationInterceptorService,
    bearerTokenInterceptor,
    hasNewTaskInterceptor,
    overrideApplicationLockInterceptor,
    unauthorizedRedirectInterceptor,
    openSignedUrlInterceptor,
    httpCounterInterceptor
];

// set our base configs
const axiosConfig = {
    baseURL:
        process.env.VUE_APP_API_BASE_URL +
        process.env.VUE_APP_API_VERSION +
        "/",
    headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
    },
    crossDomain: true,
};

/**
 * Returns a new http instance
 *
 * @param {object} context
 * @property {Router} router
 * @property {Store} store
 */
function createHttp({ router, store }) {
    const http = axios.create(axiosConfig);

    //apply our interceptor services to our axios instance
    for (const interceptor of interceptors) {
        interceptor(http, { router, store });
    }

    return http;
}

export default createHttp;
