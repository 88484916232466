import objectPropertiesHaveValue from "../services/utils/objectPropertiesHaveValue";
const nonSignatureSections = [
    "background",
    "upload",
    "overview",
    "physicalAbilities",
    "hearingAndVision",
    "languageSkills",
    "literacy",
    "cognitiveAbilities",
    "communicationNeeds",
    "otherApproaches",
    "deviceFeatures",
    "languageFeatures",
    "devicesRuledOut",
    "deviceSelected",
    "treatmentGoals",
];

export default {
    namespaced: true,

    state() {
        return {};
    },

    mutations: {},

    actions: {},

    getters: {
        evaluation(_, __, rootState){
            return rootState.applications.current?.evaluation_info?.evaluation ?? {};
        },
        sections(_state, getters, _rootState, _rootGetters) {
            //const COMPLETED = rootGetters.getStatusId("COMPLETED");
            //const IN_PROGRESS = rootGetters.getStatusId("IN_PROGRESS");

            let finalize = getters.evaluation?.signatures?.status_id;
            const sections = {
                finalize: finalize,
            };

            // cycle through our non-signature sections and forward to the appropriate getter
            nonSignatureSections.forEach(
                (section) => (sections[section] = getters[section])
            );

            return sections;
        },

        /**
         * Is this evaluation overview complete?
         *
         * @returns int (status_id)
         */
        overview(_state, _getters, _rootState, rootGetters) {
            return rootGetters["applications/evaluationType"] === null
                ? rootGetters.getStatusId("NOT_STARTED")
                : rootGetters.getStatusId("COMPLETED");
        },

        /**
         * Is this evaluation upload complete?
         *
         * @returns int (status_id)
         */
        upload(_state, _getters, rootState, rootGetters) {
            const uploads = rootState.applications.current?.evaluation_info?.uploads ?? [];
            const hasUploads =  uploads.length 
                && uploads.some(upload => upload.status_id !== rootGetters.getStatusId("REJECTED"));

            return hasUploads ? rootGetters.getStatusId("COMPLETED") : rootGetters.getStatusId("NOT_STARTED");
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        background(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "client_intro",
                "abilities_determined_by",
                "length_of_impairment",
                "course_of_impairment",
                "time_frame_of_impairment",
                "prognosis_for_speech_production",
                "anticipated_future_course_of_impairment",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        physicalAbilities(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "fine_motor_skills_description",
                "access_methods_considered",
                "ambulation_status",
                "mounting_system_required_for_transport",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        hearingAndVision(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "history_of_hearing_impairment", //required
                "history_of_vision_impairment",
            ];

            if (evaluation.history_of_hearing_impairment) {
                properties.push("hearing_sgd_modifications");
            }

            if (evaluation.history_of_vision_impairment) {
                properties.push("vision_sgd_modifications");
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        languageSkills(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "client_understands_verbal",
                "receptive_language_skills",
                "client_understanding_vs_ability_to_communicate",
                "clients_speech_skills_and_expressive_language_skills",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        literacy(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "education_status",
                "functional_reading_level",
                "method_of_message_production_sgd",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns int (status_id)
         */
        cognitiveAbilities(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "informal_assessment_and_observation",
                "cognitive_observations_narrative",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        communicationNeeds(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "to_communicate_with_the_following_partners",
                "to_communicate_in_the_following_environments",
                "to_communicate_in_the_following_situations",
                "to_communicate_messages_convey_ideas",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        otherApproaches(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "ability_to_meet_communication_needs_non_sgd",
                "speech_therapy_to_improve_functional_speech",
                "explain_why_low_tech_approaches_have_been_ruled_out",
            ];

            if (
                evaluation.ability_to_meet_communication_needs_non_sgd ===
                "other"
            ) {
                properties.push(
                    "ability_to_meet_communication_needs_non_sgd_other"
                );
            }

            if (
                evaluation.speech_therapy_to_improve_functional_speech ===
                "other"
            ) {
                properties.push(
                    "speech_therapy_to_improve_functional_speech_other"
                );
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        deviceFeatures(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "sgd_screen_size_needed",
                "sgd_access_methods",
                "sgd_extra_options",
                "sgd_requires_voice_options_because",
            ];

            if (evaluation.sgd_access_methods === "manual_direct_selection") {
                properties.push("sgd_options_keyguard_or_touchguide_needed");
            }

            if (evaluation.sgd_access_methods === "switch_scanning") {
                properties.push("sgd_options_switches_needed");
            }

            if (evaluation.sgd_access_methods === "combination") {
                properties.push("sgd_options_combination_access_needed");
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        languageFeatures(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "sgd_method_of_language",
                "sgd_type_of_message",
            ];

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        devicesRuledOut(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "was_ipad_with_communication_app_considered",
                "sgd_considered_but_ruled_out",
                "sgd1_ruled_out_reason",
                "sgd_considered_but_ruled_out2",
                "sgd2_ruled_out_reason",
            ];

            if (evaluation.was_ipad_with_communication_app_considered) {
                properties.push(
                    "additional_ipad_software_features_are_required"
                );
            }

            if (
                Array.isArray(
                    evaluation.additional_ipad_software_features_are_required
                ) &&
                evaluation.additional_ipad_software_features_are_required.includes(
                    "other"
                )
            ) {
                properties.push(
                    "additional_ipad_software_features_are_required_other"
                );
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        deviceSelected(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "sgd_selected_for_trial_purchase",
                "language_system",
                "sgd_selected_description",
            ];

            if (evaluation.accessories_selected?.length) {
                properties.push("accessories_selected_description");
            }

            if (
                evaluation.required_mounts?.length ||
                evaluation.wheelchair_mount_product_name ||
                evaluation.wheelchair_make ||
                evaluation.wheelchair_model
            ) {
                properties.push("mounts_selected_description");
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },

        /**
         * Is this evaluation section complete?
         *
         * @returns boolean
         */
        treatmentGoals(_state, getters, _rootState, _rootGetters) {
            const evaluation = getters.evaluation;
            const properties = [
                "treatment_plan",
                "number_of_recommended_treatment_sessions",
                "treatment_setting",
            ];
            // If written based treatment goal we just check for a value in the field

            if (evaluation.using_written_treatment_goals) {
                properties.push("written_treatment_goals")
            } else {

            
                // get each functional_treatment_goal property that has a non-null value
                const treatmentGoalsSelected = Object.values(
                    evaluation.functional_treatment_goals ?? {}
                ).filter((goal) => goal).length > 0;

                // Otherwise we need at least one custom goal
                const customTreatmentGoalsSelected = Object.values(
                    evaluation.functional_treatment_goals_other ?? {}
                ).filter((goal) => goal && goal.length).length > 0;
                

                // "id" and "foo" properties are a hacky way to push a 'false' and a 'true'
                // to the array, since an evaluation will always have an id, and it will never have the foo 
                // property.
                if (!treatmentGoalsSelected && !customTreatmentGoalsSelected) {
                    properties.push("foo");
                } else {
                    properties.push("evaluation_info_id");
                }
            }

            return objectPropertiesHaveValue(evaluation, properties);
        },
    },
};
