<template>
    <base-alert type="error" class="text-red-700 border-red-700 bg-red-100" :icon="icon" @close="$emit('close')">
        <slot></slot>
    </base-alert>
</template>

<script>
export default {
    emits: ["close"],
    props: {
        icon: {
            default: "bomb",
        },
    },
};
</script>
