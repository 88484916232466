<template>
    <li 
        class="px-3 py-1 cursor-pointer hover:bg-secondary-200" 
        :class="computedClasses"
        @mousedown="selectValue"
    >
        {{ value }}
    </li>
</template>

<script>
export default {
    props: {
        option: {
            type: Object,
        },
        index: {
            type: Number,
        },
        column: {
            type: String,
        },
        optionIndex: {
            type: Number,
        },
        displayTransform: {
            type: Function,
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        value() {
            return this.computedDisplayTransform(this.option);
        },
        
        computedDisplayTransform(){
            if(this.displayTransform){
                return this.displayTransform;
            }

            return this.defaultTransform;
        },

        computedClasses() {
            return { 
                "bg-secondary-200": this.index === this.optionIndex, 
            };
        },
    },

    methods: {
        selectValue(){
            this.$emit('selected', this.option);
        },

        defaultTransform(option){
            return option[this.column];
        },
    }
}
</script>

<style>

</style>